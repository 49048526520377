import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FormData, PageMeta } from "types/Form";
import { ColorCodes } from "types/Form";
import { User } from "types/Auth";
import Themes from "./Themes";
import { debounce, googleFormIcon } from "utils";
import Avatar from "components/Avatar";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import ToolTip from "components/ToolTip";
import { useParams } from "react-router-dom";
import styles from "./Header.module.scss";
import style from "../FormBuilder.module.scss";
import { createPreForm } from "services/PreEditForm";
import ThemeToggle from "Dashboard/components/ThemeToggle";
import { FaEdit } from "react-icons/fa";
import { AiOutlineAudit } from "react-icons/ai";
import avstyle from "components/Avatar/Avatar.module.scss";

type HeaderProps = {
  formId?: string;
  params: URLSearchParams;
  colorCode: ColorCodes;
  bgCode: string;
  title: string;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  user: User | null;
  logout: () => void;
  navigate: NavigateFunction;
  handleTheme: (data: { colorCode: ColorCodes; bgCode: string }) => void;
  handleTitle: (title: string) => void;
};

const AuditHeader = ({
  formId,
  params,
  colorCode,
  bgCode,
  title,
  user,
  sidebarOpen,
  setSidebarOpen,
  navigate,
  logout,
  handleTheme,
  handleTitle,
}: HeaderProps) => {
  const [forms, setForms] = useState<FormData[]>([]);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const url = window.location.href;
  const action = url.substr(url.lastIndexOf('/') + 1);
  const tabs = ["Pre Audit", "Audit Template", "Audit Items"] as const;
  // const {formIdentity} = useParams();
  console.log("this is the formId", formId)
  const [hide, setHide] = useState(true);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (hide) return;
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [hide]);

  const toggleTitle = () => {
    setHide(!hide);
  };
  const navigateToForm = (formId: string) => {
    navigate(`/form/${formId}/preaudit`);
  };

  const handleChange = debounce(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (value.length === 0)
        return toast("Title should not be empty", { type: "error" });
      handleTitle(value);
    },
    500
  );

  // const handleCreateFrom = async (templateId?: string) => {
  //   // let body = templateId ? { templateId } : undefined;
  //   let body = {
  //     templateId: templateId ? templateId : undefined,
  //     auditId: formId
  //   }
  //   let { data } = await createPreForm(body);
  //   data.auditId = formId;
  //   console.log("this is the data", data);
  //   let form = [...forms];
  //   form.unshift(data);
  //   setForms(form);
  //   navigateToForm(data._id);
  // };
  console.log("this is the actions", action)
  return (
    // <div className={styles.container}>
    //   <div className={styles.top}>
    //     <div className={styles.title}>
    // <div onClick={() => navigate("/form/list")}>{googleFormIcon}</div>
    // <input
    //   ref={inputRef}
    //   name="title"
    //   defaultValue={title || "Google Form"}
    //   onChange={handleChange}
    //   onBlur={toggleTitle}
    //   hidden={hide}
    // />
    // {hide && <h1 onClick={toggleTitle}>{title}</h1>}
    //     </div>
    //     <div className={styles.icon}>
    //       <Themes
    //         colorCode={colorCode}
    //         bgCode={bgCode}
    //         onChange={handleTheme}
    //       />
    //       <i
    //         id="preview-form"
    //         className="bx-show"
    //         onClick={() => window.open(`#/form/${formId}/view`)}
    //       ></i>
    //       <ToolTip selector="#preview-form">Preview</ToolTip>
    //       <Avatar userName={user?.name} />
    //     </div>
    //   </div>
    // <ul className={styles.bottom}>
    //   {tabs.map((label, index) => {
    //     // const url = label === "Pre Audit" ? 
    //     const url =
    //       label === "Audit Items"
    //         ? `/audit_item/${formId}/list`
    //         : label === "Pre Audit"
    //           ? `/pre_audit_item/${formId}/list`
    //           : `/form/${formId}/questions`;

    //     // const url = label === "Audit Items" ? `/audit_item/${formId}/list` : `/form/${formId}/questions`;
    //     let isActive =
    //       (params.get("tab") || "audit template") === label.toLocaleLowerCase();
    //     return (
    //       <li
    //         key={index}
    //         className={`${styles.tab} ${isActive ? styles.active : ""
    //           }`.trim()}
    //         onClick={() => {
    //           navigate(url);
    //         }
    //         }
    //       >
    //         {label}
    //         {isActive && <div className={styles.indicator}></div>}
    //       </li>
    //     );
    //   })}
    // </ul>
    // </div>
    <header className="sticky top-0 bg-white dark:bg-[#182235] border-b border-slate-200 dark:border-slate-700 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
            <div onClick={() => navigate("/form/list")}>{googleFormIcon}</div>
            <input
              ref={inputRef}
              name="title"
              className="bg-transparent border-none outline-none text-lg font-bold dark:text-white "
              defaultValue={title}
              onChange={handleChange}
              onBlur={toggleTitle}
              hidden={hide}
            />
            {hide && (
              <div className="flex justify-center items-center">
                <h1 className="dark:text-white text-lg" onClick={toggleTitle}>{title}</h1>
                <FaEdit className="ml-2 dark:text-white" />
              </div>
            )
            }
          </div>

          <div>

            <div className=" border-gray-200 dark:border-gray-700">
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                {tabs.map((label, index) => {
                  // const url = label === "Pre Audit" ? 
                  const url =
                    label === "Audit Items"
                      ? `/audit_item/${formId}/list`
                      : label === "Pre Audit"
                        ? `/pre_audit_item/${formId}/list` : label === "Audit Template" ? `/form/${formId}/edit`
                          : `/post_audit_item/${formId}/list`;

                  // const url = label === "Audit Items" ? `/audit_item/${formId}/list` : `/form/${formId}/questions`;
                  let isActive =
                    (params.get("tab") || "audit template") === label.toLocaleLowerCase();
                  return (
                    <li
                      key={index}
                      className={`mr-2 cursor-pointer inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group ${label === 'Audit Template' && action === "edit" ? `${avstyle.border} ` : label === "Pre Audit" && action === "preaudit" ? `${avstyle.border} ` : label === "Audit Items" && action === "auditItem" ? `${avstyle.border} ` : ""}`}
                      onClick={() => {
                        if (action === "preaudit") {
                          return;
                        }
                        else if (action === "postaudit") {
                          return;
                        }
                        else if (action === "auditItem") {
                          return;
                        }
                        navigate(url);
                      }}
                      style={{ cursor: action === "preaudit" ? "default" : "pointer" }}
                    >
                      <AiOutlineAudit className="text-xl mr-2" />
                      {label}
                      {isActive && <div className={styles.indicator}></div>}
                    </li>

                  );
                })}

                {/* <li className="mr-2">
            <a href="#" className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>Profile
            </a>
        </li>
        <li className="mr-2">
            <a href="#" className="inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group" aria-current="page">
                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-blue-600 dark:text-blue-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>Dashboard
            </a>
        </li>
        <li className="mr-2">
            <a href="#" className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"></path></svg>Settings
            </a>
        </li>
        <li className="mr-2">
            <a href="#" className="inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path></svg>Contacts
            </a>
        </li>
        <li>
            <a className="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">Disabled</a>
        </li> */}
              </ul>
            </div>

            {/* <ul className="flex">
        {tabs.map((label, index) => {
          // const url = label === "Pre Audit" ? 
          const url =
            label === "Audit Items"
              ? `/audit_item/${formId}/list`
              : label === "Pre Audit"
                ? `/pre_audit_item/${formId}/list`
                : `/form/${formId}/questions`;

          // const url = label === "Audit Items" ? `/audit_item/${formId}/list` : `/form/${formId}/questions`;
          let isActive =
            (params.get("tab") || "audit template") === label.toLocaleLowerCase();
          return (
            <li
              key={index}
              className={`${styles.tab} ${isActive ? styles.active : ""
                }`.trim()}
              onClick={() => {
                navigate(url);
              }
              }
            >
              {label}
              {isActive && <div className={styles.indicator}></div>}
            </li>
          );
        })}
      </ul> */}
          </div>

          {/* Header: Right side */}
          <div className="flex items-center space-x-3">
            <div>
              {/* <button
                className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ml-3 ${
                  searchModalOpen && 'bg-slate-200'
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchModalOpen(true);
                }}
                aria-controls="search-modal"
              >
                <span className="sr-only">Search</span>
                <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    className="fill-current text-slate-500 dark:text-slate-400"
                    d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                  />
                  <path
                    className="fill-current text-slate-400 dark:text-slate-500"
                    d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                  />
                </svg>
              </button> */}
              {/* <SearchModal id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} /> */}
            </div>
            {/* <Notifications align="right" /> */}
            {/* <Help align="right" /> */}
            <ThemeToggle size={8} />
            {/*  Divider */}
            <hr className="w-px h-6 bg-slate-200 dark:bg-slate-700 border-none" />
            {/* <UserMenu align="right" /> */}
            <div className="flex justify-center items-center" >
              <Themes
                colorCode={colorCode}
                bgCode={bgCode}
                onChange={handleTheme}
              />
              {/* <i
            id="preview-form"
            className="bx-show text-3xl "
            onClick={() => window.open(`#/form/${formId}/view`)}
          ></i> */}
              {/* <ToolTip selector="#preview-form">Preview</ToolTip> */}
              <Avatar userName={"Admin Panel"} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AuditHeader;
