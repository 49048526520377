import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../components/DropdownFilter";
import Datepicker from "../components/Datepicker";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard08 from "../partials/dashboard/DashboardCard08";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import DashboardCard12 from "../partials/dashboard/DashboardCard12";
import DashboardCard13 from "../partials/dashboard/DashboardCard13";
import Banner from "../partials/Banner";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            {/* <WelcomeBanner /> */}
            {/* Dashboard actions */}
            {/* <div className="sm:flex sm:justify-between sm:items-center mb-8"> */}
            {/* Left: Avatars */}
            {/* <DashboardAvatars /> */}
            {/* Right: Actions */}
            {/* <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"> */}
            {/* Filter button */}
            {/* <FilterButton /> */}
            {/* Datepicker built with flatpickr */}
            {/* <Datepicker /> */}
            {/* Add view button */}
            {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                </button>                 */}
            {/* </div> */}
            {/* </div> */}
            {/* Cards */}
            <div>
              <>
                {/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
extend: {
  colors: {
    sky: colors.sky,
    blue: colors.blue,
  },
},
    },
    plugins: [
// ...
require('@tailwindcss/forms'),
    ],
  }
  ```
*/}
                <div>
                  <main className="relative dark:bg-transparent dark:text-white">
                    <div className="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
                      <div className="bg-white dark:bg-transparent rounded-lg shadow overflow-hidden">
                        <div className="divide-y divide-gray-200 dark:divide-gray-700 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                          <aside className="py-6 lg:col-span-3">
                            <nav className="space-y-1">
                              {/* Current: "bg-blue-50 border-blue-500 text-blue-700 hover:bg-blue-50 hover:text-blue-700", Default: "border-transparent text-gray-900 dark:text-white hover:bg-gray-50 dark:bg-gray-900 hover:text-gray-900 dark:text-white" */}
                              <a
                                href="#"
                                className="border-transparent text-gray-900 dark:text-white dark:text-white hover:bg-gray-50 dark:bg-gray-900 dark:hover:bg-transparent dark:hover:text-white group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                                aria-current="page"
                              >
                                {/*
            Heroicon name: outline/user-circle

            Current: "text-blue-500 group-hover:text-blue-500", Default: "text-gray-400 group-hover:text-gray-500"
          */}
                                <svg
                                  className="text-blue-500 group-hover:text-blue-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                <span className="truncate"> Profile </span>
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-900 dark:text-white dark:text-white hover:bg-gray-50 dark:bg-gray-900 dark:hover:bg-transparent dark:hover:text-white group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                              >
                                {/* Heroicon name: outline/cog */}
                                <svg
                                  className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                                <span className="truncate"> Account </span>
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-900 dark:text-white dark:text-white hover:bg-gray-50 dark:bg-gray-900 dark:hover:bg-transparent dark:hover:text-white group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                              >
                                {/* Heroicon name: outline/key */}
                                <svg
                                  className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                                  />
                                </svg>
                                <span className="truncate"> Password </span>
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-900 dark:text-white dark:text-white hover:bg-gray-50 dark:bg-gray-900 dark:hover:bg-transparent dark:hover:text-white group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                              >
                                {/* Heroicon name: outline/bell */}
                                <svg
                                  className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                  />
                                </svg>
                                <span className="truncate">
                                  {" "}
                                  Notifications{" "}
                                </span>
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-900 dark:text-white dark:text-white hover:bg-gray-50 dark:bg-gray-900 dark:hover:bg-transparent dark:hover:text-white group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                              >
                                {/* Heroicon name: outline/credit-card */}
                                <svg
                                  className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                                  />
                                </svg>
                                <span className="truncate"> Billing </span>
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-900 dark:text-white dark:text-white hover:bg-gray-50 dark:bg-gray-900 dark:hover:bg-transparent dark:hover:text-white group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                              >
                                {/* Heroicon name: outline/view-grid-add */}
                                <svg
                                  className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
                                  />
                                </svg>
                                <span className="truncate"> Integrations </span>
                              </a>
                            </nav>
                          </aside>
                          <form
                            className="divide-y divide-gray-200 lg:col-span-9 dark:text-white"
                            action="#"
                            method="POST"
                          >
                            {/* Profile section */}
                            <div className="py-6 px-4 sm:p-6 lg:pb-8">
                              <div>
                                <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                  Profile
                                </h2>
                                <p className="mt-1 text-sm text-gray-500">
                                  This information will be displayed publicly so
                                  be careful what you share.
                                </p>
                              </div>
                              <div className="mt-6 flex flex-col lg:flex-row">
                                <div className="flex-grow space-y-6">
                                  <div>
                                    <label
                                      htmlFor="username"
                                      className="block text-sm font-medium text-gray-700 dark:text-white"
                                    >
                                      {" "}
                                      Username{" "}
                                    </label>
                                    <div className="mt-1 rounded-md shadow-sm flex">
                                      <span className="bg-gray-50 dark:bg-gray-900 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                                        {" "}
                                        workcation.com/{" "}
                                      </span>
                                      <input
                                        type="text"
                                        name="username"
                                        id="username"
                                        autoComplete="username"
                                        className="focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                                        defaultValue="deblewis"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="about"
                                      className="block text-sm font-medium text-gray-700 dark:text-white"
                                    >
                                      {" "}
                                      About{" "}
                                    </label>
                                    <div className="mt-1">
                                      <textarea
                                        id="about"
                                        name="about"
                                        rows={3}
                                        className="shadow-sm focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                                        defaultValue={""}
                                      />
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">
                                      Brief description for your profile. URLs
                                      are hyperlinked.
                                    </p>
                                  </div>
                                </div>
                                <div className="mt-6 flex-grow lg:mt-0 lg:ml-6 lg:flex-grow-0 lg:flex-shrink-0">
                                  <p
                                    className="text-sm font-medium text-gray-700 dark:text-white"
                                    aria-hidden="true"
                                  >
                                    Photo
                                  </p>
                                  <div className="mt-1 lg:hidden">
                                    <div className="flex items-center">
                                      <div
                                        className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
                                        aria-hidden="true"
                                      >
                                        <img
                                          className="rounded-full h-full w-full"
                                          src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80"
                                          alt=""
                                        />
                                      </div>
                                      <div className="ml-5 rounded-md shadow-sm">
                                        <div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 dark:bg-gray-900 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500">
                                          <label
                                            htmlFor="mobile-user-photo"
                                            className="relative text-sm leading-4 font-medium text-gray-700 dark:text-white pointer-events-none"
                                          >
                                            <span>Change</span>
                                            <span className="sr-only">
                                              {" "}
                                              user photo
                                            </span>
                                          </label>
                                          <input
                                            id="mobile-user-photo"
                                            name="user-photo"
                                            type="file"
                                            className="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="hidden relative rounded-full overflow-hidden lg:block">
                                    <img
                                      className="relative rounded-full w-40 h-40"
                                      src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80"
                                      alt=""
                                    />
                                    <label
                                      htmlFor="desktop-user-photo"
                                      className="absolute inset-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:opacity-100"
                                    >
                                      <span>Change</span>
                                      <span className="sr-only">
                                        {" "}
                                        user photo
                                      </span>
                                      <input
                                        type="file"
                                        id="desktop-user-photo"
                                        name="user-photo"
                                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-6 grid grid-cols-12 gap-6">
                                <div className="col-span-12 sm:col-span-6">
                                  <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium text-gray-700 dark:text-white"
                                  >
                                    First name
                                  </label>
                                  <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    autoComplete="given-name"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                                  />
                                </div>
                                <div className="col-span-12 sm:col-span-6">
                                  <label
                                    htmlFor="last-name"
                                    className="block text-sm font-medium text-gray-700 dark:text-white"
                                  >
                                    Last name
                                  </label>
                                  <input
                                    type="text"
                                    name="last-name"
                                    id="last-name"
                                    autoComplete="family-name"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                                  />
                                </div>
                                <div className="col-span-12">
                                  <label
                                    htmlFor="url"
                                    className="block text-sm font-medium text-gray-700 dark:text-white"
                                  >
                                    URL
                                  </label>
                                  <input
                                    type="text"
                                    name="url"
                                    id="url"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                                  />
                                </div>
                                <div className="col-span-12 sm:col-span-6">
                                  <label
                                    htmlFor="company"
                                    className="block text-sm font-medium text-gray-700 dark:text-white"
                                  >
                                    Company
                                  </label>
                                  <input
                                    type="text"
                                    name="company"
                                    id="company"
                                    autoComplete="organization"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* Privacy section */}
                            <div className="pt-6 divide-y divide-gray-200">
                              <div className="px-4 sm:px-6">
                                <div>
                                  <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                    Privacy
                                  </h2>
                                  <p className="mt-1 text-sm text-gray-500">
                                    Ornare eu a volutpat eget vulputate.
                                    Fringilla commodo amet.
                                  </p>
                                </div>
                                <ul
                                  role="list"
                                  className="mt-2 divide-y divide-gray-200"
                                >
                                  <li className="py-4 flex items-center justify-between">
                                    <div className="flex flex-col">
                                      <p
                                        className="text-sm font-medium text-gray-900 dark:text-white"
                                        id="privacy-option-1-label"
                                      >
                                        Available to hire
                                      </p>
                                      <p
                                        className="text-sm text-gray-500"
                                        id="privacy-option-1-description"
                                      >
                                        Nulla amet tempus sit accumsan. Aliquet
                                        turpis sed sit lacinia.
                                      </p>
                                    </div>
                                    {/* Enabled: "bg-blue-500", Not Enabled: "bg-gray-200" */}
                                    <button
                                      type="button"
                                      className="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:bg-transparent"
                                      role="switch"
                                      aria-checked="true"
                                      aria-labelledby="privacy-option-1-label"
                                      aria-describedby="privacy-option-1-description"
                                    >
                                      {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
                                      <span
                                        aria-hidden="true"
                                        className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      />
                                    </button>
                                  </li>
                                  <li className="py-4 flex items-center justify-between">
                                    <div className="flex flex-col">
                                      <p
                                        className="text-sm font-medium text-gray-900 dark:text-white"
                                        id="privacy-option-2-label"
                                      >
                                        Make account private
                                      </p>
                                      <p
                                        className="text-sm text-gray-500"
                                        id="privacy-option-2-description"
                                      >
                                        Pharetra morbi dui mi mattis tellus
                                        sollicitudin cursus pharetra.
                                      </p>
                                    </div>
                                    {/* Enabled: "bg-blue-500", Not Enabled: "bg-gray-200" */}
                                    <button
                                      type="button"
                                      className="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:bg-transparent"
                                      role="switch"
                                      aria-checked="false"
                                      aria-labelledby="privacy-option-2-label"
                                      aria-describedby="privacy-option-2-description"
                                    >
                                      {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
                                      <span
                                        aria-hidden="true"
                                        className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      />
                                    </button>
                                  </li>
                                  <li className="py-4 flex items-center justify-between">
                                    <div className="flex flex-col">
                                      <p
                                        className="text-sm font-medium text-gray-900 dark:text-white"
                                        id="privacy-option-3-label"
                                      >
                                        Allow commenting
                                      </p>
                                      <p
                                        className="text-sm text-gray-500"
                                        id="privacy-option-3-description"
                                      >
                                        Integer amet, nunc hendrerit adipiscing
                                        nam. Elementum ame
                                      </p>
                                    </div>
                                    {/* Enabled: "bg-blue-500", Not Enabled: "bg-gray-200" */}
                                    <button
                                      type="button"
                                      className="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:bg-transparent"
                                      role="switch"
                                      aria-checked="true"
                                      aria-labelledby="privacy-option-3-label"
                                      aria-describedby="privacy-option-3-description"
                                    >
                                      {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
                                      <span
                                        aria-hidden="true"
                                        className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      />
                                    </button>
                                  </li>
                                  <li className="py-4 flex items-center justify-between">
                                    <div className="flex flex-col">
                                      <p
                                        className="text-sm font-medium text-gray-900 dark:text-white"
                                        id="privacy-option-4-label"
                                      >
                                        Allow mentions
                                      </p>
                                      <p
                                        className="text-sm text-gray-500"
                                        id="privacy-option-4-description"
                                      >
                                        Adipiscing est venenatis enim molestie
                                        commodo eu gravid
                                      </p>
                                    </div>
                                    {/* Enabled: "bg-blue-500", Not Enabled: "bg-gray-200" */}
                                    <button
                                      type="button"
                                      className="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:bg-transparent"
                                      role="switch"
                                      aria-checked="true"
                                      aria-labelledby="privacy-option-4-label"
                                      aria-describedby="privacy-option-4-description"
                                    >
                                      {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
                                      <span
                                        aria-hidden="true"
                                        className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      />
                                    </button>
                                  </li>
                                </ul>
                              </div>
                              <div className="mt-4 py-4 px-4 flex justify-end sm:px-6">
                                <button
                                  type="button"
                                  className="bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-700 dark:text-white hover:bg-gray-50 dark:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:bg-transparent"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="ml-5 bg-sky-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 dark:bg-transparent"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </>

              {/* Line chart (Acme Plus) */}
              {/* <DashboardCard01 /> */}
              {/* Line chart (Acme Advanced) */}
              {/* <DashboardCard02 /> */}
              {/* Line chart (Acme Professional) */}
              {/* <DashboardCard03 /> */}
              {/* Bar chart (Direct vs Indirect) */}
              {/* <DashboardCard04 /> */}
              {/* Line chart (Real Time Value) */}
              {/* <DashboardCard05 /> */}
              {/* Doughnut chart (Top Countries) */}
              {/* <DashboardCard06 /> */}
              {/* Table (Top Channels) */}
              {/* <DashboardCard07 /> */}
              {/* Line chart (Sales Over Time) */}
              {/* <DashboardCard08 /> */}
              {/* Stacked bar chart (Sales VS Refunds) */}
              {/* <DashboardCard09 /> */}
              {/* Card (Customers) */}
              {/* <DashboardCard10 /> */}
              {/* Card (Reasons for Refunds) */}
              {/* <DashboardCard11 /> */}
              {/* Card (Recent Activity) */}
              {/* <DashboardCard12 /> */}
              {/* Card (Income/Expenses) */}
              {/* <DashboardCard13 /> */}
            </div>
          </div>
        </main>

        <Banner />
      </div>
    </div>
  );
}

export default Dashboard;
