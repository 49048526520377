import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [selectedItemsForSecondTreeView, setSelectedItemsForSecondTreeView] = useState([]);

  return (
    <AppContext.Provider value={{ selectedItemsForSecondTreeView, setSelectedItemsForSecondTreeView }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };
