import React, { useEffect, useState, useContext } from "react";
import XLSXStyle from "xlsx-style";
import moment from "moment-timezone";
import {
  getAuditItemFormResponsesById,
  deleteResponseById,
  getAllForms,
  updateResponse,
} from "services/AuditItem";
import { FormResponses } from "types/Form";
// import styles from "./Responses.module.scss";
import { darkMode } from "Dashboard/css/tailwind.config";
import Switch from "react-switch";
import XLSX from "xlsx";
import axios from "axios";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { HoverContext } from "context/HoverContext";
import { FaHistory } from "react-icons/fa";
import { formatDate } from "utils/date";
import e from "express";
require('@tailwindcss/forms');

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

type ResponsesProps = {
  formId?: any;
  auditData?: any;
  dummyForHandleHover?: any;
  auditItemFormId?: any;
  setDummy?: any;
  dummy?: any;
};
const animatedComponents = makeAnimated();
const AuditItemResponse = ({
  formId,
  auditData,
  auditItemFormId,
  setDummy,
  dummy,
  dummyForHandleHover,
}: ResponsesProps) => {
  const [filteredNewCarStockData, setFilteredNewCarStockData] = useState(
    []
  ) as any;
  const [allSubProjectData, setAllSubProjectData] = useState([]) as any;
  let [formDetail, setFormDetail] = useState<FormResponses | null>(null);
  const [feedback, setFeedback] = useState("");
  const [feedbackRes, setFeedbackRes] = useState("");
  const [remarkRes, setRemarkRes] = useState("");
  const [clickedImage, setClickedImage] = useState() as any;
  const [value, setValue] = useState([]) as any;
  const [remark, setRemark] = useState("");
  const [headerInfo, setHeaderInfo] = useState([]) as any;
  const [dynamicDataId, setDynamicDataId] = useState("");
  const [footerInfo, setFooterInfo] = useState([]) as any;
  let [isLoading, setIsLoading] = useState(true);
  const [currentUserData, setCurrentUserData] = useState() as any;
  const [responseId, setResponseId] = useState("");
  const [dummy101, setDummy101] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [versionHistoryData, setVersionHistoryData] = useState([]) as any;
  const [statusOption, setStatusOption] = useState("");
  const [subProjectData, setSubProjectData] = useState() as any;
  const [showModalForVersion, setShowModalForVersion] = useState(false);
  let [clickedRow, setClickedRow] = useState<number | null>(null);
  const [subAuditData, setSubAuditData] = useState([]) as any;
  const [sortAscending, setSortAscending] = useState(true);
  const [auditItemsList, setAuditItemsList] = useState([]) as any;
  const [consolidated, setConsolidated] = useState(false);
  const [audits, setAudits] = useState([]) as any;
  const { auditId, subProjectId } = useParams();
  const { handleHoverCombinedData } = useContext(HoverContext);
  const navigate = useNavigate();

  const handleRemarkChange = (event: any) => {
    setRemark(event.target.textContent);
  };

  const handleRemarkUpdate = async (responseId: any) => {
    const body = {
      faRemark: remark,
    };

    await updateResponse({
      responseId,
      body,
    });
    toast("Remark updated Successfully!", {
      type: "success",
      className: "dark:bg-gray-900 dark:text-white z-[1000]",
    });
  };
  const handleDynamicRemarkUpdate = async (responseId: any) => {
    if (responseId) {
      let body = {
        faRemark: remark,
      };
      try {
        const resposne = await axios.put(
          `${BASE_URL}/api/excel_dynamic_data/${responseId}`,
          body
        );
        setDynamicDataId("");
        toast("Remark updated Successfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      } catch (error) {
        // Handle errors if any
        console.error("Error:", error);
      }
    }
  };
  const handleFeedbackResChange = (event: any) => {
    setFeedbackRes(event.target.textContent);
  };

  const handleFeedbackUpdate = async (responseId: any) => {
    const body = {
      dealerFeedback: feedbackRes,
    };

    await updateResponse({
      responseId,
      body,
    });
    toast("Feedback updated Successfully!", {
      type: "success",
      className: "dark:bg-gray-900 dark:text-white z-[1000]",
    });
  };
  const handleDynamicFeedbackUpdate = async (responseId: any) => {
    if (responseId) {
      let body = {
        dealerFeedback: feedbackRes,
      };
      try {
        const resposne = await axios.put(
          `${BASE_URL}/api/excel_dynamic_data/${responseId}`,
          body
        );
        setDynamicDataId("");
        toast("Feedback updated Successfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      } catch (error) {
        // Handle errors if any
        console.error("Error:", error);
      }
    }
  };
  const handleRemarkResChange = (event: any) => {
    setRemarkRes(event.target.textContent);
  };

  const handleRemarkResUpdate = async (responseId: any) => {
    const body = {
      dealerRemark: remarkRes,
    };

    await updateResponse({
      responseId,
      body,
    });
    toast("Remark updated Successfully!", {
      type: "success",
      className: "dark:bg-gray-900 dark:text-white z-[1000]",
    });
  };
  const handleDynamicRemarkResUpdate = async (responseId: any) => {
    if (responseId) {
      let body = {
        dealerRemark: remarkRes,
      };
      try {
        const resposne = await axios.put(
          `${BASE_URL}/api/excel_dynamic_data/${responseId}`,
          body
        );
        setDynamicDataId("");
        toast("Remark updated Successfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      } catch (error) {
        // Handle errors if any
        console.error("Error:", error);
      }
    }
  };
  const options = [
    { value: "Reopened", label: "Reopened" },
    { value: "Approved", label: "Approved" },
  ];
  useEffect(() => {
    const fetchSubProject = async () => {
      const firstResponse = await axios.get(
        `${BASE_URL}/api/subproject/${subProjectId}`
      );
      const responseData = firstResponse.data.data;
      setSubProjectData(responseData);
    };
    fetchSubProject();
  }, []);
  useEffect(() => {
    const userId = sessionStorage.getItem("user");
    if (userId) {
      const response = axios
        .get(`${BASE_URL}/user-details/${userId}`)
        .then((response) => {
          console.log("this is a user res", response.data);
          setCurrentUserData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  useEffect(() => {
    const fetchHeaderInfo = async () => {
      const firstResponse = await axios.get(`${BASE_URL}/header-info`);
      const responseData = firstResponse.data.data;
      setHeaderInfo(responseData);
    };
    fetchHeaderInfo();
  }, []);
  useEffect(() => {
    const fetchFooterInfo = async () => {
      const firstResponse = await axios.get(`${BASE_URL}/footer-info`);
      const responseData = firstResponse.data.data;
      setFooterInfo(responseData);
    };
    fetchFooterInfo();
  }, []);
  useEffect(() => {
    axios.get(
      `${BASE_URL}/api/excel_dynamic_data`
    ).then((result) => {
      const filteredData = result.data?.filter((data: any) => consolidated ? (data.subProjectId === subProjectId) : (data.auditId === formId));
      const subProjectFilteredData = result.data?.filter(
        (data: any) => data.subProjectId === subProjectId
      );
      console.log("this is describe filter data", subProjectFilteredData);
      setAllSubProjectData(subProjectFilteredData);
      setFilteredNewCarStockData(
        filteredData
      );
    }).catch((err) => {
      console.log(err);
    })
  }, [consolidated]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/version-history`)
      .then((result) => {
        setVersionHistoryData(result.data);
        console.log("thisisversionhistorydata", result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios.get(`${BASE_URL}/api/subaudit`).then((res) => {
      if (formId) {
        // const filterList = res.data.data?.filter(
        //   (form: any) => form.subAuditId === formId
        // );

        // if (filterList.length > 0) {
        const finalData = res.data.data?.filter((item: any) => {
          return item.isShown === false && item.subAuditId === auditId;
        });
        console.log("thisissubauduitdata", finalData);

        setSubAuditData(finalData);

        // } else {
        //   // Handle the case when filterList is empty
        //   console.log("filterList is empty");
        // }
      }
    });
  }, [formId, auditItemFormId, showModal2, dummy]);
  useEffect(() => {
    // console.log(`${BASE_URL}/all-details`)
    const token = sessionStorage.getItem("auth-token") as any;

    axios
      .get(
        `${BASE_URL}/use-all-details`,
        token && {
          headers: {
            "auth-token": token,
          },
        }
      )
      .then((result) => {
        setValue(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(data)
  }, [formId, auditItemFormId, showModal2, dummy]);

  useEffect(() => {
    if (!formId) return;
    getAudits();
    getFormResponse();
    getAllAuditItemsForm();
  }, [formId, auditItemFormId, dummy101]);
  const getAudits = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/audit`);

      setAudits(response.data.data);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  };

  const getFormResponse = async () => {
    if (!formId) {
      return;
    }

    try {
      let { data } = await getAuditItemFormResponsesById(
        formId,
        auditItemFormId
      );
      setFormDetail(data);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };
  const getAllAuditItemsForm = async () => {
    try {
      let { data } = await getAllForms();
      setAuditItemsList(data);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };

  if (!formDetail) return null;
  let { formResponses, fields } = formDetail;
  console.log("thisisformdetail", auditItemsList.list);
  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Define your sheet data
    const sheetData = [];

    // Add headers to the sheet data
    const headerRow = ["Timestamp", ...fields.map(({ title }) => title)];
    sheetData.push(headerRow);

    // Add data rows to the sheet data
    formResponses
      ?.filter(
        (response: any) =>
          response.subAuditId === formId &&
          response.auditItemFormId === auditItemFormId
      )
      .forEach((response) => {
        const row = [];
        row.push(
          moment
            .tz(response.createdAt, "Asia/Kolkata")
            .format("M/D/YYYY h:mm:ss a")
        );

        response.responses.forEach(({ response }) => {
          row.push(response);
        });

        sheetData.push(row);
      });

    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}`;
    // Create a Blob containing the workbook and trigger download
    XLSX.writeFile(workbook, `exported_data_${formattedDate}.xlsx`);
  };
  let indicatorColor = "gray"; // Default color
  let status = `${subAuditData[0]?.status}`;
  // Define colors for each status
  if (status === "Pending") {
    indicatorColor = "red";
  } else if (status === "Reconcile Needed") {
    indicatorColor = "yellow";
  } else if (status === "Assigned") {
    indicatorColor = "blue";
  } else if (status === "Reopened") {
    indicatorColor = "cyan";
  } else if (status === "Approved") {
    indicatorColor = "green";
  }

  // Define CSS styles for the indicator
  const indicatorStyle = {
    backgroundColor: indicatorColor,
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px",
  };
  const handleFeedbackChange = (event: any) => {
    setFeedback(event.target.value);
  };
  const handleFeedBack = async () => {
    const token = sessionStorage.getItem("auth-token");
    const config = {
      headers: {
        "auth-token": token,
      },
    };
    console.log("thisissubau", subAuditData);
    subAuditData.map((item: any) => {
      const response = axios
        .put(
          `${BASE_URL}/api/subaudits/${item.id}`,
          {
            status: "Reopened",
            dealerFeedback: feedback,
          },
          config
        )
        .then((result) => {
          console.log("this is the result", result);
          setShowModal2(false);
          setDummy(false);
          setFeedback("");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const handleApproved = async () => {
    const token = sessionStorage.getItem("auth-token");
    const config = {
      headers: {
        "auth-token": token,
      },
    };
    subAuditData.map((item: any) => {
      const response = axios
        .put(
          `${BASE_URL}/api/subaudits/${item.id}`,
          {
            status: "Approved",
          },
          config
        )
        .then((result) => {
          setShowModal2(false);
          setDummy(false);
          setFeedback("");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const generatedRecon = (formDetail: any) => {
    let { formResponses, fields } = formDetail;
    const uniqueFields = fields?.filter((field: any) => field.unique);
    console.log("thisisisformresponses", formResponses);
    try {
      if (uniqueFields?.length === 0) {
        throw new Error("No unique fields found in the formDetail object.");
      }

      const data = [
        // Remove "Timestamp" from the header row
        [
          ...uniqueFields.map((field: any) => {
            if (field.title) {
              // Replace "&nbsp;" with an empty string
              field.title = field.title.replace(/&nbsp;/g, "");

              // You can also trim the title to remove leading and trailing white spaces
              field.title = field.title.trim();
            }
            return field;
          }),
        ],
        ...formResponses
          ?.filter((item: any) =>
            consolidated
              ? item.subProjectId === subProjectId
              : item.auditId === formId
          )
          .map((responses: any, createdAt: any) => {
            const rowData = consolidated ? {} : ([] as any);
            for (const field of uniqueFields) {
              const response = responses.responses.find(
                (resp: any) => resp.fieldId === field._id
              );
              if (consolidated) {
                const value = response
                  ? Array.isArray(response.response)
                    ? response.response.join(", ")
                    : response.response
                  : "-";
                // rowData.push();
                rowData["value"] = value;
                rowData["auditId"] = responses.auditId;
                rowData["dealerAt"] = audits
                  ?.filter((item: any) => item.id === responses.auditId)[0]
                  .title.split(",")[0]
                  .trim();
              } else {
                rowData.push(
                  response
                    ? Array.isArray(response.response)
                      ? response.response.join(", ")
                      : response.response
                    : "-"
                );
              }
            }
            return rowData;
          }),
      ];

      return {
        data: data,
        uniqueFields: uniqueFields,
      };
    } catch (error) {
      toast("No unique fields found in the audit item template!", {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      return {
        data: [],
        uniqueFields: [],
      };
    }
  };
  const generatedReconForNewCarStock = (inputData: any) => {
    const uniqueFields = generatedRecon(formDetail).uniqueFields;
    console.log("thisisuniquefields", uniqueFields, inputData);
    const data = [
      // Remove "Timestamp" from the header row
      uniqueFields.map((field: any) => field.title),
      ...inputData.map((response: any) => {
        const rowData = {} as any;
        for (const field of uniqueFields) {
          const value = response.data[field.title];
          if (consolidated) {
            rowData["value"] = value !== undefined ? value : "-";
            rowData["dealerAt"] = response.dealerCode;
            rowData["faRemark"] = response.faRemark;
            rowData["documentUrl"] = response.documentUrl;
            rowData["dealerRemark"] = response.dealerRemark;
            rowData["dealerFeedback"] = response.dealerFeedback;
            rowData["dynamicDataId"] = response._id;
          } else {
            rowData["value"] = value !== undefined ? value : "-";
            rowData["faRemark"] = response.faRemark;
            rowData["documentUrl"] = response.documentUrl;
            rowData["dealerRemark"] = response.dealerRemark;
            rowData["dealerFeedback"] = response.dealerFeedback;
            rowData["dynamicDataId"] = response._id;
            // rowData.push(value !== undefined ? value : "-");
          }
        }
        if (consolidated) {
          // Add auditId to the rowData object
          rowData["auditId"] = response.auditId;
        }

        return rowData;
      }),
    ];

    return data;
  };

  const formDetailData = generatedRecon(formDetail).data;
  const formDetailData2 = generatedReconForNewCarStock(filteredNewCarStockData);
  console.log("thisiisformdetaildata", formDetailData, formDetailData2);

  const matchingSubarrays = [];
  const foundOrNotFoundList = [] as any; // Use a single array

  for (let i = 1; i < formDetailData?.length; i++) {
    let found = false;
    let dealerAt = null;
    for (let j = 1; j < formDetailData2?.length; j++) {
      if (consolidated) {
        if (
          JSON.stringify(
            formDetailData[i].value &&
            formDetailData[i].value.replace("(Scanned)", "").trim()
          ) === JSON.stringify(formDetailData2[j].value) &&
          formDetailData[i].auditId === formDetailData2[j].auditId &&
          formDetailData[i].dealerAt === formDetailData2[j].dealerAt
        ) {
          // console.log("thisiisformdetaildata", formDetailData[i], formDetailData2[j])
          found = true;
          dealerAt = formDetailData2[j].dealerAt;
          matchingSubarrays.push(formDetailData[i]);
          break;
        } else if (
          formDetailData[i].auditId === formDetailData2[j].auditId &&
          formDetailData[i].dealerAt === formDetailData2[j].dealerAt
        ) {
          dealerAt = formDetailData2[j].dealerAt;
          // break;
        }
      } else {
        if (Array.isArray(formDetailData[i])) {
          formDetailData[i] = formDetailData[i].map((item: any) => {
            if (typeof item === "string") {
              return item.replace("(Scanned)", "").trim();
            } else {
              return item;
            }
          });
        }

        if (
          JSON.stringify(formDetailData[i]) ===
          JSON.stringify([formDetailData2[j].value])
        ) {
          found = true;
          matchingSubarrays.push(formDetailData[i]);
          break;
        }
      }
    }

    if (consolidated) {
      if (found) {
        console.log("thisx", dealerAt);
        foundOrNotFoundList.push(`Found At ${dealerAt}`);
      } else {
        console.log("thisxxx", dealerAt);
        foundOrNotFoundList.push(`Excess At ${dealerAt}`);
      }
    } else {
      if (found) {
        foundOrNotFoundList.push(
          `Found At ${auditData?.title.split(",")[0].trim()}`
        );
      } else {
        foundOrNotFoundList.push(
          `Excess At ${auditData?.title.split(",")[0].trim()}`
        );
      }
    }
  }
  // foundOrNotFoundList.sort();
  // foundOrNotFoundList.reverse();
  foundOrNotFoundList.sort((a: any, b: any) => {
    if (a.startsWith("Found") && b.startsWith("Excess")) {
      return -1; // "Found" comes before "Excess"
    } else if (a.startsWith("Excess") && b.startsWith("Found")) {
      return 1; // "Excess" comes after "Found"
    } else {
      return 0; // No change if both start with the same keyword
    }
  });

  const filteredFormResponses = formResponses?.filter((response: any) =>
    consolidated
      ? formId &&
      response.auditItemFormId === auditItemFormId &&
      response.subProjectId === subProjectId
      : formId &&
      response.auditId === formId &&
      response.auditItemFormId === auditItemFormId
  );
  const maxRows = Math.max(
    formDetailData2?.length - 1,
    filteredFormResponses?.length
  );

  const combinedData = Array.from({ length: maxRows }, (_, index) => {
    const formDetail = formDetailData2[index + 1];

    let matchingResponse = null as any;

    if (
      consolidated
        ? formDetail && formDetail.value !== "-"
        : formDetail && formDetail.value !== "-"
    ) {
      matchingResponse = filteredFormResponses.find((response: any) => {
        if (consolidated) {
          return response.responses.some(
            (item: any) =>
              item.response &&
              item.response.replace("(Scanned)", "").trim() ===
              formDetail.value &&
              formDetail.value.replace("(Scanned)", "").trim() &&
              response.auditId === formDetail.auditId &&
              response.subProjectId === subProjectId
          );
        } else {
          return response.responses.some(
            (item: any) =>
              item.response &&
              item.response.replace("(Scanned)", "").trim() ===
              formDetail.value &&
              formDetail.value.replace("(Scanned)", "").trim()
          );
        }
      });
    }
    console.log("thisifilteredform", matchingResponse);

    const dashArray = fields.map(() => ({ dash: "-" }));
    const auditItem = auditItemsList?.list?.find(
      (item: any) => item._id === formResponses[0].auditItemFormId
    );
    const isCDRReport = auditItem && auditItem.reportType === "CDR Report";
    return {
      index: index + 1,
      detailValue: consolidated
        ? formDetail
          ? !isCDRReport
            ? formDetail?.value?.replace("(Scanned)", "").trim()
            : formDetail.value
          : "-"
        : formDetail
          ? formDetail?.value?.replace("(Scanned)", "").trim()
          : "-",
      responseValue: matchingResponse ? matchingResponse.responses : dashArray,
      detailValueExtended: false,
      dynamicDataId: formDetail ? formDetail.dynamicDataId : "-",
      dealerFeedback: matchingResponse
        ? matchingResponse.dealerFeedback
        : formDetail
          ? formDetail.dealerFeedback
          : "-",
      responseAgainstCdr: matchingResponse
        ? matchingResponse.responseAgainstCdr
        : "-",
      dealerRemark: matchingResponse
        ? matchingResponse.dealerRemark
        : formDetail
          ? formDetail.dealerRemark
          : "-",
      mannualScan: !isCDRReport
        ? matchingResponse &&
          matchingResponse.responses.some(
            (item: any) => item.response && item.response?.includes("(Scanned)")
          )
          ? "yes"
          : "no"
        : "",
      faRemark: matchingResponse
        ? matchingResponse.faRemark
        : formDetail
          ? formDetail.faRemark
          : "-",
      user: matchingResponse ? matchingResponse.user : "-",
      createdAt: matchingResponse ? matchingResponse.createdAt : "-",
      clientLocation: matchingResponse ? matchingResponse.clientLocation : "-",
      responseId: matchingResponse ? matchingResponse._id : "-",
      documentUrl: matchingResponse
        ? matchingResponse.documentUrl
        : formDetail
          ? formDetail.documentUrl
          : "-",
      auditItemFormId: matchingResponse
        ? matchingResponse.auditItemFormId
        : "-",
    };
  }) as any;


  filteredFormResponses.forEach((response: any) => {
    const auditItem = auditItemsList?.list?.find(
      (item: any) => item._id === formResponses[0].auditItemFormId
    );
    const isCDRReport = auditItem && auditItem.reportType === "CDR Report";
    const matched = combinedData.some(
      (data: any) => data.responseValue === response.responses
    );
    console.log("thisiwhatitis", response.responses, combinedData);
    if (!matched) {
      combinedData.push({
        index: combinedData?.length + 1,
        detailValue: !isCDRReport
          ? response.responses
            ?.filter((data: any) => data?.title === "true")[0]
            ?.response?.replace("(Scanned)", "")
            .trim()
          : response.responses?.filter(
            (data: any) => data?.title === "true"
          )[0],
        detailValueExtended: true,
        responseValue: response.responses,
        dynamicDataId: "-",
        user: response.user,
        mannualScan: !isCDRReport
          ? response &&
            response.responses &&
            response.responses
              ?.filter((data: any) => data?.title === "true")[0]
              ?.response?.includes("(Scanned)")
            ? "yes"
            : "no"
          : "",
        createdAt: response.createdAt,
        clientLocation: response.clientLocation,
        responseAgainstCdr: response.responseAgainstCdr,
        documentUrl: response.documentUrl,
        dealerFeedback: response.dealerFeedback,
        dealerRemark: response.dealerRemark,
        faRemark: response.faRemark,
        responseId: response._id,
        auditItemFormId: response.auditItemFormId,
      });
    }
  });

  if (dummyForHandleHover) {
    handleHoverCombinedData(combinedData);
  }

  const filteredCombinedData = combinedData?.filter((item: any) => {
    const isDetailValueDash = item.detailValue === "-";
    const isAuditItemFormIdDash = item.auditItemFormId === "-";

    if (
      // (!isDetailValueDash && !isAuditItemFormIdDash) || // Filter condition 1
      (isDetailValueDash && !isAuditItemFormIdDash) || // Filter condition 1
      (!isDetailValueDash && isAuditItemFormIdDash) || // Filter condition 2
      (!isDetailValueDash && !isAuditItemFormIdDash) // Filter condition 3
    ) {
      return true; // Include the item if any of the conditions are met
    }

    return false; // Exclude the item if none of the conditions match
  });

  console.log("thisisfiltetredcombineddata", filteredCombinedData);
  let count = 0;
  let count1 = 0;
  var keys =
    filteredNewCarStockData && filteredNewCarStockData?.length > 0
      ? Object.keys(filteredNewCarStockData[0]?.data)
      : [];
  console.log("keys", keys);


  const exportToExcelFullReport = () => {
    // Header information
    const titleParts = auditData.title.split(",");
    const desiredTitle = titleParts[0].trim();
    const currentDate = formatDate(new Date());
    const header = `${headerInfo?.filter((item: any) => item.projectId === subProjectData.projectId)[0]?.headerInfo}`
    const headerInfo2 = [
      [
        `${consolidated ? "Sub Project Name:" : "Dealer name and city:"}`,
        `${consolidated ? subProjectData.title : auditData.title}`,
      ],
      ...(consolidated ? [] : [["Dealer Code:", `${desiredTitle}`]]),
      // ["Dealer name and city:", `${auditData.title}`],
      // ["Dealer Code:", `${desiredTitle}`],
      ["Audit Date:", currentDate],
      ["Verified By (Auditor Name):", ""],
      [],
      header === "undefined" ? [] : [header],
      [],
    ];
    const footer = `${footerInfo?.filter(
      (item: any) => item.projectId === subProjectData.projectId
    )[0]?.footerInfo
      }`;
    const footerData = [
      [],
      [],
      ["Over all remarks"],
      [],
      [],
      footer === "undefined" ? [] : [footer],
      [], // Empty row for spacing
      ["Dealer Representative (Name):"],
      ["Dealer Representative (Designation):"],
      [], // Empty row for spacing
      [], // Empty row for spacing
      [], // Empty row for spacing
      ["Signature and Stamp:"],
      ["Date:"],

      // Add more rows for signatures, dates, etc., as needed
    ];
    // Create a 2D array to store the table data
    const auditItem = auditItemsList?.list?.find(
      (item: any) => item._id === formResponses[0].auditItemFormId
    );
    const isCDRReport = auditItem && auditItem.reportType === "CDR Report";


    let headers = [];
    if (isCDRReport) {
      headers = [
        "S/N",

        ...formDetailData2[0]
          .map((item: any, index: any) =>
            auditItemsList.list?.length &&
              formResponses?.length &&
              auditItemsList.list?.filter(
                (item: any) => item._id === formResponses[0].auditItemFormId
              )[0].reportType === "CDR Report"
              ? `Uploaded ${item}`
              : `Uploaded ${item}`
          )
          ?.filter(Boolean),
        ...fields.map(({ title }) => title),
        // ...filteredTitles,
        // "Auditor Name",
        // "Auditor Location",
        // "Auditor Mobile No",
        "Dealer Remark",
        "Dealer Feedback",
        "Remark",
        // "Audit Date",
        // ...keys?.filter((key) => key !== "SNo.").map((key) => `Uploaded ${key}`),
      ];
    } else {
      headers = [
        "S/N",

        ...formDetailData2[0]
          .map((item: any, index: any) =>
            auditItemsList.list?.length &&
              formResponses?.length &&
              auditItemsList.list?.filter(
                (item: any) => item._id === formResponses[0].auditItemFormId
              )[0].reportType === "CDR Report"
              ? `Uploaded ${item}`
              : `Uploaded ${item}`
          )
          ?.filter(Boolean),
        ...fields.map(({ title }) => title),
        "Auditor Name",
        "Auditor Location",
        "Auditor Mobile No",
        "Dealer Remark",
        "Dealer Feedback",
        "Remark",
        "Audit Date",
        ...keys?.filter((key) => key !== "SNo.").map((key) => `Uploaded ${key}`),
      ];
    }
    if (!isCDRReport) {
      headers.splice(2, 0, "Scanned");
      headers.splice(3, 0, "Status");
    }

    const excelData = [...headerInfo2, headers];
    if (isCDRReport) {
      filteredCombinedData
        .filter((item: any) => item.detailValueExtended === true && isCDRReport)
        .forEach((data: any, index: any) => {
          const auditItem = auditItemsList?.list?.find(
            (item: any) => item._id === formResponses[0].auditItemFormId
          );
          const isCDRReport =
            auditItem && auditItem.reportType === "CDR Report";

          const rowData = [index + 1];

          if (!isCDRReport) {
            rowData.push(data.detailValue ?? "-");
            rowData.push(data.mannualScan ?? "-");
            rowData.push(
              !data.responseValue.some((item: any) => item.dash)
                ? foundOrNotFoundList[count1++]
                : "Not Found"
            );
          } else {
            rowData.push(data.responseAgainstCdr ?? "-");
          }
          // if (originalLength !== filteredLength) {
          //   // Remove the first two values from responseValue
          //   data.responseValue = data.responseValue.slice(2);
          // }

          if (Array.isArray(data.responseValue)) {
            data.responseValue.forEach((item: any) => {
              const hasFileExtension = item.response
                ? item.response?.includes(".")
                : false;
              if (!isCDRReport) {
                rowData.push(
                  hasFileExtension
                    ? `${BASE_URL}/retrieve/${item.response}`
                    : item.response ?? "-"
                );
              } else {
                rowData.push(
                  hasFileExtension
                    ? `${BASE_URL}/retrieve/${item.response}`
                    : item.response ?? "-"
                );
              }
            });
          } else {
            rowData.push(data.responseValue ?? "-");
          }
          if (!isCDRReport) {
            rowData.push(
              value.find((item: any) => item._id === data.user)?.fullname ?? "-"
            );
            rowData.push(data.clientLocation ?? "-");
            rowData.push(
              value.find((item: any) => item._id === data.user)?.mobile ?? "-"
            );
            // if (!(data.dealerRemark === "-")) {
            rowData.push(data.dealerRemark ?? "-");
            // }

            // if (!(data.dealerFeedback === "-")) {
            rowData.push(data.dealerFeedback ?? "-");
            // }
            // if (!(data.faRemark === "-")) {
            rowData.push(data.faRemark ?? "-");
            // }
            // rowData.push(data.dealerRemark ?? "-");
            // rowData.push(data.dealerFeedback ?? "-");
            // rowData.push(data.faRemark ?? "-");
            // rowData.push(`${data.faRemark + ',' + data.dealerFeedback + "," + data.dealerRemark ?? "-"}`)
            rowData.push(
              moment
                .tz(data.createdAt, "Asia/Kolkata")
                .format("YYYY-MM-DD HH:mm:ss")
            );
          } else {
            //   rowData.push(
            //     value.find((item: any) => item._id === data.user)?.fullname ?? "-"
            //   );
            //   // if (!(data.clientLocation === "-")) {
            //   rowData.push(data.clientLocation ?? "-");
            // // }
            // rowData.push(value.find((item: any) => item._id === data.user)?.mobile ?? "-");
            // if (!(data.dealerRemark === "-")) {
            rowData.push(data.dealerRemark ?? "-")
            // }

            // if (!(data.dealerFeedback === "-")) {
            rowData.push(data.dealerFeedback ?? "-")
            // }
            // if (!(data.faRemark === "-")) {
            rowData.push(data.faRemark ?? "-")
            // }
            // const createdAt = moment.tz(data.createdAt, "Asia/Kolkata");
            // if (createdAt.isValid()) {
            //   // If createdAt is a valid date, push it to the rowData array
            //   rowData.push(createdAt.format('YYYY-MM-DD HH:mm:ss') ?? "-");
            // }
          }

          // if (!isCDRReport) {
          //   if (filteredNewCarStockData[index]) {
          //     rowData.push(...Object.values(filteredNewCarStockData[index].data).slice(1));
          //   } else {
          //     rowData.push(...keys.slice(1).map((item: any) => "-"));
          //   }
          // }
          // else {
          //   rowData.push(...Object.values(filteredNewCarStockData[filteredNewCarStockData.findIndex((item: any) => {
          //     return Object.values(item?.data).some(value => value === data.responseAgainstCdr);
          //   })]?.data || []).slice(1) ?? "-");
          // }
          if (!isCDRReport) {
            const matchingSubProject = allSubProjectData.find(
              (subProject: any) => subProject.data.VIN === data.detailValue
            );

            if (matchingSubProject) {
              rowData.push(
                ...Object.entries(matchingSubProject.data)
                  .filter(([key, _]) => key !== 'SNo.')
                  .map(([_, val]) => val)
              );
            } else {
              rowData.push(...keys.slice(1).map(() => "-"));
            }
          } else {
            // const cdrIndex = allSubProjectData.findIndex((subProject: any) =>
            //   Object.values(subProject.data).some(
            //     (value) => value === data.responseAgainstCdr
            //   )
            // );

            // if (cdrIndex !== -1) {
            //   rowData.push(
            //     ...Object.entries(allSubProjectData[cdrIndex].data)
            //       .filter(([key, _]) => key !== 'SNo.')
            //       .map(([_, val]) => val)
            //   );
            // } else {
            //   rowData.push(...keys.slice(1).map(() => "-"));
            // }
          }

          excelData.push(rowData);
        });
    } else {
      filteredCombinedData.forEach((data: any, index: any) => {
        const auditItem = auditItemsList?.list?.find(
          (item: any) => item._id === formResponses[0].auditItemFormId
        );
        const isCDRReport = auditItem && auditItem.reportType === "CDR Report";

        const rowData = [index + 1];

        if (!isCDRReport) {
          rowData.push(data.detailValue ?? "-");
          rowData.push(data.mannualScan ?? "-");
          rowData.push(!data.responseValue.some((item: any) => item.dash) ? foundOrNotFoundList[count1++] : "Not Found");
        }
        else {
          rowData.push(data.responseAgainstCdr ?? "-");
        }
        if (Array.isArray(data.responseValue)) {
          data.responseValue.forEach((item: any) => {
            const hasFileExtension = item.response
              ? item.response?.includes(".")
              : false;
            if (!isCDRReport) {
              rowData.push(
                hasFileExtension
                  ? `${BASE_URL}/retrieve/${item.response}`
                  : item.response ?? "-"
              );
            } else {
              rowData.push(
                hasFileExtension
                  ? `${BASE_URL}/retrieve/${item.response}`
                  : item.response ?? "-"
              );
            }
          });
        } else {
          rowData.push(data.responseValue ?? "-");
        }
        if (!isCDRReport) {
          rowData.push(
            value.find((item: any) => item._id === data.user)?.fullname ?? "-"
          );
          rowData.push(data.clientLocation ?? "-");
          rowData.push(
            value.find((item: any) => item._id === data.user)?.mobile ?? "-"
          );
          rowData.push(data.dealerRemark ?? "-");
          rowData.push(data.dealerFeedback ?? "-");
          rowData.push(data.faRemark ?? "-");
          // rowData.push(data.dealerRemark ?? "-");
          // rowData.push(data.dealerFeedback ?? "-");
          // rowData.push(data.faRemark ?? "-");
          // rowData.push(`${data.faRemark + ',' + data.dealerFeedback + "," + data.dealerRemark ?? "-"}`)
          rowData.push(moment.tz(data.createdAt, "Asia/Kolkata").format('DD-MM-YYYY HH:mm:ss'));
        } else {
          rowData.push(
            value.find((item: any) => item._id === data.user)?.fullname ?? "-"
          );
          // if (!(data.clientLocation === "-")) {
          rowData.push(data.clientLocation ?? "-");
          // }
          rowData.push(
            value.find((item: any) => item._id === data.user)?.mobile ?? "-"
          );
          // if (!(data.dealerRemark === "-")) {
          rowData.push(data.dealerRemark ?? "-");
          // }

          // if (!(data.dealerFeedback === "-")) {
          rowData.push(data.dealerFeedback ?? "-");
          // }
          // if (!(data.faRemark === "-")) {
          rowData.push(data.faRemark ?? "-");
          // }
          const createdAt = moment.tz(data.createdAt, "Asia/Kolkata");
          if (createdAt.isValid()) {
            // If createdAt is a valid date, push it to the rowData array
            rowData.push(createdAt.format('DD-MM-YYYY HH:mm:ss') ?? "-");
          }
        }

        // if (!isCDRReport) {
        //   if (filteredNewCarStockData[index]) {
        //     rowData.push(...Object.values(filteredNewCarStockData[index].data).slice(1));
        //   } else {
        //     rowData.push(...keys.slice(1).map((item: any) => "-"));
        //   }
        // }
        // else {
        //   rowData.push(...Object.values(filteredNewCarStockData[filteredNewCarStockData.findIndex((item: any) => {
        //     return Object.values(item?.data).some(value => value === data.responseAgainstCdr);
        //   })]?.data || []).slice(1) ?? "-");
        // }
        if (!isCDRReport) {
          const matchingSubProject = allSubProjectData.find(
            (subProject: any) => subProject.data.VIN === data.detailValue
          );

          if (matchingSubProject) {
            rowData.push(
              ...Object.entries(matchingSubProject.data)
                .filter(([key, _]) => key !== 'SNo.')
                .map(([_, val]) => val)
            );
          } else {
            rowData.push(...keys.slice(1).map(() => "-"));
          }
        } else {
          const cdrIndex = allSubProjectData.findIndex((subProject: any) =>
            Object.values(subProject.data).some(
              (value) => value === data.responseAgainstCdr
            )
          );

          if (cdrIndex !== -1) {
            rowData.push(
              ...Object.entries(allSubProjectData[cdrIndex].data)
                .filter(([key, _]) => key !== 'SNo.')
                .map(([_, val]) => val)
            );
          } else {
            rowData.push(...keys.slice(1).map(() => "-"));
          }
        }
        excelData.push(rowData);
      });
    }

    excelData.push(...footerData);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}`;
    // Save the Excel file
    XLSX.writeFile(wb, `exported_data_${formattedDate}.xlsx`);
  };
  console.log("thisissiforoteinfo", footerInfo);
  console.log("keys", filteredNewCarStockData);
  const exportToExcelDraftReport = () => {
    // Header information
    const titleParts = auditData.title.split(",");
    const desiredTitle = titleParts[0].trim();
    const currentDate = formatDate(new Date());
    const header = `${headerInfo?.filter((item: any) => item.projectId === subProjectData.projectId)[0]?.headerInfo}`
    const headerInfo2 = [
      [
        `${consolidated ? "Sub Project Name:" : "Dealer name and city:"}`,
        `${consolidated ? subProjectData.title : auditData.title}`,
      ],
      ...(consolidated ? [] : [["Dealer Code:", `${desiredTitle}`]]),
      // ["Dealer name and city:", `${auditData.title}`],
      // ["Dealer Code:", `${desiredTitle}`],
      ["Audit Date:", currentDate],
      ["Verified By (Auditor Name):", ""],
      [],
      header === "undefined" ? [] : [header],
      [],
    ];
    const footer = `${footerInfo?.filter(
      (item: any) => item.projectId === subProjectData.projectId
    )[0]?.footerInfo
      }`;
    const footerData = [
      [],
      [],
      ["Over all remarks"],
      [],
      [],
      footer === "undefined" ? [] : [footer],
      [], // Empty row for spacing
      ["Dealer Representative (Name):"],
      ["Dealer Representative (Designation):"],
      [], // Empty row for spacing
      [], // Empty row for spacing
      [], // Empty row for spacing
      ["Signature and Stamp:"],
      ["Date:"],
      // Add more rows for signatures, dates, etc., as needed
    ];
    // Create a 2D array to store the table data
    const headers = [
      "S/N",
      ...formDetailData2[0].map((item: any, index: any) => `${item}`),
      "Found",
      "Not Found",
      "Excess",
      "Remarks",
    ];

    const excelData = [...headerInfo2, headers]; // Include header information

    console.log("foundOrNotFoundList", foundOrNotFoundList);
    let count1 = 0;
    let totalCounts = { found: 0, notFound: 0, excess: 0 }; // Initialize counts
    filteredCombinedData.forEach((data: any, index: any) => {
      console.log("thisiisthedata", data);
      const rowData = [index + 1];
      if (data.detailValue === "-") {
        data.responseValue.forEach((item: any) => {
          if (item.title === "true") {
            rowData.push(item.response ? item.response : "-");
          }
        });
      } else {
        rowData.push(data.detailValue);
      }
      let found = 0,
        notFound = 0,
        excess = 0;
      if (!data.responseValue.some((item: any) => item.dash)) {
        console.log("thisisfoundnotfont", foundOrNotFoundList);
        const filteredFoundNotFoundList = foundOrNotFoundList.map(
          (item: any) => {
            const match = item.match(/(Found|Excess)/);
            return match ? match[0] : null;
          }
        );
        if (filteredFoundNotFoundList[count1++] === "Found") {
          found = 1;
          totalCounts.found++; // Increment found count
        } else {
          excess = 1;
          totalCounts.excess++; // Increment excess count
        }
      } else {
        notFound = 1;
        totalCounts.notFound++; // Increment not found count
      }

      rowData.push(
        found,
        notFound,
        excess,
        `${data.dealerRemark + "," + data.dealerFeedback + "," + data.faRemark ??
        "-"
        }`
      ); // Push 1 or 0 based on condition
      // if (Array.isArray(data.responseValue)) {
      //   data.responseValue.forEach((item: any) => {
      //     console.log("thisiisitem", item)
      //     if (item.title === "true") {
      //       rowData.push(
      //         item.response ? item.response : "-"
      //       );
      //     }
      //   });
      // } else {
      //   rowData.push(data.responseValue);
      // }
      excelData.push(rowData);
    });
    // Append the row for total counts
    excelData.push([
      "Total",
      "",
      totalCounts.found,
      totalCounts.notFound,
      totalCounts.excess,
      "",
    ]);
    excelData.push(...footerData);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}`;
    // Save the Excel file
    XLSX.writeFile(wb, `exported_data_${formattedDate}.xlsx`);
  };

  console.log("thisisversion", foundOrNotFoundList);
  const sortRows = () => {
    const table = document.getElementById("summery_table") as any;
    const rows = Array.from(table.getElementsByTagName("tr"));
    console.log("thisistherows", rows);
    rows.sort((a: any, b: any) => {
      const valueA = a.children[3].innerText;
      const valueB = b.children[3].innerText;

      if (sortAscending) {
        return compareValues(valueA, valueB);
      } else {
        return compareValues(valueB, valueA);
      }
    });

    rows.forEach((row) => table.appendChild(row));
  };

  const compareValues = (valueA: any, valueB: any) => {
    if (
      valueA.includes("Found At") &&
      (valueB === "Not Found" || valueB.includes("Excess At"))
    ) {
      return -1;
    }
    if (valueA.includes("Not Found") && valueB.includes("Excess At")) {
      return -1;
    }
    if (valueA === valueB) {
      return 0;
    }
    return 1;
  };

  const handleToggleClick = () => {
    setSortAscending(!sortAscending);
    sortRows();
  };
  const handleDocumentsDownload = (documentUrls: any) => {
    console.log("thisisfiles", documentUrls);
    if (documentUrls) {
      const downloadDelay = 1000; // Delay between each download in milliseconds
      documentUrls.forEach((item: any, index: number) => {
        setTimeout(() => {
          const link = document.createElement("a");
          link.href = `${BASE_URL}/retrieve_file/${item}`;
          link.download = item; // Optional: set the filename when downloading
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, index * downloadDelay); // Delay each download by index * downloadDelay milliseconds
      });
    }
  };
  const auditItem = auditItemsList?.list?.find(
    (item: any) => item._id === formResponses[0].auditItemFormId
  );
  const isCDRCheck = auditItem && auditItem.reportType === "CDR Report";
  console.log("subproject data", subProjectData);
  return (
    <>
      {showModalForVersion && (
        <>
          <div className="flex justify-between items-center p-4 pl-0">
            <h1 className="text-xl font-bold">Recorded Version History</h1>
          </div>
          <div className="mt-5  shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 w-full">
            <table
              className={`w-full dark:bg-gray-800 ${darkMode ? "dark:text-white" : "text-gray-800"
                }`}
            >
              <thead className="text-xs text-left rounded-lg text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 whitespace-nowrap">
                <tr>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Timestamp
                    </span>
                  </th>
                  {fields.map(({ title, _id }) => (
                    <th
                      key={_id}
                      className="py-3 px-6 text-md text-gray-800 dark:text-white"
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {versionHistoryData.data?.length === 0 ? (
                  <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <td
                      colSpan={fields?.length + 1}
                      className="py-3 px-6 text-center"
                    >
                      <span className="text-gray-500 dark:text-gray-400">
                        No Records Found
                      </span>
                    </td>
                  </tr>
                ) : (
                  // ?.filter((response: any) => response.subAuditId === formId && response.auditItemFormId === auditItemFormId)

                  versionHistoryData &&
                  versionHistoryData.data
                    ?.filter((item: any) => item.documentId === responseId)
                    .map((item: any, index: any) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          {moment
                            .tz(
                              item.version.split("Updated at ")[1],
                              "Asia/Kolkata"
                            )
                            .fromNow()}
                        </td>
                        {item?.data?.responses.map(
                          ({ response }: { response: any }, ind: any) => {
                            const hasFileExtension = response
                              ? response?.includes(".")
                              : false;
                            const isButtonClicked = clickedRow === index;

                            return (
                              <td
                                key={ind}
                                className="px-6 py-4 whitespace-nowrap"
                              >
                                {hasFileExtension ? (
                                  <div>
                                    <button
                                      onClick={() =>
                                        setClickedRow(
                                          isButtonClicked ? null : index
                                        )
                                      }
                                    >
                                      <i className="bx bx-image-alt text-2xl"></i>
                                    </button>
                                    {isButtonClicked && (
                                      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto">
                                        <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
                                        <div className="bg-white rounded-lg shadow-lg p-6 mx-auto max-w-3xl relative">
                                          <button
                                            className="top-4 right-4 p-2 cursor-pointer text-gray-600 hover:text-gray-800"
                                            onClick={() => setClickedRow(null)}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-6 w-6"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              stroke="currentColor"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12"
                                              />
                                            </svg>
                                          </button>
                                          <img
                                            src={`${BASE_URL}/retrieve/${response}`}
                                            alt="my-img"
                                            className="max-w-full h-auto rounded-lg shadow-md"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  response ?? "-"
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
      {showModal2 && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999999]   p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-[505px] max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
              <button
                type="button"
                onClick={() => {
                  setShowModal2(false);
                  setDummy(false);
                }}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  Reopened & Approval
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}
                <>
                  {/* onSubmit={
                    // (e: any) => handleSubmit(e)
                  } */}
                  <form>
                    <Select
                      components={animatedComponents}
                      onChange={(event: any) => setStatusOption(event.value)}
                      options={options}
                      placeholder="Change Status To"
                      className="my-react-select-container mt-4 rounded-md  dark:border-gray-600"
                      classNamePrefix="my-react-select"
                    />
                    {statusOption === "Reopened" ? (
                      <>
                        <textarea
                          id="first_name"
                          className="bg-gray-50 border mt-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Share feedback here..."
                          value={feedback}
                          onChange={handleFeedbackChange}
                        ></textarea>
                      </>
                    ) : null}

                    <div className="flex justify-between">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (statusOption === "Reopened") {
                            if (status === "Reconcile Needed" || status === "Approved") {
                              handleFeedBack();
                            } else {
                              toast(
                                "Action not allowed until status is 'Reconcile Needed'.",
                                {
                                  type: "error",
                                  className: "dark:bg-gray-900 dark:text-white",
                                }
                              );
                            }
                          } else {
                            if (status === "Reconcile Needed") {
                              handleApproved();
                            } else {
                              toast(
                                "Action not allowed until status is 'Reconcile Needed'.",
                                {
                                  type: "error",
                                  className: "dark:bg-gray-900 dark:text-white",
                                }
                              );
                            }
                          }
                        }}
                        className="btn flex justify-center items-center bg-[#4f46e5] hover:bg-indigo-800 text-white mt-4"
                      >
                        <i className="bx bx-station m-auto mr-1 text-[18px]"></i>
                        <span className="hidden xs:block">
                          {statusOption === "Reopened"
                            ? "Submit Feedback"
                            : "Audit Approved"}{" "}
                        </span>
                      </button>
                    </div>
                  </form>
                </>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div
          className={`${(showModal2 && dummy) === false ? "" : `blur-sm`
            } overflow-hidden visible`}
        >
          <div className="flex justify-between">
            <div className="flex justify-center items-center">
              <button
                onClick={() => {
                  setShowModal2(true);
                  setDummy(true);
                }}
                className="flex justify-center items-center"
              >
                <span className="animate-blink" style={indicatorStyle}></span>
                {status}
                <i className="bx bx-station text-xl mt-1 ml-1 "></i>
              </button>
              <div className="flex justify-center items-center">
                <Switch
                  height={23}
                  width={50}
                  checked={consolidated}
                  onChange={(e: any) => {
                    if (
                      currentUserData?.category.category ===
                      "Office Coordinator" ||
                      currentUserData?.category.category === "Office Auditor"
                    ) {
                      toast("Access Denied!.", {
                        type: "error",
                        className: "dark:bg-gray-900 dark:text-white",
                      });
                    } else {
                      setConsolidated(!consolidated);
                    }
                  }}
                  className="react-switch mr-2 ml-2"
                />
                <h2 className="mr-2">Consolidated</h2>
              </div>
            </div>

            <div>
              <div className="flex justify-between items-center">
                {isCDRCheck ? null : (
                  <button
                    onClick={exportToExcelDraftReport}
                    className={`mt-4 text-white mr-2 flex justify-center items-center bg-gradient-to-br ${consolidated
                      ? "from-green-600 to-green-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                      : "from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                      } `}
                  >
                    <i className="bx bxs-download m-auto mr-1 text-[18px]"></i>
                    <span className="hidden xs:block">Draft Report</span>
                  </button>
                )}

                <button
                  onClick={exportToExcelFullReport}
                  className={`mt-4 text-white flex justify-center items-center bg-gradient-to-br ${consolidated
                    ? "from-green-600 to-green-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                    : "from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                    }`}
                >
                  <i className="bx bxs-download m-auto mr-1 text-[18px]"></i>
                  <span className="hidden xs:block">Full Report</span>
                </button>
              </div>
            </div>
          </div>

          <div className="mt-5  shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 w-full">
            <table
              id="summery_table"
              className={`w-full dark:bg-gray-800 ${darkMode ? "dark:text-white" : "text-gray-800"
                }`}
            >
              <thead>
                <tr className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 whitespace-nowrap">
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      S/N
                    </span>
                  </th>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Action
                    </span>
                  </th>
                  {formDetailData2[0].map((item: any, index: any) =>
                    auditItemsList?.list &&
                      formResponses[0]?.auditItemFormId ? (
                      auditItemsList.list?.filter(
                        (item: any) =>
                          item._id === formResponses[0].auditItemFormId
                      )[0].reportType === "CDR Report" ? (
                        <th
                          key={index}
                          className="py-3 px-6 text-md text-gray-800 dark:text-white"
                          dangerouslySetInnerHTML={{
                            __html: `Uploaded ${item}`,
                          }}
                        ></th>
                      ) : (
                        <th
                          key={index}
                          className="py-3 px-6 text-md text-gray-800 dark:text-white"
                          dangerouslySetInnerHTML={{
                            __html: `Uploaded ${item}`,
                          }}
                        ></th>
                      )
                    ) : null
                  )}
                  {auditItemsList?.list && formResponses[0]?.auditItemFormId ? (
                    auditItemsList.list?.filter(
                      (item: any) =>
                        item._id === formResponses[0].auditItemFormId
                    )[0].reportType === "CDR Report" ? null : (
                      <th className="py-3 px-6">
                        <span className="text-md text-gray-800 dark:text-white">
                          Scanned
                        </span>
                      </th>
                    )
                  ) : null}

                  {auditItemsList?.list && formResponses[0]?.auditItemFormId ? (
                    auditItemsList.list?.filter(
                      (item: any) =>
                        item._id === formResponses[0].auditItemFormId
                    )[0].reportType === "CDR Report" ? null : (
                      <th className="py-3 px-6">
                        <span className="text-md text-gray-800 dark:text-white flex items-center">
                          Status
                          {/* <i className='bx bx-sort text-lg mx-2 cursor-pointer' onClick={handleToggleClick}></i> */}
                        </span>
                      </th>
                    )
                  ) : null}

                  {/* {formDetailData2[0].map((item: any, index: any) => (
                    auditItemsList?.list &&
                      formResponses[0]?.auditItemFormId ? (
                      auditItemsList.list
                        ?.filter(
                          (auditItem: any) => auditItem._id === formResponses[0].auditItemFormId
                        )[0]?.reportType === "CDR Report" ? (
                        fields.filter(({ title }) => title !== 'ZONE' && title !== 'VIN').map(({ title, _id }: any) => (
                          <th
                            key={_id}
                            className="py-3 px-6 text-md text-gray-800 dark:text-white"
                            dangerouslySetInnerHTML={{ __html: title }}
                          ></th>
                        ))
                      ) : (
                        fields.map(({ title, _id }) => (
                          <th
                            key={_id}
                            className="py-3 px-6 text-md text-gray-800 dark:text-white"
                            dangerouslySetInnerHTML={{ __html: title }}
                          ></th>
                        ))
                      )
                    ) : null
                  ))} */}


                  {fields.map(({ title, _id }) => (
                    <th
                      key={_id}
                      className="py-3 px-6 text-md text-gray-800 dark:text-white"
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></th>
                  ))}
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Auditor Name
                    </span>
                  </th>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Auditor Mobile No.
                    </span>
                  </th>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Auditor Location
                    </span>
                  </th>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Dealer Remark
                    </span>
                  </th>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Dealer Feedback
                    </span>
                  </th>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Remark
                    </span>
                  </th>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Sub Document Link
                    </span>
                  </th>
                  <th className="py-3 px-6">
                    <span className="text-md text-gray-800 dark:text-white">
                      Audit Date
                    </span>
                  </th>

                  {keys.map((key: any, idx: any) =>
                    key !== "SNo." ? (
                      <th
                        key={idx}
                        className="py-3 px-6 text-md text-gray-800 dark:text-white"
                        dangerouslySetInnerHTML={{ __html: `Uploaded ${key}` }}
                      ></th>
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {auditItemsList.list?.length &&
                  formResponses?.length &&
                  auditItemsList.list?.filter(
                    (item: any) => item._id === formResponses[0].auditItemFormId
                  )[0].reportType === "CDR Report"
                  ? filteredCombinedData
                    ?.filter(
                      (item: any) => item.responseValue[0].dash !== "-"
                    )
                    .map((data: any, index: any) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {/* {data.clientLocation ?? "-"} */}
                          <div>
                            <button
                              title="Edit"
                              onClick={() => {
                                navigate(
                                  `/form/${data.auditItemFormId}/audititem_response_view/${data.responseId}`
                                );
                              }}
                            >
                              <i className="bx bx-edit-alt text-xl text-green-500 mr-4"></i>
                            </button>
                            <button
                              title="Delete"
                              onClick={() => {
                                const confirmed = window.confirm(
                                  "Are you sure you want to delete this item?"
                                );
                                if (confirmed) {
                                  if (
                                    currentUserData?.category.category ===
                                    "Office Auditor"
                                  ) {
                                    toast("Access Denied!.", {
                                      type: "error",
                                      className:
                                        "dark:bg-gray-900 dark:text-white",
                                    });
                                  } else {
                                    deleteResponseById(data.responseId)
                                      .then((res: any) => {
                                        toast(
                                          "Response Deleted Successfully!",
                                          {
                                            type: "success",
                                            className:
                                              "dark:bg-gray-900 dark:text-white z-[1000]",
                                          }
                                        );
                                      })
                                      .catch((error: any) => {
                                        toast("Error Deleting Response!", {
                                          type: "error",
                                          className:
                                            "dark:bg-gray-900 dark:text-white z-[1000]",
                                        });
                                        window.location.reload();
                                      });
                                  }
                                  // setDummy101(true);
                                }
                              }}
                            >
                              {" "}
                              <i className="bx bx-trash text-xl text-red-500"></i>
                            </button>
                            <button
                              onClick={() => {
                                setShowModalForVersion(!showModalForVersion);
                                setResponseId(data.responseId);
                              }}
                            >
                              <FaHistory
                                className={`text-lg ${versionHistoryData &&
                                  (versionHistoryData.data?.filter(
                                    (entry: any) =>
                                      entry.documentId === data.responseId
                                  )).length > 0
                                  ? "text-purple-500"
                                  : "text-gray-500 dark:text-white"
                                  } mx-4 `}
                              />
                            </button>
                          </div>
                        </td>
                        {auditItemsList.list?.length &&
                          formResponses?.length &&
                          auditItemsList.list?.filter(
                            (item: any) =>
                              item._id === formResponses[0].auditItemFormId
                          )[0].reportType === "CDR Report" ? (
                          <td className="px-6 py-4 whitespace-nowrap">
                            {data.responseAgainstCdr}
                          </td>
                        ) : (
                          <td className="px-6 py-4 whitespace-nowrap">
                            {data.detailValue}
                          </td>
                        )}
                        {auditItemsList?.list &&
                          formResponses[0]?.auditItemFormId ? (
                          auditItemsList.list?.filter(
                            (item: any) =>
                              item._id === formResponses[0].auditItemFormId
                          )[0].reportType === "CDR Report" ? null : (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {data.mannualScan ?? "-"}
                            </td>
                          )
                        ) : null}

                        {auditItemsList.list?.length &&
                          formResponses?.length &&
                          auditItemsList.list?.filter(
                            (item: any) =>
                              item._id === formResponses[0].auditItemFormId
                          )[0].reportType === "CDR Report" ? null : (
                          <td className="px-6 py-4 whitespace-nowrap">
                            {!data.responseValue.some(
                              (item: any) => item.dash
                            )
                              ? foundOrNotFoundList[count++]
                              : "Not Found"}
                          </td>
                        )}
                        {Array.isArray(data.responseValue) ? (
                          data?.responseValue?.map((item: any, ind: any) => {
                            const hasFileExtension = item.response
                              ? item.response?.includes(".")
                              : false;
                            const isButtonClicked = clickedRow === index;

                            return (
                              <td key={ind} className="px-6 py-4 whitespace-nowrap">
                                {hasFileExtension ? (
                                  <div>
                                    <button
                                      onMouseEnter={() =>
                                        setClickedRow(isButtonClicked ? null : index)
                                      }
                                    >
                                      <i className="bx bx-image-alt text-2xl"></i>
                                    </button>

                                    {isButtonClicked && (
                                      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto dark:bg-transparent">
                                        <div
                                          onMouseLeave={() => setClickedRow(null)}
                                          className="fixed inset-0 bg-gray-900 opacity-50 dark:bg-gray-800"
                                        ></div>
                                        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mx-auto max-w-3xl relative">
                                          <div className="flex flex-col items-start">
                                            <div className="absolute top-7 left-8 text-[13px] text-white">
                                              <p>{data.clientLocation}</p>
                                              <p>
                                                {moment
                                                  .tz(data.createdAt, "Asia/Kolkata")
                                                  .format("YYYY-MM-DD HH:mm:ss")}
                                              </p>
                                            </div>
                                            <img
                                              src={`${BASE_URL}/retrieve/${item.response}`}
                                              alt="my-img"
                                              className="max-w-full max-h-[80vh] rounded-lg shadow-md"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  (item.response && item.response.replace("(Scanned)", "").trim()) ??
                                  "-"
                                )}
                              </td>
                            );
                          })
                        ) : (
                          <td className="px-6 py-4 whitespace-nowrap">
                            {data.responseValue && data.responseValue.replace("(Scanned)", "").trim()}
                          </td>
                        )}

                        {/* {Array.isArray(data.responseValue) ? (
                            data.responseValue.map((item: any, ind: any) => {
                              const hasFileExtension = item.response
                                ? item.response?.includes(".")
                                : false;
                              const isButtonClicked = clickedRow === index;

                              return (
                                <>
                                  <td
                                    key={ind}
                                    className="px-6 py-4 whitespace-nowrap"
                                  >
                                    {hasFileExtension ? (
                                      <div>
                                        <button
                                          onMouseEnter={() =>
                                            setClickedRow(
                                              isButtonClicked ? null : index
                                            )
                                          }
                                        >
                                          <i className="bx bx-image-alt text-2xl"></i>
                                        </button>

                                        {isButtonClicked && (
                                          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto dark:bg-transparent">
                                            <div
                                              onMouseLeave={() =>
                                                setClickedRow(null)
                                              }
                                              className="fixed inset-0 bg-gray-900 opacity-50 dark:bg-gray-800"
                                            ></div>
                                            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mx-auto max-w-3xl relative">
                                              <div className="flex flex-col items-start">
                                                
                                                
                                                <div className="absolute top-7 left-8 text-[13px] text-white">
                                                  
                                                 
                                                  <p>{data.clientLocation}</p>
                                                  <p>
                                                    {moment
                                                      .tz(
                                                        data.createdAt,
                                                        "Asia/Kolkata"
                                                      )
                                                      .format(
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      )}
                                                  </p>
                                                </div>
                                                <img
                                                  src={`${BASE_URL}/retrieve/${item.response}`}
                                                  alt="my-img"
                                                  className="max-w-full max-h-[80vh] rounded-lg shadow-md"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ) : (
                                      (item.response &&
                                        item.response
                                          .replace("(Scanned)", "")
                                          .trim()) ??
                                      "-"
                                    )}
                                  </td>
                                </>
                              );
                            })
                          ) : (
                            <td className="px-6 py-4 whitespace-nowrap">
                              {data.responseValue &&
                                data.responseValue
                                  .replace("(Scanned)", "")
                                  .trim()}
                            </td>
                          )
                        } */}
                        <td className="px-6 py-4 whitespace-nowrap">
                          {value.find((item: any) => item._id === data.user)?.fullname ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {value.find((item: any) => item._id === data.user)?.mobile ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data.clientLocation ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data.dealerRemark ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data.dealerFeedback ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap" contentEditable="true" onBlur={() => {

                          // handleRemarkUpdate((data.responseId !== "-" && data.responseId !== undefined && data.responseId !== "") ? data.responseId : data.dynamicDataId)
                          if (data.responseId !== "-" && data.responseId !== undefined && data.responseId !== "") {
                            handleRemarkUpdate(data.responseId)
                          } else {
                            if (data.dynamicDataId !== "-") {
                              handleDynamicRemarkUpdate(data.dynamicDataId)
                            }
                          }


                        }}
                          onInput={handleRemarkChange}>
                          {data.faRemark ?? "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data.documentUrl?.length !== 0 && data.documentUrl !== "-" ? (
                            <button onClick={(e) => {
                              console.log("thisisifdjgjkdfhgj", data.documentUrl)
                              e.preventDefault();
                              handleDocumentsDownload(data.documentUrl)
                            }} className="text-[#8d38eb] text-center font-bold">
                              Download
                            </button>
                          ) : (
                            "-"
                          )}
                        </td>


                        <td className="px-6 py-4 whitespace-nowrap">
                          {
                            moment.tz(data.createdAt, "Asia/Kolkata").format('DD-MM-YYYY HH:mm:ss')

                          }
                        </td>
                        {
                          filteredNewCarStockData[index] ? (
                            Object.values(filteredNewCarStockData[filteredNewCarStockData.findIndex((item: any) => {
                              return Object.values(item?.data).some(value => value === data.responseAgainstCdr);
                            })]?.data || []).slice(1).map((val: any, idx: any) => (
                              <td className="px-6 py-4 whitespace-nowrap" key={idx}>
                                {val}
                              </td>
                            ))
                          ) : (
                            keys.slice(1).map((item: any, idx: any) => (
                              <td className="px-6 py-4 whitespace-nowrap" key={idx}>-</td>
                            ))
                          )
                          // allSubProjectData.filter((subProject: any) => subProject.data.VIN === data.detailValue).length > 0 ? (
                          //   allSubProjectData.filter((subProject: any) => subProject.data.VIN === data.detailValue).map((subProject: any, subIndex: any) => (
                          //     Object.entries(subProject.data).filter(([key, _]) => key !== 'SNo.').map(([key, val], idx) => (
                          //       <td className="px-6 py-4 whitespace-nowrap" key={idx}>
                          //  {val as React.ReactNode}
                          //       </td>
                          //     ))
                          //   ))
                          // ) : (
                          //   keys.slice(1).map((item: any) => <td className="px-6 py-4 whitespace-nowrap">-</td>)
                          // )
                          // 
                        }
                      </tr>
                    ))
                  : filteredCombinedData.map((data: any, index: any) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {/* {data.clientLocation ?? "-"} */}
                        <div>
                          <button
                            title="Edit"
                            onClick={() => {
                              navigate(
                                `/form/${data.auditItemFormId}/audititem_response_view/${data.responseId}`
                              );
                            }}
                          >
                            <i className="bx bx-edit-alt text-xl text-green-500 mr-4"></i>
                          </button>
                          <button
                            title="Delete"
                            onClick={() => {
                              const confirmed = window.confirm(
                                "Are you sure you want to delete this item?"
                              );
                              if (confirmed) {
                                if (
                                  currentUserData?.category.category ===
                                  "Office Auditor"
                                ) {
                                  toast("Access Denied!.", {
                                    type: "error",
                                    className:
                                      "dark:bg-gray-900 dark:text-white",
                                  });
                                } else {
                                  deleteResponseById(data.responseId)
                                    .then((res: any) => {
                                      toast(
                                        "Response Deleted Successfully!",
                                        {
                                          type: "success",
                                          className:
                                            "dark:bg-gray-900 dark:text-white z-[1000]",
                                        }
                                      );
                                      window.location.reload();
                                    })
                                    .catch((error: any) => {
                                      toast("Error Deleting Response!", {
                                        type: "error",
                                        className:
                                          "dark:bg-gray-900 dark:text-white z-[1000]",
                                      });
                                    });
                                }
                                // setDummy101(true);
                              }
                            }}
                          >
                            {" "}
                            <i className="bx bx-trash text-xl text-red-500"></i>
                          </button>
                          <button
                            onClick={() => {
                              setShowModalForVersion(!showModalForVersion);
                              setResponseId(data.responseId);
                            }}
                          >
                            <FaHistory
                              className={`text-lg ${versionHistoryData &&
                                versionHistoryData.data?.filter(
                                  (entry: any) =>
                                    entry.documentId === data.responseId
                                ).length > 0
                                ? "text-purple-500"
                                : "text-gray-500 dark:text-white"
                                } mx-4`}
                            />
                          </button>
                        </div>
                      </td>
                      {auditItemsList.list?.length &&
                        formResponses?.length &&
                        auditItemsList.list?.filter(
                          (item: any) =>
                            item._id === formResponses[0].auditItemFormId
                        )[0].reportType === "CDR Report" ? null : (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data.detailValue}
                        </td>
                      )}

                      {auditItemsList?.list &&
                        formResponses[0]?.auditItemFormId ? (
                        auditItemsList.list?.filter(
                          (item: any) =>
                            item._id === formResponses[0].auditItemFormId
                        )[0].reportType === "CDR Report" ? null : (
                          <td className="px-6 py-4 whitespace-nowrap">
                            {data.mannualScan ?? "-"}
                          </td>
                        )
                      ) : null}
                      {auditItemsList.list?.length &&
                        formResponses?.length &&
                        auditItemsList.list?.filter(
                          (item: any) =>
                            item._id === formResponses[0].auditItemFormId
                        )[0].reportType === "CDR Report" ? null : (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {!data.responseValue.some((item: any) => item.dash)
                            ? foundOrNotFoundList[count++]
                            : "Not Found"}
                        </td>
                      )}

                      {Array.isArray(data.responseValue) ? (
                        data?.responseValue?.map((item: any, ind: any) => {
                          const hasFileExtension = item.response
                            ? item.response?.includes(".")
                            : false;
                          const uniqueKey = `${index}-${item.response}`;
                          const isButtonClicked = clickedImage === uniqueKey;

                          return (
                            <>
                              <td
                                key={uniqueKey}
                                className="px-6 py-4 whitespace-nowrap"
                              >
                                {hasFileExtension ? (
                                  <div>
                                    <button
                                      onMouseEnter={() =>
                                        setClickedImage(
                                          isButtonClicked ? null : uniqueKey
                                        )
                                      }
                                    >
                                      <i className="bx bx-image-alt text-2xl"></i>
                                    </button>

                                    {isButtonClicked && (
                                      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto dark:bg-transparent">
                                        <div
                                          onMouseLeave={() =>
                                            setClickedImage(null)
                                          }
                                          className="fixed inset-0 bg-gray-900 opacity-50 dark:bg-gray-800"
                                        ></div>
                                        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mx-auto max-w-3xl relative">
                                          <div className="flex flex-col items-start">
                                            {" "}
                                            {/* Container div */}
                                            <div className="absolute top-7 left-8 text-[13px] text-white">
                                              {" "}
                                              {/* Location and Timestamp */}
                                              <p>{data.clientLocation}</p>
                                              <p>
                                                {moment
                                                  .tz(
                                                    data.createdAt,
                                                    "Asia/Kolkata"
                                                  )
                                                  .format(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                  )}
                                              </p>
                                            </div>
                                            <img
                                              src={`${BASE_URL}/retrieve/${item.response}`}
                                              alt="my-img"
                                              className="max-w-full max-h-[80vh] rounded-lg shadow-md"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  (item.response &&
                                    item.response
                                      .replace("(Scanned)", "")
                                      .trim()) ??
                                  "-"
                                )}
                              </td>
                            </>
                          );
                        })
                      ) : (
                        <td className="px-6 py-4 whitespace-nowrap">
                          {data.responseValue &&
                            data.responseValue
                              .replace("(Scanned)", "")
                              .trim()}
                        </td>
                      )}
                      <td className="px-6 py-4 whitespace-nowrap">
                        {value.find((item: any) => item._id === data.user)
                          ?.fullname ?? "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {value.find((item: any) => item._id === data.user)
                          ?.mobile ?? "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {data.clientLocation ?? "-"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap"
                        contentEditable="true"
                        onBlur={() => {
                          if (
                            data.responseId !== "-" &&
                            data.responseId !== undefined &&
                            data.responseId !== ""
                          ) {
                            handleRemarkResUpdate(data.responseId);
                          } else {
                            if (data.dynamicDataId !== "-") {
                              handleDynamicRemarkResUpdate(
                                data.dynamicDataId
                              );
                            }
                          }
                        }}
                        onInput={handleRemarkResChange}
                      >
                        {data.dealerRemark ?? "-"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap"
                        contentEditable="true"
                        onBlur={() => {
                          if (
                            data.responseId !== "-" &&
                            data.responseId !== undefined &&
                            data.responseId !== ""
                          ) {
                            handleFeedbackUpdate(data.responseId);
                          } else {
                            if (data.dynamicDataId !== "-") {
                              handleDynamicFeedbackUpdate(data.dynamicDataId);
                            }
                          }
                        }}
                        onInput={handleFeedbackResChange}
                      >
                        {data.dealerFeedback ?? "-"}
                      </td>
                      <td
                        className="px-6 py-4 whitespace-nowrap"
                        contentEditable="true"
                        onBlur={() => {
                          if (
                            data.responseId !== "-" &&
                            data.responseId !== undefined &&
                            data.responseId !== ""
                          ) {
                            handleRemarkUpdate(data.responseId);
                          } else {
                            if (data.dynamicDataId !== "-") {
                              handleDynamicRemarkUpdate(data.dynamicDataId);
                            }
                          }
                        }}
                        onInput={handleRemarkChange}
                      >
                        {data.faRemark ?? "-"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {data.documentUrl?.length !== 0 &&
                          data.documentUrl !== "-" ? (
                          <button
                            onClick={(e) => {
                              console.log(
                                "thisisifdjgjkdfhgj",
                                data.documentUrl
                              );
                              e.preventDefault();
                              handleDocumentsDownload(data.documentUrl);
                            }}
                            className="text-[#8d38eb] text-center font-bold"
                          >
                            Download
                          </button>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        {
                          moment.tz(data.createdAt, "Asia/Kolkata").format('DD-MM-YYYY HH:mm:ss')

                        }
                      </td>
                      {
                        // filteredNewCarStockData[index] ? (
                        //   Object.values(filteredNewCarStockData[index].data).slice(1).map((val: any, idx: any) => (

                        //     <td className="px-6 py-4 whitespace-nowrap" key={idx}>
                        //       {val}
                        //     </td>
                        //   ))
                        // ) : (

                        //   keys.slice(1).map((item: any) => <td className="px-6 py-4 whitespace-nowrap">-</td>)
                        // )
                        allSubProjectData.filter((subProject: any) => subProject.data.VIN === data.detailValue).length > 0 ? (
                          allSubProjectData.filter((subProject: any) => subProject.data.VIN === data.detailValue).map((subProject: any, subIndex: any) => (
                            Object.entries(subProject.data).filter(([key, _]) => key !== 'SNo.').map(([key, val], idx) => (
                              <td className="px-6 py-4 whitespace-nowrap" key={idx}>
                                {val as React.ReactNode}
                              </td>
                            ))
                          ))
                        ) : (
                          keys.slice(1).map((item: any) => <td className="px-6 py-4 whitespace-nowrap">-</td>)
                        )
                      }
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default AuditItemResponse;
