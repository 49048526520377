import React, { Fragment, useState, useEffect } from "react";
import styles from "../pages/FormBuilder.module.scss";
import Sidebar from "Dashboard/partials/Sidebar";
import Header from "Dashboard/partials/Header";
import DropDown from "components/DropDown";
import { darkMode } from "../css/tailwind.config";
import { Link, useParams, useNavigate } from "react-router-dom";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import Avatar from "components/Avatar";
import { PuffLoader } from "react-spinners";
const DealerSetup = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [auditId, setAuditId] = useState("");
  const [dealerData, setDealerData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [subProjectData, setSubProjectData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { level0Id, level1Id, dealergroup_code } = useParams();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [updateAssignedUser, setUpdateAssignedUser] = useState(false);
  const [currentUserData, setCurrentUserData] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [value, setValue] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [filterDrop, setFilterDrop] = useState();
  const [drop, setDrop] = useState({});
  const [valueOptions, setValueOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [assignedData, setAssignedData] = useState([]);
  const [clickedId, setClickedId] = useState(null);
  const [dummy, setDummy] = useState(false);

  const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

  useEffect(() => {
    const userId = sessionStorage.getItem("user");
    if (userId) {
      const response = axios
        .get(`${BASE_URL}/user-details/${userId}`)
        .then((response) => {
          console.log("this is a user res", response.data);
          setCurrentUserData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const navigate = useNavigate();
  console.log("thisisdeal*******", dealerData);
  const filteredData = dealerData.filter(
    ({
      mapToDealerGroup,
      dealer_Name,
      dealer_Code,
      address,
      Gst_No,
      Outlet_Type,
      gmname,
      gmphone,
      gmemail,
      ownername,
      ownerphone,
      owneremail,
      smname,
      smphone,
      smemail,
      accname,
      accphone,
      accemail,
      edpname,
      edpphone,
      edpemail,
      tsmname,
      tsmphone,
      tsmemail,
      dealerreceptionno,
      Assoc_Firm_Name,
      Assoc_Firm_Contact_name,
      Assoc_Firm_Contact_No,
      Assoc_Firm_Contact_Email,
      Assoc_Exec_Name,
      Assoc_Exec_Contact_No,
      Assoc_Exec_Email,
      auditexecutivelandlineno,
      cityname,
      statename,
      zonename,
    }) => {
      const query = searchQuery.toLowerCase();
      return (
        mapToDealerGroup?.toLowerCase()?.includes(query) ||
        dealer_Name?.toLowerCase()?.includes(query) ||
        dealer_Code?.toLowerCase()?.includes(query) ||
        address?.toLowerCase().includes(query) ||
        Gst_No?.toLowerCase().includes(query) ||
        Outlet_Type?.toLowerCase().includes(query) ||
        gmname?.toLowerCase().includes(query) ||
        gmphone?.toLowerCase().includes(query) ||
        gmemail?.toLowerCase().includes(query) ||
        ownername?.toLowerCase().includes(query) ||
        ownerphone?.toLowerCase().includes(query) ||
        owneremail?.toLowerCase().includes(query) ||
        smname?.toLowerCase().includes(query) ||
        smphone?.toLowerCase().includes(query) ||
        smemail?.toLowerCase().includes(query) ||
        accname?.toLowerCase().includes(query) ||
        accphone?.toLowerCase().includes(query) ||
        accemail?.toLowerCase().includes(query) ||
        edpname?.toLowerCase().includes(query) ||
        edpphone?.toLowerCase().includes(query) ||
        edpemail?.toLowerCase().includes(query) ||
        tsmname?.toLowerCase().includes(query) ||
        tsmphone?.toLowerCase().includes(query) ||
        tsmemail?.toLowerCase().includes(query) ||
        dealerreceptionno?.toLowerCase().includes(query) ||
        Assoc_Firm_Name?.toLowerCase().includes(query) ||
        Assoc_Firm_Contact_name?.toLowerCase().includes(query) ||
        Assoc_Firm_Contact_No?.toLowerCase().includes(query) ||
        Assoc_Firm_Contact_Email?.toLowerCase().includes(query) ||
        Assoc_Exec_Name?.toLowerCase().includes(query) ||
        Assoc_Exec_Contact_No?.toLowerCase().includes(query) ||
        Assoc_Exec_Email?.toLowerCase().includes(query) ||
        auditexecutivelandlineno?.toLowerCase().includes(query) ||
        cityname?.toLowerCase().includes(query) ||
        statename?.toLowerCase().includes(query) ||
        zonename?.toLowerCase().includes(query)
      );
    }
  );
  const options5 = filterDrop?.map((dataItem) => {
    return { value: dataItem._id, label: dataItem.category };
  });
  const options2 = subProjectData?.map((dataItem) => {
    return { value: dataItem.id, label: dataItem.title };
  });
  const options = valueOptions.map((dataItem) => {
    return {
      value: dataItem._id,
      label: dataItem.fullname,
      role: dataItem?.category?.category,
    };
  });
  useEffect(() => {
    async function fetchData() {
      try {
        console.log("hello sign");
        const dropval = await axios.get(`${BASE_URL}/dropdownvalue`);
        const storeddrop = dropval.data;
        setDrop(storeddrop);
        setFilterDrop(storeddrop);
        console.log("The Value of drop", drop);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        console.log("hello sign");
        const dropval = await axios.get(`${BASE_URL}/dropdownvalue`);
        const storeddrop = dropval.data;
        setDrop(storeddrop);
        setFilterDrop(storeddrop);
        console.log("The Value of drop", drop);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        const sub_data = await axios.get(`${BASE_URL}/api/subproject`);
        setSubProjectData(sub_data.data.data);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, []);
  console.log("thisissubProjectdata", subProjectData);
  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/use-all-details`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        console.log("this is the result from ax", result.data);
        const filteredData = result.data.filter(
          (data) => data.category._id === selectedOption2
        );
        setValueOptions(filteredData);
        setValue(result.data);
        console.log("Result from subproject", filteredData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedOption2]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/dealer3/dealers`);
        const filter = response.data.filter(
          (item) =>
            item.level1Id === level1Id ||
            item.mapToDealerGroup === dealergroup_code
        );
        setDealerData(filter);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [setDealerData, level1Id, showModal]);

  const [formData, setFormData] = useState({
    mapToDealerGroup: "",
    dealer_Name: "",
    dealer_Code: "",
    address: "",
    Gst_No: "",
    Outlet_Type: "",
    gmname: "",
    gmphone: "",
    gmemail: "",
    ownername: "",
    ownerphone: "",
    owneremail: "",
    smname: "",
    smphone: "",
    smemail: "",
    accname: "",
    accphone: "",
    accemail: "",
    edpname: "",
    edpphone: "",
    edpemail: "",
    tsmname: "",
    tsmphone: "",
    tsmemail: "",
    dealerreceptionno: "",
    Assoc_Firm_Name: "",
    Assoc_Firm_Contact_name: "",
    Assoc_Firm_Contact_No: "",
    Assoc_Firm_Contact_Email: "",
    Assoc_Exec_Name: "",
    Assoc_Exec_Contact_No: "",
    Assoc_Exec_Email: "",
    auditexecutivelandlineno: "",
    Level1: "",
    Level0: "",
    cityname: "",
    statename: "",
    zonename: "",
    level0Id: level0Id,
    level1Id: level1Id,
  });
  console.log("thisisformval", formData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
  };
  const handleCreate = async () => {
    if (
      !formData.dealer_Name ||
      !formData.dealer_Code ||
      !formData.mapToDealerGroup 
    ) {
      return;
    }
    try {
      const dataToSend = {
        ...formData,
        level0Id: level0Id,
        level1Id: level1Id,
      };
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/api/dealer3/dealers`,
        dataToSend,
        config
      );
      // Update the masterData state with the new entry
      // setDealerData([...dealerData, response.data.data]);
      // Clear the form fields
      setShowModal(false);

      toast("Dealer Created Successfully!", {
        type: "success",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      setFormData({
        mapToDealerGroup: "",
        dealer_Name: "",
        dealer_Code: "",
        address: "",
        Gst_No: "",
        Outlet_Type: "",
        gmname: "",
        gmphone: "",
        gmemail: "",
        ownername: "",
        ownerphone: "",
        owneremail: "",
        smname: "",
        smphone: "",
        smemail: "",
        accname: "",
        accphone: "",
        accemail: "",
        edpname: "",
        edpphone: "",
        edpemail: "",
        tsmname: "",
        tsmphone: "",
        tsmemail: "",
        dealerreceptionno: "",
        Assoc_Firm_Name: "",
        Assoc_Firm_Contact_name: "",
        Assoc_Firm_Contact_No: "",
        Assoc_Firm_Contact_Email: "",
        Assoc_Exec_Name: "",
        Assoc_Exec_Contact_No: "",
        Assoc_Exec_Email: "",
        auditexecutivelandlineno: "",
        Level1: "",
        Level0: "",
        cityname: "",
        statename: "",
        zonename: "",
        level0Id: level0Id,
        level1Id: level1Id,
      });
    } catch (error) {
      console.error("Error creating entry:", error);
      toast(`Error Creating Dealer ${error.response.data}`, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    }
  };
  const handleEdit = (item) => {
    setFormData({
      mapToDealerGroup: item.mapToDealerGroup,
      dealer_Name: item.dealer_Name,
      dealer_Code: item.dealer_Code,
      address: item.address,
      Gst_No: item.Gst_No,
      Outlet_Type: item.Outlet_Type,
      gmname: item.gmname,
      gmphone: item.gmphone,
      gmemail: item.gmemail,
      ownername: item.ownername,
      ownerphone: item.ownerphone,
      owneremail: item.owneremail,
      smname: item.smname,
      smphone: item.smphone,
      smemail: item.smemail,
      accname: item.accname,
      accphone: item.accphone,
      accemail: item.accemail,
      edpname: item.edpname,
      edpphone: item.edpphone,
      edpemail: item.edpemail,
      tsmname: item.tsmname,
      tsmphone: item.tsmphone,
      tsmemail: item.tsmemail,
      dealerreceptionno: item.dealerreceptionno,
      Assoc_Firm_Name: item.Assoc_Firm_Name,
      Assoc_Firm_Contact_name: item.Assoc_Firm_Contact_name,
      Assoc_Firm_Contact_No: item.Assoc_Exec_Contact_No,
      Assoc_Firm_Contact_Email: item.Assoc_Firm_Contact_Email,
      Assoc_Exec_Name: item.Assoc_Exec_Name,
      Assoc_Exec_Contact_No: item.Assoc_Exec_Contact_No,
      Assoc_Exec_Email: item.Assoc_Exec_Email,
      auditexecutivelandlineno: item.auditexecutivelandlineno,
      cityname: item.cityname,
      statename: item.statename,
      zonename: item.zonename,
    });
    setEditData(item);
    setShowModal(true);
    setEditMode(true);
  };
  const handleUpdate = async () => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      console.log("Updating with editData:", editData); // Add this line for debugging

      const response = await axios.put(
        `${BASE_URL}/api/dealer3/dealers/${editData._id}`,
        formData,
        config
      );

      console.log("Update response:", response); // Add this line for debugging

      // Update dealerData with the updated data from the response
      setDealerData((prevData) =>
        prevData.map((entry) =>
          entry._id === response.data._id ? response.data : entry
        )
      );
      // Reset form and close the modal
      setShowModal(false);
      toast("Dealer Updated Successfully!", {
        type: "success",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      setEditData(null);
      setFormData({
        mapToDealerGroup: "",
        dealer_Name: "",
        dealer_Code: "",
        address: "",
        Gst_No: "",
        Outlet_Type: "",
        gmname: "",
        gmphone: "",
        gmemail: "",
        ownername: "",
        ownerphone: "",
        owneremail: "",
        smname: "",
        smphone: "",
        smemail: "",
        accname: "",
        accphone: "",
        accemail: "",
        edpname: "",
        edpphone: "",
        edpemail: "",
        tsmname: "",
        tsmphone: "",
        tsmemail: "",
        dealerreceptionno: "",
        Assoc_Firm_Name: "",
        Assoc_Firm_Contact_name: "",
        Assoc_Firm_Contact_No: "",
        Assoc_Firm_Contact_Email: "",
        Assoc_Exec_Name: "",
        Assoc_Exec_Contact_No: "",
        Assoc_Exec_Email: "",
        auditexecutivelandlineno: "",
        Level1: "",
        Level0: "",
        cityname: "",
        statename: "",
        zonename: "",
      });
      setShowModal(false);
    } catch (error) {
      console.error("Error updating entry:", error);
      toast(`Error Updating Dealer ${error.response.data}`, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    }
  };
  const handleUpdateAssinedUser = async (itemId) => {
    setUpdateAssignedUser(true);
  };
  const handleUpdateUser = async (itemId) => {
    console.log("thisissubauditid", itemId);
    if (selectedOption === null || selectedOption2 === null) {
      alert("Please select a user first to update!");
    } else {
      try {
        const token = sessionStorage.getItem("auth-token");
        const config = {
          headers: {
            "auth-token": token,
          },
        };
        if (window.confirm("Are you sure you want to update the user?")) {
          // Make the DELETE request
          const filteredSubAudit = filterData.filter((item) => {
            return item.user === itemId && item.subAuditId === auditId;
          });
          console.log("thisisfiltersubauidt", filteredSubAudit[0].id);
          await axios.put(
            `${BASE_URL}/api/subaudit/${filteredSubAudit[0].id}`,
            {
              user: selectedOption.value,
            },
            config
          );

          toast("User updated successfully", {
            type: "success",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
          setUpdateAssignedUser(false);
          await handleAssign();

          setClickedId(null);
        } else {
          setUpdateAssignedUser(false);
          setClickedId(null);
        }
      } catch (err) {
        console.log(err);
        toast("Error while updating", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
    }
  };

  const handleAuditDelete = async (itemId) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      if (window.confirm("Are you sure you want to delete the user?")) {
        // Make the DELETE request
        await axios.delete(
          `${BASE_URL}/api/subaudit/${itemId}/${auditId}`,
          config
        );

        toast("User deleted successfully", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });

        await handleAssign();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleDelete = async (id) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      if (currentUserData?.category.category === "Office Coordinator") {
        toast("Access Denied!.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white",
        });
      } else {
        const response = await axios.delete(
          `${BASE_URL}/api/dealer3/dealers/${id}`,
          config
        );
        console.log("Delete Response:", response);
        setShowModal(false);
        toast("Dealer Deleted Successfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
        setDealerData(dealerData.filter((entry) => entry._id !== id));
      }
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };

  console.log("this is the dealer response", dealerData);

  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const totalPages = filteredData
    ? Math.ceil(filteredData.length / itemsPerPage)
    : 0;

  const paginationButtons = [];
  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );
  // const handleAuditAssign = async (event) => {
  //   event.preventDefault();
    
  //   try {
      
  //     const token = sessionStorage.getItem("auth-token");
  //     const config = {
  //       headers: {
  //         "auth-token": token,
  //       },
  //     };
  //     const secondReq = await axios.post(
  //       `${BASE_URL}/api/subaudit`,
  //       {
  //         platformId: "649e8a5186bc5f1770f9a120",
  //         // auditId: responseData,
  //         dealerId: auditId,
  //         user: selectedOption.value,
  //         subAuditId: auditId, //this will change in mobile application handleCompleteAudit
  //         // subProjectId: selectedOption3,
  //       },
  //       config
  //     );
  //     setShowAssignModal(false);
  //   } catch (error) {
  //     console.error(error); // Handle the error
  //   }
  // };
  const handleAuditAssign = async (event) => {
    event.preventDefault();
  
    try {
      // Check if selectedOption.value matches any assigned audit _id
      const isUserAlreadyAssigned = assignedData.some(
        (assignedAudit) => assignedAudit._id === selectedOption.value
      );
      console.log("is user already assigned", isUserAlreadyAssigned)
      if (isUserAlreadyAssigned) {
        // Show a toast indicating that the user is already assigned
        // You can replace this with your toast implementation
        toast("User Already Assigned!", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-red",
        });
        console.log("User already assigned to this audit");
      } else {
        // If the user is not already assigned, proceed with the audit assignment
        const token = sessionStorage.getItem("auth-token");
        const config = {
          headers: {
            "auth-token": token,
          },
        };
  
        const secondReq = await axios.post(
          `${BASE_URL}/api/subaudit`,
          {
            platformId: "649e8a5186bc5f1770f9a120",
            dealerId: auditId,
            user: selectedOption.value,
            subAuditId: auditId,
          },
          config
        );
        setShowAssignModal(false);
        setSelectedOption(null);
        setSelectedOption2(null);
      }
    } catch (error) {
      console.error(error); // Handle the error
    }
  };
  
  const handleAssign = async () => {
    try {
      setLoading(true); // Set loading state to true before making the API call

      const response = await axios.get(`${BASE_URL}/api/subaudit`);
      console.log("this is the res4564", response);
      const filterData = response.data.data.filter(
        (data) => data.dealerId === auditId && data.isShown === false
      );
      const filteredArray = value.filter((item) =>
        filterData.some((dataItem) => dataItem.user === item._id)
      );
      // / Map through filteredArray to update status key
      const updatedFilteredArray = filteredArray.map((item) => {
        const matchingFilterData = filterData.find(
          (dataItem) => dataItem.user === item._id
        );
        // If a matching filterData item is found, update the status key in the item
        if (matchingFilterData) {
          return {
            ...item,
            status: matchingFilterData.status, // Assuming status is the key in filterData to be added to each item in filteredArray
            subAuditId: matchingFilterData.id,
          };
        }
        return item; // Return the original item if no matching filterData item is found
      });
      setDummy(!dummy);
      // Update the state variables
      setFilterData(filterData);
      setAssignedData(updatedFilteredArray);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Set loading state to false regardless of success or error
    }
  };

  console.log("thisisafilterData", assignedData);
  useEffect(() => {
    handleAssign();
  }, [value, showAssignModal, showModal]);
  const handleStatusChange = async (id) => {
    if (auditId) {
      const response = axios
        .put(`${BASE_URL}/api/subaudits/${id}`, {
          status: "Assigned",
        })
        .then((result) => {
          console.log("this is the result", result);
          toast("Status Updated Successfully!", {
            type: "success",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  console.log("selected option ", selectedOption)
  console.log("assigned Data", assignedData)
  console.log("updat assign user", updateAssignedUser)
  console.log("options", options)
  return (
    <div>
      {showAssignModal && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
              <button
                type="button"
                onClick={() => {
                  setShowAssignModal(false);
                  setUpdateAssignedUser(false);
                  setClickedId(null);
                  setSelectedOption(null);
                  setSelectedOption2(null);
                }}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  {updateAssignedUser
                    ? "Update Assined User"
                    : "Audit Assignment"}
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}
                <>
                  {updateAssignedUser ? (
                    <label
                      htmlFor="email"
                      className="block text-[15px] font-medium text-gray-900 dark:text-white"
                    >
                      Select user to update
                    </label>
                  ) : null}
                  {/* <Select
                    onChange={(event) => {
                      setSelectedOption3(event.value);
                    }}
                    options={options2}
                    placeholder="Select sub-project!"
                    className="my-react-select-container mt-4 rounded-md  "
                    classNamePrefix="my-react-select"
                  /> */}
                  <Select
                    defaultValue={{
                      value: "64a3ce5feed18829472e4278",
                      label: "Field Auditor",
                    }}
                    onChange={(event) => {
                      setSelectedOption2(event.value);
                    }}
                    options={options5}
                    placeholder="Sort by roles"
                    className="my-react-select-container mt-4 rounded-md  "
                    classNamePrefix="my-react-select"
                  />
                  <Select
                    defaultValue={selectedOption}
                    onChange={(event) => setSelectedOption(event)}
                    options={options}
                    placeholder="Select a user..."
                    className="my-react-select-container mt-4 rounded-md "
                    classNamePrefix="my-react-select"
                    components={{
                      DropdownIndicator: () => (
                        <span className="mx-4">
                          {" "}
                          {selectedOption === null
                            ? "Roles"
                            : `${selectedOption?.role}`}
                        </span>
                      ),
                    }}
                    // isMulti={true}
                  />
                </>
                {updateAssignedUser ? null : (
                  <button
                    onClick={(e) => {
                      handleAuditAssign(e);
                    }}
                    className="btn mb-5 bg-[#4f46e5] hover:bg-indigo-800 text-white mt-4"
                  >
                    <span className="hidden xs:block ml-2">Assign Audit</span>
                  </button>
                )}

                <br />

                {/*<form>
                    <label
                      htmlFor="default-search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      
                      <input
                        id="default-search"
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-[#4f46e5] focus:border-[#4f46e5] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-white"
                        type="text"
                        placeholder="Search by user name..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <button
                        type="submit"
                        className="text-white absolute right-2.5 bottom-2.5 bg-[#4f46e5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-[#4f46e5] font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#4f46e5] dark:hover:bg-indigo-800 dark:focus:ring-blue-800"
                      >
                        Search
                      </button>
                    </div>
                    </form>*/}

                <div className="max-h-[190px] mt-5  overflow-y-auto scrollbar-hide">
                {searchQuery.length === 0 ? (
                    loading ? (
                      <div className="flex justify-center items-center">
                        <PuffLoader color="#007aff" size={50} />
                      </div>
                    ) : assignedData.length === 0 ? (
                      <div className="flex justify-center items-center">
                        No Data
                      </div>
                    ) : (
                      assignedData.map((data) => (
                        <div className="mb-4">
                          <div
                            id="toast-notification"
                            className="w-full p-4 flex items-center justify-between text-gray-900 bg-white rounded-lg shadow-lg  dark:border-[#94a3b8] dark:bg-gray-800 dark:text-gray-300 border"
                            role="alert"
                          >
                            <div className="flex items-center">
                              <div className="relative inline-block shrink-0">
                                <Avatar userName={data.fullname} />
                              </div>
                              <div className="ml-3 text-sm font-normal">
                              <div className="text-sm font-semibold text-gray-900 dark:text-white">
                                  {`${data.fullname}`}
                                </div>
                                <div className="text-sm font-normal">
                                  {data?.category?.category}
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-center items-center">
                            <div className="text-sm font-semibold text-gray-900 dark:text-white">
                                  {`${data.status}`}
                                </div>
                            {/* // TODO: Need to fix this later  */}
                              {/* <button
                                className="ml-3 text-green-500 hover:text-green-700"
                                onClick={() => {
                                  handleStatusChange(data.subAuditId);
                                }}
                              >
                                <i className="bx bx-refresh text-2xl"></i>
                              </button> */}

                              {/* {clickedId === data._id ? (
                                <button
                                  className="ml-3 text-green-500 hover:text-green-700"
                                  onClick={() => {
                                    handleUpdateUser(data._id);
                                  }}
                                >
                                  <i className="bx bx-user-check text-xl"></i>
                                </button>
                              ) : null}
                              {clickedId === data._id ? null : (
                                <button
                                  className="ml-3 text-[#6366f1] hover:text-blue-700"
                                  onClick={() => {
                                    setClickedId(data._id);
                                    handleUpdateAssinedUser(data._id);
                                  }}
                                >
                                  <i className="bx bx-edit text-xl"></i>
                                </button>
                              )}

                              <button
                                className="ml-3 text-red-500 hover:text-red-700"
                                onClick={() => {
                                  handleAuditDelete(data._id);
                                }}
                              >
                                <i className="bx bx-trash text-xl"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                      ))
                    )
                  ) : filteredData.length > 0 ? (
                    filteredData.map((data) => (
                      <div className="mb-4">
                        <div
                          id="toast-notification"
                          className="w-full p-4 flex items-center justify-between text-gray-900 bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-300"
                          role="alert"
                        >
                          <div className="flex items-center">
                            <div className="relative inline-block shrink-0">
                              <Avatar userName={data.fullname} />
                            </div>
                            <div className="ml-3 text-sm font-normal">
                              <div className="text-sm font-semibold text-gray-900 dark:text-white">
                                {data.fullname}
                              </div>
                              <div className="text-sm font-normal">
                                {data?.category?.category}
                              </div>
                            </div>
                          </div>
                          <button
                            className="ml-3 text-red-500 hover:text-red-700"
                            onClick={() => {
                              handleDelete(data._id);
                            }} // Add your delete function here
                          >
                            <i className="bx bx-trash text-xl"></i>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center items-center">
                      {loading ? (
                        <PuffLoader color="#fff" size={50} />
                      ) : (
                        <p>No data</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${
          showModal === false && showAssignModal === false ? "" : `blur-sm`
        } flex h-screen overflow-hidden visible`}
      >
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div
            id="alert-border-1"
            className="flex justify-between items-center p-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
            role="alert"
          >
            <div className="flex justify-center items-center">
              <svg
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div className="ml-3 text-sm font-medium">
                <strong>Dealer Setup Screen</strong>
              </div>
            </div>
            <div>
              <p className="flex justify-center items-center  font-semibold">
                <i className="bx bx-chevrons-right text-2xl"></i>
                {dealergroup_code}
              </p>
            </div>
          </div>
          {/* <WelcomeBanner /> */}
          <div>
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 m-5">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="px-4 py-2 w-64 h-10 rounded-l-lg rounded-r-lg border border-gray-300 dark:border-gray-500  bg-white text-gray-800 placeholder-gray-400 focus:outline-none dark:bg-transparent dark:text-white dark:placeholder-gray-500"
                />
                <button
                  className="absolute inset-y-0 right-0 px-4 h-10 text-sm font-medium  bg-indigo-500 hover:bg-indigo-500 rounded-r-lg focus:border-primary-400  text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 py-2 text-center"
                  type="button"
                  id="button-addon1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <button
                className=" text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                onClick={() => {
                  setEditMode(false);
                  setEditData(null);
                  setShowModal(true);
                  setFormData({
                    mapToDealerGroup: "",
                    dealer_Name: "",
                    dealer_Code: "",
                    address: "",
                    Gst_No: "",
                    Outlet_Type: "",
                    gmname: "",
                    gmphone: "",
                    gmemail: "",
                    ownername: "",
                    ownerphone: "",
                    owneremail: "",
                    smname: "",
                    smphone: "",
                    smemail: "",
                    accname: "",
                    accphone: "",
                    accemail: "",
                    edpname: "",
                    edpphone: "",
                    edpemail: "",
                    tsmname: "",
                    tsmphone: "",
                    tsmemail: "",
                    dealerreceptionno: "",
                    Assoc_Firm_Name: "",
                    Assoc_Firm_Contact_name: "",
                    Assoc_Firm_Contact_No: "",
                    Assoc_Firm_Contact_Email: "",
                    Assoc_Exec_Name: "",
                    Assoc_Exec_Contact_No: "",
                    Assoc_Exec_Email: "",
                    auditexecutivelandlineno: "",
                    Level1: "",
                    Level0: "",
                    cityname: "",
                    statename: "",
                    zonename: "",
                  });
                }}
              >
                <svg
                  className="w-4 h-4 fill-current opacity-50 shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">Add New Dealer</span>
              </button>
            </div>
            <nav className="flex mx-6 " aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <a
                    href="#"
                    className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <button
                      onClick={() => {
                        window.history.go(-2);
                      }}
                      to="/projects"
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      OEM Data
                    </button>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <button
                      onClick={() => {
                        window.history.back();
                      }}
                      to="/projects"
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      Dealer Group Data
                    </button>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                      Dealer Data
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <div className="flex justify-between mt-8 mx-6">
              <div>
                Page {page} of {totalPages}
              </div>
              <div className="flex items-center gap-1">{paginationButtons}</div>
            </div>
            <div
              className="mt-5 mx-5 shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 text-center"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <div>
                <table className="w-full text-sm text-center text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 whitespace-nowrap">
                    <tr className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700">
                      <th className="py-0">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Actions
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md text-gray-800 dark:text-white">
                          Map To Dealer Group
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md text-gray-800 dark:text-white">
                          Dealer_Name
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md text-gray-800 dark:text-white">
                          Dealer_Code
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Address
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Gst_No
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Outlet_Type
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          gmname
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          gmphone
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          gmemail
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          ownername
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          ownerphone
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          owneremail
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          smname
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          smphone
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          smemail
                        </span>
                      </th>

                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          accname
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          accphone
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          accemail
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          edpname
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          edpphone
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          edpemail
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          tsmname
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          tsmphone
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          tsmemail
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          dealerreceptionno
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Assoc_Firm_Name
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Assoc_Firm_Contact_name
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Assoc_Firm_Contact_No
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Assoc_Firm_Contact_Email
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Assoc_Exec_Name
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Assoc_Exec_Contact_No
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Assoc_Exec_Email
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          auditexecutivelandlineno
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          cityname
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          statename
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          zonename
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length === 0 ? (
                      <tr className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700">
                        <td colSpan={5} align="center">
                          <span className="text-gray-500 dark:text-gray-400">
                            No Records Found
                          </span>
                        </td>
                      </tr>
                    ) : (
                      filteredData
                        .slice(startIndex, endIndex)
                        .map(
                          ({
                            _id,
                            mapToDealerGroup,
                            dealer_Name,
                            dealer_Code,
                            address,
                            Gst_No,
                            Outlet_Type,
                            gmname,
                            gmphone,
                            gmemail,
                            ownername,
                            ownerphone,
                            owneremail,
                            smname,
                            smphone,
                            smemail,
                            accname,
                            accphone,
                            accemail,
                            edpname,
                            edpphone,
                            edpemail,
                            tsmname,
                            tsmphone,
                            tsmemail,
                            dealerreceptionno,
                            Assoc_Firm_Name,
                            Assoc_Firm_Contact_name,
                            Assoc_Firm_Contact_No,
                            Assoc_Firm_Contact_Email,
                            Assoc_Exec_Name,
                            Assoc_Exec_Contact_No,
                            Assoc_Exec_Email,
                            auditexecutivelandlineno,
                            cityname,
                            statename,
                            zonename,
                          }) => {
                            return (
                              <Fragment key={_id}>
                                <tr
                                  //onClick={() => navigateToForm(_id)}
                                  className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                                >
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div>
                                      <i
                                        id={`dropdown-${_id}`}
                                        className="bx-dots-vertical-rounded cursor-pointer text-gray-500 dark:text-gray-400 hover:bg-slate-800"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setAuditId(_id);

                                          handleAssign();
                                        }}
                                      ></i>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {mapToDealerGroup}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {dealer_Name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {dealer_Code}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {address}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Gst_No}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Outlet_Type}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {gmname}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {gmphone}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {gmemail}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {ownername}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {ownerphone}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {owneremail}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {smname}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {smphone}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {smemail}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {accname}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {accphone}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {accemail}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {edpname}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {edpphone}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {edpemail}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {tsmname}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {tsmphone}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {tsmemail}
                                  </td>{" "}
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {dealerreceptionno}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Assoc_Firm_Name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Assoc_Firm_Contact_name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Assoc_Firm_Contact_No}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Assoc_Firm_Contact_Email}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Assoc_Exec_Name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Assoc_Exec_Contact_No}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {Assoc_Exec_Email}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {auditexecutivelandlineno}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {cityname}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {statename}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {zonename}
                                  </td>
                                </tr>
                                {/* {dealerData.map((item) => ( */}
                                {/* <div key={item._id}> */}
                                {/* Render item details */}
                                <DropDown
                                  selector={`#dropdown-${_id}`}
                                  placement="bottom"
                                  className={styles.dropdown}
                                >
                                  {/* <DropDown.Item>
                                        <button
                                          onClick={() => {
                                            navigate(
                                              `/executive_master/${_id}`
                                            );
                                          }}
                                        >
                                          <i className="bx-link-external"></i>
                                          <span className="ml-2">
                                            Executive
                                          </span>
                                        </button>
                                      </DropDown.Item> */}
                                  <DropDown.Item
                                    onClick={() => {
                                      setShowAssignModal(true);
                                      setAuditId(_id);
                                    }}
                                  >
                                    {/* <button clasName="flex justify-center items-center"> */}
                                    <i className="bi bi-kanban"></i>
                                    <span className="ml-1">Assign Audit</span>
                                    {/* </button> */}
                                  </DropDown.Item>

                                  <DropDown.Item
                                    onClick={() =>
                                      handleEdit({
                                        _id,
                                        mapToDealerGroup,
                                        dealer_Name,
                                        dealer_Code,
                                        address,
                                        Gst_No,
                                        Outlet_Type,
                                        gmname,
                                        gmphone,
                                        gmemail,
                                        ownername,
                                        ownerphone,
                                        owneremail,
                                        smname,
                                        smphone,
                                        smemail,
                                        accname,
                                        accphone,
                                        accemail,
                                        edpname,
                                        edpphone,
                                        edpemail,
                                        tsmname,
                                        tsmphone,
                                        tsmemail,
                                        dealerreceptionno,
                                        Assoc_Firm_Name,
                                        Assoc_Firm_Contact_name,
                                        Assoc_Firm_Contact_No,
                                        Assoc_Firm_Contact_Email,
                                        Assoc_Exec_Name,
                                        Assoc_Exec_Contact_No,
                                        Assoc_Exec_Email,
                                        auditexecutivelandlineno,
                                        cityname,
                                        statename,
                                        zonename,
                                      })
                                    }
                                  >
                                    {/* <button className="flex justify-center items-center"> */}
                                    <i className="bi bi-pencil-square"></i>
                                    <span className="ml-1">Update</span>
                                    {/* </button> */}
                                  </DropDown.Item>

                                  <DropDown.Item
                                    onClick={() => handleDelete(_id)}
                                  >
                                    <i className="bx-trash"></i>
                                    <span className="ml-1">Delete</span>
                                  </DropDown.Item>
                                </DropDown>
                                {/* </div> */}
                                {/* ))} */}
                              </Fragment>
                            );
                          }
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* <div
              className={`bg-white dark:bg-slate-900 ${
                darkMode ? styles.form2 : styles.form
              }`}
            > */}
            {/* <div className="flex justify-center mt-8">
                {/* <div>
                          Page {page} of {totalPages}
                        </div> */}
            {/* <div className="flex justify-between mt-8 px-3">
                  <div className="flex items-center gap-1">
                    {paginationButtons}
                  </div>
                </div>
              </div> */}
            {/* </div> */}
          </div>
          <div className="max-w-screen-xl mx-auto px-4 md:px-8"></div>
        </div>
        {/*modal*/}
      </div>
      <div>
        {showModal && (
          <div
            id="crypto-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="
        flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full my-auto
        "
          >
            <div className="relative w-full max-w-md max-h-full">
              {/* Modal content */}
              <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                {/* Modal header */}
                <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                    {editMode ? "Edit Dealer Data" : "Add Dealer Data"}
                  </h3>
                </div>
                {/* Modal body */}
                <div className="p-6">
                  <form onSubmit={handleSubmit}>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="dealer_Name"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Map To Dealer Group <span className="text-red-500 "> *</span>
                      </label>
                      <input
                        type="text"
                        id="mapToDealerGroup"
                        name="mapToDealerGroup"
                        value={formData.mapToDealerGroup}
                        onChange={handleInputChange}
                        required
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="dealer_Name"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        dealer_Name <span className="text-red-500 "> *</span>
                      </label>
                      <input
                        type="text"
                        id="dealer_Name"
                        name="dealer_Name"
                        value={formData.dealer_Name}
                        onChange={handleInputChange}
                        required
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="dealer_Code"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        dealer_Code <span className="text-red-500 "> *</span>
                      </label>
                      <input
                        type="text"
                        id="dealer_Code"
                        name="dealer_Code"
                        value={formData.dealer_Code}
                        onChange={handleInputChange}
                        required
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12">
                      <label
                        htmlFor="Gst_No"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Gst_No
                      </label>
                      <input
                        type="text"
                        id="Gst_No"
                        name="Gst_No"
                        value={formData.Gst_No}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12">
                      <label
                        htmlFor="Outlet_Type"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Outlet_Type
                      </label>
                      <input
                        type="text"
                        id="Outlet_Type"
                        name="Outlet_Type"
                        value={formData.Outlet_Type}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="gmname"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        gmname
                      </label>
                      <input
                        type="text"
                        id="gmname"
                        name="gmname"
                        value={formData.gmname}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="gmphone"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        gmphone
                      </label>
                      <input
                        type="text"
                        id="gmphone"
                        name="gmphone"
                        value={formData.gmphone}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="gmemail"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        gmemail
                      </label>
                      <input
                        type="text"
                        id="gmemail"
                        name="gmemail"
                        value={formData.gmemail}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12">
                      <label
                        htmlFor="ownername"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        ownername
                      </label>
                      <input
                        type="text"
                        id="ownername"
                        name="ownername"
                        value={formData.ownername}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="ownerphone"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        ownerphone
                      </label>
                      <input
                        type="text"
                        id="ownerphone"
                        name="ownerphone"
                        value={formData.ownerphone}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="owneremail"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        owneremail
                      </label>
                      <input
                        type="text"
                        id="owneremail"
                        name="owneremail"
                        value={formData.owneremail}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="smname"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        smname
                      </label>
                      <input
                        type="text"
                        id="smname"
                        name="smname"
                        value={formData.smname}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="smphone"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        smphone
                      </label>
                      <input
                        type="text"
                        id="smphone"
                        name="smphone"
                        value={formData.smphone}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="smemail"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        smemail
                      </label>
                      <input
                        type="text"
                        id="smemail"
                        name="smemail"
                        value={formData.smemail}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="accname"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        accname
                      </label>
                      <input
                        type="text"
                        id="accname"
                        name="accname"
                        value={formData.accname}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="accphone"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        accphone
                      </label>
                      <input
                        type="text"
                        id="accphone"
                        name="accphone"
                        value={formData.accphone}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="accemail"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        accemail
                      </label>
                      <input
                        type="text"
                        id="accemail"
                        name="accemail"
                        value={formData.accemail}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="edpname"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        edpname
                      </label>
                      <input
                        type="text"
                        id="edpname"
                        name="edpname"
                        value={formData.edpname}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="edpphone"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        edpphone
                      </label>
                      <input
                        type="text"
                        id="edpphone"
                        name="edpphone"
                        value={formData.edpphone}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="edpemail"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        edpemail
                      </label>
                      <input
                        type="text"
                        id="edpemail"
                        name="edpemail"
                        value={formData.edpemail}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="tsmname"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        tsmname
                      </label>
                      <input
                        type="text"
                        id="tsmname"
                        name="tsmname"
                        value={formData.tsmname}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="tsmphone"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        tsmphone
                      </label>
                      <input
                        type="text"
                        id="tsmphone"
                        name="tsmphone"
                        value={formData.tsmphone}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="tsmemail"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        tsmemail
                      </label>
                      <input
                        type="text"
                        id="tsmemail"
                        name="tsmemail"
                        value={formData.tsmemail}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="dealerreceptionno"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        dealerreceptionno
                      </label>
                      <input
                        type="text"
                        id="dealerreceptionno"
                        name="dealerreceptionno"
                        value={formData.dealerreceptionno}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="Assoc_Firm_Name"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Assoc_Firm_Name
                      </label>
                      <input
                        type="text"
                        id="Assoc_Firm_Name"
                        name="Assoc_Firm_Name"
                        value={formData.Assoc_Firm_Name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="Assoc_Firm_Contact_name"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Assoc_Firm_Contact_name
                      </label>
                      <input
                        type="text"
                        id="Assoc_Firm_Contact_name"
                        name="Assoc_Firm_Contact_name"
                        value={formData.Assoc_Firm_Contact_name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="Assoc_Firm_Contact_No"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Assoc_Firm_Contact_No
                      </label>
                      <input
                        type="text"
                        id="Assoc_Firm_Contact_No"
                        name="Assoc_Firm_Contact_No"
                        value={formData.Assoc_Firm_Contact_No}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="Assoc_Firm_Contact_Email"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Assoc_Firm_Contact_Email
                      </label>
                      <input
                        type="text"
                        id="Assoc_Firm_Contact_Email"
                        name="Assoc_Firm_Contact_Email"
                        value={formData.Assoc_Firm_Contact_Email}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="Assoc_Exec_Name"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Assoc_Exec_Name
                      </label>
                      <input
                        type="text"
                        id="Assoc_Exec_Name"
                        name="Assoc_Exec_Name"
                        value={formData.Assoc_Exec_Name}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="Assoc_Exec_Contact_No"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Assoc_Exec_Contact_No
                      </label>
                      <input
                        type="text"
                        id="Assoc_Exec_Contact_No"
                        name="Assoc_Exec_Contact_No"
                        value={formData.Assoc_Exec_Contact_No}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="Assoc_Exec_Email"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Assoc_Exec_Email
                      </label>
                      <input
                        type="text"
                        id="Assoc_Exec_Email"
                        name="Assoc_Exec_Email"
                        value={formData.Assoc_Exec_Email}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="auditexecutivelandlineno"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        auditexecutivelandlineno
                      </label>
                      <input
                        type="text"
                        id="auditexecutivelandlineno"
                        name="auditexecutivelandlineno"
                        value={formData.auditexecutivelandlineno}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="cityname"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        cityname
                      </label>
                      <input
                        type="text"
                        id="cityname"
                        name="cityname"
                        value={formData.cityname}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="statename"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        statename
                      </label>
                      <input
                        type="text"
                        id="statename"
                        name="statename"
                        value={formData.statename}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label
                        htmlFor="zonename"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        zonename
                      </label>
                      <input
                        type="text"
                        id="zonename"
                        name="zonename"
                        value={formData.zonename}
                        onChange={handleInputChange}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                      />
                    </div>
                    <div className="text-red-500 mb-4">
                      {/*{fullnameError ||
                    emailError ||
                    passwordError ||
                  mobileError}*/}
                    </div>
                    <button
                      onClick={editMode ? handleUpdate : handleCreate}
                      type="submit"
                      className="mt-4 text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                    >
                      <i className="bx bx-plus text-xl"></i>
                      <span className="hidden xs:block ml-2">
                        {editMode ? "Update Dealer Data" : "Create Dealer Data"}
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DealerSetup;
