import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "./Nabar";
import Signup from "./Signup";
import AddDataComp from "./AddDataComp";
import debounce from "lodash.debounce";
import DashboardAvatars from "Dashboard/partials/dashboard/DashboardAvatars";
import Datepicker from "Dashboard/components/Datepicker";
import FilterButton from "Dashboard/components/DropdownFilter";
import AddDataRoleComp from "./AddDataRoleComp";
// import {PiUserSwitchDuotone} from "react-icons/pi";
import Select from "react-select";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const AdminControl = () => {
  const [value, setValue] = useState([]);
  const [run, setRun] = useState(true);
  const [check, setCheck] = useState(true);
  const [email, setEmail] = useState("");
  const [fullname, setFullName] = useState("");
  const [passsword, setPassword] = useState("");
  const [edit, setEdit] = useState(true);
  const [idCheck, setIdCheck] = useState("");
  const [addData, setAddData] = useState(false);
  const [addDataRole, setAddDataRole] = useState(false);
  const [value1, setValue1] = useState({});
  const [drop, setDrop] = useState({ category: "Actor" });
  const [filter, setFilter] = useState();
  const [fitlerDrop, setFilterDrop] = useState([]);
  const [status, setStatus] = useState();
  const [editupdate, setEditUpdate] = useState(false);
  const [categoryid, setCategoryId] = useState("");
  const [emailError, setEmailError] = useState("");
  const [fullnameError, setFullNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [search, setSerach] = useState();
  const [controlSerach, setControlSearch] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // for role
  const [change, setChange] = useState(false);
  const [valueRole, setValueRole] = useState({});
  const [dropRole, setDropRole] = useState();
  const [emailErrorRole, setEmailErrorRole] = useState("");
  const [passwordErrorRole, setPasswordErrorRole] = useState("");
  const [fullnameErrorRole, setFullNameErrorRole] = useState("");

  const handleChange = (value) => {
    setValueRole((prev) => ({ ...prev, category: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordErrorRole("");
    setEmailErrorRole("");
    setFullNameErrorRole("");
    console.log(value);
    try {
      const res = await axios
        .post(`${BASE_URL}/api/categories`, valueRole)
        .then((result) => {
          if (result.status === 201) {
            alert("Data Added Successfully");
          }
        });
      // console.log(res)
    } catch (err) {
      console.log(err);
    }
    // console.log({ emailError: e.response.data.errors.email, passwrodError: e.response.data.errors.password })
  };
  useEffect(() => {
    async function fetchData() {
      try {
        console.log("hello sign");
        const dropval = await axios.get(
          `${BASE_URL}/dropdownvalue`
        );
        const storeddrop = dropval.data;
        setDropRole(storeddrop);
        console.log("The Value of drop", drop);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, [change]);

  // role end here
  const location = useLocation();
  console.log("This is the value from the admin Control page", location);

  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    console.log("Value of filter", filter);
    axios
      .get(
        `${BASE_URL}/filter/${filter}`,
        token && {
          headers: {
            "auth-token": token,
          },
        }
      )
      .then((result) => {
        console.log("Result after submit", result);
        setValue(result.data);
      });
  }, [filter]);

  useEffect(() => {
    // console.log("${BASE_URL}/all-details")
    const token = sessionStorage.getItem("auth-token");

    axios
      .get(
        `${BASE_URL}/use-all-details`,
        token && {
          headers: {
            "auth-token": token,
          },
        }
      )
      .then((result) => {
        setValue(() => setValue(result.data));
        console.log("Result", result);
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(data)
  }, [run, check, status, editupdate]);

  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(
        `${BASE_URL}/categories`,
        token && {
          headers: {
            "auth-token": token,
          },
        }
      )
      .then((result) => {
        console.log("Result after submit cate", result);
      });
  }, []);
  const showAllData = () => {
    console.log("All Data");
    const token = sessionStorage.getItem("auth-token");

    axios
      .get(
        `${BASE_URL}/use-all-details`,
        token && {
          headers: {
            "auth-token": token,
          },
        }
      )
      .then((result) => {
        setValue(() => setValue(result.data));
        console.log("Result", result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    showAllData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        console.log("hello sign");
        const dropval = await axios.get(
          `${BASE_URL}/dropdownvalue`
        );
        const storeddrop = dropval.data;
        setDrop(storeddrop);
        setFilterDrop(storeddrop);
        console.log("The Value of drop", drop);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, []);

  const deleteValue = async (id) => {
    try {
      axios
        .patch(`${BASE_URL}/deactive/${id}`)
        .then((result) => {
          if (result.status === 200) {
            setStatus(false);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const activeValue = async (id) => {
    try {
      axios.patch(`${BASE_URL}/active/${id}`).then((result) => {
        if (result.status === 200) {
          setStatus(true);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const Edit = (id, email) => {
    console.log("Let's set", id);
    setEmail(() => setEmail(email));
    setIdCheck(() => setIdCheck(id));
    setEdit((prev) => !prev);
  };
  const upDate = async (id) => {
    setEmailError("");
    // setPasswordError("");
    setFullNameError("");
    setCategoryError("");
    try {
      console.log("Working..");
      const token = sessionStorage.getItem("auth-token");
      const port = `${BASE_URL}/user-details`;
      const res = await axios.patch(
        `${port}/${id}`,
        { email: email, fullname: fullname, category: categoryid },
        token && {
          headers: {
            "auth-token": token,
          },
        }
      );
      setEmail("");
      setFullName("");
      setCheck((prev) => !prev);
      setEdit((prev) => !prev);
      setEditUpdate(!editupdate);
      setEmailError("");
      setFullNameError("");
      setCategoryError("");
      const data1 = await res;
      console.log("Admin Control thing....!!", data1);
      setIdCheck("");
    } catch (err) {
      console.log("Duplicate update Error", { err });
      if (err.response.data.err.hasOwnProperty("path")) {
        setCategoryError("Select Category");
      } else {
        if (
          err.response.data.err.hasOwnProperty("code") &&
          err.response.data.err.code === 11000
        ) {
          setEmailError("Email is already Registered");
        } else {
          if (err.response.data.err.errors.hasOwnProperty("fullname")) {
            setFullNameError(err.response.data.err.errors.fullname.message);
          }
          if (err.response.data.err.errors.hasOwnProperty("email")) {
            setEmailError(err.response.data.err.errors.email.message);
          }
          // if (err.response.data.err.errors.hasOwnProperty("password")) {

          //     setPasswordError(err.response.data.err.errors.password.message)
          // }
        }
      }
    }
  };

  const newData = () => {
    setAddData(!addData);
  };
  const newDataRole = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .post(
        `${BASE_URL}/${search}`,
        token && {
          headers: {
            "auth-token": token,
          },
        }
      )
      .then((result) => {
        setValue(() => setValue(result.data));
        console.log("Result", result);
      })
      .catch((err) => {
        console.log({ err });
        axios
          .get(
            `${BASE_URL}/use-all-details`,
            token && {
              headers: {
                "auth-token": token,
              },
            }
          )
          .then((result) => {
            setValue(() => setValue(result.data));
            console.log("Result", result);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    console.log("search value in ", search);
  }, [controlSerach]);
  const debounceSave = useCallback(
    debounce((nextValue) => setControlSearch(nextValue), 1000),
    []
  );

  const serachFun = (e) => {
    e.preventDefault();
  };
  const handleSearch = (text) => {
    setSerach(text);
    debounceSave(text);
  };
  const options = fitlerDrop.map((form) => {
    return { value: form._id, label: form.category };
  });
  console.log("The value of options", options);
  return (
    <>
      {/* <Navbar /> */}
      {showModal && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full my-auto "
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  Role Creation
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}
                <>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Role Name
                    </label>
                    <input
                      type="text"
                      id="title"
                      onChange={(e) => {
                        handleChange(e.target.value);
                      }}
                      aria-describedby="helper-text-explanation"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                      placeholder="role"
                    />

                    {/* <Select
                      defaultValue={selectedLevelOption}
                      //   setSelectedOption({
                      //     value: event.value,
                      //     label: event.label,
                      //   });
                      //   setTemplateId(event.value);
                      // }
                      // required={true}
                      onChange={(event) => {
                        handleLevelChange(event);
                      }}
                      options={options2}
                      placeholder="Select Instance..."
                      className="my-react-select-container mt-3 border border-gray-600 "
                      classNamePrefix="my-react-select"
                    /> */}
                    <Select
                      defaultValue={selectedOption}
                      onChange={(event) => {
                        setSelectedOption(event.value);
                        // setFilter(event.value);
                      }}
                      options={options}
                      placeholder="Choose Role..."
                      className="my-react-select-container mt-3"
                      classNamePrefix="my-react-select"
                    />
                    <button
                      type="submit"
                      className="btn bg-[#4f46e5] hover:bg-indigo-800 text-white mt-4"
                    >
                      <span className="hidden xs:block ml-2">Create Role</span>
                    </button>
                  </form>
                </>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Avatars */}
        <DashboardAvatars />

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          {/* Filter button */}
          {/* <FilterButton /> */}
          {/* Datepicker built with flatpickr */}
          {/* <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => setFilter(e.target.value)}
            name="category"
          > */}

          {/* </select> */}

          {/* <Datepicker /> */}
          {/* <div > */}
          <form onSubmit={serachFun} className="flex">
            <input
              type="search"
              className="relative m-0 -mr-0.5 block w-[10%]  min-w-0 flex-auto rounded-l border border-solid   border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
              aria-label="Search"
              aria-describedby="button-addon1"
              placeholder="Enter name to Search.."
              name="search"
              value={search}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />

            {/* <!--Search button--> */}
            <button
              className="relative z-[2] flex items-center rounded-r  px-6  text-xs font-medium uppercase leading-tight  shadow-md transition duration-150 ease-in-out bg-indigo-500 hover:bg-indigo-600 text-white outline-nonee "
              type="button"
              id="button-addon1"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fill-rule="evenodd"
                  d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </form>
          {fitlerDrop && (
            <>
              {/* <option
                  value="sort-by-roles"
                  onClick={showAllData}
                  className="dark:bg-gray-800  dark:text-white hover:bg-teal-600"
                >
                  Sort by roles
                </option>
                {fitlerDrop.map((val, index) => (
                  <option
                    key={val._id}
                    value={val._id}
                    className="dark:bg-gray-800 dark:text-white hover:bg-teal-600"
                  >
                    {val.category}
                  </option> */}
              <Select
                defaultValue={selectedOption}
                onChange={(event) => {
                  // setSelectedOption(event.value);
                  setFilter(event.value);
                }}
                options={options}
                placeholder="Sort by role..."
                className="my-react-select-container"
                classNamePrefix="my-react-select"
              />
              {/* ))} */}
            </>
          )}
          {/* </div> */}
          {/* Add view button */}
          <button
            onClick={newData}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">
              {" "}
              {addData ? "Hide" : "Add New User"}
            </span>
          </button>
          <button
            onClick={newDataRole}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <svg
              className="w-4 h-4 fill-current opacity-50 shrink-0"
              viewBox="0 0 16 16"
            >
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2"> Add New Role</span>
          </button>
        </div>
      </div>
      <>
        {addData ? (
          <AddDataComp heading="Add New Data" button="Add New User" />
        ) : null}
        {/* {addDataRole ? (
          <AddDataRoleComp heading="Add New Data" button="Add New Role" />
        ) : null} */}
        <center>
          {" "}
          <button onClick={showAllData} className="btn btn-danger m-2 p-2">
            Show All Data
          </button>
        </center>

        <table className="table-auto w-full ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-[#1e293b] dark:text-white">
            <tr>
              <th className="px-6 py-3">Name</th>
              <th className="px-6 py-3">Email</th>
              <th className="px-6 py-3">Category</th>
              <th className="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {value &&
              value.map((val, index) => {
                const isEditing = edit && idCheck === val._id;

                return (
                  <>
                    {val.role === 0 ? (
                      <tr key={index} className={`${val.active ? "" : ""} `}>
                        <td className="border-b px-4 py-2">
                          {isEditing ? (
                            <>
                              <input
                                defaultValue={val.fullname}
                                value={fullname}
                                onChange={(e) => {
                                  setFullName(e.target.value);
                                  console.log(e.target.value);
                                }}
                                className="form-width form-control bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              />
                            </>
                          ) : (
                            <>{val.fullname}</>
                          )}
                        </td>
                        <td className="border-b px-4 py-2">
                          {isEditing ? (
                            <>
                              <input
                                defaultValue={val.email}
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  console.log(e.target.value);
                                }}
                                className="form-width form-control bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              />
                            </>
                          ) : (
                            <>{val.email}</>
                          )}
                        </td>
                        <td className="border-b px-4 py-2">
                          {isEditing ? (
                            <>
                              <select
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setCategoryId(e.target.value)}
                                name="category"
                                value={val.category.category}
                              >
                                {fitlerDrop &&
                                  fitlerDrop.map((val, index) => {
                                    return (
                                      <option
                                        value={val._id}
                                        className="dark:bg-gray-800 dark:text-white hover:bg-teal-600"
                                      >
                                        {val.category}
                                      </option>
                                    );
                                  })}
                              </select>
                            </>
                          ) : (
                            <>{val?.category?.category}</>
                          )}
                        </td>
                        <td className="border-b px-4 py-2 flex items-center">
                          {val.active ? (
                            <button
                              onClick={() => deleteValue(val._id)}
                              className=" bg-red-600 hover:bg-red-700 text-white font-medium py-1 px-4  rounded-md focus:outline-none focus:ring-2 focus:ring-red-600 mr-2"
                            >
                              <i className="bi bi-person-dash text-lg mr-1"></i>
                              Deactivate User
                            </button>
                          ) : (
                            <button
                              onClick={() => activeValue(val._id)}
                              className=" bg-green-600 hover:bg-green-700 text-white font-medium py-1 px-4 mx-2 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600 mr-2"
                            >
                              {/* <PiUserSwitchDuotone/> */}
                              <i className="bi bi-person-check text-lg mr-1"></i>
                              Activate User
                            </button>
                          )}

                          {isEditing ? (
                            <button
                              onClick={() => upDate(val._id)}
                              className="text-white font-medium py-1 px-4 my-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-indigo-500 hover:bg-indigo-600"
                              disabled={!val.active}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              onClick={() => Edit(val._id)}
                              disabled={!val.active}
                              className=" text-white font-medium py-1 px-4 my-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-indigo-500 hover:bg-indigo-600"
                            >
                              <i className="bi bi-pencil text-lg mr-2"></i>
                              Edit
                            </button>
                          )}
                        </td>
                      </tr>
                    ) : null}
                  </>
                );
              })}
          </tbody>
        </table>
      </>
    </>
  );
};
export default AdminControl;
