import React, { Fragment, useState, useEffect } from "react";
import styles from "../pages/FormBuilder.module.scss";
import Sidebar from "Dashboard/partials/Sidebar";
import Header from "Dashboard/partials/Header";
import DropDown from "components/DropDown";
import { darkMode } from "../css/tailwind.config";
import { useParams } from "react-router-dom";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const ExecutiveMaster = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [executiveData, setExecutiveData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { level2Id } = useParams();
  console.log(level2Id);

  const filteredData = executiveData.filter(
    ({
      audit_executive_name,
      address,
      contact_no,
      email_id,
      reference_of,
      appointed_for,
      available_from,
      available_to,
      status,
      remarks,
      payment_amount,
      payment_base,
      gst_no,
      user_name,
      // password,
      // category,
      // reported_to,
    }) => {
      const query = searchQuery.toLowerCase();
      return (
        audit_executive_name.toLowerCase().includes(query) ||
        address.toLowerCase().includes(query) ||
        contact_no.toLowerCase().includes(query) ||
        email_id.toLowerCase().includes(query) ||
        reference_of.toLowerCase().includes(query) ||
        appointed_for.toLowerCase().includes(query) ||
        status.toLowerCase().includes(query) ||
        remarks.toLowerCase().includes(query) ||
        payment_base.toLowerCase().includes(query) ||
        gst_no.toLowerCase().includes(query) ||
        user_name.toLowerCase().includes(query) ||
        payment_amount.toLowerCase().includes(query)
      );
    }
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/executive_master/executives`
        );
        const filter = response.data.filter(
          (item) => item.level2Id === level2Id
        );
        setExecutiveData(filter);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [setExecutiveData, level2Id]);

  const [formData, setFormData] = useState({
    audit_executive_name: "",
    address: "",
    contact_no: "",
    email_id: "",
    reference_of: "",
    appointed_for: "",
    available_from: null,
    available_to: null,
    status: "",
    remarks: "",
    payment_amount: 0,
    payment_base: "",
    gst_no: "",
    user_name: "",
    password: "",
    category: "",
    reported_to: "",
    level2Id: level2Id,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
  };
  const handleCreate = async () => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/api/executive_master/executive`,
        formData,
        config
      );

      setExecutiveData([...executiveData, response.data]);
      setFormData({
        audit_executive_name: "",
        address: "",
        contact_no: "",
        email_id: "",
        reference_of: "",
        appointed_for: "",
        available_from: "",
        available_to: "",
        status: "",
        remarks: "",
        payment_amount: 0,
        payment_base: "",
        gst_no: "",
        user_name: "",
        password: "",
        category: "",
        reported_to: "",
        level2Id: level2Id,
      });
    } catch (error) {
      console.error("Error creating entry:", error);
    }
  };
  const handleEdit = (item) => {
    setFormData({
      audit_executive_name: item.audit_executive_name,
      address: item.address,
      contact_no: item.contact_no,
      email_id: item.email_id,
      reference_of: item.reference_of,
      appointed_for: item.appointed_for,
      available_from: item.available_from,
      available_to: item.available_to,
      status: item.status,
      remarks: item.remarks,
      payment_amount: item.payment_amount,
      payment_base: item.payment_base,
      gst_no: item.gst_no,
      user_name: item.user_name,
      password: item.password,
      category: item.category,
      reported_to: item.reported_to,
    });

    setEditData(item);
    setShowModal(true);
    setEditMode(true);
  };
  const handleUpdate = async () => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.put(
        `${BASE_URL}/api/executive_master/executive/${editData._id}`,
        formData,
        config
      );
      setExecutiveData(
        executiveData.map((entry) =>
          entry._id === response.data._id ? response.data : entry
        )
      );
      setEditMode(false);
      setEditData(null);
      setFormData({
        audit_executive_name: "",
        address: "",
        contact_no: "",
        email_id: "",
        reference_of: "",
        appointed_for: "",
        available_from: null,
        available_to: null,
        status: "",
        remarks: "",
        payment_amount: 0,
        payment_base: "",
        gst_no: "",
        user_name: "",
        password: "",
        category: "",
        reported_to: "",
      });
      setShowModal(false);
    } catch (error) {
      console.error("Error updating entry:", error);
    }
  };

  const handleDelete = async (id) => {
    const token = sessionStorage.getItem("auth-token");
    const config = {
      headers: {
        "auth-token": token,
      },
    };
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/executive_master/executive/${id}`,
        config
      );
      console.log("Delete Response:", response);
      setExecutiveData(executiveData.filter((entry) => entry._id !== id));
    } catch (error) {
      console.error("Error deleting entry:", error);
    }
  };

  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const totalPages = executiveData
    ? Math.ceil(executiveData.length / itemsPerPage)
    : 0;

  const paginationButtons = [];
  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );

  return (
    <div>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div
            id="alert-border-1"
            className="flex items-center p-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
            role="alert"
          >
            <svg
              className="flex-shrink-0 w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div className="ml-3 text-sm font-medium">
              <strong>Executive Setup Screen</strong>
            </div>
            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
              data-dismiss-target="#alert-border-1"
              aria-label="Close"
            >
              <span className="sr-only">Dismiss</span>
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>
          </div>
          {/* <WelcomeBanner /> */}
          <div>
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 m-5">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="px-4 py-2 w-64 h-10 rounded-l-lg rounded-r-lg border border-gray-300 dark:border-gray-500 dark:border-[#374151] bg-white text-gray-800 placeholder-gray-400 focus:outline-none dark:bg-transparent dark:text-white dark:placeholder-gray-500"
                />
                <button
                  className="absolute inset-y-0 right-0 px-4 h-10 text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-500 rounded-r-lg focus:outline-none focus:ring focus:border-primary-400"
                  type="button"
                  id="button-addon1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <button
                className="w-15 h-10 btn bg-indigo-500 hover:bg-blue-600 text-white"
                onClick={() => {
                  setShowModal(true);
                  setEditData(null);
                  setShowModal(true);
                  setFormData({
                    audit_executive_name: "",
                    address: "",
                    contact_no: "",
                    email_id: "",
                    reference_of: "",
                    appointed_for: "",
                    available_from: null,
                    available_to: null,
                    status: "",
                    remarks: "",
                    payment_amount: 0,
                    payment_base: "",
                    gst_no: "",
                    user_name: "",
                    password: "",
                    category: "",
                    reported_to: "",
                  });
                }}
              >
                <svg
                  className="w-4 h-4 fill-current opacity-50 shrink-0"
                  viewBox="0 0 16 16"
                >
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="hidden xs:block ml-2">
                  Add New Executive Master
                </span>
              </button>
            </div>

            <div
              className="mt-5 mx-5 shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 text-center"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <table className="w-full text-sm text-center text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 whitespace-nowrap">
                  <tr className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700">
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Audit Executive Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Address
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Contact Number
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Email ID
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Reference Of
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Appointed For
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Available From
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Available To
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Status
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Remarks
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Payment Amount
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Payment Base
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        GST Number
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        User Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Password
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Category
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Reported To
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Actions
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length === 0 ? (
                    <tr className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700">
                      <td colSpan={16} align="center">
                        <span className="text-gray-500 dark:text-gray-400">
                          No Records Found
                        </span>
                      </td>
                    </tr>
                  ) : (
                    filteredData
                      .slice(startIndex, endIndex)
                      .map(({ _id, ...dealerGroup }) => {
                        return (
                          <Fragment key={_id}>
                            <tr
                              //onClick={() => navigateToForm(_id)}
                              className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                            >
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.audit_executive_name}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.address}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.contact_no}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.email_id}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.reference_of}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.appointed_for}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.available_from}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.available_to}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.status}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.remarks}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.payment_amount}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.payment_base}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.gst_no}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.user_name}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.password}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.category}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <span className="text-gray-900 dark:text-gray-100">
                                  {dealerGroup.reported_to}
                                </span>
                              </td>
                              <td className=" px-6 py-4 whitespace-nowrap">
                                <div>
                                  <i
                                    id={`dropdown-${_id}`}
                                    className="bx-dots-vertical-rounded text-gray-500 dark:text-gray-400 hover:bg-slate-800"
                                    onClick={(e) => e.stopPropagation()}
                                  ></i>
                                </div>
                              </td>
                            </tr>
                            <DropDown
                              selector={`#dropdown-${_id}`}
                              placement="bottom"
                              className={styles.dropdown}
                            >
                              <DropDown.Item onClick={handleEdit(_id)}>
                                <i class="bi bi-pencil-square"></i>
                                <span className="ml-2">Update</span>
                              </DropDown.Item>

                              <DropDown.Item onClick={() => handleDelete(_id)}>
                                <i className="bx-trash"></i>
                                <span className="ml-2">Delete</span>
                              </DropDown.Item>
                            </DropDown>
                          </Fragment>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>

            {/* <div
              className={`bg-white dark:bg-slate-900 ${
                darkMode ? styles.form2 : styles.form
              }`}
            >
              <div className="flex justify-center mt-8">
                {/* <div>
                          Page {page} of {totalPages}
                        </div> */}
            {/* <div className="flex justify-between mt-8 px-3">
                  <div className="flex items-center gap-1">
                    {paginationButtons}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="max-w-screen-xl mx-auto px-4 md:px-8"></div>
        </div>
        {/*modal*/}
      </div>
      <div></div>
      {showModal && (
        <div
          id="dealer-group-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999] w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full my-auto"
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="dealer-group-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  {editMode ? "Edit EM" : "Add EM"}
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-3">
                <form onSubmit={handleSubmit}>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="audit_executive_name"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Audit Executive Name
                    </label>
                    <input
                      type="text"
                      id="audit_executive_name"
                      name="audit_executive_name"
                      value={formData.audit_executive_name}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="contact_no"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Contact Number
                    </label>
                    <input
                      type="text"
                      id="contact_no"
                      name="contact_no"
                      value={formData.contact_no}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="email_id"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Email ID
                    </label>
                    <input
                      type="text"
                      id="email_id"
                      name="email_id"
                      value={formData.email_id}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="reference_of"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Reference Of
                    </label>
                    <input
                      type="text"
                      id="reference_of"
                      name="reference_of"
                      value={formData.reference_of}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="appointed_for"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Appointed For
                    </label>
                    <input
                      type="text"
                      id="appointed_for"
                      name="appointed_for"
                      value={formData.appointed_for}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="available_from"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Available From
                    </label>
                    <input
                      type="date"
                      id="available_from"
                      name="available_from"
                      value={formData.available_from}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="available_to"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Available To
                    </label>
                    <input
                      type="date"
                      id="available_to"
                      name="available_to"
                      value={formData.available_to}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Status
                    </label>
                    <input
                      type="text"
                      id="status"
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="remarks"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Remarks
                    </label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="payment_amount"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Payment Amount
                    </label>
                    <input
                      type="number"
                      id="payment_amount"
                      name="payment_amount"
                      value={formData.payment_amount}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="payment_base"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Payment Base
                    </label>
                    <input
                      type="text"
                      id="payment_base"
                      name="payment_base"
                      value={formData.payment_base}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="gst_no"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      GST Number
                    </label>
                    <input
                      type="text"
                      id="gst_no"
                      name="gst_no"
                      value={formData.gst_no}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="user_name"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      User Name
                    </label>
                    <input
                      type="text"
                      id="user_name"
                      name="user_name"
                      value={formData.user_name}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="category"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Category
                    </label>
                    <input
                      type="text"
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark-bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="reported_to"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Reported To
                    </label>
                    <input
                      type="text"
                      id="reported_to"
                      name="reported_to"
                      value={formData.reported_to}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  {/* Add more input fields if needed */}
                  <div className="text-red-500 mb-4">
                    {/* Display error messages if needed */}
                  </div>
                  <button
                    onClick={editMode ? handleUpdate : handleCreate}
                    type="submit"
                    className="btn bg-[#4f46e5] hover:bg-indigo-800 text-white mt-4"
                  >
                    <span className="hidden xs:block ml-2">
                      {editMode ? "Update Executive" : "Create Executive"}
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExecutiveMaster;
