import { axios } from "./index";
import { AuditFormUrl,AuditItemList,auditItemResponseUrl,AuditItemResponse } from "./config";
import {
  FormDetail,
  FormResponse,
  FormData,
  FormResponses,
  PageMeta,
} from "types/Form";
const token = sessionStorage.getItem("auth-token");
const config = {
  headers: {
    "auth-token": token,
  },
};

const getFormById = (formId: string) => {
  return axios<{ creatorId: string } & FormDetail>({
    method: "get",
    url: `${AuditFormUrl}/${formId}`,
  });
};

const sendResponse = (data: FormResponse) => {
  return axios({
    method: "post",
    url: AuditItemResponse.submitResponse,
    data,
  });
};
const updateResponse = async ({
  responseId,
  body,
}: {
  responseId: any;
  body: any;
}) => {
  try {
    const response = await axios.put<{ responseId: string; message: string }>(
      `${auditItemResponseUrl}/update/${responseId}`,
      body
    );
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error("Error updating response:", error);
    throw error; // Re-throw the error if necessary
  }
};


const getAllForms = (params?: any) => {
  console.log("this one is params",params);
  return axios<{
    list: FormData[];
    pageMeta: PageMeta;
  }>({
    method: "get",
    url: AuditItemList.getAllForms,
    params,
  });
};

const createForm = (data: { templateId?: string, auditId?: string,reportType?: string } = {}) => {
  console.log("this one is data",data);
  return axios<{
    _id: string;
    title: string;
    projectId: string | undefined;
    auditId: string | undefined; 
    reportType: any;
    updatedAt: string;
    createdAt: string;
  }>({
    method: "post",
    url: AuditItemList.createForm,
    data,
  });
};

const deleteFormById = (formId: string) => {
  return axios<{ formId: string; message: string }>({
    method: "delete",
    url: `${AuditFormUrl}/${formId}`,
  });
};
const deleteResponseById = (responseId : string) => {
  return axios<{ responseId: string }>({
    method: "delete",
    url: `${auditItemResponseUrl}/delete/${responseId}`,
  });
};
const deleteResponseByAuditId = (auditId : string) => {
  return axios<{ auditId: string }>({
    method: "delete",
    url: `${auditItemResponseUrl}/delete_by_auditId/${auditId}`,
  });
};

const updateFormById = ({
  formId,
  data,
}: {
  formId: string;
  data: FormDetail;
}) => {
  return axios<{ formId: string; message: string }>({
    method: "put",
    url: `${AuditFormUrl}/${formId}`,
    data,
  });
};

export const checkAuditItemResponseStatus = (formId: string) => {
  return axios<{ status: boolean }>({
    method: "get",
    url: `${AuditItemResponse.checkStatus}/${formId}`,
  });
};

export const getAuditItemFormResponsesById = (formId: string,auditItemFormId:string) => {
  return axios<FormResponses>({
    method: "get",
    url: `${auditItemResponseUrl}/${formId}/${auditItemFormId}`,
  });
};
export const getAuditItemResponses = () => {
  return axios<FormResponses>({
    method: "get",
    url: `${auditItemResponseUrl}`,
  });
};
export const getAuditItemResponseById = (responseId:string) => {
  return axios({
    method: "get",
    url: `${auditItemResponseUrl}/response/${responseId}`,
  });
};

export {
  getFormById,
  sendResponse,
  updateResponse,
  deleteResponseById,
  deleteResponseByAuditId,
  getAllForms,
  createForm,
  deleteFormById,
  updateFormById,
};
