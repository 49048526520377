import React, { useState } from 'react';
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.post(`${BASE_URL}/api/user/forgot-password`, { email }, config);
      setMessage(response.data.message);
      setEmail('');
    } catch (error) {
      setMessage('Error sending reset password email.');
    }
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button type="submit">Send Reset Password Email</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ForgotPasswordForm;
