import React, { createContext, useState } from 'react';
import { Navigate, useRoutes } from "react-router-dom";
import EditForm from "pages/EditForm";
import PreEditForm from "pages/EditForm/PreEditForm";
import AuditItemResponseViewForm from "pages/ViewForm/AuditItemResponseViewForm";
import PostEditForm from "pages/EditForm/PostEditForm";
import ViewForm from "pages/ViewForm";
import PreViewForm from "pages/ViewForm/PreViewForm";
import PostViewForm from "pages/ViewForm/PostViewForm";
import AuditItemViewForm from "pages/ViewForm/AuditItemViewForm";
import AuthLayout from "layouts/AuthLayout";
import Login from "pages/Login";
import ForgotPasswordForm from "pages/Login/Pages/ForgotPasswordForm";
import ResetPasswordForm from "pages/Login/Pages/ResetPasswordForm";
import Register from "pages/Register";
import FormList from "pages/FormList";
import AudutItemList from "pages/FormList/AuditItemList";
import PreAudutItemList from "pages/FormList/PreAuditItemList";
import PostAudutItemList from "pages/FormList/PostAuditItemList";
import PageNotFound from "pages/404";
import { cookie } from "utils";
// import ProtectedRoute from "./ProtectedRoute";
import App from "./App";
import Dashboard from "Dashboard/pages/Dashboard";
import OEM_Inventry from "Dashboard/pages/AuditSetup";
import Project from "Dashboard/pages/Project";
import AuditItem from "pages/EditForm/AuditItem";
import NewLogin from "UserModule/components/Login";
import Signup from "UserModule/components/Signup";
import Details from "UserModule/components/Details";
// import Admin from "UserModule/components/Admin";
import Reset from "UserModule/components/Reset";
import UserReset from "UserModule/components/userReset";
import SetPassword from "UserModule/components/SetPassword";
import ShowData from "UserModule/components/ShowData";
import Home from "UserModule/components/Home";
import { ApiProvider } from 'UserModule/components/ApiContext'
import User from "Dashboard/pages/User";
import AdminControl from "UserModule/components/AdminControl";
import ExcellUpload from "components/ExcellUpload/App";
import ExcelUpload from "Dashboard/pages/ExcelUpload";
import InstanceManagement from "Dashboard/pages/InstanceManagement";
import AuditDash from "Dashboard/pages/AuditDash"
import Account from 'Dashboard/pages/Account';
import SubProject from 'Dashboard/pages/SubProject';
import AuditSetup from 'Dashboard/pages/AuditSetup';
import AuditView from 'Dashboard/pages/AuditView';
import SubProjectAudits from 'pages/FormList/SubProjectAudits';
import MasterSetup from 'Dashboard/pages/MasterSetup';
import ExecutiveMaster from 'Dashboard/OemSetup/ExecutiveMaster';
import DealerGroup from 'Dashboard/OemSetup/DealerGroup';
import DealerSetup from 'Dashboard/OemSetup/DealerSetup';
import ProtectedRoute from "./ProtectedRoute";
const Routes = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: sessionStorage.getItem("auth-token") ? (
        <Navigate to="/dashboard" replace />
      ) : (
        <ApiProvider>
          <NewLogin />
        </ApiProvider>
      ),
    },
    
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <App />
        </ProtectedRoute>
      ),
    },
    {
      path: "/login",
      element: <Navigate to="/" replace />,
    },
    {
      path: "/users",
      element: <User />,
    },
    {
      path: "/upload",
      element: <ExcellUpload />,
    },
    {
      path: "/signup",
      element: <ApiProvider> <Signup /></ApiProvider>,
    },
    {
      path: "/detail",
      element: <ApiProvider> <Details /></ApiProvider>,
    },
    // {
    //   path: "/admin",
    //   element: <ApiProvider> <Admin /></ApiProvider>,
    // },
    {
      path: "/all-details",
      element: <ApiProvider> <AdminControl /></ApiProvider>,
    },
    {
      path: "/reset",
      element: <ApiProvider> <Reset /></ApiProvider>,
    },
    {
      path: "/reset/:userId",
      element: <ApiProvider> <UserReset /></ApiProvider>,
    },
    {
      path: "set-password/:token",
      element: <ApiProvider> <SetPassword /></ApiProvider>,
    },
    {
      path: "/showdata",
      element: <ApiProvider> <ShowData /></ApiProvider>,
    },

    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/audit_view/:auditId/:subProjectId",
      element: <AuditView hoverId={null} hoverFormId={null} dummyForHandleHover={false}/>,
    },

    {
      path: "/account_setting",
      element: <Account />,
    },
    {
      path: "/master_setup",
      element: <MasterSetup />,
    },
    {
      path: "/dealer_group/:oem_code/:level0Id",
      element: <DealerGroup />,
    },
    {
      path: "executive_master/:level2Id",
      element: <ExecutiveMaster />,
    },
    {
      path: "/dealer3/:dealergroup_code/:level0Id/:level1Id",
      element: <DealerSetup />,
    },
    {
      path: "/Projects",
      element: <Project />,
    },
    {
      path: "/subprojects/:projectId",
      element: <SubProject />,
    },
    {
      path: "/audit_setup/:projectId/:subProjectId",
      element: <AuditSetup />,
    },
    {
      path: "/ExcelUpload/:auditId",
      element: <ExcelUpload />,
    },
    {
      path: "/instance_management",
      element: <InstanceManagement />,
    },

    {
      path: "/forget-password",
      element: <ForgotPasswordForm />,
    },
    {
      path: "/reset-password",
      element: <ResetPasswordForm />,
    },
    {
      path: "/form/list/",
      element: <FormList />,
    },
    {
      path: "/audits/list/:projectId",
      element: <SubProjectAudits />,
    },
    {
      path: "/audit_item/:formId/list",
      element: <AudutItemList />,
    },
    {
      path: "/pre_audit_item/:formId/list",
      element: <PreAudutItemList />,
    },
    {
      path: "/post_audit_item/:formId/list",
      element: <PostAudutItemList />,
    },
    {
      path: "/form/:formId/edit",
      element: <EditForm />,
    },
    {
      path: "/form/:formId/preaudit",
      element: <PreEditForm />,
    },
    {
      path: "/form/:formId/postaudit",
      element: <PostEditForm />,
    },

    {
      path: "/form/:formId/auditItem",
      element: <AuditItem />,
    },
    {
      path: "/form/:formId/view",
      element: <ViewForm />,
    },
    {
      path: "/form/:formId/pre_view",
      element: <PreViewForm />,
    },
    {
      path: "/form/:formId/post_view",
      element: <PostViewForm />,
    },
    {
      path: "/form/:formId/audititem_view",
      element: <AuditItemViewForm />,
    },
    {
      path: "/form/:formId/audititem_response_view/:responseId",
      element: <AuditItemResponseViewForm />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    }

  ]);

  return routes;
};

export default Routes;
