import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../components/DropdownFilter";
import Datepicker from "../components/Datepicker";
import Banner from "../partials/Banner";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "@react-spring/web";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import Select from "react-select";
import { useParams } from "react-router-dom";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function AuditSetup() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dropdownsData, setDropdownsData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [projectInstanceId, setProjectInstanceId] = useState("");
  const [selectedItemsForSecondTreeView, setSelectedItemsForSecondTreeView] =
    useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filterDrop, setFilterDrop] = useState();
  const [value, setValue] = useState([]);

  const [drop, setDrop] = useState({});

  const [selectedOption2, setSelectedOption2] = useState(
    "64a3ce5feed18829472e4278"
  );
  const { projectId, subProjectId } = useParams();

  const options2 = filterDrop?.map((dataItem) => {
    return { value: dataItem._id, label: dataItem.category };
  });

  const fetchInstances = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/instance`
      );
      setDropdownsData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const projectData101 = await axios.get(
          `${BASE_URL}/api/project/${projectId}`
        );
        setProjectInstanceId(projectData101.data.data.instanceId);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    fetchInstances();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        console.log("hello sign");
        const dropval = await axios.get(
          `${BASE_URL}/dropdownvalue`
        );
        const storeddrop = dropval.data;
        setDrop(storeddrop);
        setFilterDrop(storeddrop);
        console.log("The Value of drop", drop);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, []);
  const options = value.map((dataItem) => {
    return {
      value: dataItem._id,
      label: dataItem.fullname,
      role: dataItem?.category?.category,
    };
  });
  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/use-all-details`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        const filteredData = result.data.filter(
          (data) => data.category._id === selectedOption2
        );
        setValue(filteredData);
        console.log("Result from subproject", filteredData, selectedOption2);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedOption2]);

  const handleToggleItem = (itemId, isParent = false) => {
    setSelectedItems((prevSelectedItems) => {
      // Check if the item is already selected
      const isItemSelected = prevSelectedItems.includes(itemId);

      // If the item is already selected, uncheck it by removing it from the selected items
      if (isItemSelected) {
        setSelectedItemsForSecondTreeView(
          (prevSelectedItemsForSecondTreeView) =>
            prevSelectedItemsForSecondTreeView.filter((id) => id !== itemId)
        );
        return prevSelectedItems.filter((id) => id !== itemId);
      }

      // If it's a parent item, disable all its sub-items' checkboxes
      if (isParent) {
        setSelectedItemsForSecondTreeView(
          (prevSelectedItemsForSecondTreeView) =>
            prevSelectedItemsForSecondTreeView.filter((id) => id !== itemId)
        );
      }

      // Filter out the selected item and its descendants
      const newSelectedItems = prevSelectedItems.filter((id) => {
        // Find the item in the data by ID
        const item = dropdownsData.find((item) => item.id === id);

        // Check if the item or any of its ancestors has the itemId as their path
        const hasAncestorSelected =
          item && item.path.some((ancestorId) => ancestorId === itemId);

        return !hasAncestorSelected;
      });

      // Add the item if it was not previously selected
      newSelectedItems.push(itemId);

      setSelectedItemsForSecondTreeView(newSelectedItems); // Update selectedItemsForSecondTreeView here

      return newSelectedItems;
    });
  };

  const renderSubItems = (
    parentId,
    level,
    parentChecked,
    projectInstanceId
  ) => {
    const parentSubItem = dropdownsData.find((item) => item.id === parentId);

    if (!parentSubItem) {
      return null;
    }

    const subItems = dropdownsData.filter(
      (item) =>
        item.path[level] === parentId &&
        item.atLevel === parentSubItem.atLevel + 1
    );

    if (subItems.length === 0) {
      return null;
    }

    return subItems.map((subItem) => {
      const isSubItemChecked = selectedItems.includes(subItem.id);
      const hasSubItems = dropdownsData.some(
        (item) => item.path[level + 1] === subItem.id
      );

      return (
        <StyledTreeItem
          key={subItem.id}
          nodeId={subItem.id.toString()}
          label={
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSubItemChecked}
                  onChange={() => handleToggleItem(subItem.id, true)}
                  disabled={
                    !isItemEnabled(subItem, projectInstanceId) || parentChecked // Disable if parent is checked
                  }
                  className="dark:text-white"
                />
              }
              label={subItem.name}
              className="dark:text-white"
            />
          }
        >
          {hasSubItems &&
            renderSubItems(
              subItem.id,
              level + 1,
              parentChecked || isSubItemChecked,
              projectInstanceId
            )}
        </StyledTreeItem>
      );
    });
  };

  const isItemEnabled = (item, projectInstanceId) => {
    // Check if the item or its ancestors have a match with the projectInstanceId
    return (
      item.id === projectInstanceId || item.path.includes(projectInstanceId)
    );
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    return format(currentDate, "do MMM yyyy");
  };
  console.log("this is a selected items", selectedItemsForSecondTreeView);
  const handleMultipleAudits = async (selectedItems) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };

      for (const itemId of selectedItems) {
        const item = dropdownsData.find((item) => item.id === itemId);

        const response = await axios.post(
          `${BASE_URL}/audit`,
          {
            platformId: "649e8a5186bc5f1770f9a120",
            title: item.name + " " + getCurrentDate(),
            subProjectId: subProjectId,
            instanceId: itemId,
          },
          config
        );
        const firstResponse = await axios.get(
          `${BASE_URL}/api/subproject/${subProjectId}`
        );
        const responseData = firstResponse.data.data.templateId;

        // Make the second POST request
        const secondReq = await axios.post(
          `${BASE_URL}/api/subaudit`,
          {
            platformId: "649e8a5186bc5f1770f9a120",
            auditId: responseData,
            user: selectedOption.value,
            // subAuditId: auditId,
          },
          config
        );
        console.log("this is the res45", secondReq.data);
        console.log(response.data); // Handle the response data as needed
      }
    } catch (error) {
      console.error(error); // Handle the error
    }
  };
  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            {/* <WelcomeBanner /> */}

            {/* Cards */}
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="lg:w-1/4">
                <TreeView
                  aria-label="customized"
                  defaultExpanded={["1"]}
                  defaultCollapseIcon={<MinusSquare />}
                  defaultExpandIcon={<PlusSquare />}
                  defaultEndIcon={<CloseSquare />}
                  sx={{
                    height: 364,
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: "auto",
                  }}
                  className="dark:text-white"
                >
                  {dropdownsData
                    .filter((item) => item.atLevel === 0)
                    .map((parentItem) => (
                      <StyledTreeItem
                        key={parentItem.id}
                        nodeId={parentItem.id.toString()}
                        label={
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedItems.includes(parentItem.id)}
                                onChange={() => handleToggleItem(parentItem.id)}
                                disabled={
                                  !isItemEnabled(parentItem, projectInstanceId)
                                }
                                className="dark:text-white"
                              />
                            }
                            label={parentItem.name}
                          />
                        }
                      >
                        {renderSubItems(
                          parentItem.id,
                          0,
                          selectedItems.includes(parentItem.id),
                          projectInstanceId
                        )}
                      </StyledTreeItem>
                    ))}
                </TreeView>
              </div>

              <div className="lg:w-3/4 mt-4 lg:mt-0">
                <div>
                  <table className="w-full table-auto text-sm text-left shadow-sm  rounded-lg bg-white dark:bg-gray-800">
                    <thead className="bg-gray-50 dark:bg-gray-600 text-gray-600 dark:text-gray-300 font-medium border-b">
                      <tr>
                        <th className="py-3 px-6">Audit Name</th>
                        <th className="py-3 px-6">Role</th>
                        <th className="py-3 px-6">Audit Assignment</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 dark:text-gray-300 divide-y divide-gray-200 dark:divide-gray-600">
                      {selectedItemsForSecondTreeView.map((itemId) => {
                        const item = dropdownsData.find(
                          (item) => item.id === itemId
                        );
                        const getCurrentDate = () => {
                          const currentDate = new Date();
                          return format(currentDate, "do MMM yyyy");
                        };

                        return (
                          <tr key={item.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {item.name} {getCurrentDate()}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                              <Select
                                defaultValue={{
                                  value: "64a3ce5feed18829472e4278",
                                  label: "Field Auditor",
                                }}
                                onChange={(event) => {
                                  setSelectedOption2(event.value);
                                }}
                                options={options2}
                                placeholder="Sort by roles"
                                className="my-react-select-container rounded-md dark:bg-gray-700"
                                classNamePrefix="my-react-select"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <Select
                                defaultValue={selectedOption}
                                onChange={(event) => setSelectedOption(event)}
                                options={options}
                                placeholder="Select a user..."
                                className="my-react-select-container rounded-md dark:bg-gray-700"
                                classNamePrefix="my-react-select"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <button
                    onClick={() =>
                      handleMultipleAudits(selectedItemsForSecondTreeView)
                    }
                  >
                    Create Audits for Selected Items
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Banner />
      </div>
    </div>
  );
}

export default AuditSetup;

// const handleSubmit = async (e: any) => {
//   e.preventDefault();
//   console.log("this is the selected option", selectedOption4);
//   if (selectedOption4) {
//     try {
//       const response = await axios.post("${BASE_URL}/audit", {
//         platformId: "649e8a5186bc5f1770f9a120",
//         title: title,
//         subProjectId: projectId,
//         instanceId: selectedOption4,
//       });
//       setShowModal(false);
//       console.log(response.data); // Handle the response data as needed
//     } catch (error) {
//       console.error(error); // Handle the error
//     }
//   } else {
//     // Show toast error message here
//     toast("First create an instance before making the post request.", {
//       type: "error",
//     });
//     // console.log((error: any) => console.log(error))
//   }
// }
