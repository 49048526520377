import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  // You can use your authentication logic here.
  // For example, check if the user is authenticated.

  const isAuthenticated = !!sessionStorage.getItem("auth-token"); // Check your authentication state

  if (isAuthenticated) {
    return <>{children}</>; // Render the route if authenticated
  } else {
    return <Navigate to="/login" replace />; // Redirect to login if not authenticated
  }
};

export default ProtectedRoute;
