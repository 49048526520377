import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { createForm, deleteFormById } from "services/Form";
import Switch from "react-switch";
import { getAllForms } from "services/AuditItem";
import { ChangeEvent } from "react";
// import DatePicker from 'react-datepicker';
import Datepicker from "Dashboard/components/Datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { User } from "types/Auth";
import ThemeToggle from "Dashboard/components/ThemeToggle";
import { deleteResponseByAuditId } from "services/AuditItem";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import {
  createPreForm,
  getAllPreForms,
  deletePreFormById,
} from "services/PreEditForm";
import {
  createPostForm,
  getAllPostForms,
  deletePostFormById,
} from "services/PostEditForm";
import Select from "react-select";
import axios from "axios";
import XLSX from "xlsx";
// import { getAllTemplates } from "services/Template";
import { PageMeta } from "types/Form";
import DropDown from "components/DropDown";
import moment from "moment-timezone";
// import { toast } from "react-toastify";
import { googleFormIcon } from "utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Header from "Dashboard/partials/Header";
import Pagination from "components/Pagination";
import { getAuditItemFormResponsesById } from "services/AuditItem";
import { useParams } from "react-router-dom";
import { MdOutlineDateRange } from "react-icons/md";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./FormList.module.scss";
import { darkMode } from "Dashboard/css/tailwind.config";
import Sidebar from "Dashboard/partials/Sidebar";
import WelcomeBanner from "Dashboard/partials/dashboard/WelcomeBanner";
import { FaPlus } from "react-icons/fa";
import Avatar from "components/Avatar";
import { PuffLoader } from "react-spinners";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import { set } from "date-fns";
import { AiOutlineAudit, AiOutlineConsoleSql } from "react-icons/ai";
import { HoverContext } from "context/HoverContext";
import AuditView from "Dashboard/pages/AuditView";
import DatePicker from "components/DatePicker";
import FilterDatePicker from "components/DatePicker/FilterDatePicker";
const animatedComponents = makeAnimated();

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const SubProjectAudits = () => {
  const [forms, setForms] = useState([]);
  const [allInstancesData, setAllInstancesData] = useState([]);
  const [subAuditData, setSubAuditData] = useState([]) as any;
  const [title, setTitle] = useState("");
  const [pageMeta, setPageMeta] = useState({} as PageMeta);
  const [subAuditList, setSubAuditList] = useState([]) as any;
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [auditData, setAuditData] = useState([]) as any;
  const [auditStartDate, setAuditStartDate] = useState("");
  const [selectedOption2, setSelectedOption2] = useState<any>(null);
  const [selectedFilteredDate, setSelectedFilteredDate] = useState("");
  const [selectedOption3, setSelectedOption3] = useState(null) as any;
  const [isHovered, setIsHovered] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [optionsReports, setOptionsReports] = useState<any>(null);
  const [dummyForHandleHover, setDummyForHandleHover] = useState(false);
  const [dynamicData, setDynamicData] = useState([]) as any;
  const [instanceLevel, setInstanceLevel] = useState<any>(null);
  const [pathofUI, setPathofUI] = useState([]);
  const [selectedLevelOption, setSelectedLevelOption] = useState<any>(null);
  const [dropdownsData, setDropdownsData] = useState([]);
  const [filteredDataInstance, setFilteredDataInstance] = useState([]);
  const [mapToDealerGroup, setMapToDealerGroup] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excelFile, setExcelFile] = useState<any>();
  const [dummy, setDummy] = useState(false);
  const [subproject, setSubproject] = useState() as any;
  let [formDetail, setFormDetail] = useState<any>();
  let [formDetail3, setFormDetail3] = useState<any>();
  const [updateAssignedUser, setUpdateAssignedUser] = useState(false);
  const [value, setValue] = useState<any>([]);
  const [clickedId, setClickedId] = useState(null);
  const [selectedSwitchId, setSelectedSwitchId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [subProjectData, setSubProjectData] = useState<any>([]);
  const [projectData, setProjectData] = useState<any>("");
  const [auditId, setAuditId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoadingReq, setIsLoadingReq] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [stateUpdate, setStateUpdate] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [loadingdata, setLoadingdata] = useState(true);
  const [filterData, setFilterData] = useState([]) as any;
  const [newFilterData, setNewFilterData] = useState([]) as any;
  const [subProjectId, setSubProjectId] = useState("");
  const [formId, setFormId] = useState("");
  const [auditItems, setAuditItems] = useState([]) as any;
  const [assignedData, setAssignedData] = useState([]) as any;
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [data, setData] = useState([]);
  // const [templates, setTemplates] = useState([]) as any;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [drop, setDrop] = useState({});
  const [valueOptions, setValueOptions] = useState<any>([]);
  const [filterDrop, setFilterDrop] = useState<any>();
  const [role, setRole] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption5, setSelectedOption5] = useState<any>(null);
  const [dropdownsData2, setDropdownsData2] = useState([]);
  const [levels, setLevels] = useState([]);
  const [subAuditItems, setSubAuditItems] = useState([]);
  const [optionsReport, setOptionsReport] = useState<any>([]);
  const [auditItemsTemplate, setAuditItemsTemplate] = useState<any>([]);
  const [response2, setResponse2] = useState<any>([]);
  const [formDetail2, setFormDetail2] = useState<any>([]);
  const [uniqueFilteredData, setUniqueFilteredData] = useState([]) as any;
  const [filteredNewCarStockData, setFilteredNewCarStockData] = useState([]);
  const [filteredTestDriveData, setFilteredTestDriveData] = useState([]);
  const [filteredRetailCDRData, setFilteredRetailCDRData] = useState([]);
  const [excelDataForAudits, setExcelDataForAudits] = useState([]) as any;
  const [userId, setUserId] = useState("") as any;
  const [progress, setProgress] = useState(0); // Initialize progress as 0
  const [auditTemplateId, setAuditTemplateId] = useState("");
  const [selectedSheets, setSelectedSheets] = useState([]) as any;
  const [sheetNames, setSheetNames] = useState([]) as any;
  const [combinedDataArrayLevel0, setCombinedDataArrayLevel0] = useState(
    []
  ) as any;
  const [combinedDataArrayLevel1, setCombinedDataArrayLevel1] = useState(
    []
  ) as any;
  const [selectedDate, setSelectedDate] = useState("") as any;
  const [currentUserData, setCurrentUserData] = useState() as any;

  const updateProgress = () => {
    setProgress((prevProgress) => prevProgress + 1);
  };

  const options3 = dropdownsData2.map((form: any) => {
    return {
      value: form.id,
      label: form.name,
      atLevel: form.atLevel,
      path: form.path,
    };
  });
  const [selectedOption4, setSelectedOption4] = useState<any>(null);
  const { handleHover, handleHoverFormId, handleHoverCombinedData } =
    useContext(HoverContext);
  const { hoveredId, hoverFormId } = useContext(HoverContext);
  const { hoverCombinedData } = useContext(HoverContext);
  const location = useLocation();

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    const token = sessionStorage.getItem("auth-token");
    const config = {
      headers: {
        "auth-token": token,
      },
    };
    if (auditId) {
      console.log("thisiisdate", date);

      axios
        .put(`${BASE_URL}/audit/${auditId}`, { date: date }, config)
        .then((response) => {
          toast("Date Updated successfully!", {
            type: "success",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
          console.log("Date updated successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error updating date:", error);
        });
    }
  };
  const handleLevelChange3 = (event: any) => {
    const { value, label, atLevel } = event;

    setSelectedOption3({ value, label, atLevel });
  };
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  useEffect(() => {
    if (hoverCombinedData.length !== 0 && isHovered) {
      // setShowModal4(true);
    }
  }, [hoverCombinedData, isHovered]);

  const handleOmniSwitchChange = async () => {
    let response;

    if (currentUserData?.category.category === "Office Auditor") {
      toast("Access Denied!.", {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white",
      });
      return;
    }
    const confirmAuditStart = window.confirm(
      "Are you sure you want to Start/Stop all the Operations?"
    );

    if (!confirmAuditStart) {
      return;
    }
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };

      const currentTime = new Date().getTime();
      const timeDifference = currentTime - lastClickTimestamp;
      let uniqueFilteredItems: any;

      if (currentUserData?.category.category === "Office Auditor") {
        // Extract unique dealer IDs using a Set
        uniqueFilteredItems = filteredItems
          .filter((item: any) => {
            return subAuditList.some(
              (subAudit: any) =>
                subAudit.subAuditId === item.subAudit
            );
          })

      } else {
        uniqueFilteredItems = filteredItems
      }

      for (const item of uniqueFilteredItems) {
        const { id, start } = item;

        // Check if it's a double click (within a certain time frame)
        if (timeDifference < DOUBLE_CLICK_THRESHOLD) {
          response = await axios.put(
            `${BASE_URL}/audit/${id}`,
            {
              start: false,
            },
            config
          );

          toast(`Audit stopped successfully!`, {
            type: "info",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
        } else {
          // Single click
          response = await axios.put(
            `${BASE_URL}/audit/${id}`,
            {
              start: !start,
            },
            config
          );

          toast(
            `Audit ${start ? "stopped" : "started"} successfully!`,
            {
              type: "info",
              className: "dark:bg-gray-900 dark:text-white z-[1000]",
            }
          );
        }


        lastClickTimestamp = currentTime; // Update the last click timestamp
      }

      setDummy(!dummy);
      lastClickTimestamp = currentTime; // Update the last click timestamp
    } catch (error) {
      console.error("Error making PUT request:", error);
      toast("Error while updating Audit!", {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    }
  };




  const handleSwitchChange = async (id: any, start: any) => {
    let response; // Declare response variable outside the conditional blocks
    try {
      if (
        currentUserData?.category.category === "Office Coordinator" ||
        currentUserData?.category.category === "Office Auditor"
      ) {
        toast("Access Denied!.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white",
        });
      } else {
        const token = sessionStorage.getItem("auth-token");
        const config = {
          headers: {
            "auth-token": token,
          },
        };
        if (start === false) {
          response = await axios
            .put(
              `${BASE_URL}/audit/${id}`,
              {
                start: true,
              },
              config
            )
            .then((res: any) => {
              setDummy(!dummy);
              toast("Audit started successfully!", {
                type: "info",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            })
            .catch((error: any) => {
              toast("Error while starting the audit!", {
                type: "error",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            });
        } else {
          response = await axios
            .put(
              `${BASE_URL}/audit/${id}`,
              {
                start: false,
              },
              config
            )
            .then((res: any) => {
              setDummy(!dummy);
              toast("Audit stopped successfully!", {
                type: "info",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            })
            .catch((error: any) => {
              toast("Error while stopping the audit!", {
                type: "error",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            });
        }
      }

    } catch (error) {
      console.error("Error making PUT request:", error);
    }
  };

  const handleOmniSwitchChangeForPreAudit = async () => {

    if (currentUserData?.category.category === "Office Auditor") {
      toast("Access Denied!.", {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white",
      });
      return;
    }
    let response;

    const confirmPreAuditStart = window.confirm(
      "Are you sure you want to Start/Stop all the PreAudits?"
    );

    if (!confirmPreAuditStart) {
      return;
    }

    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };

      const currentTime = new Date().getTime();
      const timeDifference = currentTime - lastClickTimestamp;
      console.log("filteredItems", filteredItems)
      let uniqueFilteredItems: any;

      if (currentUserData?.category.category === "Office Auditor") {
        // Extract unique dealer IDs using a Set
        uniqueFilteredItems = filteredItems
          .filter((item: any) => {
            return subAuditList.some(
              (subAudit: any) =>
                subAudit.subAuditId === item.subAudit
            );
          })

      } else {
        uniqueFilteredItems = filteredItems
      }

      console.log("unique filtered items", uniqueFilteredItems)
      for (const item of uniqueFilteredItems) {
        const { id, preAudit_start } = item;

        // Check if it's a double click (within a certain time frame)
        if (timeDifference < DOUBLE_CLICK_THRESHOLD) {
          response = await axios.put(
            `${BASE_URL}/audit/${id}`,
            {
              preAudit_start: false,
            },
            config
          );

          toast(`PreAudit stopped successfully!`, {
            type: "info",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
        } else {
          // Single click
          response = await axios.put(
            `${BASE_URL}/audit/${id}`,
            {
              preAudit_start: !preAudit_start,
            },
            config
          );

          toast(
            `PreAudit ${preAudit_start ? "stopped" : "started"} successfully!`,
            {
              type: "info",
              className: "dark:bg-gray-900 dark:text-white z-[1000]",
            }
          );
        }


        lastClickTimestamp = currentTime; // Update the last click timestamp
      }

      setDummy(!dummy);
    } catch (error) {
      console.error("Error making PUT request:", error);
      toast(`Error while updating PreAudit!`, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    }
  };

  // In your component or wherever you define the function, initialize lastClickTimestamp:
  let lastClickTimestamp = 0;

  // Set the threshold for double click in milliseconds
  const DOUBLE_CLICK_THRESHOLD = 300; // Adjust as needed



  const handleSwitchChangeForPreAudit = async (id: any, start: any) => {
    let response; // Declare response variable outside the conditional blocks
    try {
      if (
        currentUserData?.category.category === "Office Coordinator" ||
        currentUserData?.category.category === "Office Auditor"
      ) {
        toast("Access Denied!.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white",
        });
      } else {
        const token = sessionStorage.getItem("auth-token");
        const config = {
          headers: {
            "auth-token": token,
          },
        };
        if (start === false) {
          response = await axios
            .put(
              `${BASE_URL}/audit/${id}`,
              {
                preAudit_start: true,
              },
              config
            )
            .then((res: any) => {
              setDummy(!dummy);
              toast("PreAudit started successfully!", {
                type: "info",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            })
            .catch((error: any) => {
              toast("Error while starting the PreAudit!", {
                type: "error",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            });
        } else {
          response = await axios
            .put(
              `${BASE_URL}/audit/${id}`,
              {
                preAudit_start: false,
              },
              config
            )
            .then((res: any) => {
              setDummy(!dummy);
              toast("Audit stopped successfully!", {
                type: "info",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            })
            .catch((error: any) => {
              toast("Error while stopping the audit!", {
                type: "error",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            });
        }
      }

    } catch (error) {
      console.error("Error making PUT request:", error);
    }
  };

  useEffect(() => {
    const fetchSubAudit = async () => {
      const response = axios
        .get(`${BASE_URL}/api/subaudit`)
        .then((result) => {
          console.log("this 9999999999999999", result.data.data);
          setSubAuditData(result.data.data);
          const filterData = result.data.data.filter(
            (data: any) => data.subAuditId === auditId && data.isShown === true
          );
          setSubAuditItems(filterData);
          console.log("this is thexxxx ", filterData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchSubAudit();
  }, [auditId, showAssignModal, dummy]);

  console.log("thisissubauditData", subAuditData)
  useEffect(() => {
    setUserId(sessionStorage.getItem("user"));
    if (currentUserData?.category.category === "Office Auditor") {

    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/use-all-details`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        // const filteredData = result.data.filter(
        //   (data) => data.category._id === selectedOption2
        // );
        setRole(result.data);
        console.log("Result from subproject", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate();
  // const { projectId } = useParams<{ projectId: string }>();
  const { projectId } = useParams<{ projectId: string }>();
  // const { user, logout } = useAuth();
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };
  const totalPages = Math.ceil(forms.length / itemsPerPage);
  const paginationButtons = [];

  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
          }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
          }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );
  let formRef = useRef<HTMLTableElement>(null);

  const searchParams = new URLSearchParams(location.search);

  let search = searchParams.get("search");
  // let page = searchParams.get("page") || 1;

  // useEffect(() => {
  //   getTemplates();
  // }, []);

  useEffect(() => {
    getForms();
    fetchInstacnes();
  }, [
    search,
    page,
    showModal,
    selectedOption3,
    selectedOption4,
    dummy,
    showModal3,
    selectedFilteredDate,
    stateUpdate
  ]);

  // const getTemplates = async () => {
  //   let { data } = await getAllTemplates();
  //   setTemplates(data);
  // };

  const getForms = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/audit`, {
        params: { search: search },
      });

      const filteredData = response.data.data.filter(
        (form: any) => form.subProjectId === projectId
      );
      setForms(filteredData);
      console.log("this is the responseData", response.data.data);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };

  console.log("this is the forms", subAuditList, currentUserData)
  const getLevels = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/level`);
      console.log("This is the response:", response);
      setLevels(response.data.data);
    } catch (error) {
      console.log("An error occurred:", error);
      return error;
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };
  useEffect(() => {
    getLevels();
  }, [showModal]);

  const handleTheme = () => {
    localStorage.getItem("theme") === "dark"
      ? setIsDarkMode(true)
      : setIsDarkMode(false);
  };

  useEffect(() => {
    handleTheme();
  }, []);

  const navigateToForm = (formId: string) => {
    navigate(`/form/${formId}/edit`);
  };

  const handleDeleteForm = async (formId: string) => {
    const token = sessionStorage.getItem("auth-token");
    const config = {
      headers: {
        "auth-token": token,
      },
    };
    if (!window.confirm("Are you sure to delete this form?")) return;

    let {
      data: { message },
    } = await axios.delete(`${BASE_URL}/audit/${formId}`, config);
    let form = [...forms];
    toast(message, {
      type: "success",
      className: "dark:bg-gray-900 dark:text-white z-[1000]",
    });
    let index = form.findIndex(({ _id }) => _id !== formId);
    form.splice(index, 1);
    setForms(form);
  };

  const optionsRp = subAuditItems.map((form: any) => {
    const userLabel = role.filter((data: any) => data._id === form.user);
    const isHighlighted = !form.auditTemplateId;
    let label = `SubAudit For ${userLabel[0]?.fullname}`;
    if (isHighlighted) {
      label += " (Admin-uploaded subaudit)";
    }
    return { value: form.id, label, isHighlighted };
  });
  console.log("this is the optionsRp", optionsRp);
  const options = valueOptions.map((dataItem: any) => {
    return {
      value: dataItem._id,
      label: dataItem.fullname,
      role: dataItem?.category?.category,
    };
  });
  const optionsAuditItems = auditItems.map((form: any) => {
    return { value: form._id, label: `${form.title}` };
  }) as any;

  const downloadExcelTemplate = async (e: any) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${BASE_URL}/api/generate-excel-template`,
        {
          params: {
            data: optionsAuditItems,
          },
        }
      );

      // Ensure that the response contains the expected data before proceeding
      if (response.data && response.data.excelFileUrl) {
        const downloadUrl = response.data.excelFileUrl;

        // Create a link element to trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadUrl;
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, "0");
        const day = now.getDate().toString().padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}`;
        downloadLink.download = "audit_template_" + formattedDate + ".xlsx";
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);

        // Introduce a delay (e.g., 1 second) before initiating the download
        setTimeout(() => {
          downloadLink.click();
          // Remove the link from the DOM
          document.body.removeChild(downloadLink);
        }, 1000);
      } else {
        console.error("Error: Invalid response from server");
      }
    } catch (error) {
      console.error("Error downloading Excel template:", error);
    }
    // try {
    //   const response = await axios.get(
    //     `${BASE_URL}/api/generate-excel-template`,
    //     {
    //       params: {
    //         data: optionsAuditItems, // Pass optionsAuditItems as data in the request
    //       },
    //     }
    //   );
    //   var downloadUrl;
    //   if (response.data && response.data.excelFileUrl) {
    //     downloadUrl = response.data.excelFileUrl;
    //   } else {
    //     console.error("Error: Invalid response from server");
    //   }

    //   // Create a link element to trigger the download
    //   const downloadLink = document.createElement("a");
    //   downloadLink.href = downloadUrl;
    //   var currentDate = new Date();
    //   var formattedDate = currentDate.toISOString().split("T")[0];
    //   downloadLink.download = "audit_template_" + formattedDate + ".xlsx";
    //   downloadLink.style.display = "none";
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();

    //   // Remove the link from the DOM
    //   document.body.removeChild(downloadLink);
    // } catch (error) {
    //   console.error("Error downloading Excel template:", error);
    // }
  };
  console.log("thisiexceldata", excelDataForAudits);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoadingReq(true);
    // console.log("this is the selected option", selectedOption4);
    // if()

    if (excelDataForAudits.length > 0) {
      for (const dataItem of excelDataForAudits) {
        const auditTitle = `${dataItem.dealerCode}, ${dataItem.dealerName}`;
        const isAuditTitleExist = forms.some(
          (item: any) => item.title === auditTitle
        );

        if (isAuditTitleExist) {
          console.error(`Audit already exists: ${auditTitle}`);
          toast(`Audit already exists: ${auditTitle}`, {
            type: "error",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });

          break;
        }

        try {
          const token = sessionStorage.getItem("auth-token");
          const config = {
            headers: {
              "auth-token": token,
            },
          };
          const response = await axios.post(
            `${BASE_URL}/audit`,
            {
              platformId: "649e8a5186bc5f1770f9a120",
              title: auditTitle,
              subProjectId: projectId,
              subAudit: dataItem.dealerId,
              // instanceId: selectedOption4,
            },
            config
          );

          console.log("this is the responseID", response.data.data.id);

          try {
            const token = sessionStorage.getItem("auth-token");
            const config = {
              headers: {
                "auth-token": token,
              },
            };
            const subAuditResponse = await axios.post(
              `${BASE_URL}/api/subaudit`,
              {
                platformId: "649e8a5186bc5f1770f9a120",
                auditId: subProjectData, // this is audit template formId
                isShown: true,
                subAuditId: response.data.data.id,
                user: userId,
              },
              config
            );
            console.log("thisisselectedseet", selectedSheets);
            const formData = new FormData();
            formData.append("file", excelFile);
            formData.append("dealerCode", dataItem.dealerCode);
            formData.append("auditId", response.data.data.id);
            formData.append("selectedSheets", JSON.stringify(selectedSheets));
            if (projectId !== undefined) {
              formData.append("subProjectId", projectId);
            }
            await formData.append("subAuditId", subAuditResponse.data.data.id);
            console.log("dealerCode", dataItem.dealerCode, auditId);

            try {
              const token = sessionStorage.getItem("auth-token");

              const uploadResponse = await axios.post(
                `${BASE_URL}/excel_upload`,
                formData,
                {
                  headers: {
                    "auth-token": token,
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              console.log(uploadResponse.data); // Handle the upload response data as needed
            } catch (error) {
              console.error(error); // Handle the error
            }
          } catch (uploadError) {
            console.error(uploadError);
          }
          updateProgress();
        } catch (error) {
          console.error(error); // Handle the error
        }
      }
    } else {
      // Show toast error message here
      toast("Excel For Audits is empty!", {
        type: "error",
      });
    }
    setIsLoadingReq(false);
    setShowModal(false);
    setProgress(0);
    setExcelDataForAudits([]);
    setSheetNames([]);
    setExcelFile(null);
    setSelectedSheets([]);
    setLoadingProcess(false);
  };

  // }

  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/use-all-details`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        console.log("this is the result from ax", result.data);
        const filteredData = result.data.filter(
          (data: any) => data.category._id === selectedOption2
        );
        setValueOptions(filteredData);
        setValue(result.data);
        console.log("Result from subproject", filteredData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedOption2]);

  // console.log(
  //   "this is the value", value
  // )
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/excel_dynamic_data`)
      .then((result) => {
        // console.log("this******************", result.data,filteredItems);
        const filteredDynamicData = result.data.map((item: any) => {
          const filteredItem: any = {};
          for (const key in item) {
            if (key !== "__EMPTY" && key !== "__EMPTY_1") {
              filteredItem[key] = item[key];
            }
          }
          return filteredItem;
        });

        setDynamicData(filteredDynamicData);

        // setDynamicData(result.data);
        console.log("option report data", optionsReport, optionsReports);
        const filteredData = result.data.filter(
          (data: any) => data.subAuditId === optionsReport[0].value
        );
        console.log("this filteredData***********", filteredData);
        setFilteredNewCarStockData(filteredData);
        console.log(
          "this is filtered data stock from newcarstock",
          filteredNewCarStockData
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [optionsReport, selectedOption5, showModal3, dummy]);
  console.log("this is the dynamic data", dynamicData);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/testdrivedata`)
      .then((result) => {
        console.log("this******************", result.data.data);
        const filteredData = result.data.data.filter(
          (data: any) => data.subAuditId === optionsReport[0].value
        );
        console.log("this filteredData***********", filteredData);
        setFilteredTestDriveData(filteredData);
        console.log(
          "this is filtered data stock from testdrive",
          filteredTestDriveData
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [optionsReport, selectedOption5]);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/retailcdr`)
      .then((result) => {
        console.log("this******************", result.data.data);
        const filteredData = result.data.data.filter(
          (data: any) => data.subAuditId === optionsReport[0].value
        );
        console.log("this filteredData***********", filteredData);
        setFilteredRetailCDRData(filteredData);
        console.log(
          "this is filtered data stock from retailcdr",
          filteredRetailCDRData
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [optionsReport, selectedOption5]);
  const customStyles = {
    dropdownIndicator: (provided: any) => ({
      ...provided,
      paddingRight: "8px",
      paddingLeft: "8px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  useEffect(() => {
    const fetchSubProject = async () => {
      const firstResponse = await axios.get(
        `${BASE_URL}/api/subproject/${projectId}`
      );
      const responseData = firstResponse.data.data.templateId;
      setAuditTemplateId(responseData);
    };
    fetchSubProject();
  }, []);

  const handleAuditAssign = async (event: any) => {
    event.preventDefault();
    try {
      // Make the first GET request
      const firstResponse = await axios.get(
        `${BASE_URL}/api/subproject/${projectId}`
      );
      const responseData = firstResponse.data.data.templateId;
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const secondReq = await axios.post(
        `${BASE_URL}/api/subaudit`,
        {
          platformId: "649e8a5186bc5f1770f9a120",
          auditId: responseData,
          user: selectedOption.value,
          subAuditId: auditId,
        },
        config
      );
      console.log("this is the res45", secondReq.data); // Handle the response data as needed
      setShowAssignModal(false);
    } catch (error) {
      console.error(error); // Handle the error
    }
  };
  const handleAssign = async () => {
    try {
      setLoading(true); // Set loading state to true before making the API call

      const response = await axios.get(`${BASE_URL}/api/subaudit`);
      console.log("this is the res4564", response, auditId);
      if (currentUserData?.category.category === "Office Auditor") {
        setSubAuditList(response.data.data.filter((item: any) => item.user === currentUserData?._id));
        const uniqueDealerIds = new Set(
          response.data.data.filter((item: any) => item.user === currentUserData?._id).map((item: any) => item.dealerId)
        );
        setData(
          response.data.data.filter((item: any) => uniqueDealerIds.has(item.dealerId))
        );
      }
      const filterData = response.data.data.filter(
        (data: any) => data.dealerId === auditId && data.isShown === false
      );
      const newFilterData = response.data.data.filter((item: any) =>
        filteredItems.some((filteredItem: any) => filteredItem.subAudit === item.dealerId && item.isShown === false)
      );
      console.log("htidsglkdjgk", newFilterData.filter((item: any) => item.subProjectId === undefined))
      const filteredArray = value
        .filter((item: any) =>
          filterData.some((dataItem: any) => dataItem.user === item._id)
        )
        .map((item: any) => ({
          ...item,
          subProjectId: filterData.find(
            (dataItem: any) =>
              dataItem.user === item._id && dataItem.subProjectId === projectId
          )?.subProjectId,
          subAuditId: filterData.find(
            (dataItem: any) =>
              dataItem.user === item._id && dataItem.subProjectId === projectId
          )?.id,
        }));
      // Update the state variables
      setFilterData(filterData);
      setNewFilterData(newFilterData);
      if (filteredArray) {
        setLoading(true);
        setAssignedData(filteredArray);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Set loading state to false regardless of success or error
    }
  };

  console.log("thisisafilterData", filterData, newFilterData);
  useEffect(() => {
    handleAssign();
  }, [
    value,
    dummy,
    showAssignModal,
    showModal,
    showModal2,
    selectedOption3,
    selectedOption4,
  ]);

  console.log("thisisfilteredarr", assignedData);
  useEffect(() => {
    async function fetchData() {
      try {
        console.log("hello sign");
        const dropval = await axios.get(`${BASE_URL}/dropdownvalue`);
        const storeddrop = dropval.data;
        setDrop(storeddrop);
        setFilterDrop(storeddrop);
        console.log("The Value of drop", drop);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, []);
  // const handleCompleteAudit = async () => {
  //   if (auditId) {
  //     console.log("thisisauditid", auditId, subAuditId);
  //     const response = axios.put(`${BASE_URL}/api/subaudits/${subAuditId}`, {
  //       status: "Pending",
  //       subAuditId: auditId
  //     })
  //       .then((result) => {
  //         console.log("this is the result", result);
  //         // setShowModal(false);
  //         // navigate.push("/");
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };
  useEffect(() => {
    async function fetchData() {
      try {
        const subProjectData101 = await axios.get(
          `${BASE_URL}/api/subproject/${projectId}`
        );
        console.log("this is subproject109", subProjectData101.data.data);
        setSubProjectData(subProjectData101.data);
        const projectData101 = await axios.get(
          `${BASE_URL}/api/project/${subProjectData101.data.data.projectId}`
        );
        setProjectData(projectData101.data.data);
        setSubProjectData(subProjectData101.data.data.id);
        setSubproject(subProjectData101.data.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const options2 = excelDataForAudits?.map((dataItem: any) => {
    return {
      value: dataItem.dealerCode,
      disabled: true,
      label: `${dataItem.dealerCode}, ${dataItem.dealerName}`,
    };
  });
  const options5 = filterDrop?.map((dataItem: any) => {
    return { value: dataItem._id, label: dataItem.category };
  });
  const options4 = levels.map((form: any) => {
    return { value: form._id, label: form.name, atLevel: form.atLevel };
  });

  const options6 = auditItems.map((form: any) => {
    return { value: form._id, label: `${form.title} Report` };
  });
  console.log("thisisooption6", options6);
  useEffect(() => {
    const fetchAudit = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/audit/${auditId}`);
        setAuditData(response.data.data);
        setSubProjectId(response.data.data.subProjectId);
        const subprojectData = await axios.get(
          `${BASE_URL}/api/subproject/${subProjectId}`
        );
        const responseData = subprojectData.data.data.templateId;
        setFormId(responseData);
        console.log("this is subProjectData", responseData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAudit();
  }, [auditId, subProjectId, showModal, showModal2]);
  const getAuditItemTemplates = async () => {
    try {
      setLoadingdata(true);
      let {
        data: { list },
      } = await getAllForms();
      // const filterList = list.filter((form) => form.projectId === projectId);
      console.log("this is the list from index", list);
      const filterList = list.filter(
        (form) => form.auditId === auditTemplateId
      );
      console.log("this is the filter list xyz", formId);
      setAuditItems(filterList);
      // setPageMeta(pageMeta);
      setLoadingdata(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log("thishits");
    getAuditItemTemplates();
  }, [formId, showModal, showModal2, auditTemplateId]);

  const optionsForReports = [
    { value: "1", label: "CDR Report" },
    { value: "2", label: "Physical Stock Data Report" },
    { value: "3", label: "Test Drive Stock Report" },
  ];
  /* eslint-disable no-restricted-globals */
  const handleUpdateAssinedUser = async (itemId: any) => {
    setUpdateAssignedUser(true);
  };
  const handleUpdateUser = async (itemId: any) => {
    console.log("thisissubauditid", itemId);
    if (selectedOption === null || selectedOption2 === null) {
      alert("Please select a user first to update!");
    } else {
      try {
        const token = sessionStorage.getItem("auth-token");
        const config = {
          headers: {
            "auth-token": token,
          },
        };
        if (confirm("Are you sure you want to update the user?")) {
          // Make the DELETE request
          const filteredSubAudit = filterData.filter((item: any) => {
            return item.user === itemId && item.subAuditId === auditId;
          }) as any;
          console.log("thisisfiltersubauidt", filteredSubAudit[0].id);
          await axios.put(
            `${BASE_URL}/api/subaudit/${filteredSubAudit[0].id}`,
            {
              user: selectedOption.value,
            },
            config
          );

          toast("User updated successfully", {
            type: "success",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
          setUpdateAssignedUser(false);
          await handleAssign();

          setClickedId(null);
        } else {
          setUpdateAssignedUser(false);
          setClickedId(null);
        }
      } catch (err) {
        console.log(err);
        toast("Error while updating", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
    }
  };

  const handleDelete = async (itemId: any) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      if (confirm("Are you sure you want to delete the user assignment?")) {
        // Make the DELETE request
        await axios.delete(`${BASE_URL}/api/subaudit/${itemId}`, config);

        toast("User assignment deleted successfully", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });

        await handleAssign();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const SearchItems = forms.filter(
    ({
      title,
      createdAt,
      updatedAt,
    }: {
      title: string;
      createdAt: string;
      updatedAt: string;
    }) => {
      const query = searchQuery.toLowerCase();
      return (
        title.toLowerCase().includes(query) ||
        createdAt.toLowerCase().includes(query) ||
        updatedAt.toLowerCase().includes(query)
      );
    }
  );

  // const handleSearch = (event: any) => {
  //   event.preventDefault();
  //   const query = event.target.value;
  //   setSearchQuery(query);

  //   const filteredCards = forms.filter((data: any) =>
  //     data.title.toLowerCase().includes(query.toLowerCase())
  //   );
  //   //setForms(filteredCards);
  // };
  // const options2 = dropdownsData.map((form: any) => {
  //   return {
  //     value: form.id,
  //     label: form.name,
  //     atLevel: form.atLevel,
  //     path: form.path,
  //   };
  // });
  const handleLevelChange = (event: any) => {
    const { value, label, atLevel } = event;

    setSelectedLevelOption({ value, label, atLevel });
  };
  // const fetchInstacnes = async () => {
  //   try {
  //     setLoading(true);
  //     const subProjectData101 = await axios.get(`${BASE_URL}/api/subproject/${projectId}`);
  //     console.log("this is subproject101", subProjectData101)
  //     const projectData101 = await axios.get(`${BASE_URL}/api/project/${subProjectData101.data.data.projectId}`);
  //     const instanceAtLevel = axios.get(`${BASE_URL}/api/instance/${projectData101.data.data.instanceId}`).then
  //       ((response) => {
  //         console.log("this is a response from instance109", response.data.data.atLevel);
  //         setInstanceLevel(response.data.data.atLevel);
  //       });

  //     const response = await axios.get("${BASE_URL}/api/instance");
  //     // console.log("this is a response from instance109", instanceAtLevel);
  //     // setDataOriginal(response.data.data);
  //     setLoading(false);
  //     const filteredData = response.data.data.filter((project: any) => {
  //       if (pathofUI.length === 0) {
  //         return project.atLevel > instanceLevel;
  //       }
  //       return true;
  //     });
  //     console.log("this is a gilterd data from pro109", filteredData);

  //     const filteredData2 = filteredData.filter((project: any) => {
  //       return project.path[instanceLevel] === projectData101.data.data.instanceId;
  //     });
  //     console.log("this is a gilterd data from pro46", filteredData2);

  //     // console.log("this is a gilterd data from pro", filteredData2);
  //     setDropdownsData(filteredData2);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const fetchInstacnes = async () => {
    try {
      // setLoading(true);/
      const response = await axios.get(`${BASE_URL}/api/instance`);
      // setLoading(false);
      console.log("this is a response from instance", response.data.data);
      // setDataOriginal(response.data.data);
      const filteredData = response?.data?.data?.filter((project: any) => {
        if (pathofUI.length === 0) {
          return project?.atLevel === selectedOption3?.atLevel;
        }
        // for (let i = 0; i < project.path.length; i++) {
        //   if (project.path[i] !== pathofUI[i]) {
        //     return false;
        //   }
        // }
        return true;
      });
      // setData(filteredData);

      console.log("this is a gilterd data from pro195", filteredData);
      setDropdownsData2(filteredData);
    } catch (error) {
      console.error(error);
    }
  };

  const getFormResponse = async () => {
    if (!optionsReport[1].value) return;

    try {
      let { data } = await getAuditItemFormResponsesById(
        optionsReport[1].value,
        selectedOption5.value
      );
      setFormDetail(data);
      console.log(
        "king*****",
        selectedOption5,
        optionsReport[0]?.value,
        formDetail
      );
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };
  const getFormResponse2 = async () => {
    if (!optionsReport[1].value) return;

    try {
      let data2 = await getAuditItemFormResponsesById(
        optionsReport[1].value,
        selectedOption5.value
      );
      setFormDetail2(data2.data);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };
  const getFormResponse3 = async () => {
    if (!optionsReport[1].value) return;

    try {
      let data2 = await getAuditItemFormResponsesById(
        optionsReport[1].value,
        selectedOption5.value
      );
      setFormDetail3(data2.data);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };
  useEffect(() => {
    getFormResponse();
    getFormResponse2();
    getFormResponse3();
  }, [selectedOption5, optionsReport]);

  console.log(
    "this is the formDetail",
    formDetail,
    formDetail3,
    formDetail2,
    selectedOption5?.label
  );
  const generateSheet2Data = (formDetail: any) => {
    let { formResponses, fields } = formDetail;
    const data = [
      ["Timestamp", ...fields.map((field: any) => field.title)],
      ...formResponses.map((responses: any, createdAt: any) => {
        const formattedDate = moment
          .tz(createdAt, "Asia/Kolkata")
          .format("M/D/YYYY h:mm:ss a");
        const rowData = [formattedDate];
        for (const field of fields) {
          const response = responses.responses.find(
            (resp: any) => resp.fieldId === field._id
          );
          rowData.push(
            response
              ? Array.isArray(response.response)
                ? response.response.join(", ")
                : response.response
              : "-"
          );
        }
        return rowData;
      }),
    ];

    return data;
  };
  const generatedRecon = (formDetail: any) => {
    let { formResponses, fields } = formDetail;
    const uniqueFields = fields.filter((field: any) => field.unique);
    console.log("this is ooo", uniqueFields);

    const data = [
      // Remove "Timestamp" from the header row
      [...uniqueFields.map((field: any) => field.title)],
      ...formResponses
        .filter(
          (response: any) => response.subAuditId === optionsReport[1].value
        )
        .map((responses: any, createdAt: any) => {
          const rowData = [];
          for (const field of uniqueFields) {
            const response = responses.responses.find(
              (resp: any) => resp.fieldId === field._id
            );
            rowData.push(
              response
                ? Array.isArray(response.response)
                  ? response.response.join(", ")
                  : response.response
                : "-"
            );
          }
          return rowData;
        }),
    ];

    return {
      data: data,
      uniqueFields: uniqueFields,
    };
  };
  // console.log("thissdkfhskjdhflksdfk",formDetail,formDetail2,formDetail3)
  const generatedReconForTestDrive = (formDetail: any) => {
    let { formResponses, fields } = formDetail;
    const uniqueFields = fields.filter((field: any) => field.unique);
    console.log("this is ooo", uniqueFields);

    const data = [
      // Remove "Timestamp" from the header row
      [...uniqueFields.map((field: any) => field.title)],
      ...formResponses
        .filter(
          (response: any) => response.subAuditId === optionsReport[1].value
        )
        .map((responses: any, createdAt: any) => {
          const rowData = [];
          for (const field of uniqueFields) {
            const response = responses.responses.find(
              (resp: any) => resp.fieldId === field._id
            );
            rowData.push(
              response
                ? Array.isArray(response.response)
                  ? response.response.join(", ")
                  : response.response
                : "-"
            );
          }
          return rowData;
        }),
    ];

    return data;
  };
  const generatedReconForNewCarStock = (inputData: any) => {
    const uniqueFields = generatedRecon(formDetail).uniqueFields;
    console.log("this is the filteredStockDatasadsadas", inputData);

    const data = [
      // Remove "Timestamp" from the header row
      uniqueFields.map((field: any) => field.title),
      ...inputData.map((response: any) => {
        const rowData = [];
        for (const field of uniqueFields) {
          const value = response.data[field.title];
          rowData.push(value !== undefined ? value : "-");
        }
        return rowData;
      }),
    ];

    return data;
  };

  // console.log("this one is x ",optionsReport[0]?.value)
  // const downloadUploadedData = (data: any) => {
  //   const auditIdsSet = new Set(filteredItems.map((item: any) => item.id));
  //   const filteredData = data.filter((item: any) =>
  //     auditIdsSet.has(item.auditId)
  //   );
  //   console.log("thisisjfhjsdf", filteredItems, auditIdsSet, filteredData);
  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.json_to_sheet(
  //     filteredData.map((item: any) => item.data)
  //   );
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const dataBlob = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });
  //   const url = URL.createObjectURL(dataBlob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "excel_data.xlsx";
  //   a.click();
  //   URL.revokeObjectURL(url);
  // };

  // const downloadUploadedData = (data: any) => {
  //   // Assuming "SNo" is the key for the serial number
  //   const sortedData = data.sort((a: any, b: any) => {
  //     // Convert serial numbers to numbers for proper sorting
  //     const serialNumberA = Number(a.data["SNo."]);
  //     const serialNumberB = Number(b.data["SNo."]);

  //     // Compare serial numbers
  //     return serialNumberA - serialNumberB;
  //   });

  //   const auditIdsSet = new Set(filteredItems.map((item: any) => item.id));
  //   const filteredData = sortedData.filter((item: any) =>
  //     auditIdsSet.has(item.auditId)
  //   );

  //   console.log("thisisjfhjsdf", filteredItems, auditIdsSet, filteredData);

  //   const workbook = XLSX.utils.book_new();
  //   const worksheet = XLSX.utils.json_to_sheet(
  //     filteredData.map((item: any) => item.data)
  //   );
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const dataBlob = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });
  //   const url = URL.createObjectURL(dataBlob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "excel_data.xlsx";
  //   a.click();
  //   URL.revokeObjectURL(url);
  // };

  const downloadUploadedData = (data: any) => {
    const auditIdsSet = new Set(filteredItems.map((item: any) => item.id));
    const filteredData = data.filter((item: any) =>
      auditIdsSet.has(item.auditId)
    );

    // Use a counter for generating your own serial numbers
    let counter = 1;

    // Create a new array with the generated serial numbers replacing the original "SNo."
    const dataWithReplacedSerials = filteredData.map((item: any) => ({
      ...item,
      data: {
        ...item.data,
        "SNo.": counter++, // Replace "SNo." with the generated serial number
      },
    }));

    console.log("thisisjfhjsdf", filteredItems, auditIdsSet, dataWithReplacedSerials);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      dataWithReplacedSerials.map((item: any) => item.data)
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(dataBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "excel_data.xlsx";
    a.click();
    URL.revokeObjectURL(url);
  };

  const generateFullReport = async (event: any) => {
    event.preventDefault();
    console.log("thisiiscallled");
    try {
      const headerObject =
        filteredNewCarStockData.length > 0 ? filteredNewCarStockData[0] : {};
      const headerInfo = Object.entries(headerObject);
      const workbook = XLSX.utils.book_new();
      const formDetailData = generateSheet2Data(formDetail);
      const desiredKeys: string[] = ["Dealer Name", "Dealer Code"];
      const dataEntry = headerInfo.find(([key]) => key === "data");

      if (dataEntry) {
        const data: { [key: string]: any } = dataEntry[1] as {
          [key: string]: any;
        };
        const filteredHeaderInfo = Object.entries(data).filter(([key, value]) =>
          desiredKeys.includes(key)
        );

        // Convert the filtered headerInfo array to a key-value object
        const headerInfoObject = Object.fromEntries(filteredHeaderInfo);

        // Create an array of rows in key-value format
        const headerInfoRows = Object.entries(headerInfoObject).map(
          ([key, value]) => [key, value]
        );
        const worksheetForm = XLSX.utils.aoa_to_sheet([
          ...headerInfoRows,
          ...formDetailData,
        ]);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheetForm,
          "Audit Item Data"
        );
        XLSX.writeFile(workbook, "report.xlsx");
        console.log("Excel report generated successfully.");
      } else {
        console.log("The 'data' entry is not found in the headerInfo.");
      }
    } catch (error: any) {
      console.error("Error occurred while fetching data:", error.message);
    }
  };
  const generateGenericDataReport = async (event: any) => {
    event.preventDefault();

    try {
      const headerObject =
        filteredNewCarStockData.length > 0 ? filteredNewCarStockData[0] : {};
      const headerInfo = Object.entries(headerObject);
      console.log("thisis headerinfo", headerInfo);
      const rows = [];
      rows.push([]);
      rows.push([]);
      rows.push(["Found", "", "", "", ""]);
      rows.push(["Not Found", "", "", "", ""]);
      rows.push(["Excess", "", "", "", ""]);
      rows.push(["Remarks", "", "", "", ""]);
      const formDetailData = generatedRecon(formDetail).data;
      const formDetailData2 = generatedReconForNewCarStock(
        filteredNewCarStockData
      );

      const compareAndGenerateColumns = (
        formDetailData1: any,
        formDetailData2: any
      ) => {
        const result = [
          [...formDetailData2[0], "Found", "Not Found", "Excess"],
        ];

        let totalFound = 0;
        let totalNotFound = 0;
        let totalExcess = 0;

        const maxLength = Math.max(
          formDetailData1.length,
          formDetailData2.length
        );

        for (let i = 1; i < maxLength; i++) {
          const row1 = formDetailData2[i] || [];
          const row2 = formDetailData1[i - 1] || [];

          const found = row1
            .slice(1)
            .every((value: any, index: any) => value === row2[index])
            ? 1
            : 0;
          const notFound = row1
            .slice(1)
            .some((value: any, index: any) => value !== row2[index])
            ? 1
            : 0;

          const excess = row2
            .slice(1)
            .filter((value: any) => !row1.includes(value)).length;

          totalFound += found;
          totalNotFound += notFound;
          totalExcess += excess;

          result.push([...row1, found, notFound, excess]);
        }

        // Add the correct totals in the "Total" row
        const spaces = " ".repeat(formDetailData2[0].length - 1);
        result.push(["Total", spaces, totalFound, totalNotFound, totalExcess]);

        return result;
      };

      const comparedData = compareAndGenerateColumns(
        formDetailData,
        formDetailData2
      );
      console.log("comparedData", comparedData);

      // Check if comparedData is empty or not
      if (comparedData.length === 0) {
        console.error("comparedData is empty or invalid");
        return; // Don't proceed further if there's no data
      }
      const desiredKeys: string[] = ["Dealer Name", "Dealer Code"];
      const dataEntry = headerInfo.find(([key]) => key === "data");

      if (dataEntry) {
        const data: { [key: string]: any } = dataEntry[1] as {
          [key: string]: any;
        };
        const filteredHeaderInfo = Object.entries(data).filter(([key, value]) =>
          desiredKeys.includes(key)
        );

        // Convert the filtered headerInfo array to a key-value object
        const headerInfoObject = Object.fromEntries(filteredHeaderInfo);
        console.log("thsisdfkhdjkfhjks", headerInfoObject);

        // Create an array of rows in key-value format
        const headerInfoRows = Object.entries(headerInfoObject).map(
          ([key, value]) => [key, value]
        );

        // Create a new worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([
          ...headerInfoRows,
          ...comparedData,
        ]);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Stock_Data_Report");
        console.log("this is the worksheet", worksheet);
        // Generate a blob from the workbook
        const arrayBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array" as "array",
        });

        // Create a blob from the ArrayBuffer
        const blob = new Blob([arrayBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        // Create a download link and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Stock_Data_Report.xlsx";
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      } else {
        console.log("The 'data' entry is not found in the headerInfo.");
      }
    } catch (error) {
      console.error("Error generating Excel file:", error);
      // Handle the error, display an error message, or take appropriate action.
    }
  };

  const geenerateTestDriveReport = async (event: any) => {
    event.preventDefault();
    try {
      const headerObject =
        filteredTestDriveData.length > 0 ? filteredTestDriveData[0] : {};
      const headerInfo = Object.entries(headerObject);
      const rows = [];
      rows.push([]);
      rows.push([]);
      rows.push(["Found", "", "", "", ""]);
      rows.push(["Not Found", "", "", "", ""]);
      rows.push(["Excess", "", "", "", ""]);
      rows.push(["Remarks", "", "", "", ""]);
      const formDetailData = generatedRecon(formDetail2);
      const formDetailData2 = generatedReconForTestDrive(filteredTestDriveData);
      console.log("this is formDetailData", formDetailData, formDetailData2);

      const compareAndGenerateColumns = (
        formDetailData1: any,
        formDetailData2: any
      ) => {
        const result = [
          [...formDetailData2[0], "Found", "Not Found", "Excess"],
        ];

        let totalFound = 0;
        let totalNotFound = 0;
        let totalExcess = 0;

        // Ensure both arrays have the same length
        const minLength = Math.min(
          formDetailData1.length,
          formDetailData2.length
        );

        for (let i = 1; i < minLength; i++) {
          const row1 = formDetailData2[i];
          const row2 = formDetailData1[i - 1]; // Adjust index to match formDetailData

          if (row1 && row2) {
            const found = row1.every(
              (value: any, index: any) => value === row2[index + 1]
            )
              ? 1
              : 0;
            const notFound = row1.some(
              (value: any, index: any) => value !== row2[index + 1]
            )
              ? 1
              : 0;
            const excess = row2
              .slice(1)
              .some((value: any, index: any) => value !== row1[index])
              ? 1
              : 0;

            totalFound += found;
            totalNotFound += notFound;
            totalExcess += excess;

            result.push([...row1, found, notFound, excess]);
          }
        }

        // Add the correct totals in the "Total" row
        result.push(["Total", totalFound, totalNotFound, totalExcess]);

        return result;
      };

      const comparedData = compareAndGenerateColumns(
        formDetailData,
        formDetailData2
      );
      console.log("comparedData", comparedData);

      // Check if comparedData is empty or not
      if (comparedData.length === 0) {
        console.error("comparedData is empty or invalid");
        return; // Don't proceed further if there's no data
      }
      const desiredKeys = ["Dealer", "Dealer Name", "Zone"];
      const filteredHeaderInfo = headerInfo.filter(([key, value]) =>
        desiredKeys.includes(key)
      );

      // Convert the filtered headerInfo array to a key-value object
      const headerInfoObject = Object.fromEntries(filteredHeaderInfo);

      // Create an array of rows in key-value format
      const headerInfoRows = Object.entries(headerInfoObject).map(
        ([key, value]) => [key, value]
      );
      // Create a new worksheet
      const worksheet = XLSX.utils.aoa_to_sheet([
        ...headerInfoRows,
        ...comparedData,
      ]);

      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Stock_Data_Report");
      console.log("this is the worksheet", worksheet);
      // Generate a blob from the workbook
      const arrayBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array" as "array",
      });

      // Create a blob from the ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Stock_Data_Report.xlsx";
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error generating Excel file:", error);
      // Handle the error, display an error message, or take appropriate action.
    }
  };

  // const formData = new FormData();
  // formData.append('file', file);

  // try {
  //   const response = await axios.post('${BASE_URL}/excel_upload', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   });
  //   console.log(response.data);
  // } catch (error) {
  //   console.error(error);
  // }
  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];

    if (!file) return;
    setExcelFile(file);

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const extractedSheetNames = workbook.SheetNames;

      // Extract the first sheet data
      const firstSheetName = extractedSheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      let jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as any;

      // Sanitize the data
      jsonSheet = sanitizeExcelData(jsonSheet);

      // Do something with sanitized data (e.g., set it to state or process further)
      console.log('Sanitized Data:', jsonSheet);

      setSheetNames(extractedSheetNames);
    };

    reader.readAsArrayBuffer(file);
  };

  const sanitizeExcelData = (data: any[][]) => {
    // Filter out empty rows
    let sanitizedData = data.filter(row => row.some(cell => cell !== undefined && cell !== null && cell !== ""));

    // Filter out empty columns
    const columnCount = sanitizedData[0]?.length || 0;
    for (let col = columnCount - 1; col >= 0; col--) {
      if (sanitizedData.every(row => row[col] === undefined || row[col] === null || row[col] === "")) {
        sanitizedData = sanitizedData.map(row => {
          row.splice(col, 1);
          return row;
        });
      }
    }

    return sanitizedData;
  };
  const isAuthenticated = sessionStorage.getItem("auth-token");
  const handleSheetSelection = (e: any) => {
    const sheetName = e.target.value;
    setSelectedSheets([sheetName]);
    // const sheetName = e.target.value;
    // if (selectedSheets.includes(sheetName)) {
    //   setSelectedSheets(
    //     selectedSheets.filter((name: any) => name !== sheetName)
    //   );
    // } else {
    //   setSelectedSheets([...selectedSheets, sheetName]);
    // }
  };
  const handleUpload = async () => {
    if (!excelFile) return;
    if (combinedDataArrayLevel0.length > 0 || combinedDataArrayLevel1.length > 0) {
      setLoadingProcess(true);
      const formData = new FormData();
      console.log("thisiscombineddataarray", combinedDataArrayLevel0, combinedDataArrayLevel1)
      formData.append("excelFile", excelFile);
      formData.append("selectedSheets", JSON.stringify(selectedSheets));
      formData.append(
        "combinedDataArrayLevel0",
        JSON.stringify(combinedDataArrayLevel0)
      );
      formData.append(
        "combinedDataArrayLevel1",
        JSON.stringify(combinedDataArrayLevel1)
      );

      try {
        const token = sessionStorage.getItem("auth-token");

        const response = await axios.post(
          `${BASE_URL}/combined-master-excel-upload`,
          formData,
          {
            headers: {
              "auth-token": token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const { totalObjects, dealerData } = response.data;
        // Process the response as needed
        setLoadingProcess(false);
        setExcelDataForAudits(dealerData);
      } catch (error: any) {
        console.log("Error uploading file:", error.response.data.message);
        // Handle any errors here
        toast(error.response.data.message, {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
        setLoadingProcess(false);
      }
    }
  };
  useEffect(() => {
    const filterData = levels.filter(
      (item: any) => item.id === projectData.MasterId
    ) as any;
    console.log("thisisthefiltereddata", filterData, projectData.MasterId);
    if (filterData.length > 0 && filterData[0].atLevel === 0) {
      console.log("this project at level0");
      axios
        .get(`${BASE_URL}/api/dealer_group/dealergroups`)
        .then((response) => {
          // console.log("thisisfiltereddata", filterData[0].atLevelId)
          const dealerData = response.data.filter(
            (item: any) =>
              item.level0Id === projectData.atLevelId ||
              item.mapToOEM === projectData.additionalData
          );
          const promises = [] as any;

          console.log("thisisdealerdata********", dealerData);
          dealerData.forEach((dealer: any) => {
            const promise = axios
              .get(`${BASE_URL}/api/dealer3/dealers`)
              .then((dealerResponse) => {
                console.log("thisisdealerres*********", dealerResponse);
                return dealerResponse.data.filter(
                  (item: any) =>
                    item.level1Id === dealer._id ||
                    item.mapToDealerGroup === dealer.dealerGroupCode
                );
              })
              .catch((dealerError) => {
                console.error(`Error fetching data`, dealerError);
                return [];
              });
            promises.push(promise);
          });

          Promise.all(promises)
            .then((combinedDataArray) => {
              console.log(
                "Final Combined Data Array:",
                combinedDataArray.flat().map((item) => ({
                  dealer_Name: item.dealer_Name,
                  dealer_Code: item.dealer_Code,
                  dealer_Id: item._id,
                }))
              );
              setCombinedDataArrayLevel0(
                combinedDataArray.flat().map((item) => ({
                  dealer_Name: item.dealer_Name,
                  dealer_Code: item.dealer_Code,
                  dealer_Id: item._id,
                }))
              );
            })
            .catch((error) => {
              console.error("Error in Promise.all:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching dealer data:", error);
        });
    } else if (filterData.length > 0 && filterData[0].atLevel === 1) {
      console.log("this project at level0");
      axios
        .get(`${BASE_URL}/api/dealer_group/dealergroups`)
        .then((response) => {
          console.log("thisisfiltereddata", response.data);
          const dealerData = response.data.filter(
            (item: any) =>
              item._id === projectData.atLevelId ||
              item.dealerGroupCode === projectData.additionalData
          ) as any;
          const promises = [] as any;

          console.log("thisisdealerdata1", dealerData);
          dealerData.forEach((dealer: any) => {
            const promise = axios
              .get(`${BASE_URL}/api/dealer3/dealers`)
              .then((dealerResponse) => {
                return dealerResponse.data.filter(
                  (item: any) =>
                    item.level1Id === dealer._id ||
                    item.mapToDealerGroup === dealer.dealerGroupCode
                );
              })
              .catch((dealerError) => {
                console.error(`Error fetching data`, dealerError);
                return [];
              });
            promises.push(promise);
          });

          Promise.all(promises)
            .then((combinedDataArray) => {
              console.log(
                "Final Combined Data Array1:",
                combinedDataArray.flat().map((item) => ({
                  dealer_Name: item.dealer_Name,
                  dealer_Code: item.dealer_Code,
                  dealer_Id: item._id,
                }))
              );
              setCombinedDataArrayLevel1(
                combinedDataArray.flat().map((item) => ({
                  dealer_Name: item.dealer_Name,
                  dealer_Code: item.dealer_Code,
                  dealer_Id: item._id,
                }))
              );
            })
            .catch((error) => {
              console.error("Error in Promise.all:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching dealer data:", error);
        });
    }
  }, [showModal, showAssignModal, showModal2]);

  // const filteredDynamicData = dynamicData.
  useEffect(() => {
    setLoading(true);
    // if (
    //   dynamicData &&
    //   dynamicData.length > 0 &&
    //   SearchItems &&
    //   SearchItems.length > 0
    // ) {
    const filteredData = dynamicData.filter((dataItem: any) => {
      return SearchItems.some(
        (searchItem: any) => searchItem.id === dataItem.auditId
      );
    });
    setStockData(filteredData);
    const uniqueItems = Array.from(
      new Set(filteredData.map((item: any) => item.subAuditId))
    ).map((subAuditId: any) => {
      return filteredData.find((item: any) => item.subAuditId === subAuditId);
    });
    setUniqueFilteredData(uniqueItems);
    // }
    setLoading(false);
  }, [showModal3, dynamicData, dummy]);

  console.log("unique filtered data ", uniqueFilteredData);

  const handleDeleteData = (item: any) => {
    console.log("thisisistheitem", item);
    if (SearchItems.length > 0) {
      // Create an array to store filtered data
      const filteredData = SearchItems.filter((searchItem: any) => {
        // Generate the title based on the item data
        const title = `${item.data["Dealer Code"]}, ${item.data["Dealer Name"]}`;
        console.log("searchItem.title:", searchItem.title, title);
        return searchItem.title === title;
      }) as any;
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      // Check if any data is filtered
      if (filteredData.length > 0) {
        if (filteredData[0].start === false) {
          axios
            .delete(
              `${BASE_URL}/deleteObjectsBySubAuditId/${item.subAuditId}`,
              config
            )
            .then((response) => {
              return axios.delete(`${BASE_URL}/audit/${item.auditId}`, config);
            })
            .then((response) => {
              return axios.delete(
                `${BASE_URL}/api/subaudit_by_auditId/${item.auditId}`,
                config
              );
            })
            .then((response) => {
              deleteResponseByAuditId(item.auditId);
              const uniqueFilteredDataAfterDelete = uniqueFilteredData.filter(
                (data: any) => data.auditId !== item.auditId
              );
              console.log(
                "unique filtered data after delete",
                uniqueFilteredDataAfterDelete
              );
              setUniqueFilteredData(uniqueFilteredDataAfterDelete);
              setDummy(!dummy);
              console.log("Objects deleted successfully");
              toast("Uploaded data deleted successfully!", {
                type: "success",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            })
            .catch((error) => {
              console.error("Error deleting objects:", error);
              toast("Error while deleting uploaded data!", {
                type: "error",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
            });
        } else {
          console.log(
            "Not deleting objects because filteredData[0].start is not false."
          );
          toast("Audit Started, Can't delete the uploaded data!", {
            type: "error",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
        }
      } else {
        console.log("No matching data found.");
        // Handle the case where no matching data is found in SearchItems
        // You might want to show an error message or perform other actions.
      }
    }
  };

  const handleDeleteAll = () => {
    const isConfirmed = window.confirm("Are you sure you want to delete all uploaded data?");
    if (isConfirmed) {
      const deletePromises = uniqueFilteredData.map((item: any) => {
        if (SearchItems.length > 0) {
          const filteredData = SearchItems.filter((searchItem: any) => {
            const title = `${item.data["Dealer Code"]}, ${item.data["Dealer Name"]}`;
            console.log("searchItem.title:", searchItem.title, title);
            return searchItem.title === title;
          }) as any;
          const token = sessionStorage.getItem("auth-token");
          const config = {
            headers: {
              "auth-token": token,
            },
          };
          if (filteredData.length > 0) {
            if (filteredData[0].start === false) {
              return axios
                .delete(
                  `${BASE_URL}/deleteObjectsBySubAuditId/${item.subAuditId}`,
                  config
                )
                .then((response) =>
                  axios.delete(`${BASE_URL}/audit/${item.auditId}`, config)
                )
                .then((response) =>
                  axios.delete(
                    `${BASE_URL}/api/subaudit_by_auditId/${item.auditId}`,
                    config
                  )
                )
                .then((response) => {
                  deleteResponseByAuditId(item.auditId);
                  setStateUpdate(!stateUpdate)
                  console.log("Objects deleted successfully");
                  toast("Uploaded data deleted successfully!", {
                    type: "success",
                    className: "dark:bg-gray-900 dark:text-white z-[1000]",
                  });
                  return item.auditId;
                })
                .catch((error) => {
                  console.error("Error deleting objects:", error);
                  toast("Error while deleting uploaded data!", {
                    type: "error",
                    className: "dark:bg-gray-900 dark:text-white z-[1000]",
                  });
                  return null;
                });
            } else {
              console.log(
                "Not deleting objects because filteredData[0].start is not false."
              );
              toast("Audit Started, Can't delete the uploaded data!", {
                type: "error",
                className: "dark:bg-gray-900 dark:text-white z-[1000]",
              });
              return null;
            }
          } else {
            console.log("No matching data found.");
            return null;
          }
        }
      });

      Promise.all(deletePromises)
        .then((deletedAuditIds) => {
          const uniqueFilteredDataAfterDelete = uniqueFilteredData.filter(
            (data: any) => !deletedAuditIds.includes(data.auditId)
          );
          setUniqueFilteredData(uniqueFilteredDataAfterDelete);
          // setDummy(!dummy);
          console.log("All objects deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting objects:", error);
          toast("Error while deleting uploaded data!", {
            type: "error",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
        });
    }
  };

  // const handleDeleteAll = () => {
  //   uniqueFilteredData.map((item: any, index: any) => {
  //     if (SearchItems.length > 0) {
  //       // Create an array to store filtered data
  //       const filteredData = SearchItems.filter((searchItem: any) => {
  //         // Generate the title based on the item data
  //         const title = `${item.data["Dealer Code"]}, ${item.data["Dealer Name"]}`;
  //         console.log("searchItem.title:", searchItem.title, title);
  //         return searchItem.title === title;
  //       }) as any;
  //       const token = sessionStorage.getItem("auth-token");
  //       const config = {
  //         headers: {
  //           "auth-token": token,
  //         },
  //       };
  //       // Check if any data is filtered
  //       if (filteredData.length > 0) {
  //         if (filteredData[0].start === false) {
  //           axios
  //             .delete(
  //               `${BASE_URL}/deleteObjectsBySubAuditId/${item.subAuditId}`,
  //               config
  //             )
  //             .then((response) => {
  //               return axios.delete(
  //                 `${BASE_URL}/audit/${item.auditId}`,
  //                 config
  //               );
  //             })
  //             .then((response) => {
  //               return axios.delete(
  //                 `${BASE_URL}/api/subaudit_by_auditId/${item.auditId}`,
  //                 config
  //               );
  //             })
  //             .then((response) => {
  //               deleteResponseByAuditId(item.auditId);
  //               console.log("Objects deleted successfully");
  //               toast("Uploaded data deleted successfully!", {
  //                 type: "success",
  //                 className: "dark:bg-gray-900 dark:text-white z-[1000]",
  //               });
  //             })
  //             .catch((error) => {
  //               console.error("Error deleting objects:", error);
  //               toast("Error while deleting uploaded data!", {
  //                 type: "error",
  //                 className: "dark:bg-gray-900 dark:text-white z-[1000]",
  //               });
  //             });
  //         } else {
  //           console.log(
  //             "Not deleting objects because filteredData[0].start is not false."
  //           );
  //           toast("Audit Started, Can't delete the uploaded data!", {
  //             type: "error",
  //             className: "dark:bg-gray-900 dark:text-white z-[1000]",
  //           });
  //         }
  //       } else {
  //         console.log("No matching data found.");
  //         // Handle the case where no matching data is found in SearchItems
  //         // You might want to show an error message or perform other actions.
  //       }
  //     }
  //   });
  // };
  let foundCount = 0;
  let notFoundCount = 0;
  let excessCount = 0;

  hoverCombinedData.forEach((item: any) => {
    const { detailValue, detailValueExtended, auditItemFormId } = item;

    // Check for "found" condition
    if (
      detailValue !== "-" &&
      detailValueExtended === false &&
      auditItemFormId !== "-"
    ) {
      foundCount++;
    }

    // Check for "not found" condition
    if (detailValue !== "-" && auditItemFormId === "-") {
      notFoundCount++;
    }

    // Check for "excess" condition
    if (detailValueExtended === true && auditItemFormId !== "-") {
      excessCount++;
    }
  });

  console.log("thisiformid", hoverCombinedData, hoverFormId, hoveredId);
  const updateLocalStorage = (
    auditId: string,
    foundCount: number,
    notFoundCount: number,
    excessCount: number
  ) => {
    const auditCounts: {
      [key: string]: {
        foundCount: number;
        notFoundCount: number;
        excessCount: number;
      };
    } = JSON.parse(localStorage.getItem("auditCounts") || "{}");
    auditCounts[auditId] = { foundCount, notFoundCount, excessCount };
    localStorage.setItem("auditCounts", JSON.stringify(auditCounts));
  };
  // Function to fetch counts from local storage based on audit ID
  const getAuditCountsFromLocalStorage = (
    auditId: string
  ): { foundCount: number; notFoundCount: number; excessCount: number } => {
    const auditCounts: {
      [key: string]: {
        foundCount: number;
        notFoundCount: number;
        excessCount: number;
      };
    } = JSON.parse(localStorage.getItem("auditCounts") || "{}");
    return (
      auditCounts[auditId] || {
        foundCount: 0,
        notFoundCount: 0,
        excessCount: 0,
      }
    );
  };
  const handleFilterDateChange = (date: any) => {
    setSelectedFilteredDate(date);
  };

  // Assuming SearchItems is your data source for the table
  const filteredItems = SearchItems.filter((item: any) => {
    // Filter the items based on the selected date
    return selectedFilteredDate
      ? item.date.startsWith(selectedFilteredDate)
      : true;
  });
  const handleOmniAuditAssign = async (event: any) => {
    if (currentUserData?.category.category === "Office Auditor") {
      toast("Access Denied!.", {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white",
      });
      return;
    }

    const confirmAuditAssign = window.confirm(
      "Are you sure, you want to Assign all the Audits?"
    );

    if (!confirmAuditAssign) {
      return;
    }
    try {
      let filteredData: any;
      console.log("uniqueDealer", newFilterData)
      if (currentUserData?.category.category === "Office Auditor") {
        const uniqueDealerIds = new Set(
          newFilterData.filter((item: any) => item.user === currentUserData?._id).map((item: any) => item.dealerId)
        );

        console.log("uniqueDealer", uniqueDealerIds);
        // Filter data based on unique dealer IDs and set it
        const uniqueFilteredData = newFilterData.filter((item: any) => uniqueDealerIds.has(item.dealerId))
        console.log("uniqueDealer", uniqueFilteredData)

        filteredData = uniqueFilteredData.filter(
          (item: any) => item.subProjectId === undefined
        );
        console.log()
      } else {
        filteredData = newFilterData.filter(
          (item: any) => item.subProjectId === undefined
        );
      }

      console.log("thisisthefilterdata", filteredData);
      for (const item of filteredData) {
        // Make the first GET request
        const firstResponse = await axios.get(
          `${BASE_URL}/api/subproject/${projectId}`
        );
        const responseData = firstResponse.data.data.templateId;
        const token = sessionStorage.getItem("auth-token");

        const config = {
          headers: {
            "auth-token": token,
          },
        };
        const filteredData = newFilterData.filter(
          (data: any) =>
            data.user === item.user && data.subProjectId === projectId && data.dealerId === item.dealerId
        );
        if (filteredData.length === 0) {
          // Make the second POST request after the first request resolves
          await toast.promise(
            axios.post(
              `${BASE_URL}/api/subaudit`,
              {
                platformId: "649e8a5186bc5f1770f9a120",
                auditId: responseData,
                dealerId: item.dealerId,
                user: item.user,
                subAuditId: item.dealerId, //this will change in mobile application handleCompleteAudit
                subProjectId: projectId,
              },
              config
            ),
            {
              pending: "Processing...",
              success: "Assignment successful!",
              error: "An error occurred",
            }
          );
        } else {
          toast.error("Alredy Assigned!");
        }
      }
      await handleAssign();
      setDummy(!dummy);
    } catch (error) {
      console.error(error); // Handle the error
      toast.error("An error occurred");
    }
  };
  console.log("search items in sub project", SearchItems);
  console.log("filtered items in sub project", filteredItems);
  if (currentUserData?.category.category === "Office Auditor") {
    const moreFilter = filteredItems.filter((item: any) => {
      return subAuditList.some(
        (subAudit: any) => subAudit.subAuditId === item.subAudit
      );
    });
    console.log("more filter", moreFilter?.length);
  }
  const handleSignOffReport = (e: any) => {
    e.preventDefault();
    const token = sessionStorage.getItem("auth-token");
    const config = {
      headers: {
        "auth-token": token,
      },
    };
    if (auditData) {
      if (auditData.signOfReports.length === 0) {
        toast("Signoff Report is not ready", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
      auditData.signOfReports.forEach((item: any) => {
        const link = document.createElement("a");
        link.href = `${BASE_URL}/retrieve_file/${item}`;
        link.download = item; // Optional: set the filename when downloading
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };
  useEffect(() => {
    const userId = sessionStorage.getItem("user");
    if (userId) {
      const response = axios
        .get(`${BASE_URL}/user-details/${userId}`)
        .then((response) => {
          console.log("this is a user res", response.data);
          setCurrentUserData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <>
      {hoveredId && hoverFormId && dummyForHandleHover ? (
        <div className="hidden">
          <AuditView
            hoverId={hoveredId}
            hoverFormId={hoverFormId}
            dummyForHandleHover={dummyForHandleHover}
          />
        </div>
      ) : null}
      {showModal2 && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex fixed inset-0 justify-center items-center z-50 w-full h-full overflow-x-hidden overflow-y-auto bg-transparent bg-opacity-50"
        >
          <div className="relative w-full max-w-[505px] bg-white rounded-lg shadow-lg dark:bg-[#1e293b]">
            <button
              type="button"
              onClick={() => setShowModal2(false)}
              className="absolute top-3 right-3 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                Date Assignment for Audit
              </h3>
            </div>
            <div className="p-6">
              <div className="mb-4 flex justify-between  items-center">
                <label
                  htmlFor="start_date"
                  className="block mb-2 text-gray-800 dark:text-white"
                >
                  The audit is set to begin on:
                </label>
                {auditStartDate}
              </div>
              <div className="mb-4 flex justify-between  items-center">
                <label
                  htmlFor="start_date"
                  className="block mb-2 text-gray-800 dark:text-white"
                >
                  Choose audit start date or update
                </label>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  placeholder="Select Date"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
              <button
                type="button"
                onClick={() => {
                  setShowModal(false);
                  setProgress(0);
                  setExcelDataForAudits([]);
                  setSheetNames([]);
                  setExcelFile(null);
                  setSelectedSheets([]);
                  setLoadingProcess(false);
                }}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  Audit Creation
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <label
                      htmlFor="email"
                      className="block text-[15px] font-medium text-gray-900 dark:text-white"
                    >
                      Download Excel Template
                    </label>
                    <Select
                      components={animatedComponents}
                      isMulti={true}
                      defaultValue={optionsAuditItems}
                      // onChange={(event) => setSelectedOption(event.value)}
                      onChange={(event: any) => {
                        setAuditItemsTemplate(event);
                      }}
                      options={optionsAuditItems}
                      placeholder="Select Audit Items"
                      className="my-react-select-container mt-2 mb-2 rounded-md  dark:border-gray-600"
                      classNamePrefix="my-react-select"

                    // isMulti={true}
                    />
                    <button
                      type="button"
                      onClick={(e: any) => {
                        downloadExcelTemplate(e);
                      }}
                      className="btn bg-[#4f46e5] hover:bg-indigo-800 text-white "
                    >
                      <i className="bx bxs-download text-xl mr-1"></i>Download
                      Template
                    </button>
                    <br />
                    <br />
                    <hr />
                    <br />
                    <label
                      htmlFor="email"
                      className="block mb-2 text-[15px] font-medium text-gray-900 dark:text-white"
                    >
                      Upload an Excel File
                    </label>
                    <input
                      type="file"
                      id="file"
                      disabled={isLoadingReq}
                      onChange={(e: any) => handleFileUpload(e)}
                      className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                    />
                    <div className="bg-transparent mt-2 rounded-lg dark:bg-gray-800">
                      <h3 className="text-[15px] text-gray-700 dark:text-white">
                        Select Sheets to Process:
                      </h3>
                      <div className="flex flex-wrap -mx-2">
                        {sheetNames.map((sheetName: any) => (
                          <div key={sheetName} className="w-1/2 ">
                            <label className="flex items-center space-x-2 bg-white hover:bg-gray-100 dark:bg-transparent dark:hover:bg-gray-600 rounded-lg p-2 cursor-pointer">
                              <input
                                type="checkbox"
                                value={sheetName}
                                checked={selectedSheets.includes(sheetName)}
                                onChange={handleSheetSelection}
                                className="text-blue-500 focus:ring-2 focus:ring-blue-300 dark:text-blue-500 dark:focus:ring-blue-200"
                              />
                              <span className="text-gray-700 dark:text-gray-200">
                                {sheetName}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <button
                      disabled={
                        !excelFile || loadingProcess ||
                        sheetNames === undefined ||
                        sheetNames.length === 0
                      }
                      className="mb-4 btn bg-[#4f46e5] hover:bg-indigo-800 text-white"
                      onClick={handleUpload}
                    >
                      <span className="hidden xs:block mx-2">
                        {loadingProcess ? (
                          <div className="flex items-center">
                            <PuffLoader color="#fff" size={20} />
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <i className="bx bx-cloud-upload text-xl mr-1 "></i>Upload
                            and Process
                          </div>
                        )}
                      </span>

                    </button>
                    <hr />
                    {/* <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Audit Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      onChange={(e: any) => setTitle(e.target.value)}
                      aria-describedby="helper-text-explanation"
                      className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                      placeholder="title"
                    /> */}

                    <Select
                      isDisabled={isLoadingReq === true ? true : false}
                      isOptionDisabled={(option: any) => option.disabled}
                      options={options2}
                      placeholder="List of Audits to create..."
                      className="my-react-select-container mt-3 border border-gray-600 "
                      classNamePrefix="my-react-select"
                    />
                    {/* <Select
                      defaultValue={{
                        value: "64b3684a5d430ac79bbce86b",
                        label: "Level 0",
                      }}
                      // onChange={(event) => setSelectedOption(event.value)}
                      onChange={(event) => {
                        handleLevelChange3(event)
                      }}
                      options={options4}
                      placeholder="Select Level"
                      className="my-react-select-container mt-4 rounded-md  dark:border-gray-600"
                      classNamePrefix="my-react-select"

                    // isMulti={true}
                    /> */}

                    {/* <Select
                      onChange={(event: any) => {
                        setSelectedOption4(event.value);
                      }}
                      options={options3}
                      placeholder="Select Instance"
                      className="my-react-select-container mt-4 rounded-md  dark:border-gray-600  "
                      classNamePrefix="my-react-select"
                      components={{
                        DropdownIndicator: () => (
                          <span className="mx-4">
                            {" "}
                            {selectedOption3 === null
                              ? "Level 0"
                              : `${selectedOption3?.label}`}
                          </span>
                        ),
                      }}
                    /> */}
                    <button
                      type="button"
                      disabled={
                        isLoadingReq ||
                        excelDataForAudits === undefined ||
                        excelDataForAudits.length === 0
                      }
                      onClick={(e: any) => {
                        handleSubmit(e);
                      }}
                      className="btn bg-[#4f46e5] hover:bg-indigo-800 text-white mt-4"
                    >
                      <span className="hidden xs:block mx-2">
                        {isLoadingReq ? (
                          <div className="flex items-center">
                            <PuffLoader color="#fff" size={20} />
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <AiOutlineAudit className="text-white text-xl mr-1" />
                            <span>Create Audits</span>
                          </div>
                        )}
                      </span>
                    </button>
                    <div className=" mt-3">
                      {progress < excelDataForAudits.length && (
                        <div className="relative pt-1">
                          <div className="flex mb-2 items-center justify-between">
                            <div>
                              <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-[#4f46e5] bg-blue-100">
                                In Progress
                              </span>
                            </div>
                            <div className="text-right">
                              <span className="text-xs font-semibold inline-block text-blue-700">
                                {progress} / {excelDataForAudits.length}
                              </span>
                            </div>
                          </div>
                          <div className="w-full">
                            <div className="h-2 bg-gray-200 rounded-full">
                              <div
                                className="h-2 bg-[#4f46e5] rounded-full transition-width"
                                style={{
                                  width: `${(progress / excelDataForAudits.length) * 100
                                    }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </form>
                </>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAssignModal && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
              <button
                type="button"
                onClick={() => {
                  setShowAssignModal(false);
                  setUpdateAssignedUser(false);
                  setClickedId(null);
                  setAssignedData(null);
                }}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  {updateAssignedUser
                    ? "Update Assined User"
                    : "User Assignment Overview"}
                </h3>
              </div>

              {/* Modal body */}
              <div className="px-4">
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}

                <>
                  {updateAssignedUser ? (
                    <label
                      htmlFor="email"
                      className="block text-[15px] font-medium text-gray-900 dark:text-white"
                    >
                      Select user to update
                    </label>
                  ) : null}
                  {/* <Select
                      defaultValue={{ value: "64a3ce5feed18829472e4278", label: "Field Auditor" }}
                      onChange={(event: any) => {
                        setSelectedOption2(event.value);
                      }}
                      options={options5}
                      placeholder="Sort by roles"
                      className="my-react-select-container mt-4 rounded-md  "
                      classNamePrefix="my-react-select"
                    />
                    <Select
                      defaultValue={selectedOption}
                      onChange={(event: any) => setSelectedOption(event)}
                      options={options}
                      placeholder="Select a user..."
                      className="my-react-select-container mt-4 rounded-md "
                      classNamePrefix="my-react-select"
                      components={{
                        DropdownIndicator: () => <span className="mx-4"> {selectedOption === null ? "Roles" : `${selectedOption?.role}`}</span>,
                      }}
                    // isMulti={true}
                    /> */}
                </>
                {/* {
                    updateAssignedUser ? null : <button
                      onClick={(e: any) => {
                        handleAuditAssign(e)
                      }}
                      className="btn mb-5 bg-[#4f46e5] hover:bg-indigo-800 text-white mt-4"
                    >

                      <span className="hidden xs:block ml-2">
                        Assign Audit
                      </span>
                    </button>
                  } */}

                <br />

                {/*<form>
                    <label
                      htmlFor="default-search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                      Search
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      
                      <input
                        id="default-search"
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-[#4f46e5] focus:border-[#4f46e5] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-white"
                        type="text"
                        placeholder="Search by user name..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <button
                        type="submit"
                        className="text-white absolute right-2.5 bottom-2.5 bg-[#4f46e5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-[#4f46e5] font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#4f46e5] dark:hover:bg-indigo-800 dark:focus:ring-blue-800"
                      >
                        Search
                      </button>
                    </div>
                    </form>*/}

                <div className="max-h-[190px]  overflow-y-auto scrollbar-hide">
                  {loading ? (
                    <div className="flex justify-center items-center">
                      <PuffLoader color="#007aff" size={50} />
                    </div>
                  ) : assignedData.length === 0 ? (
                    <div className="flex justify-center items-center">
                      No Data
                    </div>
                  ) : (
                    assignedData.map((data: any) => (
                      <div className="mb-4">
                        <div
                          id="toast-notification"
                          className="w-full p-4 flex items-center justify-between text-gray-900 bg-white rounded-lg shadow-lg  dark:border-[#94a3b8] dark:bg-gray-800 dark:text-gray-300 border"
                          role="alert"
                        >
                          <div className="flex items-center">
                            <div className="relative inline-block shrink-0">
                              <Avatar userName={data.fullname} />
                            </div>
                            <div className="ml-3 text-sm font-normal">
                              <div className="text-sm font-semibold text-gray-900 dark:text-white">
                                {data.fullname}
                              </div>
                              <div className="text-sm font-normal">{`Mob. ${data?.mobile}`}</div>
                            </div>
                          </div>
                          <div>
                            <div className="text-sm font-normal flex justify-center items-center">
                              {data.subProjectId ? (
                                <i className="bx bx-check-shield text-white text-lg m-2"></i>
                              ) : null}
                              {data?.category?.category}
                              {data.subProjectId ? (
                                <button
                                  className="ml-3 text-red-500 hover:text-red-700 flex items-center"
                                  onClick={() => {
                                    handleDelete(data.subAuditId);
                                  }}
                                >
                                  <i className="bx bx-trash text-xl"></i>
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}

                  {/* {searchQuery.length === 0 ? (
                    assignedData.length > 0 ? (
                      assignedData.map((data: any) => (
                        <div className="mb-4">
                          <div
                            id="toast-notification"
                            className="w-full p-4 flex items-center justify-between text-gray-900 bg-white rounded-lg shadow-lg  dark:border-[#94a3b8] dark:bg-gray-800 dark:text-gray-300 border"
                            role="alert"
                          >
                            <div className="flex items-center">
                              <div className="relative inline-block shrink-0">
                                <Avatar userName={data.fullname} />
                              </div>
                              <div className="ml-3 text-sm font-normal">
                                <div className="text-sm font-semibold text-gray-900 dark:text-white">
                                  {data.fullname}
                                </div>
                                <div className="text-sm font-normal">{`Mob. ${data?.mobile}`}</div>
                              </div>
                            </div>
                            <div> */}
                  {/* {
                                    clickedId === data._id ? <button
                                      className="ml-3 text-green-500 hover:text-green-700"
                                      onClick={() => { handleUpdateUser(data._id) }}
                                    >
                                      <i className='bx bx-user-check text-xl'></i>
                                    </button> : null
                                  }
                                  {
                                    clickedId === data._id ? null : <button
                                      className="ml-3 text-[#6366f1] hover:text-blue-700"
                                      onClick={() => {
                                        setClickedId(data._id);
                                        handleUpdateAssinedUser(data._id);
                                      }}
                                    >
                                      <i className='bx bx-edit text-xl'></i>
                                    </button>
                                  }

                                  <button
                                    className="ml-3 text-red-500 hover:text-red-700"
                                    onClick={() => {
                                      handleDelete(data._id);
                                    }}
                                  >
                                    <i className='bx bx-trash text-xl'></i>
                                  </button> */}
                  {/* <div className="text-sm font-normal flex justify-center items-center">
                                {data.subProjectId ? (<i className='bx bx-check-shield text-white text-lg m-2'></i>) : null}
                                {data?.category?.category}
                                {data.subProjectId ?
                                  <button
                                    className="ml-3 text-red-500 hover:text-red-700 flex items-center"
                                    onClick={() => {
                                      handleDelete(data.subAuditId);
                                    }}
                                  >
                                    <i className='bx bx-trash text-xl'></i>
                                  </button>
                                  : null}

                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex justify-center items-center">
                        {loading ? (
                          <PuffLoader color="#fff" size={50} />
                        ) : (
                          <p>No data</p>
                        )}
                      </div>
                    )
                  ) : filteredData.length > 0 ? (
                    filteredData.map((data: any) => (
                      <div className="mb-4">
                        <div
                          id="toast-notification"
                          className="w-full p-4 flex items-center justify-between text-gray-900 bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-300"
                          role="alert"
                        >
                          <div className="flex items-center">
                            <div className="relative inline-block shrink-0">
                              <Avatar userName={data.fullname} />
                            </div>
                            <div className="ml-3 text-sm font-normal">
                              <div className="text-sm font-semibold text-gray-900 dark:text-white">
                                {data.fullname}
                              </div>
                              <div className="text-sm font-normal">
                                {data?.category?.category}
                              </div>
                            </div>
                          </div>
                          <button
                            className="ml-3 text-red-500 hover:text-red-700"
                            onClick={() => {
                              handleDelete(data._id);
                            }} // Add your delete function here
                          >
                            <i className="bx bx-trash text-xl"></i>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center items-center">
                      {loading ? (
                        <PuffLoader color="#fff" size={50} />
                      ) : (
                        <p>No data</p>
                      )}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal3 && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full "
        >
          <div className="relative w-full max-w-4xl max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400 dark:border-none">
              <button
                type="button"
                onClick={() => {
                  setShowModal3(false);
                }}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  Uploaded Data and Management Console
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                <>
                  <div
                    className="flex mb-4  justify-between mx-auto mt-2 dark:-mt-2 items-center max-w-[920px]  p-2 text-sm text-gray-800 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600"
                    role="alert"
                  >
                    <div>
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 mx-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Info</span>
                      <span className="font-medium">
                        Are you sure you want to permanently delete all uploaded
                        data?
                      </span>
                    </div>
                    <div className="flex justify-between items-center">
                      <button
                        onClick={() => {
                          handleDeleteAll();
                        }}
                        type="button"
                        className="text-white flex justify-center items-center bg-gradient-to-br from-red-600 to-red-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-md text-sm px-5 py-2 text-center mr-2 "
                      >
                        <i className="bx bx-trash text-lg mr-1"></i>
                        Delete
                      </button>
                      <p>OR</p>
                      <button
                        className="mx-2 text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                        onClick={() => {
                          downloadUploadedData(dynamicData);
                        }}
                      >
                        <i className="bx bxs-download text-lg mr-1"></i>
                        Download Data
                      </button>
                    </div>
                  </div>

                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <h2 className=" text-gray-700 uppercase  dark:text-gray-400  mb-2">
                      Total Stock Uploaded - {stockData.length}
                    </h2>
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            data uploaded for
                          </th>
                          <th scope="col" className="px-6 py-3">
                            uploaded at
                          </th>

                          <th scope="col" className="px-6 py-3">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <div className="flex justify-center items-center relative left-[50%]">

                            <tr>
                              <td
                                // colSpan={6}
                                className="px-6 py-4 text-center text-gray-500 dark:text-white"
                              >
                                <PuffLoader
                                  color="#6366f1"
                                  size={60}
                                />
                              </td>
                            </tr>
                          </div>
                        ) : uniqueFilteredData.length === 0 ? (
                          <div className="flex justify-center text-lg items-center relative left-[50%] my-4">
                            No Data
                          </div>
                        ) : (
                          uniqueFilteredData.map((item: any, index: any) => (
                            <tr
                              className={
                                index % 2 === 0
                                  ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                                  : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
                              }
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {`${item.data["Dealer Name"]}, ${item.data["Dealer Code"]}`}
                              </th>
                              <td className="px-6 py-4">
                                {moment
                                  .tz(item.createdAt, "Asia/Kolkata")
                                  .fromNow()}
                              </td>
                              <td className="px-6 py-4">
                                <button
                                  onClick={() => {
                                    handleDeleteData(item);
                                  }}
                                >
                                  <i className="bx bx-trash text-red-400 text-lg"></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal4 && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full "
        >
          <div className="relative w-full max-w-xl max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400 dark:border-none">
              <button
                type="button"
                onClick={() => {
                  setShowModal4(false);
                  handleHoverCombinedData([]);
                }}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  Concise Audit Overview
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                <>
                  <div className="relative overflow-x-auto  sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Found
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Not Found
                          </th>

                          <th scope="col" className="px-6 py-3">
                            Excess
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {hoverCombinedData.map((item: any, index: any) => ( */}
                        <tr
                          className={
                            "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
                          }
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {foundCount}
                          </th>
                          <td className="px-6 py-4">{notFoundCount}</td>
                          <td className="px-6 py-4">{excessCount}</td>
                        </tr>
                        {/* ))} */}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${(showModal ||
          showAssignModal ||
          showModal2 ||
          showModal3 ||
          showModal4) === false
          ? ""
          : `blur-sm`
          } flex h-screen overflow-hidden visible`}
      >
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          <main>
            <div className=" w-full max-w-9xl mx-auto">
              {/* Welcome banner */}
              {/* <WelcomeBanner /> */}

              {/* Dashboard actions */}
              <div className="sm:flex sm:justify-between sm:items-center ">
                {/* Left: Avatars */}
                {/* <DashboardAvatars /> */}

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Filter button */}
                  {/* <FilterButton /> */}
                  {/* Datepicker built with flatpickr */}
                  {/* <Datepicker /> */}
                  {/* Add view button */}
                  {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                </button>                 */}
                </div>
              </div>

              {/* Cards */}
              <div>
                <Fragment>
                  {/*<Header search={search} />*/}
                  <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-b dark:border-slate-600">
                    <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                      <Link to="/dashboard" className="flex items-center">
                        <img
                          src="https://flowbite.com/docs/images/logo.svg"
                          className="h-8 mr-3"
                          alt="Flowbite Logo"
                        />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                          VPS Group
                        </span>
                        {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            VPS Group
          </span> */}
                      </Link>
                      <div className="flex md:order-2">
                        <div className="w-10 h-10  rounded-full ring-2 ring-green-400 dark:ring-green-400 mr-3 ">
                          <ThemeToggle size={10} />
                        </div>

                        <div className="w-10 h-10  rounded-full ring-2 ring-green-400 dark:ring-green-400">
                          <Avatar userName={"Admin Panel"} />
                        </div>

                        <button
                          data-collapse-toggle="navbar-cta"
                          type="button"
                          className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                          aria-controls="navbar-cta"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Open main menu</span>
                          <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                      <div
                        className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
                        id="navbar-cta"
                      >
                        <form
                          className="flex justify-center items-center "
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <label
                            htmlFor="default-search"
                            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                          >
                            Search
                          </label>
                          <div className="relative pr-32">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                              </svg>
                            </div>
                            <input
                              type="search"
                              id="default-search"
                              className="block w-[155%] p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-[#4f46e5] focus:border-[#4f46e5] dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                              required
                              placeholder="Search by title"
                              defaultValue={search || ""}
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            // onChange={handleChange}
                            />
                            <button
                              type="submit"
                              className="text-white absolute right-[7px] bottom-[1px] bg-[#4f46e5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#4f46e5] dark:hover:bg-[#4f46e5] dark:focus:ring-blue-800"
                            >
                              Search
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </nav>
                  <div
                    id="alert-border-1"
                    className="flex justify-between items-center p-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
                    role="alert"
                    style={{ position: 'sticky', top: 0 }}
                  >
                    <div className="flex justify-center items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <div className="ml-2 text-sm font-medium">
                        <strong>Audit Creation Screen</strong>
                      </div>
                    </div>
                    <div>
                      <p className="flex justify-center items-center font-semibold">
                        <i className="bx bx-chevrons-right text-2xl"></i>
                        {subproject?.title}
                      </p>
                    </div>
                  </div>
                  <div>
                    {isLoading ? (
                      <div className="flex justify-center items-center m-auto h-screen">
                        <PuffLoader color="#6366f1" size={60} />
                      </div>
                    ) : (
                      <div className={styles.container}>
                        <div className={`dark:bg-slate-900 ${styles.template}`}>
                          <div
                            className={`dark:bg-slate-900 ${styles.wrapper}`}
                          >
                            <nav className="flex mb-3" aria-label="Breadcrumb">
                              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li className="inline-flex items-center">
                                  <a
                                    href="#"
                                    className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      className="w-4 h-4 mr-2"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                                    </svg>
                                    Home
                                  </a>
                                </li>
                                <li>
                                  <div className="flex items-center">
                                    <svg
                                      aria-hidden="true"
                                      className="w-6 h-6 text-gray-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd"
                                      ></path>
                                    </svg>
                                    <Link
                                      to="/projects"
                                      className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                                    >
                                      Projects
                                    </Link>
                                  </div>
                                </li>
                                <li>
                                  <div className="flex items-center">
                                    <svg
                                      aria-hidden="true"
                                      className="w-6 h-6 text-gray-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd"
                                      ></path>
                                    </svg>
                                    <Link
                                      to={`/subprojects/${projectData.id}`}
                                      className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                                    >
                                      Sub Projects
                                    </Link>
                                  </div>
                                </li>
                                <li aria-current="page">
                                  <div className="flex items-center">
                                    <svg
                                      aria-hidden="true"
                                      className="w-6 h-6 text-gray-400"
                                      fill="currentColor"
                                      viewBox="0 0 20 20"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd"
                                      ></path>
                                    </svg>
                                    <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                                      Audits
                                    </span>
                                  </div>
                                </li>
                              </ol>
                            </nav>
                            <div
                              className={
                                isDarkMode
                                  ? `text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[24px]`
                                  : `${styles.title}`
                              }
                            >
                              <div className="text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[24px]">
                                Audit Setup
                              </div>
                            </div>
                            <div className={styles.list}>
                              <button
                                disabled={loadingdata}
                                className={
                                  isDarkMode
                                    ? `text-gray-700 dark:text-white font-custom font-bold  text-[18px]`
                                    : `${styles.card}`
                                }
                                onClick={() => {
                                  // handleCreateFrom();
                                  if (optionsAuditItems.length > 0) {
                                    if (currentUserData?.category.category === "Office Auditor") {
                                      toast("Access Denied!.", {
                                        type: "error",
                                        className: "dark:bg-gray-900 dark:text-white",
                                      });
                                    } else {
                                      setShowModal(true);
                                    }
                                  }
                                  // handleCreatePreFrom();
                                  // handleCreatePostFrom();
                                }}
                              >
                                <img
                                  src={require("assets/images/add-icon.png")}
                                  className="cursor-pointer mb-[15px] rounded-xl"
                                  draggable={false}
                                  alt="Audit creation"
                                // style={{ filter: "hue-rotate(112deg)" }}
                                />
                                <div className="text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[24px] text-left">
                                  Blank
                                </div>
                              </button>
                              {/* <div className={isDarkMode ? `text-gray-700 dark:text-white font-custom font-bold  text-[18px]` : `${styles.card}`} onClick={() => {
                              // handleCreateFrom();
                              navigate(`/audit_setup/${projectData}/${subProjectData}`);
                              // handleCreatePreFrom();
                              // handleCreatePostFrom();
                            }}>
                              <img
                                src={require("assets/images/add-icon.png")}
                                className="cursor-pointer mb-[15px] rounded-lg"
                                draggable={false}
                              // style={{ filter: "hue-rotate(112deg)" }}
                              />
                              <span>Bulk Audit Setup</span>
                            </div> */}
                              {/* {templates.map(({ _id, title }) => {
                            return (
                              <div className={styles.card} key={_id}>
                                <img
                                  src={require(`assets/images/templates/${_id}.png`)}
                                  onClick={() => {
                                    // handleCreateFrom(_id);
                                    // handleCreatePreFrom(_id);
                                    // handleCreatePostFrom(_id);
                                  }}
                                  draggable={false}
                                />
                                <span>{title}</span>
                              </div>
                            );
                          })} */}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="flex justify-between mx-auto mt-2 dark:-mt-2 items-center w-[80%]  p-2 text-sm text-gray-800 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600"
                            role="alert"
                          >
                            <div>
                              <svg
                                className="flex-shrink-0 inline w-4 h-4 mx-2"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                              </svg>
                              <span className="sr-only">Info</span>
                              <span className="font-medium">
                                Uploaded Data and Management Console
                              </span>
                            </div>
                            <div className="flex justify-center items-center">
                              <button
                                onClick={handleOmniSwitchChangeForPreAudit}
                                type="button"
                                className="text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 "
                              >
                                <i className="bx bxs-user-check text-lg mr-1"></i>
                                PreAudit
                              </button>
                              <button
                                onClick={handleOmniSwitchChange}
                                type="button"
                                className="text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 "
                              >
                                <i className="bx bxs-user-check text-lg mr-1"></i>
                                Operation
                              </button>
                              <button
                                onClick={(e: any) => {
                                  handleOmniAuditAssign(e);
                                }}
                                // onClick={openModal}
                                type="button"
                                className="text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 "
                              >
                                <i className="bx bxs-user-check text-lg mr-1"></i>
                                Omni Assignment
                              </button>
                              <button
                                onClick={() => {
                                  if (currentUserData?.category.category === "Office Auditor") {
                                    toast("Access Denied!.", {
                                      type: "error",
                                      className: "dark:bg-gray-900 dark:text-white",
                                    });
                                  } else {
                                    setShowModal3(true);
                                    setDummy(!dummy);
                                  }
                                }}
                                // onClick={openModal}
                                type="button"
                                className="text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 "
                              >
                                <i className="bx bx-door-open text-lg mr-1"></i>
                                Open
                              </button>

                            </div>
                          </div>
                        </div>
                        <div className="flex w-[80%] justify-between items-center m-auto mt-3">
                          <span className="text-gray-900 dark:text-gray-100">{`Total Number of Audits Created -${currentUserData?.category.category ===
                            "Office Auditor"
                            ? filteredItems.filter((item: any) => {
                              return subAuditList.some(
                                (subAudit: any) =>
                                  subAudit.subAuditId === item.subAudit
                              );
                            }).length
                            : filteredItems?.length
                            }`}</span>
                          <div className="flex items-center">
                            <div className="mr-3">
                              <form
                                className="flex justify-center items-center "
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <label
                                  htmlFor="default-search"
                                  className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                                >
                                  Search
                                </label>
                                <div className="relative mr-3">
                                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg
                                      aria-hidden="true"
                                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                      />
                                    </svg>
                                  </div>
                                  <input
                                    type="search"
                                    id="default-search"
                                    className="block w-[105%] p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-[#4f46e5] focus:border-[#4f46e5] dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                                    required
                                    placeholder="Search Audits by title"
                                    defaultValue={search || ""}
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                  // onChange={handleChange}
                                  />
                                </div>
                              </form>
                            </div>

                            {/* <FilterDatePicker
                              value={selectedFilteredDate}
                              onChange={handleFilterDateChange}
                              placeholder="Select Date To Filter"
                            />
                            <i
                              onClick={() => {
                                setSelectedFilteredDate("");
                              }}                              
                              className="cursor-pointer bx bx-refresh text-[22px] ml-3"
                            ></i> */}
                          </div>
                        </div>
                        <div className="flex flex-col mt-3 justify-center items-center  rounded-lg ">
                          <table
                            ref={formRef}
                            className="w-[80%] text-left rtl:text-right text-gray-500 dark:text-gray-400 "
                          >
                            <thead className="text-[12px]  text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                              <tr className=" rounded-l-lg ">
                                <th
                                  scope="col"
                                  className="px-4 py-3 rounded-l-lg"
                                >
                                  <span className="flex ml-4">Title</span>
                                </th>
                                {auditItems[0]?.reportType ===
                                  "CDR Report" ? null : (
                                  <>
                                    <th scope="col" className="px-4 py-3">
                                      <span>Not Found</span>
                                    </th>

                                    <th scope="col" className="px-4 py-3">
                                      <span>Excess</span>
                                    </th>

                                    <th scope="col" className="px-4 py-3">
                                      <span>Found</span>
                                    </th>
                                  </>
                                )}

                                <th scope="col" className="px-4 py-3">
                                  <span className="flex ml-2">Created at</span>
                                </th>

                                <th scope="col" className="px-4 py-3">
                                  <span>PreAudit/Operation</span>
                                </th>
                                <th
                                  scope="col"
                                  className="px-4 py-3 rounded-r-lg"
                                >
                                  <span>Action</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {SearchItems.length === 0 ? (
                                <tr aria-label="empty">
                                  <td colSpan={12} align="center">
                                    <span className="mt-4 text-gray-500 w-auto flex justify-center items-center dark:text-gray-400">
                                      No Records Found
                                    </span>
                                  </td>
                                </tr>
                              ) : (
                                currentUserData?.category.category === "Office Auditor" ? (


                                  filteredItems.filter((item: any) => {
                                    return subAuditList.some((subAudit: any) => subAudit.subAuditId === item.subAudit);
                                  })
                                    .slice(startIndex, endIndex)
                                    .map(
                                      ({
                                        title,
                                        start,
                                        preAudit_start,
                                        id,
                                        _id,
                                        subAudit,
                                        date,
                                        createdAt,
                                        updatedAt,
                                      }: {
                                        title: string;
                                        createdAt: string;
                                        updatedAt: string;
                                        start: boolean;
                                        preAudit_start: boolean;
                                        id: string;
                                        _id: string;
                                        date: string;
                                        subAudit: string;
                                      }) => {
                                        // handleHover(id);
                                        // handleHoverFormId(options6[0]?.value);
                                        return (
                                          <Fragment key={id}>
                                            <tr
                                              // onMouseEnter={() => { handleHover(id); handleHoverFormId(options6[0]?.value); }}
                                              // onMouseLeave={() => { handleHover(null); handleHoverFormId(null) }}
                                              onClick={(e: any) => {
                                                // handleHover(null); handleHoverFormId(null)

                                                if (
                                                  (e.target.classList.contains(
                                                    "react-switch-bg"
                                                  ) ||
                                                    e.target.classList.contains(
                                                      "react-switch-handle"
                                                    )) &&
                                                  start === false
                                                ) {
                                                  return;
                                                } else if (
                                                  (e.target.classList.contains(
                                                    "react-switch-bg"
                                                  ) ||
                                                    e.target.classList.contains(
                                                      "react-switch-handle"
                                                    )) &&
                                                  start === true
                                                ) {
                                                  return;
                                                } else {
                                                  navigate(
                                                    `/audit_view/${id}/${projectId}`
                                                  );
                                                }
                                              }}
                                              className="cursor-pointer odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                            >
                                              {/* <td className="px-6 py-4 flex ">
                                            <span className="w-[23px] mr-2">{googleFormIcon}</span>
                                            <span className="text-gray-900 dark:text-gray-100">{title.length > 20 ? `${title.substring(0, 20)}...` : title}</span>
                                          </td> */}
                                              <td
                                                className="px-4 py-4 flex justify-start items-center"
                                                title={title}
                                              >
                                                <span className="w-[23px] mr-2">
                                                  {googleFormIcon}
                                                </span>
                                                <span className="text-gray-900 dark:text-gray-100">
                                                  {
                                                    <span
                                                      className={`badge ${subAuditData.filter(
                                                        (item: any) =>
                                                          (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                          item.subProjectId === projectId &&
                                                          item.isShown === false
                                                      )[0]?.status === "Assigned"
                                                        ? "bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                                                        : subAuditData.filter(
                                                          (item: any) =>
                                                            (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                            item.subProjectId === projectId &&
                                                            item.isShown === false
                                                        )[0]?.status === "Reconcile Needed"
                                                          ? "bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
                                                          : subAuditData.filter(
                                                            (item: any) =>
                                                              (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                              item.subProjectId === projectId &&
                                                              item.isShown === false
                                                          )[0]?.status === "Pending"
                                                            ? "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
                                                            : subAuditData.filter(
                                                              (item: any) =>
                                                                (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                                item.subProjectId === projectId &&
                                                                item.isShown === false
                                                            )[0]?.status === "Reopened"
                                                              ? "bg-cyan-100 text-cyan-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-cyan-900 dark:text-cyan-300"
                                                              : subAuditData.filter(
                                                                (item: any) =>
                                                                  (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                                  item.subProjectId === projectId &&
                                                                  item.isShown === false
                                                              )[0]?.status === "Approved"
                                                                ? "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
                                                                : "bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300"
                                                        }`}
                                                    >
                                                      {subAuditData.filter(
                                                        (item: any) =>
                                                          (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                          item.subProjectId === projectId &&
                                                          item.isShown === false
                                                      )[0]?.status.replace("Needed", "") ?? "Not Assigned"}
                                                    </span>

                                                  }

                                                  {title.length > 18
                                                    ? `${title.substring(
                                                      0,
                                                      20
                                                    )}...`
                                                    : title}
                                                </span>
                                              </td>
                                              {auditItems[0]?.reportType ===
                                                "CDR Report" ? null : (
                                                <>
                                                  <td className="px-6 py-4">
                                                    {/* {hoveredId === id ?  notFoundCount : "-"} */}
                                                    {
                                                      getAuditCountsFromLocalStorage(
                                                        id
                                                      ).notFoundCount
                                                    }
                                                  </td>
                                                  <td className="px-6 py-4">
                                                    {/* {hoveredId === id ? excessCount : "-"} */}
                                                    {
                                                      getAuditCountsFromLocalStorage(
                                                        id
                                                      ).excessCount
                                                    }
                                                  </td>
                                                  <td className="px-6 py-4">
                                                    {
                                                      getAuditCountsFromLocalStorage(
                                                        id
                                                      ).foundCount
                                                    }
                                                    {/* {hoveredId === id ? foundCount : "-"} */}
                                                  </td>
                                                </>
                                              )}
                                              <td className="px-6 py-4">
                                                <span className="text-gray-500 dark:text-gray-400">
                                                  {moment
                                                    .tz(createdAt, "Asia/Kolkata")
                                                    .format("MMM D, YYYY")}
                                                </span>
                                              </td>

                                              <td className="px-6 py-4">
                                                <span className="text-gray-500 dark:text-gray-400">
                                                  {
                                                    <Switch
                                                      height={23}
                                                      width={50}
                                                      onColor="#673ab7"
                                                      checked={preAudit_start}
                                                      onChange={(e: any) => {
                                                        handleSwitchChangeForPreAudit(
                                                          id,
                                                          preAudit_start
                                                        );
                                                      }}
                                                      className="react-switch"
                                                    />
                                                  }
                                                </span>
                                                <span className="text-gray-500 dark:text-gray-400">
                                                  {/* {moment.tz(updatedAt, "Asia/Kolkata").fromNow()} */}

                                                  {
                                                    <Switch
                                                      height={23}
                                                      width={50}
                                                      checked={start}
                                                      onChange={(e: any) => {
                                                        handleSwitchChange(
                                                          id,
                                                          start
                                                        );
                                                      }}
                                                      className="react-switch ml-2"
                                                    />
                                                  }
                                                </span>
                                              </td>
                                              <td className="px-6 py-4 whitespace-nowrap">
                                                <div>
                                                  {/* <i onMouseEnter={() => {
                                                setIsHovered(true);
                                              }}
                                                onMouseLeave={() => {
                                                  setIsHovered(false);
                                                }} className='bx bx-door-open text-lg mr-1'></i> */}

                                                  <i
                                                    onMouseLeave={() => {
                                                      handleHover(null);
                                                      setDummyForHandleHover(
                                                        false
                                                      );
                                                      handleHoverFormId(null);
                                                      handleHoverCombinedData([]);
                                                      foundCount = 0;
                                                      notFoundCount = 0;
                                                      excessCount = 0;
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setDummyForHandleHover(
                                                        true
                                                      );
                                                      setIsHovered(true);
                                                      handleHover(id);
                                                      handleHoverFormId(
                                                        options6[0]?.value
                                                      );

                                                      if (hoverCombinedData) {
                                                        updateLocalStorage(
                                                          id,
                                                          foundCount,
                                                          notFoundCount,
                                                          excessCount
                                                        );
                                                      }
                                                    }}
                                                    className="bx bx-refresh text-[22px] mr-2"
                                                  ></i>
                                                  <i
                                                    id={`dropdown-${id}`}
                                                    className="bx-dots-vertical-rounded text-gray-500 text-xl dark:text-gray-400 hover:bg-slate-800"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setAuditId(subAudit);
                                                      handleAssign();
                                                    }}
                                                  ></i>
                                                </div>
                                              </td>
                                            </tr>
                                            <DropDown
                                              selector={`#dropdown-${id}`}
                                              placement="bottom"
                                              className={styles.dropdown}
                                            >
                                              <DropDown.Item
                                                onClick={() => {
                                                  setShowAssignModal(true);
                                                  setAuditId(subAudit);
                                                }}
                                              >
                                                <i className="bi bi-kanban text-lg"></i>
                                                <span className="ml-2">
                                                  Assigned User
                                                </span>
                                              </DropDown.Item>
                                              {/* <DropDown.Item
                                                onClick={() => {
                                                  setAuditId(id);
                                                  setAuditStartDate(date);
                                                  setShowModal2(true);
                                                }}
                                              >
                                                <i className="bi bi-file-earmark-bar-graph"></i>
                                                <span className="ml-2">
                                                  Date Assignment
                                                </span>
                                              </DropDown.Item> */}
                                              <DropDown.Item
                                                onClick={async (e) => {
                                                  await setAuditId(id);
                                                  await handleSignOffReport(e);
                                                }}
                                              >
                                                <i className="bx bx-cloud-download"></i>
                                                <span className="ml-2">
                                                  SignOff Report
                                                </span>
                                              </DropDown.Item>

                                              {/* <DropDown.Item onClick={() => {
                                            setAuditId(id);
                                          }}>
                                            <MdOutlineDateRange className="text-xl" />
                                            <span className="ml-2">Date Assignment</span>
                                          </DropDown.Item> */}

                                              {/* DatePicker */}

                                              {/* <DropDown.Item onClick={() => handleDeleteForm(id)}>
                                            <i className="bx-trash"></i>
                                            <span className="ml-2">Remove</span>
                                          </DropDown.Item> */}
                                              {/* <DropDown.Item onClick={() => handleDeleteForm(id)}>
                                            <i className="bi bi-sign-merge-left"></i>
                                            <span className="ml-2">Merge Audit</span>
                                          </DropDown.Item> */}

                                              {/* <DropDown.Item onClick={() => navigate(`/ExcelUpload/${id}`)}>
                                            <i className="bi bi-file-earmark-spreadsheet"></i>
                                            <span className="ml-2">Upload From Excel</span>
                                          </DropDown.Item> */}
                                            </DropDown>
                                          </Fragment>
                                        );
                                      }
                                    )
                                ) : (
                                  filteredItems
                                    .slice(startIndex, endIndex)
                                    .map(
                                      ({
                                        title,
                                        start,
                                        preAudit_start,
                                        id,
                                        _id,
                                        subAudit,
                                        date,
                                        createdAt,
                                        updatedAt,
                                      }: {
                                        title: string;
                                        createdAt: string;
                                        updatedAt: string;
                                        start: boolean;
                                        preAudit_start: boolean;
                                        id: string;
                                        _id: string;
                                        date: string;
                                        subAudit: string;
                                      }) => {
                                        // handleHover(id);
                                        // handleHoverFormId(options6[0]?.value);
                                        return (
                                          <Fragment key={id}>
                                            <tr
                                              // onMouseEnter={() => { handleHover(id); handleHoverFormId(options6[0]?.value); }}
                                              // onMouseLeave={() => { handleHover(null); handleHoverFormId(null) }}
                                              onClick={(e: any) => {
                                                // handleHover(null); handleHoverFormId(null)

                                                if (
                                                  (e.target.classList.contains(
                                                    "react-switch-bg"
                                                  ) ||
                                                    e.target.classList.contains(
                                                      "react-switch-handle"
                                                    )) &&
                                                  start === false
                                                ) {
                                                  return;
                                                } else if (
                                                  (e.target.classList.contains(
                                                    "react-switch-bg"
                                                  ) ||
                                                    e.target.classList.contains(
                                                      "react-switch-handle"
                                                    )) &&
                                                  start === true
                                                ) {
                                                  return;
                                                } else {
                                                  navigate(
                                                    `/audit_view/${id}/${projectId}`
                                                  );
                                                }
                                              }}

                                              className="cursor-pointer odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                                            >
                                              {/* <td className="px-6 py-4 flex ">
                                            <span className="w-[23px] mr-2">{googleFormIcon}</span>
                                            <span className="text-gray-900 dark:text-gray-100">{title.length > 20 ? `${title.substring(0, 20)}...` : title}</span>
                                          </td> */}
                                              <td
                                                className="px-4 py-4 flex justify-start items-center"
                                                title={title}
                                              >
                                                <span className="w-[23px] mr-2">
                                                  {googleFormIcon}
                                                </span>
                                                <span className="text-gray-900 dark:text-gray-100">
                                                  {
                                                    <span
                                                      className={`badge ${subAuditData.filter(
                                                        (item: any) =>
                                                          (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                          item.subProjectId === projectId &&
                                                          item.isShown === false
                                                      )[0]?.status === "Assigned"
                                                        ? "bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                                                        : subAuditData.filter(
                                                          (item: any) =>
                                                            (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                            item.subProjectId === projectId &&
                                                            item.isShown === false
                                                        )[0]?.status === "Reconcile Needed"
                                                          ? "bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"
                                                          : subAuditData.filter(
                                                            (item: any) =>
                                                              (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                              item.subProjectId === projectId &&
                                                              item.isShown === false
                                                          )[0]?.status === "Pending"
                                                            ? "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
                                                            : subAuditData.filter(
                                                              (item: any) =>
                                                                (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                                item.subProjectId === projectId &&
                                                                item.isShown === false
                                                            )[0]?.status === "Reopened"
                                                              ? "bg-cyan-100 text-cyan-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-cyan-900 dark:text-cyan-300"
                                                              : subAuditData.filter(
                                                                (item: any) =>
                                                                  (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                                  item.subProjectId === projectId &&
                                                                  item.isShown === false
                                                              )[0]?.status === "Approved"
                                                                ? "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300"
                                                                : "bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300"
                                                        }`}
                                                    >
                                                      {subAuditData.filter(
                                                        (item: any) =>
                                                          (item.status === "Assigned" ? item.subAuditId === subAudit : item.subAuditId === id) &&
                                                          item.subProjectId === projectId &&
                                                          item.isShown === false
                                                      )[0]?.status.replace("Needed", "") ?? "Not Assigned"}
                                                    </span>

                                                  }

                                                  {title.length > 20
                                                    ? `${title.substring(
                                                      0,
                                                      18
                                                    )}...`
                                                    : title}
                                                </span>
                                              </td>
                                              {auditItems[0]?.reportType ===
                                                "CDR Report" ? null : (
                                                <>
                                                  <td className="px-6 py-4">
                                                    {/* {hoveredId === id ?  notFoundCount : "-"} */}
                                                    {
                                                      getAuditCountsFromLocalStorage(
                                                        id
                                                      ).notFoundCount
                                                    }
                                                  </td>
                                                  <td className="px-6 py-4">
                                                    {/* {hoveredId === id ? excessCount : "-"} */}
                                                    {
                                                      getAuditCountsFromLocalStorage(
                                                        id
                                                      ).excessCount
                                                    }
                                                  </td>
                                                  <td className="px-6 py-4">
                                                    {
                                                      getAuditCountsFromLocalStorage(
                                                        id
                                                      ).foundCount
                                                    }
                                                    {/* {hoveredId === id ? foundCount : "-"} */}
                                                  </td>
                                                </>
                                              )}
                                              <td className="px-6 py-4">
                                                <span className="text-gray-500 dark:text-gray-400">
                                                  {moment
                                                    .tz(createdAt, "Asia/Kolkata")
                                                    .format("MMM D, YYYY")}
                                                </span>
                                              </td>

                                              <td className="px-6 py-4">
                                                <span className="text-gray-500 dark:text-gray-400">
                                                  {
                                                    <Switch
                                                      height={23}
                                                      width={50}
                                                      onColor="#673ab7"
                                                      checked={preAudit_start}
                                                      onChange={(e: any) => {
                                                        handleSwitchChangeForPreAudit(
                                                          id,
                                                          preAudit_start
                                                        );
                                                      }}
                                                      className="react-switch"
                                                    />
                                                  }
                                                </span>
                                                <span className="text-gray-500 dark:text-gray-400">
                                                  {/* {moment.tz(updatedAt, "Asia/Kolkata").fromNow()} */}

                                                  {
                                                    <Switch
                                                      height={23}
                                                      width={50}
                                                      checked={start}
                                                      onChange={(e: any) => {
                                                        handleSwitchChange(
                                                          id,
                                                          start
                                                        );
                                                      }}
                                                      className="react-switch ml-2"
                                                    />
                                                  }
                                                </span>
                                              </td>
                                              <td className="px-6 py-4 whitespace-nowrap">
                                                <div>
                                                  {/* <i onMouseEnter={() => {
                                                setIsHovered(true);
                                              }}
                                                onMouseLeave={() => {
                                                  setIsHovered(false);
                                                }} className='bx bx-door-open text-lg mr-1'></i> */}

                                                  <i
                                                    onMouseLeave={() => {
                                                      handleHover(null);
                                                      setDummyForHandleHover(
                                                        false
                                                      );
                                                      handleHoverFormId(null);
                                                      handleHoverCombinedData([]);
                                                      foundCount = 0;
                                                      notFoundCount = 0;
                                                      excessCount = 0;
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setDummyForHandleHover(
                                                        true
                                                      );
                                                      setIsHovered(true);
                                                      handleHover(id);
                                                      handleHoverFormId(
                                                        options6[0]?.value
                                                      );

                                                      if (hoverCombinedData) {
                                                        updateLocalStorage(
                                                          id,
                                                          foundCount,
                                                          notFoundCount,
                                                          excessCount
                                                        );
                                                      }
                                                    }}
                                                    className="bx bx-refresh text-[22px] mr-2"
                                                  ></i>
                                                  <i
                                                    id={`dropdown-${id}`}
                                                    className="bx-dots-vertical-rounded text-gray-500 text-xl dark:text-gray-400 hover:bg-slate-800"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setAuditId(subAudit);
                                                      handleAssign();
                                                    }}
                                                  ></i>
                                                </div>
                                              </td>
                                            </tr>
                                            <DropDown
                                              selector={`#dropdown-${id}`}
                                              placement="bottom"
                                              className={styles.dropdown}
                                            >
                                              <DropDown.Item
                                                onClick={() => {
                                                  setShowAssignModal(true);
                                                  setAuditId(subAudit);
                                                }}
                                              >
                                                <i className="bi bi-kanban text-lg"></i>
                                                <span className="ml-2">
                                                  Assigned User
                                                </span>
                                              </DropDown.Item>
                                              {/* <DropDown.Item
                                                onClick={() => {
                                                  setAuditId(id);
                                                  setAuditStartDate(date);
                                                  setShowModal2(true);
                                                }}
                                              >
                                                <i className="bi bi-file-earmark-bar-graph"></i>
                                                <span className="ml-2">
                                                  Date Assignment
                                                </span>
                                              </DropDown.Item> */}
                                              <DropDown.Item
                                                onClick={async (e) => {
                                                  await setAuditId(id);
                                                  await handleSignOffReport(e);
                                                }}
                                              >
                                                <i className="bx bx-cloud-download"></i>
                                                <span className="ml-2">
                                                  SignOff Report
                                                </span>
                                              </DropDown.Item>

                                              {/* <DropDown.Item onClick={() => {
                                            setAuditId(id);
                                          }}>
                                            <MdOutlineDateRange className="text-xl" />
                                            <span className="ml-2">Date Assignment</span>
                                          </DropDown.Item> */}

                                              {/* DatePicker */}

                                              {/* <DropDown.Item onClick={() => handleDeleteForm(id)}>
                                            <i className="bx-trash"></i>
                                            <span className="ml-2">Remove</span>
                                          </DropDown.Item> */}
                                              {/* <DropDown.Item onClick={() => handleDeleteForm(id)}>
                                            <i className="bi bi-sign-merge-left"></i>
                                            <span className="ml-2">Merge Audit</span>
                                          </DropDown.Item> */}

                                              {/* <DropDown.Item onClick={() => navigate(`/ExcelUpload/${id}`)}>
                                            <i className="bi bi-file-earmark-spreadsheet"></i>
                                            <span className="ml-2">Upload From Excel</span>
                                          </DropDown.Item> */}
                                            </DropDown>
                                          </Fragment>
                                        );
                                      }
                                    )

                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="flex justify-center my-6 ">
                          {/* <div>
                        Page {page} of {totalPages}
                      </div> */}
                          <div className="flex items-center gap-1">
                            {paginationButtons}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default SubProjectAudits;
