import { axios } from "./index";
import { PreResponse, Form, formUrl, responseUrl,PreForm,PreFormUrl,preResponseUrl } from "./config";
import {
  FormDetail,
  FormResponse,
  FormData,
  FormResponses,
  PageMeta,
} from "types/Form";

const getPreFormById = (formId: string) => {
  return axios<{ creatorId: string } & FormDetail>({
    method: "get",
    url: `${PreFormUrl}/${formId}`,
  });
};
const getPreFormByAuditId = (auditId: string) => {
  return axios<{ creatorId: string } & FormDetail>({
    method: "get",
    url: `${PreFormUrl}/${auditId}`,
  });
};

const sendResponse = (data: FormResponse) => {
  return axios({
    method: "post",
    url: PreResponse.submitResponse,
    data,
  });
};

const getAllPreForms = (params?: any) => {
  return axios<{
    list: FormData[];
    pageMeta: PageMeta;
  }>({
    method: "get",
    url: PreForm.getAllForms,
    params,
  });
};

const createPreForm = (data: { templateId?: string, auditId?: string } = {}) => {
  console.log("this one is data from preEdit",data);
  return axios<{
    _id: string;
    title: string;
    projectId: string | undefined;
    auditId: string | undefined; 
    updatedAt: string;
    createdAt: string;
  }>({
    method: "post",
    url: PreForm.createForm,
    data,
  });
};

const deletePreFormById = (formId: string) => {
  return axios<{ formId: string; message: string }>({
    method: "delete",
    url: `${PreFormUrl}/${formId}`,
  });
};

const updatePreFormById = ({
  formId,
  data,
}: {
  formId: string;
  data: FormDetail;
}) => {
  return axios<{ formId: string; message: string }>({
    method: "put",
    url: `${PreFormUrl}/${formId}`,
    data,
  });
};

export const checkPreResponseStatus = (formId: string) => {
  return axios<{ status: boolean }>({
    method: "get",
    url: `${PreResponse.checkStatus}/${formId}`,
  });
};

export const getPreFormResponsesById = (formId: string) => {
  return axios<FormResponses>({
    method: "get",
    url: `${preResponseUrl}/${formId}`,
  });
};

export {
  getPreFormById,
  sendResponse,
  getPreFormByAuditId,
  getAllPreForms,
  createPreForm,
  deletePreFormById,
  updatePreFormById,
};
