import React, { createContext, useState } from "react";

export const HoverContext = createContext();

export const HoverProvider = ({ children }) => {
  const [hoveredId, setHoveredId] = useState(null);
  const [hoverFormId, setHoverFormId] = useState(null);
  const [hoverCombinedData, setHoverCombinedData] = useState([]);
  const handleHover = (id) => {
    setHoveredId(id);
  };
  const handleHoverFormId = (id) => {
    setHoverFormId(id);
  };
  const handleHoverCombinedData = (data) => {
    setHoverCombinedData(data);
  };

  return (
    <HoverContext.Provider
      value={{ hoveredId, handleHover, hoverFormId, handleHoverFormId,handleHoverCombinedData,hoverCombinedData }}
    >
      {children}
    </HoverContext.Provider>
  );
};
