import React, { useState, useEffect } from "react";
import { readData } from "./jsonToBackendApi";

const DisplayInfo = () => {
  const [user, setUser] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let mounted = true;
    readData("user").then((data) => {
      if (mounted) {
        setUser(data);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  console.log("this is the user response", user);

  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const totalPages = user ? Math.ceil(user.length / itemsPerPage) : 0;

  const paginationButtons = [];
  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );

  const keys =
    user?.length > 0
      ? Array.from(
          new Set(
            user.flatMap((item) => [
              ...Object.keys(item),
              ...item.responses.map((response) => response.title),
            ])
          )
        )
      : [];

  console.log("this is the keys", user);
  return (
    <div className="max-w-screen-xl mx-auto px-4 md:px-8">
      <div className="flex justify-between mt-8">
        <div>
          Page {page} of {totalPages}
        </div>
        <div className="flex items-center gap-1">{paginationButtons}</div>
      </div>
      <div className="mt-5 shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 text-center">
        <table className="w-full text-sm text-center text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 whitespace-nowrap">
            <tr>
              {keys.map((key) => (
                <th className="py-3 px-6" key={key}>
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {user?.slice(startIndex, endIndex).map((item, idx) => (
              <tr
                key={idx}
                className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
              >
                {keys.map((key) => (
                  <td className="px-6 py-4 whitespace-nowrap" key={key}>
                    {
                      item.responses.find(
                        (response) => response.title === key
                      )?.response || item[key]?.toString()

                    }
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DisplayInfo;
