import ReactDOM from "react-dom/client";
import Router from "./router";
import "./index.css"
import ThemeProvider from "Dashboard/utils/ThemeContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
<ThemeProvider><Router /></ThemeProvider>
);
