import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const AddDataComp = ({ heading, button, addData, setAddData, onAddUserSuccess}) => {
  const [change, setChange] = useState(false);
  const [value, setValue] = useState({});
  const [drop, setDrop] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [fullnameError, setFullNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [permissionData, setPermissionData] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  const handleChange = (key, value) => {
    setValue((prev) => ({ ...prev, [key]: value }));
  };
  const options2 = drop.map((form) => {
    return { value: form._id, label: form.category };
  });
  const selectedIds = selectedOption.map((option) => option.value);

  const filteredOptions = permissionData.filter((permission) =>
    selectedIds.includes(permission._id)
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordError("");
    setEmailError("");
    setFullNameError("");
    setMobileError("");
    try {
      const result = await axios.post(`${BASE_URL}/signup/`, value);
      if (result.status === 201) {
        setValue({ category: "Actor" });
        setChange(!change);
        // alert("Data Added Successfully");
        toast("User Created Successfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
        setAddData(false);
        if (typeof onAddUserSuccess === 'function') {
          console.log("on add user success data function call")
          onAddUserSuccess();
        }
      }
    } catch (err) {
      if (
        err.response.data.err.hasOwnProperty("code") &&
        err.response.data.err.code === 11000
      ) {
        setEmailError("Email/Phone is already Registered");
      } else {
        if (err.response.data.err.errors.hasOwnProperty("fullname")) {
          setFullNameError(err.response.data.err.errors.fullname.message);
        }
        if (err.response.data.err.errors.hasOwnProperty("email")) {
          setEmailError(err.response.data.err.errors.email.message);
        }
        if (err.response.data.err.errors.hasOwnProperty("password")) {
          setPasswordError(err.response.data.err.errors.password.message);
        }
        if (err.response.data.err.errors.hasOwnProperty("mobile")) {
          setMobileError(err.response.data.err.errors.mobile.message);
        }
      }
    }
  };
  

  useEffect(() => {
    async function fetchData() {
      try {
        console.log("hello sign");
        const dropval = await axios.get(
          `${BASE_URL}/dropdownvalue`
        );
        const storeddrop = dropval.data;
        setDrop(storeddrop);
        console.log("The Value of drop", drop);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, [change]);

  const options = permissionData.map((permission) => ({
    value: permission._id,
    label: permission.name,
  }));

  return (
    <div>
      {/* <div className=""> */}
      {/* <form onSubmit={handleSubmit} className="flex mb-4">
          <input
            type="text"
            name="fullname"
            value={value.fullname}
            onChange={(e) => handleChange("fullname", e.target.value)}
            className="form-input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[20%] p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mr-2"
            placeholder="Full Name"
          />
          <input
            type="text"
            name="email"
            value={value.email}
            onChange={(e) => handleChange("email", e.target.value)}
            className="form-input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[20%] p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  mr-2"
            placeholder="Email"
          />
          <input
            type="text"
            name="mobile"
            value={value.mobile}
            onChange={(e) => handleChange("mobile", e.target.value)}
            className="form-input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[20%] p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  mr-2"
            placeholder="Mobile Number"
          />
          <input
            type="password"
            name="password"
            value={value.password}
            onChange={(e) => handleChange("password", e.target.value)}
            className="form-input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[20%] p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  mr-2"
            placeholder="Password"
          />
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[30%] p-2.5 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
            onChange={(e) => {
              handleChange("category", e.target.value);
              setCategoryId(e.target.value);
              console.log("The value of category", e.target.value);
            }}
            name="category"
          >
            {drop &&
              drop.map((val, index) => (
                <option
                  key={val._id}
                  value={val._id}
                  className="dark:bg-gray-800 dark:text-white hover:bg-teal-600"
                >
                  {val.category}
                </option>
              ))}
          </select>
          <Select
            defaultValue={selectedOption}
            onChange={(event) => setSelectedOption(event)}
            options={options}
            placeholder="Choose a Permission..."
            className="my-react-select-container w-[40%] ml-2"
            classNamePrefix="my-react-select"
            isMulti={true}
          />
          <div className="text-red-500 mb-4">
            {fullnameError || emailError || passwordError || mobileError}
          </div>

          <button className="ml-2 relative z-[2] flex items-center rounded  px-6  text-xs font-medium uppercase leading-tight  shadow-md transition duration-150 ease-in-out bg-indigo-500 hover:bg-indigo-600 text-white outline-nonee ">
            {button}
          </button>
        </form> */}
      <div
        id="crypto-modal"
        tabIndex={-1}
        aria-hidden="true"
        className="
          flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full my-auto
          "
      >
        <div className="relative w-full max-w-lg max-h-full">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
            <button
              type="button"
              onClick={() => setAddData(false)}
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="crypto-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            {/* Modal header */}
            <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                Add User
              </h3>
            </div>
            {/* Modal body */}
            <div className="p-6">
              {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}
              <>
                <form onSubmit={handleSubmit}>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Full Name <span className="text-red-500 "> *</span>
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    required
                    value={value.fullname}
                    onChange={(e) => handleChange("fullname", e.target.value)}
                    aria-describedby="helper-text-explanation"
                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                    placeholder="Full Name"
                  />
                  <div className="flex justify-between">
                    <div className="w-full">
                      <label className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-white">
                        Email
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={value.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                        aria-describedby="helper-text-explanation"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                        placeholder="Email"
                      />
                    </div>
                    <div className="w-full ml-3">
                      <label className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Mobile Number <span className="text-red-500 "> *</span>
                      </label>
                      <input
                        type="text"
                        name="mobile"
                        value={value.mobile}
                        required
                        onChange={(e) => handleChange("mobile", e.target.value)}
                        aria-describedby="helper-text-explanation"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                        placeholder="Mobile Number"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="w-full">
                      <label className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-white">
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        value={value.address}
                        onChange={(e) =>
                          handleChange("address", e.target.value)
                        }
                        aria-describedby="helper-text-explanation"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                        placeholder="Address"
                      />
                    </div>
                    <div className="w-full ml-3">
                      <label className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Location/City <span className="text-red-500 "> *</span>
                      </label>
                      <input
                        type="text"
                        name="mobile"
                        value={value.location}
                        required
                        onChange={(e) =>
                          handleChange("location", e.target.value)
                        }
                        aria-describedby="helper-text-explanation"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                        placeholder="Location/City"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="w-full">
                      <label className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-white">
                        Terms & Condition
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={value.termsndcondition}
                        onChange={(e) =>
                          handleChange("termsndcondition", e.target.value)
                        }
                        aria-describedby="helper-text-explanation"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                        placeholder="Terms & Condition"
                      />
                    </div>
                    <div className="w-full ml-3">
                      <label className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Remarks
                      </label>
                      <input
                        type="text"
                        name="mobile"
                        value={value.remarks}
                        onChange={(e) =>
                          handleChange("remarks", e.target.value)
                        }
                        aria-describedby="helper-text-explanation"
                        className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                        placeholder="Remarks"
                      />
                    </div>
                  </div>

                  <label className="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Password <span className="text-red-500 "> *</span>
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={value.password}
                    required
                    onChange={(e) => handleChange("password", e.target.value)}
                    aria-describedby="helper-text-explanation"
                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#4f46e5] focus:border-[#4f46e5] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                    placeholder="Password"
                  />

                  <Select
                    defaultValue={selectedOption2}
                    onChange={(e) => {
                      handleChange("category", e.value);
                      setSelectedOption2(e.value);
                      setCategoryId(e.value);
                    }}
                    options={options2}
                    placeholder="Choose Role..."
                    className="my-react-select-container mt-3"
                    classNamePrefix="my-react-select"
                  />
                  {/* <Select
                    defaultValue={selectedOption}
                    onChange={(event) => setSelectedOption(event)}
                    options={options}
                    placeholder="Choose a Permission..."
                    className="my-react-select-container mt-3"
                    classNamePrefix="my-react-select"
                    isMulti={true}
                  /> */}
                  <div className="text-red-500 mb-4">
                    {fullnameError ||
                      emailError ||
                      passwordError ||
                      mobileError}
                  </div>
                  <button
                    type="submit"
                    className="btn bg-[#4f46e5] hover:bg-indigo-800 text-white mt-4"
                  >
                    <span className="hidden xs:block ml-2">Create User</span>
                  </button>
                </form>
              </>
              <div></div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default AddDataComp;
