import React, { useRef, useState, useEffect, Fragment } from "react";
import { FormRegister } from "types/UseForm";
import { useZxing } from "react-zxing";
import styles from "./Input.module.scss";

type InputProps = {
  register?: ReturnType<FormRegister>;
} & React.ComponentProps<"input">;

// const { Clipboard } = Plugins;
const Input = ({
  className,
  type = "text",
  placeholder = "Enter Here",
  register,
  ...props
}: InputProps) => {
  let inputRef = useRef<HTMLInputElement>(null);

  let [mask, setMask] = useState(true);
  const [result, setResult] = useState("");
  const [isCopied, setIsCopied] = useState(false); // State to track copied status
//   const { ref } = useZxing({
//     onResult(result:any) {
//       setResult(result.getText());
//     },
//   });

  useEffect(() => {
    if (!inputRef.current) return;
    let inputField = inputRef.current.querySelector("input");
    if (!inputField) return;
    inputField.addEventListener("blur", handleBlur);
    return () => {
      if (!inputField) return;
      inputField.removeEventListener("blur", handleBlur);
    };
  }, []);

  const handleFocus = (): void => {
    inputRef.current?.classList.remove(styles.blur);
    inputRef.current?.classList.add(styles.focus);
  };

  const handleBlur = (): void => {
    inputRef.current?.classList.remove(styles.focus);
    inputRef.current?.classList.add(styles.blur);
  };

  const handleCopy = async () => {
    if (result) {
    //   await Clipboard.write({
    //     string: result
    //   });
    //   await Haptics.impact({ style: ImpactStyle.Medium });
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
        setResult("");
      }, 2000);
    }
  };

  return (
    <div ref={inputRef} className={styles.field}>
      {/* <video ref={ref} /> */}
      <p className="flex justify-between  m-2">
        <strong>Result: {result}</strong>
        <button onClick={handleCopy}>
          <span
            className={`bx bx-clipboard text-xl ${
              isCopied ? "text-green-500" : ""
            }`}
          ></span>
        </button>
      </p>
      <input
        defaultValue={result}
        type={type === "password" ? (mask ? "password" : "text") : type}
        placeholder={placeholder}
        className={`${styles.field} ${type === "password" ? styles.space : ""
          } ${className || ""}`.trim()}
        onFocus={handleFocus}
        {...(type === "number" && {
          onWheel: (e) => (e.target as HTMLInputElement).blur(),
        })}
        {...(register && register)}
        {...props}
      />
      {type === "password" && (
        <Fragment>
          {mask ? (
            <i className="bx-hide" onClick={() => setMask(false)}></i>
          ) : (
            <i className="bx-show" onClick={() => setMask(true)}></i>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Input;
