import React, { useRef, useState, useEffect, Fragment, useMemo } from "react";
import { FormRegister } from "types/UseForm";
// import { useZxing } from "react-zxing";
import styles from "./Input.module.scss";
import { FormField, FormIndexes, FormPages } from "types/Form";
import { useFormContext } from "context/form";
import InputComponent from "components/Input";
import Radio from "components/Radio";
import CheckBox from "components/CheckBox";
type MultiOptionsProps = { formPage: FormPages; fieldId: string } & FormField &
  FormIndexes;
type CombinedProps = InputProps & MultiOptionsProps;
type InputProps = {
  register?: ReturnType<FormRegister>;
  isRemarksRequired?: any;
  isRemarksIncluded?: any;
} & React.ComponentProps<"input">;

// const { Clipboard } = Plugins;
const Input = ({
  _id,
  fieldId,
  fieldType,
  options,
  other,
  rules,
  sectionIndex,
  fieldIndex,
  formPage: { isEdit },
  className,
  isRemarksRequired,
  isRemarksIncluded,
  type = "text",
  placeholder = "Enter Here",
  register,
  ...props
}: CombinedProps) => {
  const { register: contextRegister, setValue, clearValue, formData, formErrors } =
    useFormContext();
  const name = `sections.${sectionIndex}.fields.${fieldIndex}.response`;
  const field = contextRegister(name, rules);
  const value = formData.sections[sectionIndex].fields[fieldIndex].response;
  const handleOtherOption = (value: Boolean) => {
    setValue(`sections.${sectionIndex}.fields.${fieldIndex}.other`, value);
  };

  const otherField = useMemo(() => {
    if (!other) return null;

    let Component = fieldType === "checkbox" ? CheckBox : Radio;

    let props = {
      id: `${_id}-other`,
      label: "Other",
      defaultChecked:
        fieldType === "checkbox" ? value?.includes("Other") : value === "Other",
      value: "Other",
      register: field,
    };

    return <Component {...props} />;
  }, []);
  let inputRef = useRef<HTMLInputElement>(null);

  let [mask, setMask] = useState(true);
  const [result, setResult] = useState("");
  const [isCopied, setIsCopied] = useState(false); // State to track copied status
//   const { ref } = useZxing({
//     onResult(result:any) {
//       setResult(result.getText());
//     },
//   });

  useEffect(() => {
    if (!inputRef.current) return;
    let inputField = inputRef.current.querySelector("input");
    if (!inputField) return;
    inputField.addEventListener("blur", handleBlur);
    return () => {
      if (!inputField) return;
      inputField.removeEventListener("blur", handleBlur);
    };
  }, []);

  const handleFocus = (): void => {
    inputRef.current?.classList.remove(styles.blur);
    inputRef.current?.classList.add(styles.focus);
  };

  const handleBlur = (): void => {
    inputRef.current?.classList.remove(styles.focus);
    inputRef.current?.classList.add(styles.blur);
  };

  const handleCopy = async () => {
    if (result) {
    //   await Clipboard.write({
    //     string: result
    //   });
    //   await Haptics.impact({ style: ImpactStyle.Medium });
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
        setResult("");
      }, 2000);
    }
  };
  // console.log("thisire",result)
  return (
    <div ref={inputRef} className={styles.field}>
      {/* <video ref={ref} /> */}
      <p className="flex justify-between  m-2">
        <strong>Result: {result}</strong>
        <button onClick={handleCopy}>
          <span
            className={`bx bx-clipboard text-xl ${isCopied ? "text-green-500" : ""
              }`}
          ></span>
        </button>
      </p>
      <input
        defaultValue={result}
        type={type === "password" ? (mask ? "password" : "text") : type}
        placeholder={placeholder}
        className={`${styles.field} ${type === "password" ? styles.space : ""
          } ${className || ""}`.trim()}
        onFocus={handleFocus}
        {...(type === "number" && {
          onWheel: (e) => (e.target as HTMLInputElement).blur(),
        })}
        {...(register && register)}
        {...props}
      />
      {type === "password" && (
        <Fragment>
          {mask ? (
            <i className="bx-hide" onClick={() => setMask(false)}></i>
          ) : (
            <i className="bx-show" onClick={() => setMask(true)}></i>
          )}
        </Fragment>
      )}
      {isRemarksIncluded && (
        <Fragment>
          <div className={styles.option_field}>

            <div>
              {otherField}
              <InputComponent
                placeholder="Enter here"
                register={contextRegister(
                  `sections.${sectionIndex}.fields.${fieldIndex}.otherReason`
                )}
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>

  );
};

export default Input;
