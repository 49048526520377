import React from "react";
import ExcelToJson from "components/ExcellUpload/components/ExcelToJson";

function ExcelUpload() {
  return (
    <div>
      <ExcelToJson />
    </div>
  );
}

export default ExcelUpload;
