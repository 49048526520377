import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LineChart from "../../charts/LineChart01";
import Icon from "../../images/icon-01.svg";
import EditMenu from "../../components/DropdownEditMenu";
import axios from "axios";

// Import utilities
import { tailwindConfig, hexToRGB } from "../../utils/Utils";
import moment from "moment";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

function DashboardCard01() {
  const [showModal3, setShowModal3] = useState(false);
  const [auditData, setAuditData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [project, setProject] = useState([]);
  const [subProject, setSubProject] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentUserData, setCurrentUserData] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const userId = sessionStorage.getItem("user");
    console.log("userId", userId);
    if (userId) {
      const response = axios
        .get(`${BASE_URL}/user-details/${userId}`)
        .then((response) => {
          console.log("this is a user res", response.data);
          setCurrentUserData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${BASE_URL}/api/subaudit`);
      const auditResponse = await axios.get(`${BASE_URL}/audit`);
      console.log("this is the res4564", response);

      // if (currentUserData?.category.category === "Office Auditor") {
      //   setData(response.data.data.filter(item => item.user === currentUserData?._id));
      // }else{
      //   setData(response.data.data);
      // }

      if (currentUserData?.category.category === "Office Auditor") {
        setData(
          response.data.data.filter(item => item.user === currentUserData?._id)
        );
      } else {
        setData(response.data.data);
      }
      setAuditData(auditResponse.data.data);
    };
    fetchData();
  }, [currentUserData]);

  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/use-all-details`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        // const filteredData = result.data.filter(
        //   (data) => data.category._id === selectedOption2
        // );
        setUserDetails(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // gettting all projects
  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/api/project`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        console.log("projects data", result.data.data);
        setProject(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //getting all subprojects
  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/api/subproject`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        console.log("subprojects data", result.data.data);
        setSubProject(result.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("thisisdata", userDetails);
  const searchParams = new URLSearchParams(location.search);
  let search = searchParams.get("search");
  console.log("auditData", data, auditData);
  const filteredRows = data
    .filter(
      (item) =>
        item.status === "Assigned" &&
        item.isShown === false &&
        item.subProjectId
    )
    .map((item, index) => {
      const matchingAuditItems = auditData.filter(
        (auditItem) =>
          auditItem.subAudit === item.subAuditId &&
          auditItem.subProjectId === item.subProjectId
      );

      const projects = matchingAuditItems.map((matchingAuditItem) => {
        const matchingSubProject = subProject.find(
          (subprojectItem) =>
            subprojectItem.id === matchingAuditItem.subProjectId
        );

        const matchingProject = matchingSubProject
          ? project.find(
              (projectItem) =>
                projectItem.id === matchingSubProject.projectId &&
                projectItem.projectStatus !== "CLOSED"
            )
          : null;

        return matchingProject ? matchingProject.title : null;
      });

      const subProjectTitles = matchingAuditItems.map((matchingAuditItem) => {
        const matchingSubProject = subProject.find(
          (subprojectItem) =>
            subprojectItem.id === matchingAuditItem.subProjectId
        );

        return matchingSubProject ? matchingSubProject.title : null;
      });

      const status = item.status;
      const userFullName = userDetails.find(
        (user) => user._id === item.user
      )?.fullname;

      return {
        index,
        projects: projects.join(", "),
        subProjectTitles: subProjectTitles.join(", "),
        title: matchingAuditItems[0] ? matchingAuditItems[0].title : null,
        userFullName,
        status,
        subProjectId: item.subProjectId,
      };
    });
  const filteredData = filteredRows
    .filter((row) => row.projects !== "")
    .filter(({ projects, subProjectTitles, title, userFullName }) => {
      const query = searchQuery.toLowerCase();
      return (
        projects.toLowerCase().includes(query) ||
        subProjectTitles?.toLowerCase().includes(query) ||
        title?.toLowerCase().includes(query) ||
        userFullName?.toLowerCase().includes(query)
      );
    });

  console.log("filtered rows data", filteredRows);
  console.log("filtered data in dasboard", filteredData);
  const removeClosedProjectCount =
    data.filter(
      (item) =>
        item.status === "Assigned" &&
        item.isShown === false &&
        item.subProjectId
    ).length - filteredData.length;

  const assignedCount = data.filter(
    (item) =>
      item.status === "Assigned" && item.isShown === false && item.subProjectId
  ).length - removeClosedProjectCount;

  return (
    <>
      {showModal3 && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex backdrop-blur-sm absolute justify-center items-center z-[9999999] w-full h-full mx-auto p-4 overflow-x-hidden overflow-y-auto md:inset-0 "
        >
          <div className="relative w-full max-w-6xl max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400 dark:border-none">
              <button
                type="button"
                onClick={() => {
                  setShowModal3(false);
                }}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className=" flex justify-between px-6 py-4 border-b rounded-t dark:border-gray-600">
                <div>
                  <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                    {`Number of Assigned Audits -  ${assignedCount}`}
                  </h3>
                </div>
                <div className="relative px-6 mr-6">
                  <input
                    type="search"
                    className="px-4 py-2 w-64 h-10 rounded-l-lg rounded-r-lg border border-gray-300 dark:border-gray-500 dark:border-[#374151] bg-white text-gray-800 placeholder-gray-400 focus:outline-none dark:bg-transparent dark:text-white dark:placeholder-gray-500"
                    aria-label="Search"
                    placeholder="Search.."
                    name="search"
                    autoComplete="false"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />

                  {/* Search button */}
                  <button
                    className="absolute inset-y-0 right-0 px-4 h-10 text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-500 rounded-r-lg  focus:border-primary-400 flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 py-2 text-center"
                    type="button"
                    id="button-addon1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {/* Modal body */}
              <div className="p-6">
                <>
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            S.No.
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Project
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Sub Project
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Audit Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Audit Status
                          </th>

                          <th scope="col" className="px-6 py-3">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.length > 0 ? (
                          filteredData.map((rowData, index) => (
                            <tr
                              className={
                                rowData.index % 2 === 0
                                  ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                                  : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
                              }
                              key={index + 1}
                            >
                              {/* Render your table cells using rowData.projects, rowData.subProjectTitles, etc. */}
                              <td className="px-6 py-4">{index + 1}</td>
                              <td className="px-6 py-4">{rowData.projects}</td>
                              <td className="px-6 py-4">
                                {rowData.subProjectTitles}
                              </td>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                              >
                                {rowData.title}
                                <span className="ml-2">{`(${rowData.userFullName})`}</span>
                              </th>
                              <td className="px-6 py-4">
                                <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                                  {rowData.status}
                                </span>
                              </td>
                              <td className="px-6 py-4">
                                <button
                                  onClick={() => {
                                    navigate(
                                      `/audits/list/${rowData.subProjectId}`
                                    );
                                  }}
                                >
                                  <i className="bx bx-link-external text-blue-400 text-lg"></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              className="px-6 py-4 text-center text-gray-500 dark:text-white"
                            >
                              No assigned audits available.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
        <div
          className="px-5 pt-5 cursor-pointer"
          onClick={() => {
            setShowModal3(true);
          }}
        >
          <header className="flex justify-between items-start mb-2">
            {/* Icon */}
            <img src={Icon} width="32" height="32" alt="Icon 01" />
            {/* Menu button */}
            {/* <EditMenu align="right" className="relative inline-flex">
            <li>
              <Link
                className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3"
                to="#0"
              >
                Option 1
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3"
                to="#0"
              >
                Option 2
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3"
                to="#0"
              >
                Remove
              </Link>
            </li>
          </EditMenu> */}
          </header>
          <h2 className="text-lg font-semibold text-slate-800 dark:text-slate-100 mb-2">
            Total Assigned Audit
          </h2>
          {/* <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-1">Sales</div> */}
          <div className="flex items-start mb-4">
            <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">
              {assignedCount}
            </div>
            {/* <div className="text-sm font-semibold text-white px-1.5 bg-emerald-500 rounded-full">+49%</div> */}
          </div>
        </div>
        {/* Chart built with Chart.js 3 */}
        {/* <div className="grow max-sm:max-h-[128px] xl:max-h-[128px]"> */}
        {/* Change the height attribute to adjust the chart height */}
        {/* <LineChart data={chartData} width={389} height={128} /> */}
        {/* </div> */}
      </div>
    </>
  );
}

export default DashboardCard01;
