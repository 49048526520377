import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import {
  Link,
  UNSAFE_DataRouterStateContext,
  useLocation,
  useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import Banner from "../partials/Banner";
import WelcomeBannerAudit from "Dashboard/partials/dashboard/WelcomeBannerAudit";
import Responses from "components/FormBuilder/Responses";
import PreResponse from "components/FormBuilder/Responses/PreResponse";
import PostResponse from "components/FormBuilder/Responses/PostResponse";
import AuditItemResponse from "components/FormBuilder/Responses/AuditItemResponse";
import Select from "react-select";
import { getAllForms } from "services/AuditItem";
import { PuffLoader } from "react-spinners";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

function AuditView({ hoverId, hoverFormId, dummyForHandleHover }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [dummy, setDummy] = useState(false);
  const [subProjectId, setSubProjectId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [formId, setFormId] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOption2, setSelectedOption2] = useState([]);
  const [subProjectTitle, setSubProjectTitle] = useState("");
  const [role, setRole] = useState([]);
  const [auditItems, setAuditItems] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [auditData, setAuditData] = useState();
  const { auditId } = useParams();
  let location = useLocation();
  const [subAuditItems, setSubAuditItems] = useState([]);
  const options = subAuditItems.map((form) => {
    const userLabel = role.filter((data) => data._id === form.user);
    const isHighlighted = !form.auditTemplateId;
    const isDisabled = isHighlighted;
    let label = `Data submitted by ${userLabel[0]?.fullname}`;
    if (isDisabled) {
      label += " (Admin-uploaded data!)";
    }
    return { value: form.id, label, isHighlighted };
  });
  // const highlightedOptions = options.filter((option) => option.isHighlighted);
  const options2 = auditItems.map((form) => {
    return { value: form._id, label: form.title };
  });
  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/use-all-details`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        // const filteredData = result.data.filter(
        //   (data) => data.category._id === selectedOption2
        // );
        setRole(result.data);
        console.log("Result from subproject", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let searchParams = new URLSearchParams(location.search);
  useEffect(() => {
    const fetchAudit = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/audit/${auditId}`);
        setAuditData(response.data.data);
        setSubProjectId(response.data.data.subProjectId);
        const subprojectData = await axios.get(
          `${BASE_URL}/api/subproject/${subProjectId}`
        );
        console.log("this is subProjectData", subprojectData.data.data);
        setSubProjectTitle(subprojectData?.data?.data?.title);
        const responseData = subprojectData.data.data.templateId;
        setFormId(responseData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAudit();
  }, [auditId, subProjectId]);
  useEffect(() => {
    axios.get(`${BASE_URL}/api/subaudit`).then((res) => {
      console.log("this is abc", res.data.data);
      const filterList = res.data.data.filter(
        (form) => form.subAuditId === auditId
      );
      console.log("this is the filter list abc", filterList);
      setTemplateId(filterList[0]?.auditId);
      console.log("this is the template id", templateId);
    });
  }, []);
  const getAuditItemTemplates = async () => {
    setIsLoading(true);
    try {
      let {
        data: { list },
      } = await getAllForms();
      // const filterList = list.filter((form) => form.projectId === projectId);
      console.log("this is the list from index", list);
      const filterList = list.filter((form) => form.auditId === formId);
      console.log("this is the filter list xyz", filterList);
      setAuditItems(filterList);
      setIsLoading(false);
      // setPageMeta(pageMeta);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAuditItemTemplates();
  }, [formId]);
  useEffect(() => {
    const fetchSubAudit = async () => {
      const response = axios
        .get(`${BASE_URL}/api/subaudit`)
        .then((result) => {
          console.log("this 9999999999999999", result.data.data);
          const filterData = result.data.data.filter(
            (data) => data.subAuditId === auditId && data.isShown === true
          );
          setSubAuditItems(filterData);
          console.log("this is thexxxx ", filterData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchSubAudit();
  }, []);
  console.log("this is the formIdccc", hoverId, hoverFormId);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <div className={`${dummy === false ? "" : `blur-sm`}sticky top-0 `}>
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* <div className="sticky top-0"> */}
          <div
            id="alert-border-1"
            className="flex justify-between items-center p-4 text-blue-800 border-t-4 border-blue-300  bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
            role="alert"
          >
            <div className="flex justify-center items-center">
              <svg
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div className="ml-2 text-sm font-medium">
                <strong>Audit View Summary Screen</strong>
              </div>
              <i className="bx bx-chevrons-right text-2xl"></i>
                <div className="text-sm font-medium">
                  <strong>{subProjectTitle && subProjectTitle}</strong>
                </div>
            </div>
            <div>
              <p className="flex justify-center items-center font-semibold">
                <i className="bx bx-chevrons-right text-2xl"></i>
                {auditData?.title}
              </p>
            </div>
          </div>
        </div>
        <nav
          className="flex  px-4 sm:px-6 lg:px-8 pt-8 w-full max-w-9xl mx-auto"
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <a
                href="#"
                className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
              >
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
                Home
              </a>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <Link
                  to="/projects"
                  className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                >
                  Projects
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <button
                  onClick={() => window.history.go(-2)}
                  className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                >
                  Sub Projects
                </button>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <button
                  onClick={() => window.history.back()}
                  className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                >
                  Audits
                </button>
              </div>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                  Audit View Summary{" "}
                </span>
              </div>
            </li>
          </ol>
        </nav>
        {/* </div> */}
        {/* </div> */}
        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Welcome banner */}
            {/* <WelcomeBannerAudit auditId={auditId} /> */}
            {/* <div
              className={`${
                dummy === false
                  ? "flex justify-between"
                  : `blur-sm flex justify-between`
              }`}
            >
              <Select
                defaultValue={selectedOption}
                onChange={(event) => {
                  if (event && event.length > 0) {
                    const firstSelectedOption = event[0];
                    if (
                      firstSelectedOption.label.includes(
                        "(Admin-uploaded data!)"
                      )
                    ) {
                      setSelectedOption(event);
                    } else {
                      toast("First option should always be admin uploaded!", {
                        type: "error",
                        className: "dark:bg-gray-900 dark:text-white z-[1000]",
                      });
                    }
                  } else {
                    setSelectedOption(event);
                  }
                }}
                isOptionDisabled={() => selectedOption.length >= 2}
                isMulti={true}
                options={options}
                placeholder="Select a SubAudit..."
                className="my-react-select-container mr-2 w-1/2"
                classNamePrefix="my-react-select"
                // isOptionDisabled={(option) => option.isDisabled}
                formatOptionLabel={(option) => (
                  <div
                    className={`${
                      option.isHighlighted
                        ? "bg-yellow-200 text-yellow-800" // Apply your highlight styles here
                        : ""
                    }`}
                  >
                    {option.label}
                  </div>
                )}
              />
              <Select
                defaultValue={options2[0]}
                onChange={(event) => {
                  setSelectedOption2(event.value);
                }}
                options={options2}
                placeholder="Choose a audit item template..."
                className="my-react-select-container w-1/2"
                classNamePrefix="my-react-select"
              />
            </div> */}
            <div>
              {/* {selectedOption.length === 0 && selectedOption2.length === 0 ? (
                <div className="flex items-center justify-center h-full mt-4">
                  <p className="text-gray-400 text-lg">
                    Select a subaudit to view data.
                  </p>
                </div>
              ) : null} */}

              {/* <PreResponse formId={selectedOption} /> */}
              {/* <PostResponse formId={selectedOption} /> */}
              {/* <Responses formId={selectedOption} /> */}
              {!options2[0] ? (
                <div className="flex justify-center items-center m-auto">
                  <PuffLoader color="#6366f1" size={60} />
                </div>
              ) : (
                <AuditItemResponse
                  auditData={auditData}
                  dummyForHandleHover={dummyForHandleHover}
                  formId={auditId}
                  auditItemFormId={options2[0].value}
                  setDummy={setDummy}
                  dummy={dummy}
                />
              )}
              {!hoverFormId && !hoverId ? null : (
                <AuditItemResponse
                  auditData={auditData}
                  dummyForHandleHover={dummyForHandleHover}
                  formId={hoverId}
                  auditItemFormId={hoverFormId}
                  setDummy={setDummy}
                  dummy={dummy}
                />
              )}
            </div>
          </div>
        </main>

        <Banner />
      </div>
    </div>
  );
}

export default AuditView;
