import { ElementType, useRef, ComponentProps, Fragment } from "react";
import { FormRegister } from "types/UseForm";

import styles from "./TextEditor.module.scss";

type TextEditorOwnProps<E extends ElementType> = {
  as?: E;
  register?: ReturnType<FormRegister> | {};
  disabled?: boolean;
};

type TextEditorProps<E extends ElementType> = TextEditorOwnProps<E> &
  Omit<ComponentProps<E>, keyof TextEditorOwnProps<E>>;

type ToolBarActions =
  | "bold"
  | "italic"
  | "underline"
  | "strikethrough"
  | "link";

type ToolBar = {
  icon: string;
  action: ToolBarActions;
};

let toolbar: ToolBar[] = [
  {
    icon: "bx-bold",
    action: "bold",
  },
  {
    icon: "bx-italic",
    action: "italic",
  },
  {
    icon: "bx-underline",
    action: "underline",
  },
  {
    icon: "bx-link-alt",
    action: "link",
  },
  {
    icon: "bx-strikethrough",
    action: "strikethrough",
  },
];

const TextEditor = <E extends ElementType = "div">({
  as,
  disabled = false,
  defaultValue = "",
  placeholder = "Enter Here",
  register = {},
  ...props
}: TextEditorProps<E>) => {
  let editorRef = useRef<HTMLDivElement>(null);
  let toolBarRef = useRef<HTMLUListElement>(null);

  const handleFocus = (): void => {
    editorRef.current?.classList.remove(styles.blur);
    editorRef.current?.classList.add(styles.focus);
    toolBarRef.current?.classList.add(styles.show);
  };

  const handleBlur = () => {
    if (editorRef.current) {
      editorRef.current.classList.remove(styles.focus);
      editorRef.current.classList.add(styles.blur);
    }
    if (toolBarRef.current) {
      toolBarRef.current.classList.remove(styles.show);
    }
  };

  const handleToolBar = (action: ToolBarActions) => {
    const selection = window.getSelection();
    
    if (!selection) return;
    
    const range = selection.getRangeAt(0);
    const linkElement = findLinkElement(range);
    
    switch (action) {
      case "bold":
        document.execCommand("bold");
        break;
    
      case "italic":
        document.execCommand("italic");
        break;
    
      case "underline":
        document.execCommand("underline");
        break;
    
      case "strikethrough":
        document.execCommand("strikethrough");
        break;
    
      case "link":
        if (linkElement) {
          const url = prompt("Enter the URL:", linkElement.href);
          if (!url) return;
    
          linkElement.href = url;
          // Change the color of the anchor tag
          const color = prompt("Enter the color for the linked text:");
          if (color) {
            linkElement.style.color = color;
          }
        } else {
          const url = prompt("Enter the URL:");
          if (!url) return;
    
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
    
          const selectedText = range.extractContents();
          link.appendChild(selectedText);
          range.insertNode(link);
          range.selectNode(link);
          selection.removeAllRanges();
          selection.addRange(range);
          
          // Change the color of the anchor tag
          const color = prompt("Enter the color for the linked text:");
          if (color) {
            link.style.color = color;
          }
        }
        
        break;
    
      default:
        return;
    }
    
    clearSelection();
  };
  
  
  
  const findLinkElement = (range: Range): HTMLAnchorElement | null => {
    let currentNode: Node | null = range.startContainer;
    while (currentNode) {
      if (currentNode.nodeType === Node.ELEMENT_NODE) {
        const element = currentNode as HTMLElement;
        if (element.tagName === "A") {
          return element as HTMLAnchorElement;
        }
      }
      currentNode = currentNode.parentNode;
    }
    return null;
  };
  

  const clearSelection = () => {
    let selection = window.getSelection();
    if (selection?.empty) {
      selection.empty();
    } else if (selection?.removeAllRanges) {
      selection.removeAllRanges();
    }
  };

  let Component = as || "div";

  return (
    <Fragment>
      {disabled ? (
        <div dangerouslySetInnerHTML={{ __html: defaultValue }}></div>
      ) : (
        <div
          tabIndex={-1}
          ref={editorRef}
          className={styles.container}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <Component
            className={styles.editor}
            contentEditable={true}
            placeholder={placeholder}
            dangerouslySetInnerHTML={{ __html: defaultValue }}
            {...register}
            {...props}
          />
          <ul ref={toolBarRef} className={styles.toolbar}>
            {toolbar.map(({ icon, action }, index) => {
              return (
                <li key={index} onClick={() => handleToolBar(action)}>
                  <i className={icon}></i>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default TextEditor;
