import React, { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const SetPassword = () => {
  const { token } = useParams();
  console.log("Token:", token);
  const [value, setValue] = useState({});
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [warning, setWarning] = useState("");

  const navigate = useNavigate(); // Initialize useNavigate
  const handleChange = (key, value) => {
    setValue((prev) => ({ ...prev, [key]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${BASE_URL}/new-password/`, { value, token })
      .then((result) => {
        alert("Passwor updated Successfully");
      })
      .catch((err) => {
        console.log({ err });
        setWarning(err.response.data);
      });
  };

  return (
    <div>
      <section className="flex flex-col md:flex-row h-screen items-center">
        <div className="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
          <img
            src="https://source.unsplash.com/random"
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
          <div className="w-full h-100">
            <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
              Set passsword
            </h1>

            <form className="mt-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="newPassword">New Password</label>
                <input
                  type="password"
                  className="w-full px-4 py-3 rounded-lg  text-black mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                  placeholder="New Password"
                  id="newPassword"
                  value={value.fullname}
                  onChange={(e) => handleChange("newpassword", e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  className="w-full px-4 py-3 rounded-lg  text-black mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                  placeholder="Comfirm Password"
                  id="confirmPassword"
                  value={value.email}
                  onChange={(e) =>
                    handleChange("confirmpassword", e.target.value)
                  }
                  required
                />
              </div>
              {error && <div className="error">{error}</div>}
              {successMessage && (
                <div className="success">{successMessage}</div>
              )}
              <button
                type="submit"
                className="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
              >
                Set Password
              </button>
            </form>
            <hr className="my-6 border-gray-300 w-full" />
            <p className="mt-8">
              Remember your password?{" "}
              <Link
                to="/"
                className="text-blue-500 hover:text-blue-700 font-semibold"
              >
                Log In
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SetPassword;
