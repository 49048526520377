import { Fragment, useEffect, useRef, useState } from "react";
// import { createPreForm, getAllForms, deleteFormById } from "services/PreEditForm";
import { createPreForm, getAllPreForms, deletePreFormById } from "services/PreEditForm";
import { createPostForm, getAllPostForms, deletePostFormById } from "services/PostEditForm";
import { Link, useParams } from "react-router-dom";
// import { getAllTemplates } from "services/Template";
import { FormData, PageMeta } from "types/Form";
import DropDown from "components/DropDown";
import moment from "moment-timezone";
import ClipLoader from "react-spinners/ClipLoader";

import { toast } from "react-toastify";
import { googleFormIcon } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Header from "./Header";
import Pagination from "components/Pagination";
import { darkMode } from "Dashboard/css/tailwind.config";
import Sidebar from "Dashboard/partials/Sidebar";
import WelcomeBanner from 'Dashboard/partials/dashboard/WelcomeBanner';
import styles from "./FormList.module.scss";
import { PuffLoader } from "react-spinners";
const PreAuditItemList = () => {
  const { formId } = useParams();
  console.log("this is the formId", formId);
  const { projectId } = useParams<{ projectId: string }>();

  const [forms, setForms] = useState<FormData[]>([]);

  const [pageMeta, setPageMeta] = useState({} as PageMeta);

  const [templates, setTemplates] = useState<FormData[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  // console.log("this is the project id from params", getProjectId());
  const location = useLocation();

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  // const { user, logout } = useAuth();

  let formRef = useRef<HTMLTableElement>(null);

  const searchParams = new URLSearchParams(location.search);

  const [page, setPage] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handleTheme = () => {
    localStorage.getItem("theme") === "dark" ? setIsDarkMode(true) : setIsDarkMode(false);
  };

  useEffect(() => {
    handleTheme();
  }, []);

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };
  const totalPages = Math.ceil(forms.length / itemsPerPage);
  const paginationButtons = [];

  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
          }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
          }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );
  let search = searchParams.get("search");
  // let page = searchParams.get("page") || 1;
  console.log("this is the page", page);
  console.log("this is the search", search);
  // useEffect(() => {
  //   getTemplates();
  // }, []);

  useEffect(() => {
    getForms();
  }, [search, page]);

  // const getTemplates = async () => {
  //   let { data } = await getAllTemplates();
  //   setTemplates(data);
  // };

  const getForms = async () => {
    try {
      let {
        data: { list, pageMeta },
      } = await getAllPreForms({ search });

      // Filter the forms based on the auditId and formId
      const filteredForms = list.filter(form => form.auditId === formId);
      console.log("this is the list from pre", list);
      setForms(filteredForms);
      setPageMeta(pageMeta);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };


  const handleCreateFrom = async (templateId?: string) => {
    // let body = templateId ? { templateId } : undefined;
    let body = {
      templateId: templateId ? templateId : undefined,
      auditId: formId
    }
    let { data } = await createPreForm(body);
    data.auditId = formId;
    console.log("this is the data", data);
    let form = [...forms];
    form.unshift(data);
    setForms(form);
    navigateToForm(data._id);
  };
  // const handleCreatePreFrom = async (templateId?: string) => {
  //   let body = templateId ? { templateId } : undefined;
  //   let { data } = await createPreForm(body);
  //   let form = [...forms];
  //   form.unshift(data);
  //   setForms(form);
  //   // navigateToForm(data._id);
  // };
  // const handleCreatePostFrom = async (templateId?: string) => {
  //   let body = templateId ? { templateId } : undefined;
  //   let { data } = await createPostForm(body);
  //   let form = [...forms];
  //   form.unshift(data);
  //   setForms(form);
  //   // navigateToForm(data._id);
  // };

  const navigateToForm = (formId: string) => {
    navigate(`/form/${formId}/preaudit`);
  };

  const handleDeleteForm = async (formId: string) => {
    if (!window.confirm("Are you sure to delete this form?")) return;

    let {
      data: { message },
    } = await deletePreFormById(formId);
    let form = [...forms];
    toast(message, { type: "success", className: "dark:bg-gray-900 dark:text-white z-[1000]", });
    let index = form.findIndex(({ _id }) => _id === formId);
    form.splice(index, 1);
    setForms(form);
  };

  // const handlePageChange = (page: number) => {
  //   navigate({
  //     search:
  //       page !== 0
  //         ? `?page=${page + 1}${search ? `&search=${search}` : ""}`
  //         : "",
  //   });
  //   if (formRef.current) formRef.current.scrollIntoView({ behavior: "smooth" });
  // };
  // console.log(user)
  useEffect(() => {
    if (isLoading === false) {
      if (forms.length === 0) {
        console.log("this is the forms if", forms.length)
        // setTimeout(() => {
        setShowModal(true);
        // }, 800);        
      }
    }
    console.log("this is the forms lenth", forms.length)
  }, [isLoading]);
  return (
    <div >
      {(forms.length === 0 && showModal) && (
        <div
          id="popup-modal"
          tabIndex={-1}
          className="flex absolute justify-center items-center z-[999]   p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                onClick={() => {
                  navigate(-1);
                }
                }
                type="button"
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="popup-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6 text-center">
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Do you wany to add pre audit form?
                </h3>
                <button
                  data-modal-hide="popup-modal"
                  onClick={() => {
                    setShowModal(false)
                  }}
                  type="button"
                  className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                >
                  Yes, I'm sure
                </button>
                <button

                  onClick={() => {
                    navigate(-1);
                  }
                  }
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  No, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={(forms.length === 0) ? `${showModal === false ? "" : `blur-sm`} flex h-screen overflow-hidden visible` : "flex h-screen overflow-hidden visible"}>



        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

          {/*  Site header */}
          {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

          <main>
            <div className=" w-full max-w-9xl mx-auto">

              {/* Welcome banner */}
              {/* <WelcomeBanner /> */}

              {/* Dashboard actions */}
              <div className="sm:flex sm:justify-between sm:items-center ">

                {/* Left: Avatars */}
                {/* <DashboardAvatars /> */}

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Filter button */}
                  {/* <FilterButton /> */}
                  {/* Datepicker built with flatpickr */}
                  {/* <Datepicker /> */}
                  {/* Add view button */}
                  {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Add view</span>
              </button>                 */}
                </div>

              </div>

              {/* Cards */}
              <div>

                <Fragment>
                  <Header search={search} />
                  <div
                    id="alert-border-1"
                    className="flex items-center p-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
                    role="alert"
                  >
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <div className="ml-3 text-sm font-medium">
                      <strong>PreAudit Template Setup Screen</strong>
                    </div>

                  </div>
                  {isLoading ? (
                    <div className="flex justify-center items-center m-auto h-screen">
                      <PuffLoader
                        color="#6366f1"
                        size={60}
                      />
                    </div>
                  ) : (
                    <div className={styles.container}>



                      <div className={`dark:bg-slate-900 ${styles.template}`}>

                        <div className={`dark:bg-slate-900 ${styles.wrapper}`}>
                          <nav className="flex mb-3" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                              <li className="inline-flex items-center">
                                <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                  <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                  Home
                                </a>
                              </li>
                              <li>
                                <div className="flex items-center">
                                  <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                  <button onClick={() => { window.history.go(-2) }} className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Audit Templates</button>
                                </div>
                              </li>
                              <li>
                                <div className="flex items-center">
                                  <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                  <button onClick={() => { window.history.back() }} className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Audit Template</button>
                                </div>
                              </li>
                              <li aria-current="page">
                                <div className="flex items-center">
                                  <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                  <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Pre Audit Template</span>
                                </div>
                              </li>
                            </ol>
                          </nav>
                          <div className="text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[24px]">Start a new pre-audit template</div>
                          <div className={styles.list}>
                            <div className={isDarkMode ? `dark:text-white text-black mb-[15px] font-custom font-bold  text-[18px]` : `${styles.card}`} onClick={() => {
                              handleCreateFrom();
                              // handleCreatePreFrom();
                              // handleCreatePostFrom();
                            }}>
                              <img
                                src={require("assets/images/add-icon.png")}
                                className="cursor-pointer mb-[15px] rounded-lg"
                                draggable={false}
                              // style={{ filter: "hue-rotate(112deg)" }}
                              />
                              <div className="text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[24px]">Blank</div>
                            </div>
                            {templates.map(({ _id, title }) => {
                              return (
                                <div className={styles.card} key={_id}>
                                  <img
                                    src={require(`assets/images/templates/${_id}.png`)}
                                    onClick={() => {
                                      handleCreateFrom(_id);
                                      // handleCreatePreFrom(_id);
                                      // handleCreatePostFrom(_id);
                                    }}
                                    draggable={false}
                                  />
                                  <span>{title}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className={`bg-white dark:bg-slate-900 ${darkMode ? styles.form2 : styles.form}`}>
                        <table ref={formRef} className={`${styles.wrapper} w-full`}>

                          <thead>
                            <tr>
                              <th></th>
                              <th className="py-3">
                                <span className="text-lg text-gray-800 dark:text-white">Title</span>
                              </th>
                              <th className="py-3">
                                <span className="text-lg  text-gray-800 dark:text-white">Created at</span>
                              </th>
                              <th className="py-3">
                                <span className="text-lg  text-gray-800 dark:text-white">Last updated at</span>
                              </th>
                              <th className="py-3">
                                <span className="text-lg  text-gray-800 dark:text-white">Action</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {forms.length === 0 ? (
                              <tr aria-label="empty">
                                <td colSpan={5} align="center">
                                  <span className="text-gray-500 dark:text-gray-400">No Records Found</span>
                                </td>
                              </tr>
                            ) : (
                              forms.slice(startIndex, endIndex).map(({ title, _id, createdAt, updatedAt }) => {
                                return (
                                  <Fragment key={_id}>
                                    <tr
                                      onClick={() => navigateToForm(_id)}
                                      className="cursor-pointer group hover:bg-gray-100 dark:hover:bg-slate-800"
                                    >
                                      <td>{googleFormIcon}</td>
                                      <td className="py-3">
                                        <span className="text-gray-900 dark:text-gray-100">{title}</span>
                                      </td>
                                      <td className="py-3">
                                        <span className="text-gray-500 dark:text-gray-400">
                                          {moment.tz(createdAt, "Asia/Kolkata").format("MMM D, YYYY")}
                                        </span>
                                      </td>
                                      <td className="py-3">
                                        <span className="text-gray-500 dark:text-gray-400">
                                          {moment.tz(updatedAt, "Asia/Kolkata").fromNow()}
                                        </span>
                                      </td>
                                      <td className="py-3">
                                        <div>
                                          <i
                                            id={`dropdown-${_id}`}
                                            className="bx-dots-vertical-rounded text-gray-500 dark:text-gray-400 hover:bg-slate-800"
                                            onClick={(e) => e.stopPropagation()}
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                    <DropDown
                                      selector={`#dropdown-${_id}`}
                                      placement="bottom"
                                      className={styles.dropdown}
                                    >
                                      <DropDown.Item onClick={() => handleDeleteForm(_id)}>
                                        <i className="bx-trash"></i>
                                        <span className="ml-2">Remove</span>
                                      </DropDown.Item>

                                    </DropDown>
                                  </Fragment>
                                );
                              })
                            )}
                          </tbody>

                        </table>

                        <div className="flex justify-center mt-8">
                          {/* <div>
                          Page {page} of {totalPages}
                        </div> */}
                          {/* <div className="flex items-center gap-1">
                            {paginationButtons}
                          </div> */}
                        </div>
                      </div>

                    </div>
                  )}
                </Fragment>

              </div>

            </div>
          </main>



        </div>
      </div>

    </div>

  );
};

export default PreAuditItemList;
