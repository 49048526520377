import { Fragment, useMemo } from "react";
import DropDown from "components/DropDown";

import styles from "./Avatar.module.scss";
import { Link } from "react-router-dom";

type AvatarProps = {
  userName?: string;
  backgroundColor?:string;
  // logout: () => void;
};


const Avatar = ({ userName,backgroundColor}: AvatarProps) => {
  const initial = useMemo(() => {
    if (!userName) return '';
  
    let [firstName, lastName] = userName.split(" ");
    let result = '';
  
    if (firstName) {
      result += firstName.charAt(0);
    }
  
    if (lastName) {
      result += lastName.charAt(0);
    }
  
    // Ensure the result is limited to a maximum of 10 characters
    if (result.length > 10) {
      result = result.slice(0, 10);
    }
  
    return result;
  }, [userName]);
  

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
  }
  console.log("thisisbg",backgroundColor)
  return (
    <Fragment>
      <div id="user-dropdown" className={`${styles.avatar}`} style={{backgroundColor: backgroundColor}}
>
        <span>{initial}</span>
      </div>
    </Fragment>
  );
};

export default Avatar;
