export function formatDate(date: string | Date): string {

    if (!date) return "";
  
    const dateObject = date instanceof Date ? date : new Date(date);
  
    // Use Intl.DateTimeFormat for locale-specific formatting
    const formatter = new Intl.DateTimeFormat('en-IN'); // Adjust 'en-IN' for user preference (future)
    const formattedDate = formatter.format(dateObject);
  
    return formattedDate;
  }