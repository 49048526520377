import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// import './css/style.css';
import "../Dashboard/css/style.css";
import "../Dashboard/charts/ChartjsConfig";
import "../index.css";
// Import pages
import Dashboard from "Dashboard/pages/Dashboard";
import OEM_Inventry from "Dashboard/pages/AuditSetup";
import Project from "Dashboard/pages/Project";
import User from "Dashboard/pages/User";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route
          exact
          path="/OEM_Inventry_Data_Upload"
          element={<OEM_Inventry />}
        />
        <Route exact path="/projects" element={<Project />} />
        <Route exact path="/users" element={<User />} />
      </Routes>
    </>
  );
}

export default App;
