import React, { useEffect, useState } from "react";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const AddDataRoleComp = ({ heading, button }) => {
  const [change, setChange] = useState(false);
  const [value, setValue] = useState({});
  const [drop, setDrop] = useState();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [fullnameError, setFullNameError] = useState("");

  const handleChange = (value) => {
    setValue((prev) => ({ ...prev, category: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordError("");
    setEmailError("");
    setFullNameError("");
    console.log(value);
    try {
      const res = await axios
        .post(`${BASE_URL}/api/categories`, value)
        .then((result) => {
          if (result.status === 201) {
            alert("Data Added Successfully");
          }
        });
      // console.log(res)
    } catch (err) {
      console.log(err);
    }
    // console.log({ emailError: e.response.data.errors.email, passwrodError: e.response.data.errors.password })
  };
  useEffect(() => {
    async function fetchData() {
      try {
        console.log("hello sign");
        const dropval = await axios.get(
          `${BASE_URL}/dropdownvalue`
        );
        const storeddrop = dropval.data;
        setDrop(storeddrop);
        console.log("The Value of drop", drop);
      } catch (er) {
        console.log(er);
      }
    }
    fetchData();
  }, [change]);

  return (
    <div className=" py-8">
      <div className="">
        <form onSubmit={handleSubmit} className="flex  mb-4">
          {/* <h2 className="text-3xl text-center text-red-500 mb-8">{heading}</h2> */}
          <input
            type="text"
            name="rolename"
            defaultValue={value.rolename || ""}
            onChange={(e) => handleChange(e.target.value)}
            className="form-input w-[58%] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mr-2"
            placeholder="Role name"
          />

          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-[30%] p-2.5 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 "
            // onChange={(e) => handleChange("category", e.target.value)}
            name="category"
          >
            {drop &&
              drop.map((val, index) => (
                <option
                  key={val._id}
                  value={val._id}
                  className="dark:bg-gray-800 dark:text-white hover:bg-teal-600"
                >
                  {val.category}
                </option>
              ))}
          </select>

          <div className="text-red-500 mb-4">
            {fullnameError || emailError || passwordError}
          </div>

          <button className="ml-2 relative z-[2] flex items-center rounded  px-6  text-xs font-medium uppercase leading-tight  shadow-md transition duration-150 ease-in-out bg-indigo-500 hover:bg-indigo-600 text-white outline-nonee ">
            {button}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddDataRoleComp;
