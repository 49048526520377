import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import login_img from "../images/reset.jfif";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const Reset = () => {
  const [email, setEmail] = useState("");
  const [resetPasswordError, setPasswordError] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const userId = sessionStorage.getItem("user");
  const forgotHandle = async (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setPasswordError("");
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/forgot-password`,
        { email: email, userId: userId },
        config
      );
      setEmailSent(true);
      console.log(response.data.message);
    } catch (err) {
      console.error(err);
      setPasswordError(err.response.data.error);
    }
  };

  return (
    <div>
      <section className="flex flex-col md:flex-row h-screen items-center">
        <div className="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
          <img src={login_img} alt="" className="w-full h-full object-cover" />
        </div>

        <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
          <div className="w-full h-100">
            <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
              Reset Password
            </h1>
            <form className="mt-6" onSubmit={forgotHandle}>
              {emailSent ? (
                <div className="alert alert-success" role="alert">
                  Email sent to {email}. Check your mail for instructions.
                </div>
              ) : (
                <div>
                  <div className="block text-gray-700">
                    <label>Email address</label>
                    <input
                      type="email"
                      className="w-full px-4 py-3 rounded-lg  text-black mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                  >
                    Send Reset Link
                  </button>
                  <div className="text-danger mt-2">{resetPasswordError}</div>
                </div>
              )}
            </form>
            <hr className="my-6 border-gray-300 w-full" />
            <p className="mt-8">
              Remember your password?{" "}
              <Link
                to="/"
                className="text-blue-500 hover:text-blue-700 font-semibold"
              >
                Log In
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Reset;
