import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ApiContext } from "./ApiContext";
import useForm from "hooks/useForm";
import { GoogleLogin } from "@react-oauth/google";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "../images/sasya_logo.png";
import login_img from "../images/reset.jfif";

import jwt_decode from "jwt-decode";
import { ClipLoader, PuffLoader, PulseLoader } from "react-spinners";
import { toast } from "react-toastify";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const Login = () => {
  const [value, setValue] = useState({ role: 0, active: true });
  const [props, setProps] = useState({});
  const [identifierError, setIdentifierError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailPasswordError, setEmailPasswordError] = useState("");
  const [loading, setLoading] = useState(false); // State to control loader visibility
  const { register, formErrors } = useForm();
  const { truevalue, settruevalue, isAuth, setIsAuth } = useContext(ApiContext);

  console.log("isAuth", isAuth);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a logout success message in the URL query parameters
    const urlSearchParams = new URLSearchParams(window.location.search);
    const messageParam = urlSearchParams.get("message");

    if (messageParam === "logout") {
      // Display the logout success message
      setEmailPasswordError("Logout successful");
      // Clear it from the URL
      window.history.replaceState(null, "", "/login");
    }
  }, []);

  var usercheck = "";
  var datacheck = "";
  var rolecheck = "";
  var activeDeactive = "";
  var adminCheck = "";
  const handleChange = (key, value) => {
    setValue((prev) => ({ ...prev, [key]: value }));
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIdentifierError("");
    setPasswordError("");
    setEmailPasswordError("");
    setLoading(true);
  
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
  
      const res = await axios.post(`${BASE_URL}/login/`, value, config);
  
      const datacheck = res.data;
      const usercheck = res.data.user;
      const rolecheck = res.data.role;
      const activeDeactive = res.data.active;
      console.log("Login response", res.data);
  
      if (rolecheck === 1) {
        settruevalue(!truevalue);
        setIsAuth(!isAuth);
        return navigate({
          pathname: "/dashboard",
          state: { datacheck },
        });
      }
  
      if (usercheck && activeDeactive === true) {
        const port = `${BASE_URL}/user-details`;
        const userdetails = await axios.get(`${port}/${usercheck}`);
        const userDetailData = userdetails.data;
        console.log("thisisdatacheck", userDetailData);
  
        if (userDetailData.category && userDetailData.category.category === "Field Auditor") {
          // Display an alert indicating that Field Auditor cannot access
          toast("Field Auditor access to admin platform denied.", {
            type: "error",
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
          });
          setLoading(false);
        } else {
          // Proceed with login for users other than Field Auditor
          sessionStorage.setItem("auth-token", res.headers["auth-token"]);
          sessionStorage.setItem("user", res.data.user);
          setProps(userDetailData);
          settruevalue(!truevalue);
          setIsAuth(!isAuth);
          setLoading(false);
          return navigate({
            pathname: "/dashboard",
            state: { datacheck: userDetailData },
          });
        }
      } else {
        throw new Error("Incorrect password or User not found");
      }
    } catch (error) {
      console.log("Error from backend:", error);
  
      if (error.response) {
        const errorStatus = error.response.status;
        const errorData = error.response.data;
  
        if (errorStatus === 400) {
          if (typeof errorData === 'string') {
            setEmailPasswordError(errorData);
          } else if (errorData && errorData.error) {
            setEmailPasswordError(errorData.error);
          } else {
            setEmailPasswordError("An error occurred");
          }
        } else if (errorStatus === 401) {
          setEmailPasswordError("Incorrect password");
        } else if (errorStatus === 403) {
          setEmailPasswordError("Unauthorized access");
        } else if (errorStatus === 404) {
          setEmailPasswordError("User not found");
        } else {
          setEmailPasswordError("An unexpected error occurred");
        }
      } else {
        console.error("Network Error:", error);
        setEmailPasswordError("An error occurred");
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="relative">
        <img
          src={logo}
          alt="Logo"
          className="h-12 w-12 absolute top-0 right-0 m-4"
        />
      </div>
      <section className="flex flex-col md:flex-row h-screen items-center">
        <div className="bg-indigo-600 hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
          <img src={login_img} alt="" className="w-full h-full object-cover" />
        </div>

        <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
          <div className="w-full h-100">
            <div className="flex">
              <img
                src="https://flowbite.com/docs/images/logo.svg"
                className="h-8 mr-3"
                alt="Flowbite Logo"
              />
              <span className="self-center text-2xl font-semibold whitespace-nowrap text-gray-700">
                VPS Group
              </span>
            </div>
            <h1 className="text-xl text-gray-600 md:text-2xl font-bold leading-tight mt-4">
              Log in to your account
            </h1>

            <form className="mt-6" action="#" method="POST">
              <div>
                <label className="block text-gray-700">
                  Email or Mobile Number
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  value={value.identifier}
                  onChange={(e) => handleChange("identifier", e.target.value)}
                  placeholder="Enter Email or Mobile Number"
                  className={`w-full px-4 py-3 rounded-lg  text-black mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none ${
                    formErrors?.identifier ? "border-red-500" : ""
                  }`}
                  autoFocus
                  // autoComplete="true"
                  required
                  {...register("identifier", {
                    required: {
                      value: true,
                      message: "Please enter email or mobile number",
                    },
                  })}
                />
                {formErrors?.identifier && (
                  <span className="text-red-500">
                    {formErrors.identifier.message}
                  </span>
                )}
                {identifierError && (
                  <span className="text-red-500">{identifierError}</span>
                )}
              </div>
              <div className="mt-4">
                <label className="block text-gray-700">Password</label>
                <input
                  type="password"
                  name=""
                  id=""
                  placeholder="Enter Password"
                  minLength={6}
                  className={`w-full px-4 py-3 rounded-lg  text-black mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none ${
                    formErrors?.password ? "border-red-500" : ""
                  }`}
                  value={value.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                  required
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Please enter password",
                    },
                  })}
                />
                {formErrors?.password && (
                  <span className="text-red-500">
                    {formErrors.password.message}
                  </span>
                )}
                {passwordError && (
                  <span className="text-red-500">{passwordError}</span>
                )}
              </div>
              {emailPasswordError && (
                <div className="text-red-500 mt-2">{emailPasswordError}</div>
              )}

              {/* <div className="text-right mt-2">
                <Link
                  to="/reset"
                  className="text-sm font-semibold text-gray-700 hover:text-blue-700 focus:text-blue-700"
                >
                  Forgot Password?
                </Link>
              </div> */}
              <div className="text-center mt-6">
                <button
                  disabled={loading}
                  type="submit"
                  className="w-full block bg-indigo-500 hover:bg-indigo-400 focus:bg-indigo-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                  onClick={handleSubmit}
                >
                  {loading ? <PulseLoader size={12} color="#fff" /> : "Log In"}
                </button>
              </div>
            </form>

            {/* <hr className="my-6 border-gray-300 w-full" /> */}

            {/* <GoogleLogin
              onSuccess={(credentialResponse) => {
                const googleData = jwt_decode(credentialResponse.credential);
                console.log(googleData);
                console.log(credentialResponse);
                handleGoogleLogin(googleData);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            /> */}
            {/* <p className="mt-8">
              Need an account?{" "}
              {/* Use the Link component to navigate to the signup page */}
            {/* <Link
                to="/signup"
                className="text-blue-500 hover:text-blue-700 font-semibold"
              >
                Create an account
              </Link>
            </p> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
