export const baseURL =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_BASE_URL}`
    : `${process.env.REACT_APP_BASE_URL}`;
const userUrl = `${baseURL}/api/user`;
const formUrl = `${baseURL}/api/form`;
const PreFormUrl = `${baseURL}/api/preform`;
const PostFormUrl = `${baseURL}/api/postform`;
const AuditFormUrl = `${baseURL}/api/auditItem`;
const responseUrl = `${baseURL}/api/response`;
const preResponseUrl = `${baseURL}/api/pre_response`;
const postResponseUrl = `${baseURL}/api/post_response`;
const auditItemResponseUrl = `${baseURL}/api/audititem_response`;
const templateUrl = `${baseURL}/api/template`;
const projectUrl = `${baseURL}/api/project`;

const User = {
  login: `${userUrl}/login`,
  register: `${userUrl}/register`,
};

const Form = {
  getAllForms: `${formUrl}/all`,
  createForm: `${formUrl}/create`,
};
const PreForm = {
  getAllForms: `${PreFormUrl}/all`,
  createForm: `${PreFormUrl}/create`,
};
const PostForm = {
  getAllForms: `${PostFormUrl}/all`,
  createForm: `${PostFormUrl}/create`,
};
const AuditItemList = {
  getAllForms: `${AuditFormUrl}/all`,
  createForm: `${AuditFormUrl}/create`,
};

const Response = {
  submitResponse: `${responseUrl}/submit`,
  checkStatus: `${responseUrl}/status`,
};

const PreResponse = {
  submitResponse: `${preResponseUrl}/submit`,
  checkStatus: `${preResponseUrl}/status`,
};
const PostResponse = {
  submitResponse: `${postResponseUrl}/submit`,
  checkStatus: `${postResponseUrl}/status`,
};
const AuditItemResponse = {
  submitResponse: `${auditItemResponseUrl}/submit`,
  updateResponse: `${auditItemResponseUrl}/update/:responseId`,
  deleteResponse: `${auditItemResponseUrl}/delete/:responseId`,
  checkStatus: `${auditItemResponseUrl}/status`,
};

const Template = {
  getAllTemplate: `${templateUrl}/all`,
};

const Project = {
  getAllProject: `${projectUrl}/all`,
  createProject: `${projectUrl}/create`,
};

export {
  User,
  Form,
  Response,
  PreResponse,
  PostResponse,
  postResponseUrl,
  AuditItemResponse,
  auditItemResponseUrl,
  Template,
  formUrl,
  responseUrl,
  preResponseUrl,
  PreForm,
  PreFormUrl,
  PostForm,
  PostFormUrl,
  AuditItemList,
  AuditFormUrl,
  Project,
  projectUrl,
};
