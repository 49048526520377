import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { createForm, getAllForms, deleteFormById } from "services/Form";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../components/DropdownFilter";
import Datepicker from "../components/Datepicker";
import Banner from "../partials/Banner";
import { BsTrash, BsTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ClipLoader, PuffLoader } from "react-spinners";
import {
  FaEdit,
  FaMinus,
  FaPlus,
  FaSave,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

function SubProject() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [remarks, setRemarks] = useState([]);
  const [newRemark, setNewRemark] = useState("");
  const [feedback, setFeedback] = useState([]);
  const [newFeedback, setNewFeedback] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [platformId, setPlatformId] = useState("649e8a5186bc5f1770f9a120");
  const [templateId, setTemplateId] = useState("");
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [projectData, setProjectData] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [editRemarkId, setEditRemarkId] = useState(null);
  const [editFeedbackId, setEditFeedbackId] = useState(null);
  const [currentUserData, setCurrentUserData] = useState();
  const [activeTab, setActiveTab] = useState("Remarks");
  const [selectedOptionValue, setSelectedOptionValue] = useState(null);
  const [showModalForDealerDialogue, setShowModalForDealerDialogue] =
    useState(false);
  const { projectId } = useParams();
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);

  const [page, setPage] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handleOpenForDealerDialogue = () => {
    const token = sessionStorage.getItem("auth-token");
    if (token) {
      setShowModalForDealerDialogue(true);
    } else {
      toast("Access Denied!.", {
        type: "error",
        className: "dark:bg-red-700 dark:text-white",
      });
    }
  };
  useEffect(() => {
    // Fetch remarks on component mount
    fetchRemarks();
  }, []);
  useEffect(() => {
    const userId = sessionStorage.getItem("user");
    if (userId) {
      const response = axios
        .get(`${BASE_URL}/user-details/${userId}`)
        .then((response) => {
          console.log("this is a user res", response.data);
          setCurrentUserData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const fetchRemarks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/header-info`);
      setRemarks(response.data.data);
      console.log("thisisresponse", response.data.data);
    } catch (error) {
      console.error("Error fetching remarks:", error);
    }
  };
  const addRemark = async (event) => {
    event.preventDefault();
    try {
      // Fetch remarks before attempting to add a new one
      await fetchRemarks();

      if (
        remarks.filter((item) => item.projectId === projectId).length === 0 &&
        newRemark.trim() !== ""
      ) {
        const token = sessionStorage.getItem("auth-token");
        const config = {
          headers: {
            "auth-token": token,
          },
        };
        const response = await axios.post(
          `${BASE_URL}/header-info`,
          {
            headerInfo: newRemark,
            projectId: projectId,
          },
          config
        );
        setNewRemark("");
        fetchRemarks(); // Refresh remarks after addition
        console.log("Remark added:", response.data);
      } else {
        // If remarks array is not empty or if newRemark is empty
        console.log("Error: You can create only one header description.");
        // Display an error toast
        toast("You can create only one header description.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
    } catch (error) {
      console.error("Error adding header description.:", error);
    }
  };

  const updateRemark = async (id, updatedRemark) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.put(
        `${BASE_URL}/header-info/${id}`,
        {
          headerInfo: updatedRemark,
        },
        config
      );
      setEditRemarkId(null);
      fetchRemarks(); // Refresh remarks after update
      console.log("header description updated:", response.data);
    } catch (error) {
      console.error("Error updating header description.:", error);
    }
  };
  const handleCancelEdit = () => {
    setEditRemarkId(null);
    fetchRemarks(); // Restore original remarks
  };
  const handleEditClick = (id, currentRemark) => {
    setEditRemarkId(id);
    setRemarks(
      remarks.map((remark) =>
        remark._id === id ? { ...remark, headerInfo: currentRemark } : remark
      )
    );
  };
  const handleEditInputChange = (event) => {
    setRemarks(
      remarks.map((remark) =>
        remark._id === editRemarkId
          ? { ...remark, headerInfo: event.target.value }
          : remark
      )
    );
  };
  const deleteRemark = async (id) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.delete(
        `${BASE_URL}/header-info/${id}`,
        config
      );
      fetchRemarks(); // Refresh remarks after deletion
      console.log("header description deleted:", response.data);
    } catch (error) {
      console.error("Error deleting header description:", error);
    }
  };

  const handleInputChange = (event) => {
    setNewRemark(event.target.value);
  };
  useEffect(() => {
    // Fetch feedback on component mount
    fetchFeedback();
  }, []);
  const fetchFeedback = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/footer-info`);
      setFeedback(response.data.data);
    } catch (error) {
      console.error("Error fetching remarks:", error);
    }
  };
  const addFeedback = async (event) => {
    event.preventDefault();
    try {
      // Fetch existing feedback before attempting to add a new one
      await fetchFeedback();

      if (
        feedback.filter((item) => item.projectId === projectId).length === 0 &&
        newFeedback.trim() !== ""
      ) {
        const token = sessionStorage.getItem("auth-token");
        const config = {
          headers: {
            "auth-token": token,
          },
        };
        const response = await axios.post(
          `${BASE_URL}/footer-info`,
          {
            footerInfo: newFeedback,
            projectId: projectId,
          },
          config
        );
        setNewFeedback("");
        fetchFeedback(); // Refresh feedback after addition
        console.log("Feedback added:", response.data);
      } else {
        // If feedback array is not empty or if newFeedback is empty
        console.log("Error: You can create only one footer description.");
        // Display an error toast
        toast("You can create only one footer description.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
    } catch (error) {
      console.error("Error adding footer description.:", error);
    }
  };

  const updateFeedback = async (id, updatedFeedback) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.put(
        `${BASE_URL}/footer-info/${id}`,
        {
          footerInfo: updatedFeedback,
        },
        config
      );
      setEditFeedbackId(null);
      fetchFeedback(); // Refresh remarks after update
      console.log("footer description updated:", response.data);
    } catch (error) {
      console.error("Error updating footer description.:", error);
    }
  };
  const handleCancelEditFeedback = () => {
    setEditFeedbackId(null);
    fetchFeedback(); // Restore original remarks
  };
  const handleEditClickFeedback = (id, currentFeedback) => {
    setEditFeedbackId(id);
    setFeedback(
      feedback.map((feedback) =>
        feedback._id === id
          ? { ...feedback, footerInfo: currentFeedback }
          : feedback
      )
    );
  };
  const handleEditInputChangeFeedback = (event) => {
    setFeedback(
      feedback.map((feedback) =>
        feedback._id === editFeedbackId
          ? { ...feedback, footerInfo: event.target.value }
          : feedback
      )
    );
  };
  const deleteFeedback = async (id) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.delete(
        `${BASE_URL}/footer-info/${id}`,
        config
      );
      fetchFeedback(); // Refresh feedbacks after deletion
      console.log("feedback deleted:", response.data);
    } catch (error) {
      console.error("Error deleting remark:", error);
    }
  };

  const handleInputChangeFeedback = (event) => {
    setNewFeedback(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  let search = searchParams.get("search");
  useEffect(() => {
    fetchProjects();
    fetchProject();
  }, []);
  const getForms = async () => {
    try {
      let {
        data: { list, pageMeta },
      } = await getAllForms({ search });
      // const filterList = list.filter((form) => form.projectId === projectId);
      console.log("this is the list from index", list);
      setForms(list);
      // setPageMeta(pageMeta);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };
  useEffect(() => {
    getForms();
  }, [search]);

  console.log("this is the forms", forms);
  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/subproject`);
      setLoading(false);

      const filteredData = response.data.data.filter(
        (project) => project.projectId === projectId
      );
      setData(filteredData);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchProject = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/project/${projectId}`);
      setLoading(false);
      setProjectData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const token = sessionStorage.getItem("auth-token");
  const config = {
    headers: {
      "auth-token": token,
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/api/subproject`,
        // config,
        {
          platformId,
          title,
          description,
          projectId,
          templateId,
        },
        config
      );
      if (response.status === 201 && response.statusText === "Created") {
        toast("Project Created Sucessfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
      const newProject = response.data.data;
      setData([...data, newProject]);
      console.log("project added successfully", response.data, projects);
      setState(false);
      setShowModal(false);
      setTitle("");
      setDescription("");
    } catch (error) {
      toast("Access Denied!", {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    // Show a confirmation dialog to confirm deletion
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this subproject?"
    );

    if (!confirmDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      if (
        currentUserData?.category.category === "Office Coordinator" ||
        currentUserData?.category.category === "Office Auditor"
      ) {
        toast("Access Denied!.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white",
        });
      } else {
        toast
          .promise(
            axios.delete(`${BASE_URL}/api/subproject/${id}`, config),
            {
              pending: "Deleting Sub Project...", // Display while deletion is in progress
              success: "Sub Project Deleted Successfully!",
              error: "Error Deleting Sub Project",
            },
            { theme: `${localStorage.getItem("theme")}` }
          )
          .then(async (res) => {
            const newProjects = data.filter((project) => project.id !== id);
            console.log("subproject deleted successfully", newProjects);
            setData(newProjects);
            console.log("this is a res", res);
            // Perform any additional actions based on the deletion response
            // For instance, check res.status if needed
          });
      }
    } catch (error) {
      toast("Access Denied!", {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      console.error(error);
    }
  };

  const isAuthenticated = sessionStorage.getItem("auth-token");

  const handleNavigation = (projectId) => {
    if (isAuthenticated) {
      navigate(`/audits/list/${projectId}`);
    } else {
      console.error("Authentication required to access this route.");
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    handleSubmit();
    setState(false);
  };

  // const handleOpen = () => {
  //   setShowModal(true);
  // };
  const handleOpen = () => {
    if (
      currentUserData?.category.category === "Office Coordinator" ||
      currentUserData?.category.category === "Office Auditor"
    ) {
      toast("Access Denied!.", {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white",
      });
    } else {
      setShowModal(true);
    }
  };

  const options = forms.map((form) => {
    return { value: form._id, label: form.title };
  });

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginationButtons = [];

  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 dark:bg-gray-800 text-black bg-slate-300 dark:text-white rounded-md ${
        page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 text-black bg-slate-300 py-2 dark:bg-gray-800 dark:text-white rounded-md ${
          page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 text-black bg-slate-300 py-2 dark:bg-gray-800 dark:text-white rounded-md ${
        page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );
  console.log("this is a data", selectedOption);
  // Function to update the project title
  const handleUpdateTitle = async (projectId, newTitle) => {
    try {
      if (
        currentUserData?.category.category === "Office Coordinator" ||
        currentUserData?.category.category === "Office Auditor"
      ) {
        toast("Access Denied!.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white",
        });
      } else {
        const token = sessionStorage.getItem("auth-token");
        const config = {
          headers: {
            "auth-token": token,
          },
        };
        const response = await axios.put(
          `${BASE_URL}/api/subproject/${projectId}`,
          {
            title: newTitle,
          },
          config
        );

        if (response.status === 200) {
          // If the request is successful, update the title in the state
          const updatedData = data.map((project) =>
            project.id === projectId ? { ...project, title: newTitle } : project
          );
          toast.success("Title Updated!", {
            className: "dark:bg-gray-900 dark:text-white z-[1000]",
            autoClose: 5000,
          });
          setData(updatedData);
        }
      }
    } catch (error) {
      // Handle errors, show an alert, or perform other error handling
      toast.error("Error updating title!", {
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
        autoClose: 5000,
      });
      console.error("Error updating title:", error);
    }
  };
  return (
    <>
      {showModalForDealerDialogue && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full "
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400 dark:border-none">
              <button
                type="button"
                onClick={() => {
                  setShowModalForDealerDialogue(false);
                }}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  Excel Descriptor
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                <>
                  <div>
                    <ul className="hidden shadow-lg  text-sm font-medium text-center text-gray-500 rounded-lg  sm:flex dark:text-gray-400">
                      <li className="w-full cursor-pointer">
                        <a
                          className={`inline-block w-full py-3 px-6  border-transparent focus:outline-none transition duration-300 relative ${
                            activeTab === "Remarks"
                              ? "text-gray-900 border-purple-600 dark:text-white dark:bg-transparent"
                              : "text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                          }`}
                          onClick={() => handleTabClick("Remarks")}
                        >
                          Header Info
                          {activeTab === "Remarks" && (
                            <span className="absolute inset-x-0 bottom-0 h-1 bg-purple-600 rounded-b-lg" />
                          )}
                        </a>
                      </li>

                      <li className="w-full cursor-pointer">
                        <a
                          className={`inline-block w-full py-3 px-6  border-transparent focus:outline-none transition duration-300 relative ${
                            activeTab === "Feedback"
                              ? "text-gray-900 border-purple-600 dark:text-white dark:bg-transparent"
                              : "text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                          }`}
                          onClick={() => handleTabClick("Feedback")}
                        >
                          Footer Info
                          {activeTab === "Feedback" && (
                            <span className="absolute inset-x-0 bottom-0 h-1 bg-purple-600 rounded-b-lg" />
                          )}
                        </a>
                      </li>
                    </ul>

                    {/* Render content based on activeTab */}
                    {activeTab === "Remarks" && (
                      <div>
                        <form onSubmit={addRemark}>
                          <div className="relative my-4">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                              <i className="bx bx-edit-alt text-lg"></i>
                            </div>
                            <div className="relative">
                              <input
                                type="text"
                                id="default-search"
                                value={newRemark}
                                onChange={handleInputChange}
                                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Type header description here..."
                                style={{ overflow: "auto", maxHeight: "120px" }}
                                required
                              />
                            </div>
                            <button
                              type="submit"
                              className="text-white absolute end-2.5 bottom-2.5 bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-4 py-2"
                            >
                              Add To Header
                            </button>
                          </div>
                        </form>

                        <div>
                          {remarks.length > 0 ? (
                            <ul className="divide-y divide-gray-300 dark:divide-gray-700">
                              {remarks.length > 0 ? (
                                <ul className="divide-y divide-gray-300 dark:divide-gray-700">
                                  {remarks
                                    .filter(
                                      (item) => item.projectId === projectId
                                    )
                                    .map((remark) => (
                                      <li
                                        key={remark._id}
                                        className="py-4 flex items-center justify-between"
                                      >
                                        {editRemarkId === remark._id ? (
                                          <div className="flex-grow flex items-center">
                                            <input
                                              type="text"
                                              value={remark.headerInfo}
                                              onChange={handleEditInputChange}
                                              className="block w-full p-2 text-sm border border-gray-300 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                              style={{
                                                overflow: "auto",
                                                maxHeight: "80px",
                                              }}
                                              required
                                            />
                                            <div className="ml-2 flex items-center space-x-2">
                                              <button
                                                onClick={() =>
                                                  updateRemark(
                                                    remark._id,
                                                    remark.headerInfo
                                                  )
                                                }
                                                className="btn-green"
                                              >
                                                <FaSave className="text-green-500" />
                                              </button>
                                              <button
                                                onClick={handleCancelEdit}
                                                className="btn-gray"
                                              >
                                                <FaTimes className="text-gray-500" />
                                              </button>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="flex-grow flex justify-between items-center">
                                            <p className="text-gray-800 dark:text-gray-300">
                                              {remark.headerInfo}
                                            </p>
                                            <div className="ml-2 flex items-center space-x-2">
                                              <button
                                                onClick={() =>
                                                  handleEditClick(
                                                    remark._id,
                                                    remark.headerInfo
                                                  )
                                                }
                                                className="btn-blue"
                                              >
                                                <FaEdit className="text-blue-500" />
                                              </button>
                                              <button
                                                onClick={() =>
                                                  deleteRemark(remark._id)
                                                }
                                                className="btn-red"
                                              >
                                                <FaTrash className="text-red-500" />
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </li>
                                    ))}
                                </ul>
                              ) : (
                                <p className="text-gray-800 flex justify-center items-center dark:text-gray-300">
                                  No header info available
                                </p>
                              )}
                            </ul>
                          ) : (
                            <p className="text-gray-800 dark:text-gray-300 flex justify-center items-center">
                              No header info available
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    {activeTab === "Feedback" && (
                      <div>
                        <form onSubmit={addFeedback}>
                          <div className="relative my-4">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                              <i className="bx bx-edit-alt text-lg"></i>
                            </div>
                            <div className="relative">
                              <input
                                type="text"
                                id="default-search"
                                value={newFeedback}
                                onChange={handleInputChangeFeedback}
                                className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Type footer description here..."
                                style={{ overflow: "auto", maxHeight: "120px" }}
                                required
                              />
                            </div>
                            <button
                              type="submit"
                              className="text-white absolute end-2.5 bottom-2.5 bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-4 py-2"
                            >
                              Add To Footer
                            </button>
                          </div>
                        </form>

                        <div>
                          {feedback?.length > 0 ? (
                            <ul className="divide-y divide-gray-300 dark:divide-gray-700">
                              {feedback?.length > 0 ? (
                                <ul className="divide-y divide-gray-300 dark:divide-gray-700">
                                  {feedback
                                    .filter(
                                      (item) => item.projectId === projectId
                                    )
                                    .map((feedback) => (
                                      <li
                                        key={feedback._id}
                                        className="py-4 flex items-center justify-between"
                                      >
                                        {editFeedbackId === feedback._id ? (
                                          <div className="flex-grow flex items-center">
                                            <input
                                              type="text"
                                              value={feedback.footerInfo}
                                              onChange={
                                                handleEditInputChangeFeedback
                                              }
                                              className="block w-full p-2 text-sm  border border-gray-300 rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-200 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                              style={{
                                                overflow: "auto",
                                                maxHeight: "80px",
                                              }}
                                              required
                                            />
                                            <div className="ml-2 flex items-center space-x-2">
                                              <button
                                                onClick={() =>
                                                  updateFeedback(
                                                    feedback._id,
                                                    feedback.footerInfo
                                                  )
                                                }
                                                className="btn-green"
                                              >
                                                <FaSave className="text-green-500" />
                                              </button>
                                              <button
                                                onClick={
                                                  handleCancelEditFeedback
                                                }
                                                className="btn-gray"
                                              >
                                                <FaTimes className="text-gray-500" />
                                              </button>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="flex-grow flex justify-between items-center">
                                            <p className="text-gray-800 dark:text-gray-300">
                                              {feedback.footerInfo}
                                            </p>
                                            <div className="ml-2 flex items-center space-x-2">
                                              <button
                                                onClick={() =>
                                                  handleEditClickFeedback(
                                                    feedback._id,
                                                    feedback.footerInfo
                                                  )
                                                }
                                                className="btn-blue"
                                              >
                                                <FaEdit className="text-blue-500" />
                                              </button>
                                              <button
                                                onClick={() =>
                                                  deleteFeedback(feedback._id)
                                                }
                                                className="btn-red"
                                              >
                                                <FaTrash className="text-red-500" />
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </li>
                                    ))}
                                </ul>
                              ) : (
                                <p className="text-gray-800 dark:text-gray-300 text-center">
                                  No feedbacks available
                                </p>
                              )}
                            </ul>
                          ) : (
                            <p className="text-gray-800 dark:text-gray-300 text-center">
                              No feedbacks available
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400 dark:border-none">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600 ">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  Sub Project Creation
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}
                <>
                  {/* <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Audit Title
                  </label> */}
                  <input
                    type="text"
                    placeholder="Title of the sub project"
                    value={title}
                    required
                    onChange={(e) => setTitle(e.target.value)}
                    className="bg-white border mb-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#3730a3] focus:border-[#3730a3] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-white dark:focus:border-white"
                  />
                  <input
                    type="text"
                    placeholder="Description of the sub project"
                    value={description}
                    rows={1}
                    required
                    onChange={(e) => setDescription(e.target.value)}
                    className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#3730a3] focus:border-[#3730a3] block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-white dark:focus:border-white"
                  />
                  <Select
                    defaultValue={selectedOption}
                    onChange={(event) => {
                      setSelectedOption(event.value);
                      setTemplateId(event.value);
                    }}
                    options={options}
                    placeholder="Choose a template..."
                    className="my-react-select-container mt-3  dark:border-gray-600  "
                    classNamePrefix="my-react-select"
                  />
                </>
                <button
                  onClick={(e) => handleSubmit(e)}
                  className="mt-4 text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                >
                  <i className="bx bx-folder-plus text-lg"></i>
                  <span className="hidden xs:block ml-2">
                    Create Sub Project
                  </span>
                </button>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${
          (showModal || showModalForDealerDialogue) === false ? "" : `blur-sm`
        } flex h-screen overflow-hidden visible`}
      >
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div
            id="alert-border-1"
            className="flex justify-between items-center p-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
            role="alert"
          >
            <div className="flex justify-center items-center">
              <svg
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div className="ml-2 text-sm font-medium">
                <strong>SubProject Creation Screen</strong>
              </div>
            </div>
            <div>
              <p className="flex justify-center items-center  font-semibold">
                <i className="bx bx-chevrons-right text-2xl"></i>
                {projectData?.title}
              </p>
            </div>
          </div>
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Welcome banner */}
              {/* <WelcomeBanner /> */}
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li className="inline-flex items-center">
                    <a
                      href="#"
                      className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                      </svg>
                      Home
                    </a>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <Link
                        to="/projects"
                        className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                      >
                        Projects
                      </Link>
                    </div>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                        Sub Projects
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
              {/* Dashboard actions */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Avatars */}
                {/* <DashboardAvatars /> */}
                <div className="w-full"></div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Filter button */}
                  {/* <FilterButton /> */}
                  {/* Datepicker built with flatpickr */}
                  {/* <Datepicker /> */}
                  {/* Add view button */}
                  <button
                    onClick={handleOpenForDealerDialogue}
                    className=" text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                  >
                    {state ? (
                      <i className="bx bx-spreadsheet text-lg"></i>
                    ) : (
                      <i className="bx bx-spreadsheet text-lg"></i>
                    )}
                    {/* <svg
                      className="w-4 h-4 fill-current opacity-50 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg> */}
                    <span className="hidden xs:block ml-2">
                      Excel Descriptor
                    </span>
                  </button>
                  <button
                    onClick={handleOpen}
                    className=" text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                  >
                    {state ? (
                      <FaMinus className="text-md" />
                    ) : (
                      <FaPlus className="text-md " />
                    )}
                    {/* <svg
                      className="w-4 h-4 fill-current opacity-50 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg> */}
                    <span className="hidden xs:block ml-2">
                      {state ? "Hide Form" : "Add Sub Project"}
                    </span>
                  </button>
                </div>
              </div>
              <div className="flex justify-between mt-8">
                <div>
                  Page {page} of {totalPages}
                </div>
                <div className="flex items-center gap-1">
                  {paginationButtons}
                </div>
              </div>
              <div className="flex flex-wrap items-start justify-start">
                {loading ? (
                  <div className="flex justify-center items-center m-auto">
                    <PuffLoader color="#6366f1" size={60} />
                  </div>
                ) : (
                  Array.isArray(data) && data.length !== 0 ? (
                  data.slice(startIndex, endIndex).map((project) => (
                    <div
                      key={project.id}
                      className="flex-shrink-0 m-5 relative overflow-hidden bg-[#6366f1] rounded-lg max-w-xs shadow-lg w-64"
                    >
                      <svg
                        className="absolute bottom-0 left-0 mb-8"
                        viewBox="0 0 375 283"
                        fill="none"
                        style={{ transform: "scale(1.5)", opacity: "0.1" }}
                      >
                        <rect
                          x="159.52"
                          y="175"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 159.52 175)"
                          fill="white"
                        />
                        <rect
                          y="107.48"
                          width="152"
                          height="152"
                          rx="8"
                          transform="rotate(-45 0 107.48)"
                          fill="white"
                        />
                      </svg>
                      <div className="relative  px-20 flex items-center justify-center mt-2 mr-2">
                        {/* Additional content here */}
                        <div className="absolute top-0 right-0 m-2 ">
                          <button
                            onClick={() => handleDelete(project.id)} // Replace handleDelete with your actual delete function
                            className="p-1 bg-white rounded-full text-[#6366f1] text-xs font-bold leading-none flex items-center"
                          >
                            <BsTrashFill className="text-lg" />
                          </button>
                        </div>
                      </div>
                      <div className="relative text-white px-3 pb-6 mt-8">
                        <Tooltip followCursor title={project.title}>
                          <span
                            className="block font-semibold text-xl"
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) =>
                              handleUpdateTitle(
                                project.id,
                                e.target.textContent
                              )
                            }
                          >
                            {project.title.length > 13
                              ? `${project.title.slice(0, 12)}...`
                              : project.title}
                          </span>
                        </Tooltip>
                        <div className="flex justify-between mt-2">
                          <Tooltip followCursor title={project.description}>
                            <span className="block opacity-75">
                              {project.description.length > 20
                                ? `${project.description.slice(0, 15)}...`
                                : project.description}
                            </span>
                          </Tooltip>
                          <button
                            // onClick={() =>
                            //   navigate(`/audits/list/${project.id}`)
                            // }
                            onClick={() => handleNavigation(project.id)}
                            className="block bg-white rounded-full text-[#6366f1] text-xs font-bold px-3 py-2 leading-none flex items-center"
                          >
                            Open
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center w-full mt-8">
                    <p className="text-gray-500">No sub-projects available.</p>
                  </div>
                )
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default SubProject;
