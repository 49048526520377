import { AuthProvider } from "hooks/useAuth";
import React, { createContext, useState } from 'react';
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Routes from "./Router";
import "assets/scss/index.scss";
import "react-toastify/dist/ReactToastify.css";
import { Fragment } from "react";
import "../index.css"
import { AppProvider } from "context/AppContext";
import ProtectedRoute from "./ProtectedRoute";
import { HoverProvider } from 'context/HoverContext';
const Router = () => {
  return (
    <GoogleOAuthProvider clientId="770789705935-vndsbh7qpdgs2f4h51gtpdqdaubtl35q.apps.googleusercontent.com">
      <HoverProvider>
      <AppProvider>
        <Fragment>
          <HashRouter>
            <Routes />
          </HashRouter>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            pauseOnHover
            closeButton
          />
        </Fragment>
      </AppProvider>
      </HoverProvider>
    </GoogleOAuthProvider>
  );
};

export default Router;
