import React, { Fragment, useState, useEffect } from "react";
import styles from "./FormBuilder.module.scss";
import Sidebar from "Dashboard/partials/Sidebar";
import XLSX from "xlsx";
import Header from "Dashboard/partials/Header";
import DropDown from "components/DropDown";
import { darkMode } from "../css/tailwind.config";
import { Link, useNavigate } from "react-router-dom";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import makeAnimated from "react-select/animated";
import { AiOutlineAudit } from "react-icons/ai";
import { PuffLoader } from "react-spinners";
const animatedComponents = makeAnimated();
const MasterSetup = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [levelItems, setLevelItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropdownsData, setDropdownsData] = useState([]);
  const [dropdownsData1, setDropdownsData1] = useState([]);
  const [dropdownsData2, setDropdownsData2] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [showModal2, setShowModal2] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentUserData, setCurrentUserData] = useState();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [selectedMasterOption, setSelectedMasterOption] = useState([]);

  const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

  // This function is called when the user selects an option
  const handleMasterSelectChange = (selectedOptions) => {
    setSelectedMasterOption(selectedOptions);
  };
  const optionsMasterItems = [
    // Your other options go here
    { value: "default", label: "Master Template", isDisabled: true },
  ];
  useEffect(() => {
    const userId = sessionStorage.getItem("user");
    if (userId) {
      const response = axios
        .get(`${BASE_URL}/user-details/${userId}`)
        .then((response) => {
          console.log("this is a user res", response.data);
          setCurrentUserData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  console.log("thisis546", selectedOption, selectedOption1);
  const token = sessionStorage.getItem("auth-token");
  const config = {
    headers: {
      "auth-token": token,
    },
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("excelFile", file);
      if (!file) {
        toast.error("Excel file does not exist.", {
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
        setLoading(false);
        return;
      }
      await axios.post(`${BASE_URL}/api/master_excel_upload`, formData, {
        headers: {
          "auth-token": config,
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setShowModal2(false);
      toast("Excel file uploaded successfully", {
        type: "success",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    } catch (error) {
      console.error("Error uploading Excel file:", error);
      toast(`Error uploading Excel file ${error.response.data}`, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      setLoading(false);
    }
  };

  const filteredData = masterData.filter(
    ({ companyName, address, contactPerson, authorizedPerson }) => {
      const query = searchQuery.toLowerCase();
      return (
        companyName.toLowerCase().includes(query) ||
        address.toLowerCase().includes(query) ||
        contactPerson?.name.toLowerCase().includes(query) ||
        contactPerson?.designation.toLowerCase().includes(query) ||
        contactPerson?.contactNo.toLowerCase().includes(query) ||
        contactPerson?.emailId.toLowerCase().includes(query) ||
        authorizedPerson?.name.toLowerCase().includes(query) ||
        authorizedPerson?.designation.toLowerCase().includes(query) ||
        authorizedPerson?.authorizedNo.toLowerCase().includes(query) ||
        authorizedPerson?.emailId.toLowerCase().includes(query)
      );
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/master_setup/masters`
        );
        setMasterData(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [setMasterData, showModal2]);
  const optionsLevelItems = [
    { label: "OEM", value: 1 },
    { label: "Dealer Group", value: 2 },
    { label: "Dealer", value: 3 },
  ];
  const [formData, setFormData] = useState({
    OEM_Code: "",
    companyName: "",
    address: "",
    contactPerson: {
      name: "",
      designation: "",
      contactNo: "",
      emailId: "",
    },
    authorizedPerson: {
      name: "",
      designation: "",
      authorizedNo: "",
      emailId: "",
    },
  });
  const [formErrors, setFormErrors] = useState({
    OEM_Code: "",
    companyName: "",
    address: "",
    contactPerson: {
      name: "",
      designation: "",
      contactNo: "",
      emailId: "",
    },
    authorizedPerson: {
      name: "",
      designation: "",
      authorizedNo: "",
      emailId: "",
    },
  });

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "companyName") {
      if (!value) {
        setFormErrors({
          ...formErrors,
          [name]: "Company Name is required",
        });
      } else {
        setFormErrors({
          ...formErrors,
          companyName: "",
        });
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  function flattenObject(ob) {
    const result = {};

    for (const i in ob) {
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = flattenObject(ob[i]);
        for (const x in temp) {
          result[i + "." + x] = temp[x];
        }
      } else {
        result[i] = ob[i];
      }
    }
    console.log("result in flattern object master data check", result);
    return result;
  }
  const handleExcelDownload = () => {
    // Function to remove specific keys from an object
    const removeKeys = (obj, keysToRemove) => {
      for (const key of keysToRemove) {
        delete obj[key];
      }
      return obj;
    };

    // Define the keys to be removed
    const keysToRemove = ["_id", "__v", "createdAt", "updatedAt"];

    // Flatten the data and remove specific keys
    const flattenedData = dropdownsData.map((obj) =>
      // console.log("master setup tabel data formate check",obj);
      removeKeys(flattenObject(obj), keysToRemove)
    );
    const flattenedData1 = dropdownsData1.map((obj) =>
      removeKeys(flattenObject(obj), keysToRemove)
    );
    const flattenedData2 = dropdownsData2.map((obj) =>
      removeKeys(flattenObject(obj), keysToRemove)
    );

    const wb = XLSX.utils.book_new();

    // Worksheet for flattenedData
    const ws = XLSX.utils.json_to_sheet(flattenedData);
    XLSX.utils.book_append_sheet(wb, ws, "OEM");

    // Worksheet for flattenedData1
    const ws1 = XLSX.utils.json_to_sheet(flattenedData1);
    XLSX.utils.book_append_sheet(wb, ws1, "Dealer Group");

    // Worksheet for flattenedData2
    const ws2 = XLSX.utils.json_to_sheet(flattenedData2);
    XLSX.utils.book_append_sheet(wb, ws2, "Dealer");

    // Create Excel buffer and blob
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}`;
    // Create a link and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `masterdata-${formattedDate}.xlsx`;
    a.click();

    // Revoke the URL
    URL.revokeObjectURL(url);
  };

  const handleContactPersonInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "contactNo") {
      const phoneRegex = /^[0-9]{10}$/;

      if (!value || !phoneRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          [name]: "Invalid phone number",
        });
      } else {
        setFormErrors({
          ...formErrors,
          contactNo: "",
        });
      }
    }

    if (name === "emailId") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!value || !emailRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          [name]: "Invalid email address",
        });
      } else {
        setFormErrors({
          ...formErrors,
          emailId: "",
        });
      }
    }

    setFormData({
      ...formData,
      contactPerson: {
        ...formData.contactPerson,
        [name]: value,
      },
    });
  };

  const handleAuthorizedPersonInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "authorizedNo") {
      const phoneRegex = /^[0-9]{10}$/;

      if (!value || !phoneRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          authorizedNo: "Invalid phone number",
        });
      } else {
        setFormErrors({
          ...formErrors,
          authorizedNo: "", // Clear the error message if the field is valid
        });
      }
    }

    if (name === "emailId") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!value || !emailRegex.test(value)) {
        setFormErrors({
          ...formErrors,
          emailId: "Invalid email address",
        });
      } else {
        setFormErrors({
          ...formErrors,
          emailId: "", // Clear the error message if the field is valid
        });
      }
    }

    setFormData({
      ...formData,
      authorizedPerson: {
        ...formData.authorizedPerson,
        [name]: value,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleCreate = async () => {
    if (
      !formData.OEM_Code ||
      !formData.companyName ||
      !formData.contactPerson.name ||
      !formData.contactPerson.contactNo ||
      !formData.contactPerson.emailId
    ) {
      return;
    }
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/api/master_setup/master`,
        formData,
        config
      );
      if (response.status === 201 && response.statusText === "Created") {
        toast("OEM Created Successfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
        setMasterData([...masterData, response.data]);
        setFormData({
          OEM_Code: "",
          companyName: "",
          address: "",
          contactPerson: {
            name: "",
            designation: "",
            contactNo: "",
            emailId: "",
          },
          authorizedPerson: {
            name: "",
            designation: "",
            authorizedNo: "",
            emailId: "",
          },
        });
      }
      setShowModal(false);
    } catch (error) {
      console.error("Error creating entry:", error);
      toast(`Error Creating OEM ${error.response.data}`, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    }
  };
  const handleEdit = (item) => {
    setFormData({
      OEM_Code: item.OEM_Code,
      companyName: item.companyName,
      address: item.address,
      contactPerson: {
        name: item?.contactPerson?.name,
        designation: item?.contactPerson?.designation,
        contactNo: item?.contactPerson?.contactNo,
        emailId: item?.contactPerson?.emailId,
      },
      authorizedPerson: {
        name: item?.authorizedPerson?.name,
        designation: item?.authorizedPerson?.designation,
        authorizedNo: item?.authorizedPerson?.authorizedNo,
        emailId: item?.authorizedPerson?.emailId,
      },
    });
    setEditData(item);
    setShowModal(true);
    setEditMode(true);
  };
  const handleUpdateMaster = async () => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.put(
        `${BASE_URL}/api/master_setup/master/${editData._id}`,
        formData,
        config
      );
      setMasterData((prevData) =>
        prevData.map((entry) =>
          entry._id === response.data._id ? response.data : entry
        )
      );
      setEditMode(false);
      setEditData(null);
      setFormData({
        OEM_Code: "",
        companyName: "",
        address: "",
        contactPerson: {
          name: "",
          designation: "",
          contactNo: "",
          emailId: "",
        },
        authorizedPerson: {
          name: "",
          designation: "",
          authorizedNo: "",
          emailId: "",
        },
      });
      setShowModal(false);
      toast("OEM Updated Successfully!", {
        type: "success",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    } catch (error) {
      console.error("Error updating entry:", error);
      toast(`Error Updating OEM ${error.response.data}`, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    }
  };

  const handleDelete = async (id,OEM_Code) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      if (currentUserData?.category.category === "Office Coordinator") {
        toast("Access Denied!.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white",
        });
      } else {
        const response = await axios.delete(
          `${BASE_URL}/api/master_setup/master/${id}/${OEM_Code}`,
          config
        );
        console.log("Delete Response:", response);
        setMasterData(masterData.filter((entry) => entry._id !== id));
        setShowModal(false);
        toast("OEM Deleted Successfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
    } catch (error) {
      toast(error.response.data.message, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      console.error("Error deleting entry:", error);
    }
  };

  console.log("this is the master response", masterData);

  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const totalPages = filteredData
    ? Math.ceil(filteredData.length / itemsPerPage)
    : 0;

  const paginationButtons = [];
  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );

  console.log("thisisddfjkghdf", levelItems);
  const downloadExcelTemplate = async (e) => {
    try {
      // Make an Axios GET request to the server to generate and fetch the Excel file.
      const response = await axios.get(
        `${BASE_URL}/api/generateExcelAndUpload`
      );

      const downloadUrl = response.data.sasUrl;
      console.log("resdata", response);
      // Create a link element to trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.download = "output.xlsx"; // Specify the desired file name
      downloadLink.style.display = "none"; // Hide the link

      // Add the link to the DOM and trigger the click event
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the link from the DOM
      document.body.removeChild(downloadLink);
    } catch (error) {
      // Handle any network or other errors here
      console.error(
        "An error occurred while downloading Excel template:",
        error
      );
    }
  };
  useEffect(() => {
    fetchInstacnes();
  }, [selectedOption, selectedOption1, showModal2]);
  const fetchInstacnes = async () => {
    try {
      // setLoading(true);/
      const response = await axios.get(`${BASE_URL}/api/master_setup/masters`);
      // setLoading(false);
      console.log("this is a response from master", response.data);
      //setDataOriginal(response.data);
      // const filteredData = response.data.filter((project) => {
      //   if (pathofUI.length === 0) {
      //     return project.atLevel === selectedOption.atLevel;
      //   }
      //   for (let i = 0; i < project.path.length; i++) {
      //     if (project.path[i] !== pathofUI[i]) {
      //       return false;
      //     }
      //   }
      //   return true;
      // });
      //setData(response.data);

      //console.log("this is a gilterd data from pro", filteredData);
      setDropdownsData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDealerGroups();
  }, [selectedOption]);
  const fetchDealerGroups = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/dealer_group/dealergroups`
      );
      console.log("this is a response from dealer group", response.data);
      setDropdownsData1(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDealer();
  }, [selectedOption, showModal2]);
  const fetchDealer = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/dealer3/dealers`);
      console.log("this is a response from dealer", response.data);
      setDropdownsData2(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleLevelChange = (event) => {
    const { value, label, atLevel } = event;

    setSelectedOption({ value, label, atLevel });
  };
  const handleLevelChange1 = (event) => {
    const { value, label, atLevel } = event;

    setSelectedOption1({ value, label, atLevel });
  };
  const options = dropdownsData.map((form) => ({
    value: form._id,
    label: form.companyName,
    atLevel: form.atLevel,
  }));
  const options2 = dropdownsData1.map((form) => ({
    value: form._id,
    label: form.dealergroupname,
    atLevel: form.atLevel,
  }));
  // console.log("selecte",selectedOption1)
  // const dropdownData = (levelItems) => {
  //   // Determine the label based on the selected option's atLevel
  //   let label = "";
  //   if (levelItems.length > 0) {
  //     label = levelItems[0].label;
  //   }

  //   if (label === "OEM") {
  //     return dropdownsData.map((form) => ({
  //       value: form._id,
  //       label: form.companyName,
  //       atLevel: form.atLevel,
  //     }));
  //   } else if (label === "Dealer Group") {
  //     return dropdownsData1.map((form) => ({
  //       value: form._id,
  //       label: form.dealergroupname,
  //       atLevel: form.atLevel,
  //     }));
  //   } else if (label === "Dealer") {
  //     return dropdownsData2.map((form) => ({
  //       value: form._id,
  //       label: form.dealer_Name,
  //       atLevel: form.atLevel,
  //     }));
  //   } else {
  //     return [];
  //   }
  // };

  // const options2 = dropdownData(levelItems);

  console.log("this is the keys", masterData);
  return (
    <div>
      <div className="flex h-screen overflow-hidden">
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div
            id="alert-border-1"
            className="flex items-center p-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
            role="alert"
          >
            <svg
              className="flex-shrink-0 w-4 h-4"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div className="ml-3 text-sm font-medium">
              <strong>OEM Setup Screen</strong>
            </div>
          </div>
          {/* <WelcomeBanner /> */}
          {currentUserData?.category?.category === "Office Auditor" ? (
            <div className="flex justify-center items-center h-screen">
              <div className="text-center">
                <h1 className="text-4xl font-bold mb-4">Access Denied</h1>
              </div>
            </div>
          ) : (
            <div>
              <div className="grid grid-flow-col sm:auto-cols-max  sm:justify-end gap-2 m-5 justify-center items-center">
                <div className="relative">
                  <input
                    type="search"
                    className="px-4 py-2 w-64 h-10 rounded-l-lg rounded-r-lg border border-gray-300 dark:border-gray-500 dark:border-[#374151] bg-white text-gray-800 placeholder-gray-400 focus:outline-none dark:bg-transparent dark:text-white dark:placeholder-gray-500"
                    aria-label="Search"
                    placeholder="Search.."
                    name="search"
                    autoComplete="false"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />

                  {/* Search button */}
                  <button
                    className="absolute inset-y-0 right-0 px-4 h-10 text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-500 rounded-r-lg  focus:border-primary-400 flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 py-2 text-center"
                    type="button"
                    id="button-addon1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex justify-center items-center">
                  <button
                    onClick={handleExcelDownload}
                    className=" text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                  >
                    <i className="bx bx-spreadsheet text-xl mr-2"></i>
                    Download Master Data
                  </button>
                  <button
                    onClick={() => {
                      setShowModal2(true);
                    }}
                    className="mx-2 text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                  >
                    <i className="bx bx-spreadsheet text-xl mr-2"></i>
                    Excel Upload
                  </button>

                  <button
                    className=" text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                    onClick={() => {
                      setEditMode(false);
                      setEditData(null);
                      setShowModal(true);
                      setFormData({
                        companyName: "",
                        address: "",
                        contactPerson: {
                          name: "",
                          designation: "",
                          contactNo: "",
                          emailId: "",
                        },
                        authorizedPerson: {
                          name: "",
                          designation: "",
                          authorizedNo: "",
                          emailId: "",
                        },
                      });
                    }}
                  >
                    <i className="bx bx-plus text-xl"></i>
                    <span className="hidden xs:block ml-2">Add New OEM</span>
                  </button>
                </div>
              </div>
              <nav className="flex mx-6" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li className="inline-flex items-center">
                    <a
                      href="#"
                      className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                      </svg>
                      Home
                    </a>
                  </li>
                  {/* <li>
                    <div className="flex items-center">
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <Link
                        to="/projects"
                        className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                      >
                        Projects
                      </Link>
                    </div>
                  </li> */}
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                        OEM Data
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
              <div className="flex justify-between mt-8 mx-6">
                <div>
                  Page {page} of {totalPages}
                </div>
                <div className="flex items-center gap-1">
                  {paginationButtons}
                </div>
              </div>

              <div
                className="mt-5 mx-5 shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 text-center"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                <table className="w-full text-sm text-center  text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 whitespace-nowrap">
                    <tr>
                      <th className="py-0">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Actions
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md text-gray-800 dark:text-white">
                          OEM Code
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md text-gray-800 dark:text-white">
                          company Name
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Address
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Contact Person Name
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Contact Person Designation
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Contact Person Contact No
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Contact Person Email Id
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Authorised Person name
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Authorised Person Designation
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Authorised Person Authorised No
                        </span>
                      </th>
                      <th className="py-3 px-6">
                        <span className="text-md  text-gray-800 dark:text-white">
                          Authorised Person Email Id
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length === 0 ? (
                      <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td colSpan={16} align="center">
                          <span className="text-gray-500 dark:text-gray-400">
                            No Records Found
                          </span>
                        </td>
                      </tr>
                    ) : (
                      filteredData
                        .slice(startIndex, endIndex)
                        .map(
                          ({
                            _id,
                            OEM_Code,
                            companyName,
                            address,
                            contactPerson,
                            authorizedPerson,
                          }) => {
                            return (
                              <Fragment key={_id}>
                                <tr
                                  className="bg-white border-b dark:bg-transparent cursor-pointer dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                                  // onClick={() => navigateToForm(_id)}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(
                                      `/dealer_group/${OEM_Code}/${_id}`
                                    );
                                  }}
                                  //className="cursor-pointer group hover:bg-gray-100 dark:hover:bg-slate-800"
                                  //key={masterData._id}
                                >
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div>
                                      <i
                                        id={`dropdown-${_id}`}
                                        className="bx-dots-vertical-rounded text-gray-500 dark:text-gray-400 cursor-pointer"
                                        onClick={(e) => e.stopPropagation()}
                                      ></i>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {OEM_Code}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {companyName}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {address}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {contactPerson?.name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {contactPerson?.designation}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {contactPerson?.contactNo}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {contactPerson?.emailId}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {authorizedPerson?.name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {authorizedPerson?.designation}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {authorizedPerson?.authorizedNo}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    {authorizedPerson?.emailId}
                                  </td>

                                  
                                </tr>
                                {/* {masterData.map((item) => ( */}
                                {/* <div key={item._id}> */}
                                {/* Render item details */}
                                <DropDown
                                  selector={`#dropdown-${_id}`}
                                  placement="bottom"
                                  className={styles.dropdown}
                                >
                                  <DropDown.Item>
                                    <button
                                      className="flex justify-center items-center"
                                      onClick={() => {
                                        navigate(
                                          `/dealer_group/${OEM_Code}/${_id}`
                                        );
                                      }}
                                    >
                                      <i className="bx-link-external"></i>
                                      <span className="ml-2">
                                        Dealer Group Setup
                                      </span>
                                    </button>
                                  </DropDown.Item>
                                  <DropDown.Item
                                    onClick={() =>
                                      handleEdit({
                                        _id,
                                        OEM_Code,
                                        companyName,
                                        address,
                                        contactPerson,
                                        authorizedPerson,
                                      })
                                    }
                                  >
                                    <button className="flex justify-center items-center">
                                      <i className="bi bi-pencil-square"></i>
                                      <span className="ml-2">Update</span>
                                    </button>
                                  </DropDown.Item>

                                  <DropDown.Item
                                    onClick={() => handleDelete(_id,OEM_Code)}
                                  >
                                    <button className="flex justify-center items-center">
                                      <i className="bx-trash"></i>
                                      <span className="ml-2">Delete</span>
                                    </button>
                                  </DropDown.Item>
                                </DropDown>
                                {/* </div> */}
                                {/* ))} */}
                              </Fragment>
                            );
                          }
                        )
                    )}
                  </tbody>
                </table>
              </div>

              <div
                className={`bg-white dark:bg-slate-900 ${
                  darkMode ? styles.form2 : styles.form
                }`}
              ></div>
            </div>
          )}
          <div className="max-w-screen-xl mx-auto px-4 md:px-8"></div>
        </div>
        {/*modal*/}
      </div>
      <div>
        {showModal2 && (
          <div
            id="crypto-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full backdrop-blur-sm backdrop-filter bg-opacity-50 bg-black transition-opacity ease-in-out duration-300"
          >
            <div className="relative w-full max-w-md max-h-full">
              {/* Modal content */}
              <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
                <button
                  type="button"
                  onClick={() => {
                    setShowModal2(false);
                    setLoading(false);
                    setFile(null);
                  }}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="crypto-modal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                {/* Modal header */}
                <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                    Bulk Master Data Setup
                  </h3>
                </div>
                {/* Modal body */}
                <div className="p-6">
                  {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <label
                        htmlFor="email"
                        className="block text-[15px] font-medium text-gray-900 dark:text-white"
                      >
                        Download Excel Template
                      </label>
                      <Select
                        isMulti={true}
                        defaultValue={[optionsMasterItems[0]]} // Set the default option
                        onChange={handleMasterSelectChange}
                        options={optionsMasterItems}
                        placeholder="Select Audit Items"
                        className="my-react-select-container mt-2 mb-4 rounded-md dark:border-gray-600"
                        classNamePrefix="my-react-select"
                      />
                      <button
                        type="button"
                        onClick={(e) => {
                          downloadExcelTemplate(e);
                        }}
                        className="text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                      >
                        <i className="bx bxs-download text-xl mr-1"></i>Download
                        Template
                      </button>
                      {/* <br /> */}
                      <br />
                      <hr />
                      <br />
                      {/* <br /> */}
                      {/* <Select
                        components={animatedComponents}
                        isMulti={true}
                        options={optionsLevelItems}
                        placeholder="Select level to upload"
                        className="my-react-select-container mt-6 mb-2 rounded-md  dark:border-gray-600"
                        classNamePrefix="my-react-select"
                        onChange={(e) => {
                          setLevelItems(e);
                        }}
                      /> */}
                      {levelItems.map((item) => (
                        <div key={item.value}>
                          {item.label === "Dealer Group" && (
                            <>
                              <Select
                                onChange={(event) => {
                                  handleLevelChange1(event);
                                }}
                                options={options}
                                placeholder="Select OEM name"
                                className="my-react-select-container mt-4  rounded-md  dark:border-gray-600"
                                classNamePrefix="my-react-select"
                                components={
                                  {
                                    // DropdownIndicator: () => (
                                    // <span className="mx-4">
                                    //   {" "}
                                    //   {/* {selectedOption === null */}
                                    //     ? "OEM"
                                    //     : `${selectedOption?.label}`}
                                    // </span>
                                    // ),
                                  }
                                }
                              />
                            </>
                          )}
                          {item.label === "Dealer" && (
                            <>
                              <Select
                                onChange={(event) => {
                                  handleLevelChange(event);
                                }}
                                options={options2}
                                placeholder="Select dealer group name"
                                className="my-react-select-container mt-4 mb-3 rounded-md  dark:border-gray-600"
                                classNamePrefix="my-react-select"
                                components={
                                  {
                                    // DropdownIndicator: () => (
                                    // <span className="mx-4">
                                    //   {" "}
                                    //   {/* {selectedOption === null */}
                                    //     ? "OEM"
                                    //     : `${selectedOption?.label}`}
                                    // </span>
                                    // ),
                                  }
                                }
                              />
                            </>
                          )}
                        </div>
                      ))}
                      <label
                        htmlFor="email"
                        className="block mb-2 text-[15px] font-medium text-gray-900 dark:text-white"
                      >
                        Upload an Excel File
                      </label>
                      <input
                        type="file"
                        accept=".xlsx"
                        onChange={handleFileChange}
                        id="file"
                        // disabled={isLoadingReq}
                        // onChange={(e) => handleFileUpload(e)}
                        className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                      />
                      <button
                        className="mt-4 text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                        onClick={(e) => {
                          handleUpload(e);
                        }}
                      >
                        <i className="bx bx-cloud-upload text-xl mr-1 "></i>
                        {loading ? (
                          <PuffLoader color="#fff" size={20} />
                        ) : (
                          "Upload and Process"
                        )}
                      </button>
                    </form>
                  </>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showModal && (
          <div
            id="crypto-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="
      fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[999] backdrop-blur-md backdrop-filter bg-opacity-50 bg-black transition-opacity ease-in-out duration-300
    "
          >
            <div className="bg-white dark:bg-[#1e293b] w-full max-w-[520px] rounded-lg shadow-lg overflow-hidden">
              <button
                type="button"
                onClick={() => {
                  setShowModal(false);
                  // setEditMode(false);
                }}
                className="absolute top-2 right-2 text-gray-600 dark:text-gray-300 rounded-full p-2 hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-300"
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                  {editMode ? "Edit OEM" : "Add OEM"}
                </h3>
                <hr />
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-2 gap-4 mt-2"
                >
                  <div>
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      OEM Code
                      <span className="text-red-500 "> *</span>
                    </label>
                    <input
                      type="text"
                      id="OEM_Code"
                      required
                      name="OEM_Code"
                      value={formData.OEM_Code}
                      onChange={handleInputChange}
                      className="w-full border h-10 border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Company Name
                      <span className="text-red-500 "> *</span>
                    </label>
                    <input
                      type="text"
                      required
                      id="companyName"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleInputChange}
                      className="w-full border h-10 border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={formData?.address}
                      onChange={handleInputChange}
                      className="w-full border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="contactName"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Contact Person Name<span className="text-red-500 "> *</span>
                    </label>
                    <input
                      type="text"
                      id="contactName"
                      required
                      name="name"
                      value={formData?.contactPerson?.name}
                      onChange={handleContactPersonInputChange}
                      className="w-full border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="contactDesignation"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Contact Person Designation
                    </label>
                    <input
                      type="text"
                      id="contactDesignation"
                      name="designation"
                      value={formData?.contactPerson?.designation}
                      onChange={handleContactPersonInputChange}
                      className="w-full border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="contactNo"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Contact Person Contact No<span className="text-red-500 "> *</span>
                    </label>
                    <input
                      required
                      type="text"
                      id="contactNo"
                      name="contactNo"
                      value={formData?.contactPerson?.contactNo}
                      onChange={handleContactPersonInputChange}
                      className="w-full border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="contactEmail"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Contact Person Email ID<span className="text-red-500 "> *</span>
                    </label>
                    <input
                      type="text"
                      required
                      id="contactEmail"
                      name="emailId"
                      value={formData?.contactPerson?.emailId}
                      onChange={handleContactPersonInputChange}
                      className="w-full border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="authorizedName"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Authorized Person Name
                    </label>
                    <input
                      type="text"
                      id="authorizedName"
                      name="name"
                      value={formData?.authorizedPerson?.name}
                      onChange={handleAuthorizedPersonInputChange}
                      className="w-full border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="authorizedDesignation"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Authorized Person Designation
                    </label>
                    <input
                      type="text"
                      id="authorizedDesignation"
                      name="designation"
                      value={formData?.authorizedPerson?.designation}
                      onChange={handleAuthorizedPersonInputChange}
                      className="w-full border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="authorizedNo"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Authorized Person Authorized No
                    </label>
                    <input
                      type="text"
                      id="authorizedNo"
                      name="authorizedNo"
                      value={formData?.authorizedPerson?.authorizedNo}
                      onChange={handleAuthorizedPersonInputChange}
                      className="w-full border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="authorizedEmail"
                      className="block text-sm font-medium text-gray-700 dark:text-white mb-2"
                    >
                      Authorized Person Email ID
                    </label>
                    <input
                      type="text"
                      id="authorizedEmail"
                      name="emailId"
                      value={formData?.authorizedPerson?.emailId}
                      onChange={handleAuthorizedPersonInputChange}
                      className="w-full h-10 dark:bg-transparent border border-gray-300 dark:border-gray-500 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark-bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  {/* <div className="col-span-2 text-red-500"> */}
                  {/* {fullnameError ||
                      emailError ||
                      passwordError ||
                      mobileError} */}
                  {/* </div> */}
                  <button
                    onClick={editMode ? handleUpdateMaster : handleCreate}
                    type="submit"
                    className="bg-[#4f46e5] hover:bg-indigo-800 text-white py-2 px-4 rounded-md mt-4 col-span-2 text-center transition duration-300
                     flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm"
                  >
                    {editMode ? "Update OEM" : "Create OEM"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MasterSetup;
