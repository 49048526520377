import React, { useState, useEffect } from "react";
import Avatar from "components/Avatar/userAvatar";
import { Link } from "react-router-dom";
import User01 from "../../images/user-36-01.jpg";
import User02 from "../../images/user-36-02.jpg";
import User03 from "../../images/user-36-03.jpg";
import User04 from "../../images/user-36-04.jpg";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

function DashboardAvatars() {
  const [value, setValue] = useState([]);
  const showAllData = () => {
    console.log("All Data");
    const token = sessionStorage.getItem("auth-token");

    axios
      .get(
        `${BASE_URL}/use-all-details`,
        token && {
          headers: {
            "auth-token": token,
          },
        }
      )
      .then((result) => {
        setValue(result.data);
        console.log("Resultdfdgljfdlkg", result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    showAllData();
  }, []);
  function createColorSequence() {
    const colorNames = ["red", "blue", "green", "orange", "purple", "pink"];
    const sequence = [];

    for (let i = 0; i < colorNames.length; i++) {
      sequence.push(colorNames[i]);
    }

    return sequence;
  }

  const colorSequence = createColorSequence();
  let currentIndex = 0;

  function getRandomColor() {
    const color = colorSequence[currentIndex];
    currentIndex = (currentIndex + 1) % colorSequence.length;
    return color;
  }

  return (
    <ul
      className="flex flex-wrap justify-center sm:justify-start mb-8 sm:mb-0 -space-x-3 -ml-px"
      style={{ overflowX: "auto" }}
    >
      {value &&
        value.slice(0, 6).map((item) => (
          <li key={item.id}>
            <Link className="block">
              <Avatar
                userName={`${item.fullname}`}
                backgroundColor={getRandomColor()}
              />
            </Link>
          </li>
        ))}
    </ul>
  );
}

export default DashboardAvatars;
