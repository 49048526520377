import { Fragment, useState, useRef, useEffect, CSSProperties } from "react";
import {
  FormDragRef,
  HandleDragOver,
  HandleDrop,
  HandleDragLeave,
  HandleDragEnter,
  HandleDragStart,
  ColorCodes,
  FormPages,
  FormDetail,
  FormSubmitData,
} from "types/Form";
import Section from "./Section";
import Field from "./Field/Field2";
import Header from "Dashboard/partials/Header";
import Responses from "./Responses/AuditItemResponse";
import useForm from "hooks/useForm";
import Modal from "components/Modal";
import useTitle from "hooks/useTitle";
import useAuth from "hooks/useAuth";
import { FormProvider } from "context/form";
import Sidebar from "Dashboard/partials/Sidebar";
import AuditHeader from "./Header/AuditHeader"
import WelcomeBanner from 'Dashboard/partials/dashboard/WelcomeBanner';
import { Link, useLocation, useParams,useNavigate } from "react-router-dom";
import { GrNext } from "react-icons/gr";
import { TfiViewListAlt } from "react-icons/tfi";
import {
  getFormById,
  sendResponse,
  updateResponse,
  updateFormById,
  checkAuditItemResponseStatus,
  getAuditItemResponses,
  getAuditItemResponseById,
} from "services/AuditItem";
import { setFormTheme, focusElement, isEmpty } from "utils";
import { toast } from "react-toastify";
import { PuffLoader } from 'react-spinners';
import styles from "./FormBuilder.module.scss";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;


let initialDragRef = {
  source: {
    droppableId: null,
    draggableId: null,
  },
  destination: {
    droppableId: null,
    draggableId: null,
  },
  dragElement: null,
};

const PreEdit = (formPage: FormPages) => {
  let location = useLocation();

  let searchParams = new URLSearchParams(location.search);

  let [selectedId, setSelectedId] = useState<string | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  let [isSubmited, setIsSubmited] = useState(false);

  let [isResponded, setIsResponded] = useState(false);
  const [audtiAllItemResponses, setAudtiAllItemResponses] = useState([]) as any;
  let [activeSection, setActiveSection] = useState<number>(0);
  const [userId, setUserId] = useState("") as any;
  let [dragId, setDragId] = useState<string | null>(null);

  let [isLoading, setIsLoading] = useState(true);

  let dragRef = useRef<FormDragRef>(initialDragRef);

  let focusFieldId = useRef<string | null>(null);

  let { formId } = useParams() as any;
  let { responseId } = useParams() as any;

  let { user, logout } = useAuth();

  let { isEdit, isView } = formPage;
  const [isDarkMode, setIsDarkMode] = useState(false);
  let navigate = useNavigate();

  const handleTheme101 = () => {
    localStorage.getItem("theme") === "dark" ? setIsDarkMode(true) : setIsDarkMode(false);
  };
  useEffect(() => {
    setUserId(sessionStorage.getItem("user"));
  }, []);

  useEffect(() => {
    handleTheme101();
  }, []);


  const updateForm = async (data: FormDetail) => {
    if (!formId) return;

    await updateFormById({ formId, data });
  };

  let form = useForm<FormDetail>({
    ...(isEdit && { onChange: updateForm }),
  });

  let { formData, setFormData, reset, handleSubmit } = form;
  console.log("this is the formdata from audit", formData);

  let { sections = [], colorCode, bgCode, title, } = formData;

  useTitle(title);

  useEffect(() => {
    getFormDetails();
    if (isView) getResponseStatus();
  }, [formId]);

  useEffect(() => {
    if (!focusFieldId.current) return;
    let element = document.querySelector(
      `[data-field-id='${focusFieldId.current}']`
    );
    if (element) focusElement(element);
    focusFieldId.current = null;
  }, [formData]);

  useEffect(() => {
    if (!colorCode || !bgCode) return;
    setFormTheme({ colorCode, bgCode });
    return () => {
      document.querySelector("html")!.removeAttribute("style");
    };
  }, [colorCode, bgCode]);

  const getResponseStatus = async () => {
    if (!formId) return;

    let {
      data: { status },
    } = await checkAuditItemResponseStatus(formId);
    setIsResponded(status);
  };

  const getFormDetails = async () => {
    if (!formId) return;

    try {
      const { data: formDetail } = await getFormById(formId);
      // if (isEdit && formDetail.creatorId !== user?._id) {
      //   toast("Form creator only have the edit access", { type: "error" });
      //   navigate.push("/form/list");
      // } else {
      setFormData(formDetail, false);
      // setProjectId(formDetail);
    }
    finally {
      if (isLoading) setIsLoading(false);
    }
  };

  const handleDragStart: HandleDragStart = (droppableId, draggableId) => {
    let dragElement = document.querySelector(
      `[data-draggable-id='${draggableId}'][data-droppable-id='${droppableId}']`
    ) as HTMLElement;

    dragRef.current = {
      ...dragRef.current,
      source: { droppableId, draggableId },
      dragElement,
    };
    setTimeout(() => {
      if (!dragElement) return;
      dragElement.style.visibility = "hidden";
    }, 0);
  };

  const handleDragEnter: HandleDragEnter = (
    event,
    droppableId,
    draggableId
  ) => {
    event.stopPropagation();
    dragRef.current = {
      ...dragRef.current,
      destination: { droppableId, draggableId },
    };
  };

  const handleDragLeave: HandleDragLeave = (
    event,
    droppableId,
    draggableId
  ) => {
    event.stopPropagation();
  };

  const handleDragEnd = () => {
    let { dragElement } = dragRef.current;

    if (dragElement) {
      dragElement.style.visibility = "visible";
    }
    setDragId(null);
    setTimeout(() => {
      dragRef.current = initialDragRef;
    }, 0);
  };

  const handleDrop: HandleDrop = () => {
    let { source, destination } = dragRef.current;

    if (
      typeof source.draggableId !== "number" ||
      typeof source.droppableId !== "number" ||
      typeof destination.draggableId !== "number" ||
      typeof destination.droppableId !== "number"
    )
      return;

    let { dragElement } = dragRef.current;
    let form = { ...formData };

    if (dragElement) {
      focusFieldId.current = dragElement.getAttribute("data-field-id");
    }

    form.sections[destination.droppableId].fields.splice(
      destination.draggableId,
      0,
      form.sections[source.droppableId].fields.splice(source.draggableId, 1)[0]
    );

    setFormData(form);
  };

  const handleDragOver: HandleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTheme = (theme: { colorCode: ColorCodes; bgCode: string }) => {
    setFormData({ ...formData, ...theme });
  };

  const onSubmit = (data: any, action: "next" | "back" | "submit") => {
    if (action === "next") {
      setActiveSection((section) => section + 1);
    } else if (action === "back") {
      setActiveSection((section) => section - 1);
    } else {
      submitResponse(data);
      setTimeout(() => {
        navigate(-1)
      }, 2000);
    }
  };

  const submitResponse = async (data: FormDetail) => {
    if (!formData._id) return;

    let body = {
      responses: getFormResponse(data),
      formId: formData._id,
      user: userId,
      platformId: "64ca3362a9fececfa92854b2",
      subAuditId: formData.auditId,
      auditItemFormId: formId,
    };
    await updateResponse({
      responseId, body
    });
    clearForm();
    setIsSubmited(true);
    setTimeout(() => {
      setIsSubmited(false);
    }
      , 2000);

  };

  const getFormResponse = (data: FormDetail): FormSubmitData[] => {
    let formData = data.sections.reduce((formData, section) => {
      section.fields.forEach(
        ({ response, fieldType, other, otherReason, _id, rules }) => {
          if (!_id) return;
          let data: FormSubmitData = {
            fieldId: _id,
            title: rules.unique?.value,
            response: null,
          };
          if (other && otherReason) {
            if (fieldType === "radio" && response === "Other") {
              data.response = `Other : ${otherReason}`;
            } else if (
              fieldType === "checkbox" &&
              Array.isArray(response) &&
              response.includes("Other")
            ) {
              data.response = [
                ...response.filter((val) => val !== "Other"),
                `Other : ${otherReason}`,
              ];
            }
          } else if (response) {
            if (response[0] && response[0].name) {
              handleUpload(response[0]);
              data.response = response[0].name;
            }
            else {
              data.response = response;
            }
          }
          formData.push(data);
        }
      );

      return formData;
    }, [] as FormSubmitData[]);

    return formData;
  };

  const onInvalid = (errors: any, action?: "next" | "back") => {
    if (action === "back") {
      setActiveSection((section) => section - 1);
    } else if (action === "next") {
      if (isEmpty(errors?.sections?.[activeSection])) {
        setActiveSection((section) => section + 1);
      }
    }
  };

  const clearForm = () => {
    reset();
    setActiveSection(0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleTitle = (title: string) => {
    if (!formId) return;
    let form = { ...formData, title };
    setFormData(form);
  };
  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);
    const token = sessionStorage.getItem("auth-token");
    const config = {
      headers: {
        "auth-token": token,
      },
    };
    try {
      const response = await axios.post(`${BASE_URL}/upload`, formData, {
        headers: {
          "auth-token": token,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("this is the response", response.data.file)
      return response.data.file;
    } catch (error) {
      console.log(error);
    }
  };
  const getAllAuditItemResponses = async () => {
    try {
      const response = await getAuditItemResponses();
      console.log("rejx", response.data);
      setAudtiAllItemResponses(response.data);
    }
    catch (error) {
      console.log("this is the error", error);
    }
  }

  useEffect(() => {
    getAllAuditItemResponses();
  }
    , [isSubmited]);
  const filteredItems = audtiAllItemResponses.filter((item: any) => item.auditItemFormId === formData._id && item.user === userId);
  const itemCount = filteredItems.length;
  console.log("this is the item count", itemCount, filteredItems, audtiAllItemResponses, formData._id);
  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/*  Site header */}
        {
          isEdit && (
            // <AuditHeader
            //   formId={formId}
            //   colorCode={colorCode}
            //   bgCode={bgCode}
            //   title={title}
            //   user={user}
            //   params={searchParams}
            //   navigate={navigate}
            //   logout={logout}
            //   handleTitle={handleTitle}
            //   handleTheme={handleTheme}
            //   sidebarOpen={sidebarOpen}
            //   setSidebarOpen={setSidebarOpen}
            // />
            <></>
          )
        }

        <main>
          <div className=" w-full max-w-9xl mx-auto">

            {/* <div className="sm:flex sm:justify-between sm:items-center dark:bg-[#182235] bg-white">


              <div className="grid grid-flow-col  sm:auto-cols-max justify-start sm:justify-end gap-2 mt-2">
              <nav className="flex mb-3 ml-7" aria-label="Breadcrumb" >
                          <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                              <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                Home
                              </a>
                            </li>
                            <li>
                              <div className="flex items-center">
                                <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                <Link to="/projects" className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Projects</Link>
                              </div>
                            </li>
                            <li>
                              <div className="flex items-center">
                                <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                <Link to="/projects" className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Audits</Link>
                              </div>
                            </li>
                            <li>
                              <div className="flex items-center">
                                <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                <Link to={`/audit_item/${formData.auditId}/list`} className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Audit Items</Link>
                              </div>
                            </li>
                            
                            <li aria-current="page">
                              <div className="flex items-center">
                                <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Sub Audit Item</span>
                              </div>
                            </li>
                          </ol>
                        </nav>
              </div>

            </div> */}

            {/* Cards */}
            <Fragment>
              {isEdit && (

                <></>
              )}
              {searchParams.get("tab") === "responses" ? (
                <Responses formId={formId} />
              ) : (
                <Fragment>
                  <FormProvider {...form}>
                    <div
                      className={styles.bg}
                      style={{ "--top": isEdit ? "111px" : "0px" } as CSSProperties}
                    >
                      {isLoading ? (
                        <div className="flex justify-center items-center m-auto h-screen bg-transparent">
                          <PuffLoader
                            color="#6366f1"
                            size={60}
                          />
                        </div>
                      ) : (
                        <div className={styles.container}>
                          {sections.map(
                            ({ _id, title, description, fields }, sectionIndex) => {
                              if (isView && !(sectionIndex === activeSection))
                                return null;

                              let sectionHeader =
                                sections.length > 1
                                  ? `Section ${sectionIndex + 1} of ${sections.length}`
                                  : undefined;

                              let isSelected = selectedId === sectionIndex.toString();

                              return (
                                <Fragment key={sectionIndex}>
                                  <Section
                                    title={title}
                                    selectedId={selectedId}
                                    description={description}
                                    sectionIndex={sectionIndex}
                                    sectionHeader={sectionHeader}
                                    formPage={formPage}
                                    isSelected={isSelected}
                                    onClick={() =>
                                      setSelectedId(sectionIndex.toString())
                                    }
                                  />
                                  <div
                                    className={styles.wrapper}
                                    {...(isEdit && {
                                      "data-droppable-id": sectionIndex,
                                      onDragEnter: (e) =>
                                        handleDragEnter(e, sectionIndex, 0),
                                      onDragLeave: (e) =>
                                        handleDragLeave(e, sectionIndex, 0),
                                      onDragOver: handleDragOver,
                                      onDrop: handleDrop,
                                    })}
                                  >
                                    {fields.map((field: any, fieldIndex) => {
                                      console.log("thisisfield", field)
                                      let fieldId = `${sectionIndex}${fieldIndex}`;
                                      let isSelected = selectedId === fieldId;
                                      if (field.isSwitchToAudit === true) {
                                        // If field.isSwitchToAudit is true, skip rendering the Field component
                                        return null;
                                      } else {
                                        return (
                                          <Field
                                            key={fieldId}
                                            field={field}
                                            fieldId={fieldId}
                                            tabIndex={-1}
                                            sectionIndex={sectionIndex}
                                            fieldIndex={fieldIndex}
                                            formPage={formPage}
                                            focusFieldId={focusFieldId}
                                            isSelected={isSelected}
                                            {...(isEdit && {
                                              "data-field-id": fieldId,
                                              "data-draggable-id": fieldIndex,
                                              "data-droppable-id": sectionIndex,
                                              draggable: dragId === fieldId,
                                              onClick: () => setSelectedId(fieldId),
                                              onDragStart: () =>
                                                handleDragStart(sectionIndex, fieldIndex),
                                              onDragLeave: (e) =>
                                                handleDragLeave(
                                                  e,
                                                  sectionIndex,
                                                  fieldIndex
                                                ),
                                              onDragEnter: (e) =>
                                                handleDragEnter(
                                                  e,
                                                  sectionIndex,
                                                  fieldIndex
                                                ),
                                              onDragEnd: handleDragEnd,
                                              setDragId: setDragId,
                                            })}
                                          />
                                        );
                                      }
                                    })}
                                  </div>
                                </Fragment>
                              );
                            }
                          )}
                          {isView && (
                            <div className={`${styles.cta}`}>
                              <div>
                                {activeSection > 0 && (
                                  <button
                                    className={styles.btn_navigate}
                                    onClick={handleSubmit(
                                      (data) => onSubmit(data, "back"),
                                      (errors) => onInvalid(errors, "back")
                                    )}
                                  >
                                    Back
                                  </button>
                                )}
                                {activeSection < sections.length - 1 && (
                                  <button
                                    className={styles.btn_navigate}
                                    onClick={handleSubmit(
                                      (data) => onSubmit(data, "next"),
                                      (errors) => onInvalid(errors, "next")
                                    )}
                                  >
                                    Next
                                  </button>
                                )}
                                {activeSection === sections.length - 1 && (
                                  <button
                                    className={styles.btn_submit}
                                    onClick={handleSubmit(
                                      (data) => onSubmit(data, "submit"),
                                      onInvalid
                                    )}
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                              <button className={styles.btn_clear} onClick={clearForm}>
                                Clear Form
                              </button>


                            </div>

                          )}
                          <div className={styles.footer}>
                            {/* <span>Audit Form</span> */}
                          </div>
                        </div>
                      )}
                    </div>

                  </FormProvider>
                  <Modal isOpen={isSubmited}>
                    <div className={styles.popup}>
                      <h2>Thank You!</h2>
                      {/* {isResponded && <span>You have already submitted the form</span>} */}
                      {isSubmited && (
                        <span>Your response have been saved successfully</span>
                      )}
                    </div>
                  </Modal>
                </Fragment>
              )}
            </Fragment>

          </div>
        </main>

        {/* <Banner /> */}

      </div>
    </div>

  );
};

export default PreEdit;
