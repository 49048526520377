import { Fragment, useEffect, useRef, useState } from "react";
import { createForm, getAllForms, deleteFormById } from "services/Form";
import { createPreForm, getAllPreForms, deletePreFormById } from "services/PreEditForm";
import { createPostForm, getAllPostForms, deletePostFormById } from "services/PostEditForm";
// import { getAllTemplates } from "services/Template";
import { FormData, PageMeta } from "types/Form";
import DropDown from "components/DropDown";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { googleFormIcon } from "utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Header from "./Header";
import Pagination from "components/Pagination";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./FormList.module.scss";
import { darkMode } from "Dashboard/css/tailwind.config";
import Sidebar from "Dashboard/partials/Sidebar";
import WelcomeBanner from 'Dashboard/partials/dashboard/WelcomeBanner';
import { PuffLoader } from "react-spinners";
import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const FormList = () => {
  const [forms, setForms] = useState<FormData[]>([]);

  const [pageMeta, setPageMeta] = useState({} as PageMeta);

  const [isDarkMode, setIsDarkMode] = useState(false);

  const [templates, setTemplates] = useState<FormData[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [currentUserData, setCurrentUserData] = useState() as any;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();


  const navigate = useNavigate();

  // const { projectId } = useParams<{ projectId: string }>();

  // console.log(projectId);
  // const { user, logout } = useAuth();
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };
  const totalPages = Math.ceil(forms.length / itemsPerPage);
  const paginationButtons = [];

  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
          }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
          }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );
  let formRef = useRef<HTMLTableElement>(null);

  const searchParams = new URLSearchParams(location.search);

  let search = searchParams.get("search");
  // let page = searchParams.get("page") || 1;

  // useEffect(() => {
  //   getTemplates();
  // }, []);

  useEffect(() => {
    getForms();
  }, [search, page]);

  // const getTemplates = async () => {
  //   let { data } = await getAllTemplates();
  //   setTemplates(data);
  // };
  useEffect(() => {
    const userId = sessionStorage.getItem("user");
    if (userId) {
      const response = axios
        .get(`${BASE_URL}/user-details/${userId}`)
        .then((response: any) => {
          console.log("this is a user res", response.data);
          setCurrentUserData(response.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, []);
  const getForms = async () => {
    try {
      let {
        data: { list, pageMeta },
      } = await getAllForms({ search });
      // const filterList = list.filter((form) => form.projectId === projectId);
      console.log("this is the list from index", list);
      setForms(list);
      setPageMeta(pageMeta);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };

  const handleCreateFrom = async (templateId?: string) => {
    let body = {
      // projectId: projectId,
      templateId: templateId ? templateId : undefined
    }
    let { data } = await createForm(body);
    // data.projectId = projectId;
    let form = [...forms];
    form.unshift(data);
    setForms(form);
    navigateToForm(data._id);
  };
  // const handleCreatePreFrom = async (templateId?: string) => {

  //   let body = {
  //     projectId: projectId,
  //     templateId: templateId ? projectId : undefined
  //   }
  //   let { data } = await createPreForm(body);
  //   data.projectId = projectId;
  //   let form = [...forms];
  //   form.unshift(data);
  //   setForms(form);
  //   // navigateToForm(data._id);
  // };
  // const handleCreatePostFrom = async (templateId?: string) => {
  //   let body = templateId ? { templateId } : undefined;
  //   let { data } = await createPostForm(body);
  //   let form = [...forms];
  //   form.unshift(data);
  //   setForms(form);
  //   // navigateToForm(data._id);
  // };

  const handleTheme = () => {
    localStorage.getItem("theme") === "dark" ? setIsDarkMode(true) : setIsDarkMode(false);
  };

  useEffect(() => {
    handleTheme();
  }, []);


  const navigateToForm = (formId: string) => {
    navigate(`/form/${formId}/edit`);
  };

  const handleDeleteForm = async (formId: string) => {
    if (!window.confirm("Are you sure to delete this form?")) return;

    let {
      data: { message },
    } = await deleteFormById(formId);
    let form = [...forms];
    toast(message, { type: "success", className: "dark:bg-gray-900 dark:text-white z-[1000]" });
    let index = form.findIndex(({ _id }) => _id === formId);
    form.splice(index, 1);
    setForms(form);
  };

  // const handlePageChange = (page: number) => {
  //   navigate({
  //     search:
  //       page !== 0
  //         ? `?page=${page + 1}${search ? `&search=${search}` : ""}`
  //         : "",
  //   });
  //   if (formRef.current) formRef.current.scrollIntoView({ behavior: "smooth" });
  // };
  // console.log(user)
  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        {/* <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

        <main>
          <div className=" w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            {/* <WelcomeBanner /> */}

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center ">

              {/* Left: Avatars */}
              {/* <DashboardAvatars /> */}

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Filter button */}
                {/* <FilterButton /> */}
                {/* Datepicker built with flatpickr */}
                {/* <Datepicker /> */}
                {/* Add view button */}
                {/* <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                        <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                </button>                 */}
              </div>

            </div>

            {/* Cards */}
            <div>
              <Fragment>
                <Header search={search} />
                <div
                  id="alert-border-1"
                  className="flex items-center p-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
                  role="alert"
                >
                  <svg
                    className="flex-shrink-0 w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                  </svg>
                  <div className="ml-3 text-sm font-medium">
                    <strong>Audit Setup Screen</strong>
                  </div>

                </div>

                {isLoading ? (
                  <div className="flex justify-center items-center m-auto h-screen">
                    <PuffLoader
                      color="#6366f1"
                      size={60}
                    />
                  </div>
                ) :
                  (

                    currentUserData?.category?.category === "Office Auditor" ?
                      (<div className="flex justify-center items-center h-screen">
                        <div className="text-center">
                          <h1 className="text-4xl font-bold mb-4">Access Denied</h1>
                        </div>
                      </div>

                      )


                      : (

                        <div className={styles.container}>



                          <div className={`dark:bg-slate-900 ${styles.template}`}>

                            <div className={`dark:bg-slate-900 ${styles.wrapper}`}>
                              <nav className="flex mb-3" aria-label="Breadcrumb">
                                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                  <li className="inline-flex items-center">
                                    <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                      <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                      Home
                                    </a>
                                  </li>
                                  {/* <li>
                              <div className="flex items-center">
                                <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                <Link to="/projects" className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Projects</Link>
                              </div>
                            </li> */}
                                  <li aria-current="page">
                                    <div className="flex items-center">
                                      <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                      <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Audit Templates</span>
                                    </div>
                                  </li>
                                </ol>
                              </nav>
                              <div className={isDarkMode ? `text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[24px]` : `${styles.title}`}>
                                <div className="text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[24px]">Start a new audit template</div>
                              </div>
                              <div className={styles.list}>
                                <div className={isDarkMode ? `text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[18px]` : `${styles.card}`} onClick={() => {
                                  handleCreateFrom();
                                  // handleCreatePreFrom();
                                  // handleCreatePostFrom();
                                }}>
                                  <img
                                    src={require("assets/images/add-icon.png")}
                                    className="cursor-pointer mb-[15px] rounded-lg"
                                    draggable={false}
                                  // style={{ filter: "hue-rotate(112deg)" }}
                                  />
                                  <div className="text-gray-700 dark:text-white mb-[15px] font-custom font-bold  text-[24px]">Blank</div>
                                </div>

                                {templates.map(({ _id, title }) => {
                                  return (
                                    <div className={styles.card} key={_id}>
                                      <img
                                        src={require(`assets/images/templates/${_id}.png`)}
                                        onClick={() => {
                                          handleCreateFrom(_id);
                                          // handleCreatePreFrom(_id);
                                          // handleCreatePostFrom(_id);
                                        }}
                                        draggable={false}
                                      />
                                      <span>{title}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>

                          <div className={`bg-white dark:bg-slate-900 ${darkMode ? styles.form2 : styles.form}`}>
                            <table ref={formRef} className={`${styles.wrapper} w-full`}>

                              <thead>
                                <tr>
                                  <th></th>
                                  <th className="py-3">
                                    <span className="text-lg text-gray-800 dark:text-white">Title</span>
                                  </th>
                                  <th className="py-3">
                                    <span className="text-lg  text-gray-800 dark:text-white">Created at</span>
                                  </th>
                                  <th className="py-3">
                                    <span className="text-lg  text-gray-800 dark:text-white">Last updated at</span>
                                  </th>
                                  <th className="py-3">
                                    <span className="text-lg  text-gray-800 dark:text-white">Action</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {forms.length === 0 ? (
                                  <tr aria-label="empty">
                                    <td colSpan={5} align="center">
                                      <span className="text-gray-500 dark:text-gray-400">No Records Found</span>
                                    </td>
                                  </tr>
                                ) : (
                                  forms.slice(startIndex, endIndex).map(({ title, _id, createdAt, updatedAt }) => {
                                    return (
                                      <Fragment key={_id}>
                                        <tr
                                          onClick={() => navigateToForm(_id)}
                                          className="cursor-pointer group hover:bg-gray-100 dark:hover:bg-slate-800"
                                        >
                                          <td>{googleFormIcon}</td>
                                          <td className="py-3">
                                            <span className="text-gray-900 dark:text-gray-100">{title}</span>
                                          </td>
                                          <td className="py-3">
                                            <span className="text-gray-500 dark:text-gray-400">
                                              {moment.tz(createdAt, "Asia/Kolkata").format("MMM D, YYYY")}
                                            </span>
                                          </td>
                                          <td className="py-3">
                                            <span className="text-gray-500 dark:text-gray-400">
                                              {moment.tz(updatedAt, "Asia/Kolkata").fromNow()}
                                            </span>
                                          </td>
                                          <td className="py-3">
                                            <div>
                                              <i
                                                id={`dropdown-${_id}`}
                                                className="bx-dots-vertical-rounded text-gray-500 dark:text-gray-400 hover:bg-slate-800"
                                                onClick={(e) => e.stopPropagation()}
                                              ></i>
                                            </div>
                                          </td>
                                        </tr>
                                        <DropDown
                                          selector={`#dropdown-${_id}`}
                                          placement="bottom"
                                          className={styles.dropdown}
                                        >
                                          <DropDown.Item onClick={() => handleDeleteForm(_id)}>
                                            <i className="bx-trash"></i>
                                            <span className="ml-2">Remove</span>
                                          </DropDown.Item>

                                        </DropDown>
                                      </Fragment>
                                    );
                                  })
                                )}
                              </tbody>

                            </table>

                            <div className="flex justify-center mt-8">
                              {/* <div>
                          Page {page} of {totalPages}
                        </div> */}
                              <div className="flex items-center gap-1">
                                {paginationButtons}
                              </div>
                            </div>
                          </div>

                        </div>

                      ))
                }
              </Fragment>


            </div>

          </div>
        </main>



      </div>
    </div>


  );
};


export default FormList;