import { ComponentProps, Fragment, useEffect, useRef, useState } from "react";
import { FormRegister } from "types/UseForm";

import styles from "./FileInput.module.scss";

type FileInputProps = {
  register?: ReturnType<FormRegister>;
} & ComponentProps<"input">;;

const FileInput = ({ className, register,
  ...props }: FileInputProps) => {
    console.log("thisipropsfromintername:",register)
  return (
    <div className={`${styles.field} ${className || ""}`.trim()}>
      <input
        type="file"
        accept="image/*"
        capture="environment"
        {...(register && register)}
        {...props}
      />
    </div>
  );
};

export default FileInput;
