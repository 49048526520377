import Avatar from "components/Avatar";
import React, { useState, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

function WelcomeBannerAudit({ auditId }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [assignedData, setAssignedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [subAuditItems, setSubAuditItems] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [value, setValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const options = subAuditItems.map((form) => {
    return { value: form._id, label: form.user };
  });
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    const filteredCards = assignedData.filter((data) =>
      data.fullname.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filteredCards);
  };
  const handleAssign = async () => {
    try {
      setLoading(true); // Set loading state to true before making the API call

      const response = await axios.get(
        `${BASE_URL}/api/subaudit`
      );
      console.log("this is the res4564", response);
      const filterData = response.data.data.filter(
        (data) => data.subAuditId === auditId
      );
      const filteredArray = value.filter((item) =>
        filterData.some((dataItem) => dataItem.user === item._id)
      );
      // Update the state variables
      setFilterData(filterData);
      setAssignedData(filteredArray);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Set loading state to false regardless of success or error
    }
  };

  useEffect(() => {
    handleAssign();
  }, [value]);
  useEffect(() => {
    const token = sessionStorage.getItem("auth-token");
    axios
      .get(`${BASE_URL}/use-all-details`, {
        headers: token ? { "auth-token": token } : undefined,
      })
      .then((result) => {
        // const filteredData = result.data.filter((data) => data.category._id === selectedOption2);
        // setValueOptions(filteredData);
        setValue(result.data);
        console.log("Result from subproject", result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleDelete = async (itemId) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      if (window.confirm("Are you sure you want to delete the user?")) {
        // Make the DELETE request
        await axios.delete(
          `${BASE_URL}/api/subaudit/${itemId}/${auditId}`,
          config
        );

        // Show the toast alert
        toast("User deleted successfully", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });

        // Update the UI by refreshing the data
        await handleAssign();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const hour = new Date().getHours();
  let greeting;

  if (hour < 12) {
    greeting = "Morning";
  } else if (hour < 16) {
    greeting = "Afternoon";
  } else {
    greeting = "Evening";
  }
  useEffect(() => {
    const fetchSubAudit = async () => {
      const response = axios
        .get(`${BASE_URL}/api/subaudit`)
        .then((result) => {
          console.log("this 9999999999999999", result.data.data);
          const filterData = result.data.data.filter(
            (data) => data.subAuditId === auditId && data.user !== null
          );
          setSubAuditItems(filterData);
          console.log("this is thexxxx ", filterData);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchSubAudit();
  }, []);

  return (
    <div className="relative bg-indigo-200 dark:bg-indigo-500 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      >
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#A5B4FC" offset="0%" />
              <stop stopColor="#818CF8" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="24.537%"
              x2="50%"
              y2="100%"
              id="welcome-c"
            >
              <stop stopColor="#4338CA" offset="0%" />
              <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-d)"
                d="M64-24h80v152H64z"
              />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-f)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-h)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="flex justify-between">
        <div>
          <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">
            Good {greeting}, VPS Group. 👋
          </h1>
          <p className="dark:text-indigo-200">
            Here is what’s happening with your projects today:
          </p>
        </div>
      </div>
    </div>
  );
}

export default WelcomeBannerAudit;
