import React, { useState } from "react";
import { useEffect } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { addDataToModel, deleteData } from "./jsonToBackendApi";
import DisplayInfo from "./DisplayInfo";
import { FaDownload, FaTrashAlt } from "react-icons/fa";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { getAllForms, getFormById } from "services/AuditItem";
import Sidebar from "Dashboard/partials/Sidebar";
import Header from "Dashboard/partials/Header";
import WelcomeBanner from "Dashboard/partials/dashboard/WelcomeBanner";
import DashboardAvatars from "Dashboard/partials/dashboard/DashboardAvatars";
import FilterButton from "Dashboard/components/DropdownFilter";
import DatePicker from "Dashboard/components/Datepicker";
import Banner from "Dashboard/partials/Banner";
import Dashboard from "Dashboard/pages/Dashboard";
import Datepicker from "Dashboard/components/Datepicker";
import { toast } from "react-toastify";
// import ExcelToJson from "components/ExcellUpload/components/ExcelToJson";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;


function ExcelUpload() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [file, setFile] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [auditItems, setAuditItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [user, setUser] = useState([]);
  const [userId, setUserId] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [subAuditId, setSubAuditId] = useState("");
  const [auditFormId, setAuditFormId] = useState("");
  const [auditFormItem, setAuditFormItem] = useState([]);
  const { auditId } = useParams();
  function filePathset(e) {
    e.preventDefault();
    var file = e.target.files[0];
    console.log(file);
    setFile(file);
  }

  useEffect(() => {
    setUserId(sessionStorage.getItem("user"));
  }, [showModal]);

  const addData = async (data) => {
    const response = await axios.post(
      `${BASE_URL}/api/subaudit`,
      {
        platformId: "649e8a5186bc5f1770f9a120",
        auditId: auditFormId,
        subAuditId: auditId,
        user: null,
      }
    );
    console.log("this is the transformed data", response.data.data.id);
    setSubAuditId(response.data.data.id);

    const parsedData = JSON.parse(data);
    console.log("this is the parsed data", auditFormItem);
    const sections = auditFormItem?.sections ?? [];
    const fields101 = sections.flatMap((section) => section.fields);
    // const fields102 = fields101.flatMap((field) => field.fields);
    console.log("this is the fields101", fields101);
    // Step 1: Extract the IDs from field101 array
    const field101Ids = fields101.map((obj) => obj._id);

    // Step 2: Create a Set to store unique IDs from parsedObj array
    const uniqueParsedObjIds = new Set();

    // Step 3: Iterate through parsedObj array and add unique IDs to the Set
    parsedData.forEach((obj) => {
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        const idMatch = key.match(/\((.*?)\)/);
        if (idMatch) {
          const id = idMatch[1];
          uniqueParsedObjIds.add(id);
        }
      });
    });

    console.log("this is the unique parsed obj ids", parsedData);

    // Step 4: Compare the extracted IDs with the unique IDs in the Set
    let isMatching = true;
    field101Ids.forEach((id) => {
      if (!uniqueParsedObjIds.has(id)) {
        isMatching = false;
      }
    });
    console.log("this is the is matching", field101Ids, uniqueParsedObjIds);
    // Step 5: Display the appropriate message
    if (isMatching) {
      console.log("Success message: IDs match (ignoring duplicates)!");
      const transformedData = parsedData.map((item) => {
        const transformedItem = {
          platformId: "649e8a5186bc5f1770f9a120", // Replace with the appropriate property from your data
          subAuditId: response.data.data.id, // Replace with the appropriate property from your data
          auditItemFormId: auditFormId, // Replace with the appropriate property from your data
          responses: [],
        };

        Object.keys(item).forEach((key) => {
          if (key !== "SNo." && key !== "") {
            const regexMatch = key.match(/^(.*?)\((.*?)\)$/); // Using regex to extract title and ID inside brackets
            if (regexMatch && regexMatch[2]) {
              const fieldTitle = regexMatch[1];
              const fieldId = regexMatch[2];
              transformedItem.responses.push({
                fieldId: fieldId,
                title: fieldTitle,
                response: item[key],
              });
            }
          }
        });

        return transformedItem;
      });

      setUser(transformedData);
      setShowModal(true);
    } else {
      console.log("Error message: IDs do not match or there are missing IDs!");
      toast("IDs do not match or there are missing IDs!", { type: "error" });
    }
  };
  console.log("this is the item**************", auditId);

  const handleAddData = async () => {
    console.log("this is the user123", user);
    await addDataToModel(user).then((data) => {
      console.log("this is the data", data);
    });
    axios.put(`${BASE_URL}/api/subaudits/${subAuditId}`, {
      user: userId,
    });
    setShowModal(false);
  };

  const clearData = () => {
    deleteData("user").then((data) => {});
    // deleteData('admin').then((data) => {});
    // deleteData('products').then((data) => {});
    alert(`Data deleted Succesfully!`);
  };

  function readFile() {
    if (file === "") {
      alert("Insert some file");
    } else {
      var f = file;

      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });

        var sheetName = wb.SheetNames[0]; // Get the first sheet name
        if (sheetName !== "User") {
          // Change sheet name if it's not "User"
          wb.SheetNames[0] = "User";
        }

        console.log("Here is the sheet name", wb.SheetNames[0]);

        var XL_row_object = XLSX.utils.sheet_to_row_object_array(
          wb.Sheets[sheetName]
        );
        var json_object = JSON.stringify(XL_row_object);
        console.log("this is the json object", json_object);
        addData(json_object);
      };
      reader.readAsBinaryString(f);
      alert(`Sheets added successfully!`);
    }
  }
  // const options = forms.map((form) => {
  //     return { value: form._id, label: form.title };
  //   });

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/subaudit`)
      .then((res) => {
        console.log("this is abc", res.data.data);
        const filterList = res.data.data.filter(
          (form) => form.subAuditId === auditId
        );

        if (filterList.length === 0) {
          // No subAudit data available, show error toast
          toast("First, assign a user to an audit!", {
            type: "error",
          });
        } else {
          setTemplateId(filterList[0]?.auditId);
          console.log("this is the template id", templateId);
        }
      })
      .catch((error) => {
        console.error("Error fetching subAudit data:", error);
      });
  }, [auditId, userId]);
  const getForms = async () => {
    try {
      let {
        data: { list },
      } = await getAllForms();
      // const filterList = list.filter((form) => form.projectId === projectId);
      console.log("this is the list from index", list);
      const filterList = list.filter((form) => form.auditId === templateId);
      console.log("this is the filter list xyz", filterList);
      setAuditItems(filterList);
      // setPageMeta(pageMeta);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getForms();
  }, [templateId, auditId, userId]);

  useEffect(() => {
    getAuditItem(selectedOption);
  }, [selectedOption]);
  const getAuditItem = async (id) => {
    try {
      let { data } = await getFormById(id);
      console.log("this is the data from auditItem", data.auditId);
      setAuditFormId(data._id);
      // setAuditItems(data);
      setAuditFormItem(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  console.log("this is the audit items", selectedOption);

  const options = auditItems.map((form) => {
    return { value: form._id, label: form.title };
  });

  const downloadExcelTemplate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${BASE_URL}/api/generate-excel-template/${selectedOption}`
      );
      const link = document.createElement("a");
      link.download = "template";

      // 👇️ set to relative path
      link.href = `/${response.data.id}.xlsx`;
      console.log("this is the link", response.data.id.xlsx);
      link.click();

      console.log("response from", response);
    } catch (error) {
      console.error("Error downloading Excel template:", error);
    }
  };
  const keys =
    user?.length > 0
      ? Array.from(
          new Set(
            user.flatMap((item) => [
              ...Object.keys(item),
              ...item.responses.map((response) => response.title),
            ])
          )
        )
      : [];

  const handleWrapperClick = () => {
    if (selectedOption === "") {
      toast("Please select a template", { type: "error" });
    }
  };
  return (
    <>
      {showModal && (
        <>
          <div
            id="popup-modal"
            tabIndex={-1}
            className="flex absolute justify-center items-center z-[999] w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full mx-auto my-auto"
          >
            <div className="relative w-full max-w-xl max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="popup-modal"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="p-6 text-center">
                  <svg
                    className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    Are you sure you want to add this data?
                  </h3>
                  <div className="max-w-screen-xl mx-auto px-2 md:px-2 mb-5">
                    <div className="mt-5 shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 text-center">
                      <div
                        className="table-container"
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                      >
                        <table className="w-full text-sm text-center text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 whitespace-nowrap">
                            <tr>
                              {keys.map((key) => (
                                <th className="py-3 px-6" key={key}>
                                  {key}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {user?.map((item, idx) => (
                              <tr
                                key={idx}
                                className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                              >
                                {keys.map((key) => (
                                  <td
                                    className="px-6 py-4 whitespace-nowrap"
                                    key={key}
                                  >
                                    {item.responses.find(
                                      (response) => response.title === key
                                    )?.response || item[key]?.toString()}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      handleAddData();
                    }}
                    data-modal-hide="popup-modal"
                    type="button"
                    className="text-white bg-[#4f46e5] hover:bg-[#4c45d7] focus:ring-4 focus:outline-none focus:ring-[#4f46e5] dark:focus:ring-[#4f46e5] font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Yes, I'm sure
                  </button>
                  <button
                    onClick={() => {
                      setShowModal(false);
                    }}
                    data-modal-hide="popup-modal"
                    type="button"
                    className="text-white bg-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-200 rounded-lg  text-sm font-medium px-5 py-2.5 hover:text-white focus:z-10"
                  >
                    No, cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div
        className={`${
          showModal === false ? "" : `blur-sm`
        } flex h-screen overflow-hidden visible`}
      >
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Welcome banner */}
              {/* <WelcomeBanner /> */}

              {/* Dashboard actions */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Avatars */}
                <DashboardAvatars />

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Filter button */}
                  <FilterButton />
                  {/* Datepicker built with flatpickr */}
                  <Datepicker />
                  {/* Add view button */}
                  <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                    <svg
                      className="w-4 h-4 fill-current opacity-50 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">Add view</span>
                  </button>
                </div>
              </div>

              {/* Cards */}
              <div>
                <div>
                  {/* <input type="file" id="file" onChange={(e) => filePathset(e)} /> */}
                  <div className="mb-3">
                    <Select
                      defaultValue={selectedOption}
                      onChange={(event) => {
                        setSelectedOption(event.value);
                      }}
                      options={options}
                      placeholder="Choose a template..."
                      className="my-react-select-container w-[40%]"
                      classNamePrefix="my-react-select"
                    />
                    <div onClick={handleWrapperClick}>
                      <label
                        htmlFor="formFile"
                        className="mb-2 mt-2 inline-block text-neutral-700 dark:text-neutral-200"
                      >
                        Upload an xls file
                      </label>
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => filePathset(e)}
                        className="relative m-0 block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                        disabled={selectedOption === ""}
                      />
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      readFile();
                    }}
                    className=" text-white font-medium py-2 px-4 my-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-indigo-500 hover:bg-indigo-600"
                  >
                    <BsFileEarmarkSpreadsheetFill className="mr-2 inline-flex" />
                    Read File
                  </button>

                  <button
                    onClick={(e) => {
                      // downloadTemplate();
                      downloadExcelTemplate(e);
                    }}
                    className="bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-4 mx-2 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600"
                  >
                    <FaDownload className="mr-2 inline-flex" />
                    Download Template
                  </button>

                  <button
                    onClick={() => {
                      clearData();
                    }}
                    className="bg-red-600 hover:bg-red-700 text-white font-medium py-2 px-4  rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
                  >
                    <FaTrashAlt className="mr-2 inline-flex" />
                    Clear Data
                  </button>

                  <DisplayInfo />
                </div>
              </div>
            </div>
          </main>

          <Banner />
        </div>
      </div>
    </>
  );
}

export default ExcelUpload;
