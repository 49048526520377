import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { createForm, getAllForms, deleteFormById } from "services/Form";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../components/DropdownFilter";
import Datepicker from "../components/Datepicker";
import Banner from "../partials/Banner";
import { BsTrash, BsTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ClipLoader, PuffLoader } from "react-spinners";
import { FaMinus, FaPlus } from "react-icons/fa";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { Fragment, useRef, CSSProperties } from "react";
import {
  FormDragRef,
  HandleDragOver,
  HandleDrop,
  HandleDragLeave,
  HandleDragEnter,
  HandleDragStart,
  ColorCodes,
  FormPages,
  FormDetail,
  FormSubmitData,
} from "types/Form";
import Section from "components/FormBuilder/Section";
import Field from "components/FormBuilder/Field";
import Responses from "components/FormBuilder/Responses";
import useForm from "hooks/useForm";
import Modal from "components/Modal";
import useTitle from "hooks/useTitle";
import useAuth from "hooks/useAuth";
import { FormProvider } from "context/form";
// import Sidebar from "Dashboard/partials/Sidebar";
// import AuditHeader from "./Header/AuditHeader"
// import AuditHeader from "components/FormBuilder/Header/AuditHeader";
// import {  useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getFormById,
  sendResponse,
  updateFormById,
  checkResponseStatus,
} from "services/Form";
import { setFormTheme, focusElement, isEmpty } from "utils";
// import { toast } from "react-toastify";
// import { ClipLoader } from 'react-spinners';
// import styles from "./FormBuilder.module.scss";
import styles from "./FormBuilder.module.scss";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

let initialDragRef = {
  source: {
    droppableId: null,
    draggableId: null,
  },
  destination: {
    droppableId: null,
    draggableId: null,
  },
  dragElement: null,
};

function InstanceManagement(formPage: FormPages) {
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  const [title101, setTitle101] = useState("");
  const [path, setPath] = useState([]);
  const [description, setDescription] = useState("");
  const [platformId, setPlatformId] = useState("649e8a5186bc5f1770f9a120");
  const [templateId, setTemplateId] = useState("");
  const [projects, setProjects] = useState([]);
  const [data101, setData101] = useState<any>([]);
  // Path
  const [breadcrumbs, setBreadcrumbs] = useState<any>([]);

  const [pathofUI, setPathofUI] = useState<any>([]);

  const [body, setBody] = useState<any>({});
  const [dataOriginal, setDataOriginal] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLevelOption, setSelectedLevelOption] = useState({
    value: "64b0498c83a1a495027de649",
    label: "Level 0",
    atLevel: 0,
  });
  // const [isLoading, setIsLoading] = useState(true);
  const [dropdownsData, setDropdownsData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [dropdowns, setDropdowns] = useState([]);
  // let location = useLocation();

  // let searchParams = new URLSearchParams(location.search);

  let [selectedId, setSelectedId] = useState<string | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  let [isSubmited, setIsSubmited] = useState(false);

  let [isResponded, setIsResponded] = useState(false);

  let [activeSection, setActiveSection] = useState<number>(0);

  let [dragId, setDragId] = useState<string | null>(null);

  let [isLoading, setIsLoading] = useState(true);

  let dragRef = useRef<FormDragRef>(initialDragRef);

  let focusFieldId = useRef<string | null>(null);

  //   let { formId } = useParams();
  const formId = selectedLevelOption.value;

  // let navigate = useNavigate();

  let { user, logout } = useAuth();

  let { isEdit, isView } = formPage;
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleTheme101 = () => {
    localStorage.getItem("theme") === "dark"
      ? setIsDarkMode(true)
      : setIsDarkMode(false);
  };

  useEffect(() => {
    handleTheme101();
  }, []);

  const updateForm = async (data: FormDetail) => {
    if (!formId) return;

    await updateFormById({ formId, data });
  };

  let form = useForm<FormDetail>({
    ...(isEdit && { onChange: updateForm }),
  });

  let { formData, setFormData, reset, handleSubmit } = form;
  console.log("this is the formdata", formData);

  let { sections = [], colorCode, bgCode, title, projectId } = formData;

  useTitle(title);

  useEffect(() => {
    getFormDetails();
    if (isView) getResponseStatus();
  }, [formId]);

  useEffect(() => {
    if (!focusFieldId.current) return;
    let element = document.querySelector(
      `[data-field-id='${focusFieldId.current}']`
    );
    if (element) focusElement(element);
    focusFieldId.current = null;
  }, [formData]);

  useEffect(() => {
    if (!colorCode || !bgCode) return;
    setFormTheme({ colorCode, bgCode });
    return () => {
      document.querySelector("html")!.removeAttribute("style");
    };
  }, [colorCode, bgCode]);

  const getResponseStatus = async () => {
    if (!formId) return;

    let {
      data: { status },
    } = await checkResponseStatus(formId);
    setIsResponded(status);
  };

  const getFormDetails = async () => {
    if (!formId) return;

    try {
      const { data: formDetail } = await axios.get(
        `${BASE_URL}/api/level/${formId}`
      );
      console.log("this is the formdetail page", formDetail);

      // if (isEdit && formDetail.creatorId !== user?._id) {
      //   toast("Form creator only have the edit access", { type: "error" });
      //   navigate("/form/list");
      // } else {
      setFormData(formDetail.data.dataField, false);
      // setProjectId(formDetail);
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };

  const handleDragStart: HandleDragStart = (droppableId, draggableId) => {
    let dragElement = document.querySelector(
      `[data-draggable-id='${draggableId}'][data-droppable-id='${droppableId}']`
    ) as HTMLElement;

    dragRef.current = {
      ...dragRef.current,
      source: { droppableId, draggableId },
      dragElement,
    };
    setTimeout(() => {
      if (!dragElement) return;
      dragElement.style.visibility = "hidden";
    }, 0);
  };

  const handleDragEnter: HandleDragEnter = (
    event,
    droppableId,
    draggableId
  ) => {
    event.stopPropagation();
    dragRef.current = {
      ...dragRef.current,
      destination: { droppableId, draggableId },
    };
  };

  const handleDragLeave: HandleDragLeave = (
    event,
    droppableId,
    draggableId
  ) => {
    event.stopPropagation();
  };

  const handleDragEnd = () => {
    let { dragElement } = dragRef.current;

    if (dragElement) {
      dragElement.style.visibility = "visible";
    }
    setDragId(null);
    setTimeout(() => {
      dragRef.current = initialDragRef;
    }, 0);
  };

  const handleDrop: HandleDrop = () => {
    let { source, destination } = dragRef.current;

    if (
      typeof source.draggableId !== "number" ||
      typeof source.droppableId !== "number" ||
      typeof destination.draggableId !== "number" ||
      typeof destination.droppableId !== "number"
    )
      return;

    let { dragElement } = dragRef.current;
    let form = { ...formData };

    if (dragElement) {
      focusFieldId.current = dragElement.getAttribute("data-field-id");
    }

    form.sections[destination.droppableId].fields.splice(
      destination.draggableId,
      0,
      form.sections[source.droppableId].fields.splice(source.draggableId, 1)[0]
    );

    setFormData(form);
  };

  const handleDragOver: HandleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTheme = (theme: { colorCode: ColorCodes; bgCode: string }) => {
    setFormData({ ...formData, ...theme });
  };

  const onSubmit = (data: any, action: "next" | "back" | "submit") => {
    if (action === "next") {
      setActiveSection((section) => section + 1);
    } else if (action === "back") {
      setActiveSection((section) => section - 1);
    } else {
      submitResponse(data);
    }
  };

  const submitResponse = async (data: FormDetail) => {
    console.log("this is the data form xyz", data);
    if (!formData._id) return;

    let body = {
      responses: getFormResponse(data),
      formId: formData._id,
    };

    console.log("this is the body", body);

    await sendResponse(body);
    try {
      const response = await axios.post(`${BASE_URL}/api/instance`, {
        platformId: platformId,
        name: title101,
        levelID: selectedLevelOption.value,
        atLevel: selectedLevelOption.atLevel,
        path: path,
        data: body,
      });
      console.log("this is a body from instance", body);
      if (response.status === 201 && response.statusText === "Created") {
        toast("Project Created Sucessfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
      const newProject = response.data.data;
      setData101([...data101, newProject]);
      console.log("project added successfully", response.data, projects);
      setState(false);
      setShowModal(false);
      setPath([]);
      setTitle101("");
      setDescription("");
    } catch (error) {
      console.error(error);
    }
    clearForm();
    setIsSubmited(true);
  };

  const getFormResponse = (data: FormDetail): FormSubmitData[] => {
    let formData = data.sections.reduce((formData, section) => {
      console.log("this is a section 123", section);
      section.fields.forEach(
        ({ response, fieldType, other, otherReason, _id, rules }) => {
          if (!_id) return;
          let data: FormSubmitData = {
            fieldId: _id,
            response: null,
            title: rules.unique?.value
          };
          if (other && otherReason) {
            if (fieldType === "radio" && response === "Other") {
              data.response = `Other : ${otherReason}`;
            } else if (
              fieldType === "checkbox" &&
              Array.isArray(response) &&
              response.includes("Other")
            ) {
              data.response = [
                ...response.filter((val) => val !== "Other"),
                `Other : ${otherReason}`,
              ];
            }
          } else if (response) {
            data.response = response;
          }
          formData.push(data);
        }
      );

      return formData;
    }, [] as FormSubmitData[]);

    return formData;
  };

  const onInvalid = (errors: any, action?: "next" | "back") => {
    if (action === "back") {
      setActiveSection((section) => section - 1);
    } else if (action === "next") {
      if (isEmpty(errors?.sections?.[activeSection])) {
        setActiveSection((section) => section + 1);
      }
    }
  };

  const clearForm = () => {
    reset();
    setActiveSection(0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleTitle = (title: string) => {
    if (!formId) return;
    let form = { ...formData, title };
    setFormData(form);
  };


  const [selectedInstanceLevelOption, setSelectedInstanceLevelOption] =
    useState({
      value: "64b0498c83a1a495027de649",
      label: "Level 0",
      atLevel: 0,
    });
  // const { projectId } = useParams();
  const [state, setState] = useState(false);
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);

  const [page, setPage] = useState(1);

  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handlePageChange = (pageNumber: any) => {
    setPage(pageNumber);
  };
  console.log("this is the project id", selectedOption);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  let search = searchParams.get("search");
  useEffect(() => {
    fetchProjects();
  }, [selectedLevelOption]);
  console.log("this is a search", selectedLevelOption);
  const getForms = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/level`);
      console.log("This is the response:", response);
      setForms(response.data.data);
    } catch (error) {
      console.log("An error occurred:", error);
      return error;
    } finally {
      if (isLoading) setIsLoading(false);
    }
  };

  useEffect(() => {
    getForms();
  }, []);

  console.log("this is the forms", forms);
  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/api/instance`);
      setLoading(false);
      console.log("this is a response from instance", response.data.data);
      setDataOriginal(response.data.data);
      const filteredData = response.data.data.filter((project: any) => {
        if (pathofUI.length === 0) {
          return project.atLevel === 0;
        }
        for (let i = 0; i < project.path.length; i++) {
          if (project.path[i] !== pathofUI[i]) {
            return false;
          }
        }
        return true;
      });
      setData101(filteredData);

      console.log("this is a gilterd data", pathofUI);
      setDropdownsData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit101 = async () => {
    // e.preventDefault();

    console.log("this is a title", title101);
  };
  const token = sessionStorage.getItem("auth-token");
  const config = {
    headers: {
      "auth-token": token,
    },
  };

  const handleDelete = async (id: any) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}/api/project/projects/${id}`,
        config
      );
      const newProjects = data101.filter((project: any) => project._id !== id);
      console.log("project deleted successfully", newProjects);
      setData101(newProjects);
      console.log("this is a res", res);
      if (res.status === 200 && res.statusText === "OK") {
        toast("Project Deleted Sucessfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const handleSubmitForm = (e: any) => {
  //   e.preventDefault();
  //   handleSubmit101();
  //   setState(false);
  // };

  const handleOpen = () => {
    // setState(!state);
    setShowModal(true);
  };
  const options = forms.map((form: any) => {
    return {
      value: form.id,
      label: form.name,
      atLevel: form.atLevel,
      path: form.path,
    };
  });

  const options2 = dropdownsData.map((form: any) => {
    return {
      value: form.id,
      label: form.name,
      atLevel: form.atLevel,
      path: form.path,
    };
  });

  const totalPages = Math.ceil(data101?.length / itemsPerPage);
  const paginationButtons = [];

  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 dark:bg-gray-800 text-black bg-slate-300 dark:text-white rounded-md ${page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
          }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 text-black bg-slate-300 py-2 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
          }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 text-black bg-slate-300 py-2 dark:bg-gray-800 dark:text-white rounded-md ${page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
        }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );
  console.log("this is a data", options);
  const handleLevelChange = (event: any) => {
    const { value, label, atLevel } = event;

    setSelectedLevelOption({ value, label, atLevel });
  };
  const handleInstacneLevelChange = (event: any, index: any) => {
    const { value, label, atLevel } = event;
    const updatePath = [...path] as any;
    updatePath[index] = value;
    setPath(updatePath);
    setSelectedInstanceLevelOption({ value, label, atLevel });
  };

  const renderSelectDropdowns = () => {
    const numDropdowns = selectedLevelOption.atLevel;
    const dropdowns = [];

    for (let i = 0; i < numDropdowns; i++) {
      dropdowns.push(
        <Select
          key={i}
          defaultValue={selectedInstanceLevelOption.value}
          onChange={(event) => {
            handleInstacneLevelChange(event, i);
          }}
          options={
            options2
              .filter((option: any) => {
                return option.atLevel === i;
              })
              .filter((option: any) => {
                if (i - 1 < 0) return true;
                return option.path[i - 1] === path[i - 1];
              }) as any
          }
          placeholder={`Choose instance for level ${i}...`}
          className="my-react-select-container mt-3"
          classNamePrefix="my-react-select"
        />
      );
    }

    return dropdowns;
  };

  const handleGrupedInstance = (project: any) => {
    const newBreadcrumb = { id: project.id, name: project.name };
    setBreadcrumbs((prevBreadcrumbs: any) => [...prevBreadcrumbs, newBreadcrumb]);

    let newPath = [...pathofUI];
    newPath[project.atLevel] = project.id;
    newPath = newPath.slice(0, project.atLevel + 1);
    setPathofUI(newPath);

    const filteredData = dataOriginal.filter((item: any) => {
      if (newPath.length !== item.path.length) {
        return false;
      }

      for (let i = 0; i < newPath.length; i++) {
        if (item.path[i] !== newPath[i]) {
          return false;
        }
      }
      return true;
    });

    setData101(filteredData);
  };

  const handleBreadcrumbClick = (index: number) => {
    const clickedBreadcrumb = breadcrumbs[index];
    const newPath = pathofUI.slice(0, index + 1);
    setPathofUI(newPath);

    const filteredData = dataOriginal.filter((item: any) => {
      if (newPath.length !== item.path.length) {
        return false;
      }

      for (let i = 0; i < newPath.length; i++) {
        if (item.path[i] !== newPath[i]) {
          return false;
        }
      }
      return true;
    });

    setBreadcrumbs((prevBreadcrumbs: any) =>
      prevBreadcrumbs.slice(0, index + 1)
    );
    setData101(filteredData);
  };

  let isUnique = false;

  sections.forEach(({ _id, title, description, fields }, sectionIndex) => {
    fields.forEach((field) => {
      const { rules } = field;
      if (rules && rules.unique && rules.unique.value) {
        isUnique = true;
        return false;
      }
    });

    if (isUnique) {
      return false;
    }
  });

  console.log("this is a dropdowns from level", selectedLevelOption);
  return (
    <>
      {showModal && (
        <div
          id="crypto-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999]   w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-[75%] my-auto "
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="crypto-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  Instance Management
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                {/* <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Connect with one of our available wallet providers or create a new
                  one.
                </p> */}
                <>
                  {/* <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Audit Title
                  </label> */}
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit101();
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Name of the instance"
                      value={title101}
                      required
                      onChange={(e) => setTitle101(e.target.value)}
                      className="bg-white border mb-3 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    {/* <input
                      placeholder="Data Fields"
                      value={description}
                      required
                      onChange={(e) => setDescription(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    /> */}
                    <Select
                      defaultValue={selectedOption}
                      //   setSelectedOption({
                      //     value: event.value,
                      //     label: event.label,
                      //   });
                      //   setTemplateId(event.value);
                      // }
                      onChange={(event) => {
                        handleLevelChange(event);
                      }}
                      options={options}
                      placeholder="Choose a level..."
                      className="my-react-select-container mt-3 "
                      classNamePrefix="my-react-select"
                    />
                    {renderSelectDropdowns()}{" "}
                    {/* <button
                      type="submit"
                      className="btn bg-indigo-700 hover:bg-indigo-800 text-white mt-4"
                    >
                      <span className="hidden xs:block ml-2">
                        Create Instance
                      </span>
                    </button> */}
                  </form>
                </>
                <div>
                  {/* <InstanceForm/> */}
                  <div className="flex overflow-hidden bg-transparent">
                    {/* Sidebar */}
                    {/* <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} /> */}

                    {/* Content area */}
                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                      {/*  Site header */}
                      {isEdit && (
                        // <AuditHeader
                        //   formId={formId}
                        //   colorCode={colorCode}
                        //   bgCode={bgCode}
                        //   title={title}
                        //   user={user}
                        //   params={searchParams}
                        //   navigate={navigate}
                        //   logout={logout}
                        //   handleTitle={handleTitle}
                        //   handleTheme={handleTheme}
                        //   sidebarOpen={sidebarOpen}
                        //   setSidebarOpen={setSidebarOpen}
                        // />
                        <></>
                      )}

                      <main>
                        <div className=" w-full max-w-9xl mx-auto">
                          <div className="sm:flex sm:justify-between sm:items-center dark:bg-[#182235] ">
                            {/* <div className="grid grid-flow-col  sm:auto-cols-max justify-start sm:justify-end gap-2 mt-2">
                <nav className="flex mb-3 ml-7" aria-label="Breadcrumb" >
                  <ol className="inline-flex items-center space-x-1 md:space-x-3">
                    <li className="inline-flex items-center">
                      <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                        <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                        Home
                      </a>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <Link to="/projects" className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Projects</Link>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <Link to={`/form/list/${formData.projectId}`} className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Audits</Link>
                      </div>
                    </li>

                    <li aria-current="page">
                      <div className="flex items-center">
                        <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Audit Item</span>
                      </div>
                    </li>
                  </ol>
                </nav>
              </div> */}
                          </div>

                          {/* Cards */}
                          <Fragment>
                            {isEdit && <></>}
                            {searchParams.get("tab") === "responses" ? (
                              // <Responses formId={formId} />
                              <></>
                            ) : (
                              <Fragment>
                                <FormProvider {...form}>
                                  <div
                                    className="bg-transparent"
                                    style={
                                      {
                                        "--top": isEdit ? "111px" : "0px",
                                      } as CSSProperties
                                    }
                                  >
                                    {isLoading ? (
                                      <div className="flex justify-center items-center m-auto h-screen bg-transparent">
                                        <PuffLoader color="#6366f1" size={60} />
                                      </div>
                                    ) : (
                                      <div className={`${styles.container} `}>
                                        {sections.map(
                                          (
                                            { _id, title, description, fields },
                                            sectionIndex
                                          ) => {
                                            if (
                                              isView &&
                                              !(sectionIndex === activeSection)
                                            )
                                              return null;

                                            let sectionHeader =
                                              sections.length > 1
                                                ? `Section ${sectionIndex + 1
                                                } of ${sections.length}`
                                                : undefined;

                                            let isSelected =
                                              selectedId ===
                                              sectionIndex.toString();

                                            return (
                                              <Fragment key={sectionIndex}>
                                                <Section
                                                  title={title}
                                                  selectedId={selectedId}
                                                  description={description}
                                                  sectionIndex={sectionIndex}
                                                  sectionHeader={sectionHeader}
                                                  formPage={formPage}
                                                  isSelected={isSelected}
                                                  onClick={() =>
                                                    setSelectedId(
                                                      sectionIndex.toString()
                                                    )
                                                  }
                                                />
                                                <div
                                                  className={`${styles.wrapper} `}
                                                  {...(isEdit && {
                                                    "data-droppable-id":
                                                      sectionIndex,
                                                    onDragEnter: (e) =>
                                                      handleDragEnter(
                                                        e,
                                                        sectionIndex,
                                                        0
                                                      ),
                                                    onDragLeave: (e) =>
                                                      handleDragLeave(
                                                        e,
                                                        sectionIndex,
                                                        0
                                                      ),
                                                    onDragOver: handleDragOver,
                                                    onDrop: handleDrop,
                                                  })}
                                                >
                                                  {fields.map(
                                                    (field, fieldIndex) => {
                                                      let fieldId = `${sectionIndex}${fieldIndex}`;
                                                      console.log("this is the field id", field);
                                                      let isSelected =
                                                        selectedId === fieldId;
                                                      return (
                                                        <Field
                                                          isUnique={isUnique}
                                                          key={fieldId}
                                                          field={field}
                                                          fieldId={fieldId}
                                                          tabIndex={-1}
                                                          sectionIndex={
                                                            sectionIndex
                                                          }
                                                          fieldIndex={
                                                            fieldIndex
                                                          }
                                                          formPage={formPage}
                                                          focusFieldId={
                                                            focusFieldId
                                                          }
                                                          isSelected={
                                                            isSelected
                                                          }
                                                          {...(isEdit && {
                                                            "data-field-id":
                                                              fieldId,
                                                            "data-draggable-id":
                                                              fieldIndex,
                                                            "data-droppable-id":
                                                              sectionIndex,
                                                            draggable:
                                                              dragId ===
                                                              fieldId,
                                                            onClick: () =>
                                                              setSelectedId(
                                                                fieldId
                                                              ),
                                                            onDragStart: () =>
                                                              handleDragStart(
                                                                sectionIndex,
                                                                fieldIndex
                                                              ),
                                                            onDragLeave: (e) =>
                                                              handleDragLeave(
                                                                e,
                                                                sectionIndex,
                                                                fieldIndex
                                                              ),
                                                            onDragEnter: (e) =>
                                                              handleDragEnter(
                                                                e,
                                                                sectionIndex,
                                                                fieldIndex
                                                              ),
                                                            onDragEnd:
                                                              handleDragEnd,
                                                            setDragId:
                                                              setDragId,
                                                          })}
                                                        />
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </Fragment>
                                            );
                                          }
                                        )}
                                        {
                                          <div className={styles.cta}>
                                            <div>
                                              {activeSection > 0 && (
                                                <button
                                                  className={
                                                    styles.btn_navigate
                                                  }
                                                  onClick={handleSubmit(
                                                    (data) =>
                                                      onSubmit(data, "back"),
                                                    (errors) =>
                                                      onInvalid(errors, "back")
                                                  )}
                                                >
                                                  Back
                                                </button>
                                              )}
                                              {activeSection <
                                                sections.length - 1 && (
                                                  <button
                                                    className={
                                                      styles.btn_navigate
                                                    }
                                                    onClick={handleSubmit(
                                                      (data) =>
                                                        onSubmit(data, "next"),
                                                      (errors) =>
                                                        onInvalid(errors, "next")
                                                    )}
                                                  >
                                                    Next
                                                  </button>
                                                )}
                                              {activeSection ===
                                                sections.length - 1 && (
                                                  <button
                                                    className={styles.btn_submit}
                                                    onClick={handleSubmit(
                                                      (data) =>
                                                        onSubmit(data, "submit"),
                                                      onInvalid
                                                    )}
                                                  >
                                                    Submit
                                                  </button>
                                                )}
                                            </div>
                                            <button
                                              className={styles.btn_clear}
                                              onClick={clearForm}
                                            >
                                              Clear Form
                                            </button>
                                          </div>
                                        }
                                        <div className={styles.footer}>
                                          {/* <span>Audit Form</span> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </FormProvider>
                                {/* <Modal isOpen={isSubmited}>
                                  <div className={styles.popup}>
                                    <h2>Thank You!</h2>
                                    {isResponded && <span>You have already submitted the form</span>}
                                    {isSubmited && (
                                      <span>Your response have been saved successfully</span>
                                    )}
                                  </div>
                                </Modal> */}
                              </Fragment>
                            )}
                          </Fragment>
                        </div>
                      </main>

                      {/* <Banner /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`${showModal === false ? "" : `blur-sm`
          } flex h-screen overflow-hidden visible`}
      >
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Welcome banner */}
              {/* <WelcomeBanner /> */}
              <nav className="flex " aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                  <li className="inline-flex items-center">
                    <a
                      href="#"
                      className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                      </svg>
                      Home
                    </a>
                  </li>
                  {/* <li>
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <Link
                      to="/projects"
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      Projects
                    </Link>
                  </div>
                </li> */}
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                        Instance Management
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
              {/* Dashboard actions */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Avatars */}
                {/* <DashboardAvatars /> */}
                <div className="w-full"></div>

                {/* Right: Actions */}
                {/* <Select
                  defaultValue={selectedLevelOption.value}
                  onChange={(event) => {
                    // setSelectedOption({
                    //   value: event.value,
                    //   label: event.label,
                    //   atLevel: event.atLevel,
                    // });
                    // setTemplateId(event.value);
                    handleLevelChange(event);
                  }}
                  options={options as any}
                  placeholder={selectedLevelOption.label}
                  className="my-react-select-container w-[40%] mr-3"
                  classNamePrefix="my-react-select"
                /> */}

                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Filter button */}
                  {/* <FilterButton /> */}
                  {/* Datepicker built with flatpickr */}
                  {/* <Datepicker /> */}
                  {/* Add view button */}

                  <button
                    onClick={handleOpen}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  >
                    {state ? (
                      <FaMinus className="text-md" />
                    ) : (
                      <FaPlus className="text-md " />
                    )}
                    {/* <svg
                      className="w-4 h-4 fill-current opacity-50 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg> */}
                    <span className="hidden xs:block ml-2">
                      {state ? "Hide Form" : "Add Instance"}
                    </span>
                  </button>
                </div>
              </div>

              <div className="flex justify-between mt-8">
                <div>
                  Page {page} of {totalPages}
                </div>
                <div className="flex items-center gap-1">
                  {paginationButtons}
                </div>
              </div>
              <div className="mt-5">
                {loading ? (
                  <div className="flex justify-center items-center m-auto">
                    <PuffLoader color="#6366f1" size={60} />
                  </div>
                ) : (
                  // data.slice(startIndex, endIndex).map((project) => (
                  // <div
                  //   key={project.id}
                  //   className="flex-shrink-0 m-5 relative overflow-hidden bg-[#6366f1] rounded-lg max-w-xs shadow-lg w-64"
                  // >
                  //   <svg
                  //     className="absolute bottom-0 left-0 mb-8"
                  //     viewBox="0 0 375 283"
                  //     fill="none"
                  //     style={{ transform: "scale(1.5)", opacity: "0.1" }}
                  //   >
                  //     <rect
                  //       x="159.52"
                  //       y="175"
                  //       width="152"
                  //       height="152"
                  //       rx="8"
                  //       transform="rotate(-45 159.52 175)"
                  //       fill="white"
                  //     />
                  //     <rect
                  //       y="107.48"
                  //       width="152"
                  //       height="152"
                  //       rx="8"
                  //       transform="rotate(-45 0 107.48)"
                  //       fill="white"
                  //     />
                  //   </svg>
                  //   <div className="relative  px-20 flex items-center justify-center mt-2 mr-2">
                  //     {/* Additional content here */}
                  //     <div className="absolute top-0 right-0 m-2 ">
                  //       <button
                  //         onClick={() => handleDelete(project._id)} // Replace handleDelete with your actual delete function
                  //         className="p-1 bg-white rounded-full text-[#6366f1] text-xs font-bold leading-none flex items-center"
                  //       >
                  //         <BsTrashFill className="text-lg" />
                  //       </button>
                  //     </div>
                  //   </div>
                  //   <div className="relative text-white px-3 pb-6 mt-8">
                  //     <Tooltip followCursor title={project.title}>
                  //       <span className="block font-semibold text-xl">
                  //         {project.name.length > 13
                  //           ? `${project.name.slice(0, 12)}...`
                  //           : project.name}
                  //       </span>
                  //     </Tooltip>
                  //     <div className="flex justify-between mt-2">
                  //       <Tooltip followCursor title={project.description}>
                  //         <span className="block opacity-75">
                  //           {project.name.length > 20
                  //             ? `${project.name.slice(0, 20)}...`
                  //             : project.name}
                  //         </span>
                  //       </Tooltip>
                  //       <button
                  //         onClick={() =>
                  //           navigate(`/audits/list/${project.id}`)
                  //         }
                  //         className="block bg-white rounded-full text-[#6366f1] text-xs font-bold px-3 py-2 leading-none flex items-center"
                  //       >
                  //         View
                  //       </button>
                  //     </div>
                  //   </div>
                  // </div>
                  <div className="relative overflow-x-auto shadow-md rounded-lg">
                    <nav className="flex items-center space-x-2 text-gray-500 text-sm mb-2">
                      <button onClick={() => handleBreadcrumbClick(-1)}>Home</button>
                      {breadcrumbs.map((breadcrumb: any, index: any) => (
                        <React.Fragment key={breadcrumb.id}>
                          <span>/</span>
                          <button onClick={() => handleBreadcrumbClick(index)}>
                            {breadcrumb.name}
                          </button>
                        </React.Fragment>
                      ))}
                    </nav>
                    <div className="mt-5 shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 text-center">


                      <table className="w-full text-sm text-left rounded-lg text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              Instance Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                              at level
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Created At
                            </th>

                            <th scope="col" className="px-6 py-3">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data101
                            .slice(startIndex, endIndex)
                            .map((project: any) => {
                              return (
                                <>
                                  <tr
                                    key={project.id}
                                    className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700"
                                  >
                                    <th
                                      scope="row"
                                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                    >
                                      {project.name}
                                    </th>
                                    <td className="px-6 py-4">
                                      {project.atLevel}
                                    </td>
                                    <td className="px-6 py-4">
                                      {" "}
                                      {moment
                                        .tz(project.createdAt, "Asia/Kolkata")
                                        .format("MMM D, YYYY")}
                                    </td>
                                    <td className="px-6 py-4">
                                      <button
                                        onClick={() =>
                                          handleGrupedInstance(project)
                                        }
                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                      >
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                  <tr></tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  // ))
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default InstanceManagement;
