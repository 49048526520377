import { ChangeEvent } from "react";
import { User } from "types/Auth";
import { Link, useNavigate } from "react-router-dom";
import { debounce, googleFormIcon } from "utils";
import Avatar from "components/Avatar";

import styles from "./Header.module.scss";
import ThemeToggle from "Dashboard/components/ThemeToggle";

type HeaderProps = {
  search: string | null;
  // user: User | null;
  // logout: () => void;
};

const Header = ({ search }: HeaderProps) => {
  const navigate = useNavigate();

  const handleChange = debounce<ChangeEvent<HTMLInputElement>>(
    ({ target: { value } }) => {
      navigate({ search: value ? `?search=${value}` : "" });
    },
    500
  );

  return (
    // <div className={styles.container}>
    //   <div className={styles.logo}>
    //     {googleFormIcon}
    //     <span>Google Form</span>
    //   </div>
    // <div className={styles.search_box}>
    //   <input
    // placeholder="Search by title"
    // defaultValue={search || ""}
    // onChange={handleChange}
    //   />
    //   <i className="bx-search"></i>
    // </div>
    // <div className={styles.avatar}>
    //   <Avatar userName={"Admin Panel"} />
    // </div>
    // // </div>
    <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-b dark:border-slate-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to="/dashboard" className="flex items-center">
          <img
            src="https://flowbite.com/docs/images/logo.svg"
            className="h-8 mr-3"
            alt="Flowbite Logo"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            VPS Group
          </span>
          {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            VPS Group
          </span> */}
        </Link>
        <div className="flex md:order-2">

          <div className="w-10 h-10  rounded-full ring-2 ring-green-400 dark:ring-green-400 mr-3 ">

            <ThemeToggle size={10} />
          </div>

          <div className="w-10 h-10  rounded-full ring-2 ring-green-400 dark:ring-green-400">
            <Avatar userName={"Admin Panel"} />
          </div>


          <button
            data-collapse-toggle="navbar-cta"
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-cta"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-cta"
        >
          <form className="flex justify-center items-center " onSubmit={(e) => {
            e.preventDefault()
          }}>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative pr-32">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-[155%] p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-[#4f46e5] focus:border-[#4f46e5] dark:bg-[#1e293b] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#4f46e5] dark:focus:border-[#4f46e5]"
                required
                placeholder="Search by title"
                defaultValue={search || ""}
                onChange={handleChange}
              />
              <button
                type="submit"
                className="text-white absolute right-[7px] bottom-[1px] bg-[#4f46e5] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-[#4f46e5] dark:hover:bg-[#4f46e5] dark:focus:ring-blue-800"
              >
                Search
              </button>
            </div>
          </form>

        </div>
      </div>
    </nav>

  );
};

export default Header;
