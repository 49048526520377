import React, { Fragment, useState, useEffect } from "react";
import styles from "../pages/FormBuilder.module.scss";
import Sidebar from "Dashboard/partials/Sidebar";
import Header from "Dashboard/partials/Header";
import DropDown from "components/DropDown";
import { darkMode } from "../css/tailwind.config";
import { Link, useParams, useNavigate } from "react-router-dom";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const DealerGroup = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [dealerGroupData, setDealerGroupData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dummy, setDummy] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const [currentUserData, setCurrentUserData] = useState();
  const [editMode, setEditMode] = useState(false);
  const { level0Id, oem_code } = useParams();

  const navigate = useNavigate();

  const filteredData = dealerGroupData.filter(
    ({
      // systemId,
      dealerGroupCode,
      mapToOEM,
      dealergroupname,
      address,
      gstno,
      gmname,
      gmphone,
      gmemail,
      ownername,
      ownerphone,
      owneremail,
      smname,
      smphone,
      smemail,
      accname,
      accphone,
      accemail,
      edpname,
      edpphone,
      edpemail,
      tsmname,
      tsmphone,
      tsmemail,
      dealerreceptionno,
      assocfirmname,
      assocfirmcontactname,
      assocfirmcontactno,
      assocfirmcontactemail,
      assocexecname,
      assocexeccontactno,
      assocexecemail,
      auditexecutivelandlineno,
      cityname,
      statename,
      zonename,
    }) => {
      const query = searchQuery.toLowerCase();
      return (
        //systemId.toLowerCase().includes(query) ||
        dealerGroupCode && dealerGroupCode .toLowerCase().includes(query) ||
        mapToOEM &&  mapToOEM .toLowerCase().includes(query) ||
        dealergroupname &&  dealergroupname.toLowerCase().includes(query) ||
        address &&  address.toLowerCase().includes(query) ||
        gstno &&  gstno.toLowerCase().includes(query) ||
        gmname &&  gmname.toLowerCase().includes(query) ||
        gmphone &&  gmphone.toLowerCase().includes(query) ||
        gmemail &&  gmemail.toLowerCase().includes(query) ||
        ownername &&  ownername.toLowerCase().includes(query) ||
        ownerphone &&  ownerphone.toLowerCase().includes(query) ||
        owneremail &&  owneremail.toLowerCase().includes(query) ||
        smname &&  smname.toLowerCase().includes(query) ||
        smphone &&  smphone.toLowerCase().includes(query) ||
        smemail &&  smemail.toLowerCase().includes(query) ||
        accname &&  accname.toLowerCase().includes(query) ||
        accphone &&  accphone.toLowerCase().includes(query) ||
        accemail &&  accemail.toLowerCase().includes(query) ||
        edpname &&  edpname.toLowerCase().includes(query) ||
        edpphone &&  edpphone.toLowerCase().includes(query) ||
        edpemail &&  edpemail.toLowerCase().includes(query) ||
        tsmname &&  tsmname.toLowerCase().includes(query) ||
        tsmphone &&  tsmphone.toLowerCase().includes(query) ||
        tsmemail &&  tsmemail.toLowerCase().includes(query) ||
        dealerreceptionno &&  dealerreceptionno.toLowerCase().includes(query) ||
        assocfirmcontactemail &&  assocfirmcontactemail.toLowerCase().includes(query) ||
        assocfirmcontactno && assocfirmcontactno.toLowerCase().includes(query) ||
        assocfirmcontactname &&  assocfirmcontactname.toLowerCase().includes(query) ||
        assocexecname &&  assocexecname.toLowerCase().includes(query) ||
        assocfirmname &&  assocfirmname.toLowerCase().includes(query) ||
        assocexecemail &&  assocexecemail.toLowerCase().includes(query) ||
        assocexeccontactno &&  assocexeccontactno.toLowerCase().includes(query) ||
        assocexecemail &&  assocexecemail.toLowerCase().includes(query) ||
        auditexecutivelandlineno &&  auditexecutivelandlineno.toLowerCase().includes(query) ||
        cityname  && cityname.toLowerCase().includes(query) ||
        zonename &&  zonename.toLowerCase().includes(query) ||
        statename &&  statename.toLowerCase().includes(query)
      );
    }
  );
  useEffect(() => {
    const userId = sessionStorage.getItem("user");
    if (userId) {
      const response = axios
        .get(`${BASE_URL}/user-details/${userId}`)
        .then((response) => {
          console.log("this is a user res", response.data);
          setCurrentUserData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/dealer_group/dealergroups`
        );
        // console.log("")
        const dealerData = response.data.filter(
          (item) => item.level0Id === level0Id || item.mapToOEM === oem_code
        );
        setDealerGroupData(dealerData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [dummy, showModal]);

  const [formData, setFormData] = useState({
    //systemId:'',
    dealerGroupCode: "",
    mapToOEM: "",
    dealergroupname: "",
    address: "",
    gstno: "",
    gmname: "",
    gmphone: "",
    gmemail: "",
    ownername: "",
    ownerphone: "",
    owneremail: "",
    smname: "",
    smphone: "",
    smemail: "",
    accname: "",
    accphone: "",
    accemail: "",
    edpname: "",
    edpphone: "",
    edpemail: "",
    tsmname: "",
    tsmphone: "",
    tsmemail: "",
    dealerreceptionno: "",
    assocfirmname: "",
    assocfirmcontactname: "",
    assocfirmcontactno: "",
    assocfirmcontactemail: "",
    assocexecname: "",
    assocexeccontactno: "",
    assocexecemail: "",
    auditexecutivelandlineno: "",
    cityname: "",
    statename: "",
    zonename: "",
    level0Id: level0Id,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleContactPersonInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     contactPerson: {
  //       ...formData.contactPerson,
  //       [name]: value,
  //     },
  //   });
  // };

  // const handleAuthorizedPersonInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     authorizedPerson: {
  //       ...formData.authorizedPerson,
  //       [name]: value,
  //     },
  //   });
  // }
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data:", formData);
  };
  // const getMasterId = async () => {
  //   try {
  //     const response = await axios.get('${BASE_URL}/api/master_setup/dealer/_id');
  //     if (response.data && response.data.masterId) {
  //       const masterId = response.data.masterId;
  //       return masterId;
  //     } else {
  //       console.error('Master data not found');
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error('Error fetching master data:', error);
  //     return null;
  //   }
  // };
  // const masterId = getMasterId();
  // console.log('Master ID:', masterId);

  const handleCreate = async () => {
    if (
      !formData.dealerGroupCode ||
      !formData.mapToOEM ||
      !formData.dealergroupname 
    ) {
      return;
    }
    try {
      // formData.levelOId = leve
      const dataToSend = {
        ...formData,
        level0Id: level0Id,
      };
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/api/dealer_group/dealergroup`,
        dataToSend,
        config
      );
      setDealerGroupData([...dealerGroupData, response.data]);
      setShowModal(false);
      toast("Dealer Group Created Successfully!", {
        type: "success",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      setFormData({
        //systemId:'',
        dealerGroupCode: "",
        mapToOEM: "",
        dealergroupname: "",
        address: "",
        gstno: "",
        gmname: "",
        gmphone: "",
        gmemail: "",
        ownername: "",
        ownerphone: "",
        owneremail: "",
        smname: "",
        smphone: "",
        smemail: "",
        accname: "",
        accphone: "",
        accemail: "",
        edpname: "",
        edpphone: "",
        edpemail: "",
        tsmname: "",
        tsmphone: "",
        tsmemail: "",
        dealerreceptionno: "",
        assocfirmname: "",
        assocfirmcontactname: "",
        assocfirmcontactno: "",
        assocfirmcontactemail: "",
        assocexecname: "",
        assocexeccontactno: "",
        assocexecemail: "",
        auditexecutivelandlineno: "",
        cityname: "",
        statename: "",
        zonename: "",
        levelOId: level0Id,
      });
    } catch (error) {
      console.error("Error creating entry:", error);
      toast(`Error Creating Dealer Group ${error.response.data}`, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    }
  };
  const handleEdit = (data) => {
    setFormData({
      // systemId: data.systemId,
      dealerGroupCode: data.dealerGroupCode,
      mapToOEM: data.mapToOEM,
      dealergroupname: data.dealergroupname,
      address: data.address,
      gstno: data.gstno,
      gmname: data.gmname,
      gmphone: data.gmphone,
      gmemail: data.gmemail,
      ownername: data.ownername,
      ownerphone: data.ownerphone,
      owneremail: data.owneremail,
      smname: data.smname,
      smphone: data.smphone,
      smemail: data.smemail,
      accname: data.accname,
      accphone: data.accphone,
      accemail: data.accemail,
      edpname: data.edpname,
      edpphone: data.edpphone,
      edpemail: data.edpemail,
      tsmname: data.tsmname,
      tsmphone: data.tsmphone,
      tsmemail: data.tsmemail,
      dealerreceptionno: data.dealerreceptionno,
      assocfirmname: data.assocfirmname,
      assocfirmcontactname: data.assocfirmcontactname,
      assocfirmcontactno: data.assocfirmcontactno,
      assocfirmcontactemail: data.assocfirmcontactemail,
      assocexecname: data.assocexecname,
      assocexeccontactno: data.assocexeccontactno,
      assocexecemail: data.assocexecemail,
      auditexecutivelandlineno: data.auditexecutivelandlineno,
      cityname: data.cityname,
      statename: data.statename,
      zonename: data.zonename,
    });
    setEditData(data);
    setShowModal(true);
    setEditMode(true);
  };
  const handleUpdate = async () => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      const response = await axios.put(
        `${BASE_URL}/api/dealer_group/dealergroup/${editData._id}`,
        formData,
        config
      );
      setDealerGroupData((prevData) =>
        prevData.map((entry) =>
          entry._id === response.data._id ? response.data : entry
        )
      );
      toast("Dealer Group Updated Successfully!", {
        type: "success",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      setShowModal(false);
      setEditMode(false);
      setEditData(null);
      setFormData({
        //systemId:'',
        dealerGroupCode: "",
        mapToOEM: "",
        dealergroupname: "",
        address: "",
        gstno: "",
        gmname: "",
        gmphone: "",
        gmemail: "",
        ownername: "",
        ownerphone: "",
        owneremail: "",
        smname: "",
        smphone: "",
        smemail: "",
        accname: "",
        accphone: "",
        accemail: "",
        edpname: "",
        edpphone: "",
        edpemail: "",
        tsmname: "",
        tsmphone: "",
        tsmemail: "",
        dealerreceptionno: "",
        assocfirmname: "",
        assocfirmcontactname: "",
        assocfirmcontactno: "",
        assocfirmcontactemail: "",
        assocexecname: "",
        assocexeccontactno: "",
        assocexecemail: "",
        auditexecutivelandlineno: "",
        cityname: "",
        statename: "",
        zonename: "",
      });
      setShowModal(false);
    } catch (error) {
      console.error("Error updating entry:", error);
      toast(`Error Updating Dealer Group ${error.response.data}`, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
    }
  };

  const handleDelete = async (id,dealerGroupCode) => {
    try {
      const token = sessionStorage.getItem("auth-token");
      const config = {
        headers: {
          "auth-token": token,
        },
      };
      if (currentUserData?.category.category === "Office Coordinator") {
        toast("Access Denied!.", {
          type: "error",
          className: "dark:bg-gray-900 dark:text-white",
        });
      } else {
        const response = await axios.delete(
          `${BASE_URL}/api/dealer_group/dealergroup/${id}/${dealerGroupCode}`,
          config
        );
        console.log("Delete Response:", response);
        setShowModal(false);
        setDummy(true);
        toast("Dealer Group Deleted Successfully!", {
          type: "success",
          className: "dark:bg-gray-900 dark:text-white z-[1000]",
        });
      }

      // // Update the dealerGroupData state, excluding the deleted item
      // setDealerGroupData((prevDealerGroupData) =>
      //   prevDealerGroupData.filter((entry) => entry._id !== id)
      // );
    } catch (error) {
      toast(error.response.data.message, {
        type: "error",
        className: "dark:bg-gray-900 dark:text-white z-[1000]",
      });
      console.error("Error deleting entry:", error);
    }
  };

  const itemsPerPage = 8;
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = page * itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const totalPages = filteredData
    ? Math.ceil(filteredData.length / itemsPerPage)
    : 0;

  const paginationButtons = [];
  const maxVisibleButtons = 3; // Maximum number of visible page buttons

  paginationButtons.push(
    <button
      key="previous"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page - 1)}
      disabled={page === 1}
    >
      Previous
    </button>
  );

  paginationButtons.push(
    <button
      key={1}
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === 1 ? "opacity-100 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(1)}
    >
      1
    </button>
  );

  if (page > maxVisibleButtons + 2) {
    paginationButtons.push(<span key="ellipsis1">...</span>);
  }

  for (
    let i = Math.max(2, page - maxVisibleButtons);
    i <= Math.min(totalPages - 1, page + maxVisibleButtons);
    i++
  ) {
    paginationButtons.push(
      <button
        key={i}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === i ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>
    );
  }

  if (page < totalPages - maxVisibleButtons - 1) {
    paginationButtons.push(<span key="ellipsis2">...</span>);
  }

  if (totalPages > 1) {
    paginationButtons.push(
      <button
        key={totalPages}
        className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
          page === totalPages ? "opacity-100 cursor-not-allowed" : "opacity-100"
        }`}
        onClick={() => handlePageChange(totalPages)}
      >
        {totalPages}
      </button>
    );
  }

  paginationButtons.push(
    <button
      key="next"
      className={`px-4 py-2 text-black bg-slate-300 dark:bg-gray-800 dark:text-white rounded-md ${
        page === totalPages ? "opacity-50 cursor-not-allowed" : "opacity-100"
      }`}
      onClick={() => handlePageChange(page + 1)}
      disabled={page === totalPages}
    >
      Next
    </button>
  );

  return (
    <div>
      <div
        className={`${
          showModal === false ? "" : `blur-sm`
        } flex h-screen overflow-hidden visible`}
      >
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div
            id="alert-border-1"
            className="flex justify-between items-center p-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800"
            role="alert"
          >
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div className="ml-3 text-sm font-medium">
                <strong>Dealer Group Setup Screen</strong>
              </div>
            </div>
            <div>
              <p className="flex justify-center items-center  font-semibold">
                <i className="bx bx-chevrons-right text-2xl"></i>
                {oem_code}
              </p>
            </div>
          </div>
          {/* <WelcomeBanner /> */}
          <div>
          <div className="grid grid-flow-col sm:auto-cols-max  sm:justify-end gap-2 m-5 justify-center items-center">
                <div className="relative">
                  <input
                    type="search"
                    className="px-4 py-2 w-64 h-10 rounded-l-lg rounded-r-lg border border-gray-300 dark:border-gray-500 dark:border-[#374151] bg-white text-gray-800 placeholder-gray-400 focus:outline-none dark:bg-transparent dark:text-white dark:placeholder-gray-500"
                    aria-label="Search"
                    placeholder="Search.."
                    name="search"
                    autoComplete="false"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />

                  {/* Search button */}
                  <button
                    className="absolute inset-y-0 right-0 px-4 h-10 text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-500 rounded-r-lg  focus:border-primary-400 flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 py-2 text-center"
                    type="button"
                    id="button-addon1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

              <button
                className="text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                onClick={() => {
                  setEditMode(false);
                  setEditData(null);
                  setShowModal(true);
                  setFormData({
                    //systemId:'',
                    dealerGroupCode: "",
                    mapToOEM: "",
                    dealergroupname: "",
                    address: "",
                    gstno: "",
                    gmname: "",
                    gmphone: "",
                    gmemail: "",
                    ownername: "",
                    ownerphone: "",
                    owneremail: "",
                    smname: "",
                    smphone: "",
                    smemail: "",
                    accname: "",
                    accphone: "",
                    accemail: "",
                    edpname: "",
                    edpphone: "",
                    edpemail: "",
                    tsmname: "",
                    tsmphone: "",
                    tsmemail: "",
                    dealerreceptionno: "",
                    assocfirmname: "",
                    assocfirmcontactname: "",
                    assocfirmcontactno: "",
                    assocfirmcontactemail: "",
                    assocexecname: "",
                    assocexeccontactno: "",
                    assocexecemail: "",
                    auditexecutivelandlineno: "",
                    cityname: "",
                    statename: "",
                    zonename: "",
                  });
                }}
              >
                <i className="bx bx-plus text-xl"></i>
                <span className="hidden xs:block ml-2">
                  Add New Dealer Group
                </span>
              </button>
            </div>
            <nav className="flex mx-6 " aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <a
                    href="#"
                    className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <button
                      onClick={() => {
                        window.history.back();
                      }}
                      to="/projects"
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                    >
                      OEM Data
                    </button>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
                      Dealer Group Data
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <div className="flex justify-between mt-8 mx-6">
              <div>
                Page {page} of {totalPages}
              </div>
              <div className="flex items-center gap-1">{paginationButtons}</div>
            </div>
            <div
              className="mt-5 mx-5 shadow-sm rounded-lg overflow-x-auto dark:bg-gray-900 text-center"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 whitespace-nowrap">
                  <tr className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700">
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Actions
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Dealer Group Code
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Map To OEM
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Dealer Group Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Address
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Gst No
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        GM Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        GM Phone
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        GM Email
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Owner Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Owner Phone
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Owner Email
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        SM Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        SM Phone
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        SM Email
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Accountant Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Accountant Phone
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Accountant Email
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        EDP Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        EDP Phone
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        EDP Email
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        TSM Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        TSM Phone
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        TSM Email
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Dealer Reception No
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Assoc Firm Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Assoc Firm Contact Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Assoc Firm Contact No
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Assoc Firm Contact Email
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Assoc Exec Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Assoc Exec Contact No
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Assoc Exec Email
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Audit Executive Landline No
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        City Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        State Name
                      </span>
                    </th>
                    <th className="py-3 px-6">
                      <span className="text-md text-gray-800 dark:text-white">
                        Zone Name
                      </span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredData.length === 0 ? (
                    <tr className="bg-white border-b dark:bg-transparent dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700">
                      <td colSpan={16} align="center">
                        <span className="text-gray-500 dark:text-gray-400">
                          No Records Found
                        </span>
                      </td>
                    </tr>
                  ) : (
                    filteredData
                      .slice(startIndex, endIndex)
                      .map(({ _id, ...dealerGroup }) => {
                        return (
                          <Fragment key={_id}>
                            <tr
                              onClick={() => {
                                navigate(
                                  `/dealer3/${dealerGroup?.dealerGroupCode}/${level0Id}/${_id}`
                                );
                              }}
                              //onClick={() => navigateToForm(dealerGroup._id)}
                              className="bg-white border-b dark:bg-transparent dark:border-gray-700 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
                              //key={dealerGroup._id}
                            >
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div>
                                  <i
                                    id={`dropdown-${_id}`}
                                    className="bx-dots-vertical-rounded cursor-pointer text-gray-500 dark:text-gray-400 "
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      console.log("thisisid", _id);
                                    }}
                                  ></i>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.dealerGroupCode}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.mapToOEM}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.dealergroupname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.address}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.gstno}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.gmname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.gmphone}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.gmemail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.ownername}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.ownerphone}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.owneremail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.smname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.smphone}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.smemail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.accname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.accphone}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.accemail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.edpname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.edpphone}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.edpemail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.tsmname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.tsmphone}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.tsmemail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.dealerreceptionno}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.assocfirmname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.assocfirmcontactname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.assocfirmcontactno}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.assocfirmcontactemail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.assocexecname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.assocexeccontactno}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.assocexecemail}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.auditexecutivelandlineno}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.cityname}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.statename}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                {dealerGroup?.zonename}
                              </td>
                            </tr>
                            {/* {filteredData.map((item) => ( */}
                            {/* <div key={item._id}> */}
                            {/* Render item details */}
                            <DropDown
                              selector={`#dropdown-${_id}`}
                              placement="bottom"
                              className={styles.dropdown}
                            >
                              <DropDown.Item>
                                <button
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    navigate(
                                      `/dealer3/${dealerGroup.dealerGroupCode}/${level0Id}/${_id}`
                                    );
                                  }}
                                >
                                  <i className="bx-link-external"></i>
                                  <span className="ml-2">Dealer Setup</span>
                                </button>
                              </DropDown.Item>
                              <DropDown.Item
                                onClick={() =>
                                  handleEdit({ _id, ...dealerGroup })
                                }
                              >
                                <button className="flex justify-center items-center">
                                  <i className="bi bi-pencil-square"></i>
                                  <span className="ml-2">Update</span>
                                </button>
                              </DropDown.Item>

                              <DropDown.Item
                                onClick={() => {
                                  console.log("thisisid", _id);
                                  handleDelete(_id,dealerGroup.dealerGroupCode);
                                }}
                              >
                                {/* <button className="flex justify-center items-center"> */}
                                <i className="bx-trash"></i>
                                <span className="ml-2">Delete</span>
                                {/* </button> */}
                              </DropDown.Item>
                            </DropDown>
                            {/* </div> */}
                            {/* ))} */}
                          </Fragment>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>

            {/* <div
              className={`bg-white dark:bg-slate-900 ${
                darkMode ? styles.form2 : styles.form
              }`}
            >
              <div className="flex justify-center mt-8"> */}
            {/* <div>
                          Page {page} of {totalPages}
                        </div> */}
            {/* <div className="flex justify-between mt-8 px-3">
                  <div className="flex items-center gap-1">
                    {paginationButtons}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="max-w-screen-xl mx-auto px-4 md:px-8"></div>
        </div>
        {/*modal*/}
      </div>
      <div></div>
      {showModal && (
        <div
          id="dealer-group-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="flex absolute justify-center items-center z-[999] w-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full my-auto"
        >
          <div className="relative w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-[#1e293b] border border-gray-400">
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="dealer-group-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {/* Modal header */}
              <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                  {editMode ? "Edit Dealer Group" : "Add Dealer Group"}
                </h3>
              </div>
              {/* Modal body */}
              <div className="p-6">
                <form onSubmit={handleSubmit}>
                  <div className="col-span-12">
                    <label
                      htmlFor="dealergroupname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Dealer Group Code <span className="text-red-500 "> *</span>
                    </label>
                    <input
                      type="text"
                      id="dealerGroupCode"
                      required
                      name="dealerGroupCode"
                      value={formData.dealerGroupCode}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="dealergroupname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Map To OEM <span className="text-red-500 "> *</span>
                    </label>
                    <input
                      type="text"
                      id="mapToOEM"
                      name="mapToOEM"
                      value={formData.mapToOEM}
                      onChange={handleInputChange}
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="dealergroupname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Dealer Group Name <span className="text-red-500 "> *</span>
                    </label>
                    <input
                      type="text"
                      id="dealergroupname"
                      name="dealergroupname"
                      value={formData.dealergroupname}
                      onChange={handleInputChange}
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="gstno"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      GST Number
                    </label>
                    <input
                      type="text"
                      id="gstno"
                      name="gstno"
                      value={formData.gstno}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="gmname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      GM Name
                    </label>
                    <input
                      type="text"
                      id="gmname"
                      name="gmname"
                      value={formData.gmname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="gmphone"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      GM Phone
                    </label>
                    <input
                      type="text"
                      id="gmphone"
                      name="gmphone"
                      value={formData.gmphone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="gmemail"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      GM Email
                    </label>
                    <input
                      type="text"
                      id="gmemail"
                      name="gmemail"
                      value={formData.gmemail}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="ownername"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Owner Name
                    </label>
                    <input
                      type="text"
                      id="ownername"
                      name="ownername"
                      value={formData.ownername}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="ownerphone"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Owner Phone
                    </label>
                    <input
                      type="text"
                      id="ownerphone"
                      name="ownerphone"
                      value={formData.ownerphone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="owneremail"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Owner Email
                    </label>
                    <input
                      type="text"
                      id="owneremail"
                      name="owneremail"
                      value={formData.owneremail}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="smname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      SM Name
                    </label>
                    <input
                      type="text"
                      id="smname"
                      name="smname"
                      value={formData.smname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="smphone"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      SM Phone
                    </label>
                    <input
                      type="text"
                      id="smphone"
                      name="smphone"
                      value={formData.smphone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="smemail"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      SM Email
                    </label>
                    <input
                      type="text"
                      id="smemail"
                      name="smemail"
                      value={formData.smemail}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="accname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Accountant Name
                    </label>
                    <input
                      type="text"
                      id="accname"
                      name="accname"
                      value={formData.accname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="accphone"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Accountant Phone
                    </label>
                    <input
                      type="text"
                      id="accphone"
                      name="accphone"
                      value={formData.accphone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="accemail"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Accountant Email
                    </label>
                    <input
                      type="text"
                      id="accemail"
                      name="accemail"
                      value={formData.accemail}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="edpname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      EDP Name
                    </label>
                    <input
                      type="text"
                      id="edpname"
                      name="edpname"
                      value={formData.edpname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="edpphone"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      EDP Phone
                    </label>
                    <input
                      type="text"
                      id="edpphone"
                      name="edpphone"
                      value={formData.edpphone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="edpemail"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      EDP Email
                    </label>
                    <input
                      type="text"
                      id="edpemail"
                      name="edpemail"
                      value={formData.edpemail}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="tsmname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      TSM Name
                    </label>
                    <input
                      type="text"
                      id="tsmname"
                      name="tsmname"
                      value={formData.tsmname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="tsmphone"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      TSM Phone
                    </label>
                    <input
                      type="text"
                      id="tsmphone"
                      name="tsmphone"
                      value={formData.tsmphone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="tsmemail"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      TSM Email
                    </label>
                    <input
                      type="text"
                      id="tsmemail"
                      name="tsmemail"
                      value={formData.tsmemail}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="dealerreceptionno"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Dealer Reception No
                    </label>
                    <input
                      type="text"
                      id="dealerreceptionno"
                      name="dealerreceptionno"
                      value={formData.dealerreceptionno}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="assocfirmname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Assoc Firm Name
                    </label>
                    <input
                      type="text"
                      id="assocfirmname"
                      name="assocfirmname"
                      value={formData.assocfirmname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="assocfirmcontactname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Assoc Firm Contact Name
                    </label>
                    <input
                      type="text"
                      id="assocfirmcontactname"
                      name="assocfirmcontactname"
                      value={formData.assocfirmcontactname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="assocfirmcontactno"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Assoc Firm Contact No
                    </label>
                    <input
                      type="text"
                      id="assocfirmcontactno"
                      name="assocfirmcontactno"
                      value={formData.assocfirmcontactno}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="assocfirmcontactemail"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Assoc Firm Contact Email
                    </label>
                    <input
                      type="text"
                      id="assocfirmcontactemail"
                      name="assocfirmcontactemail"
                      value={formData.assocfirmcontactemail}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="assocexecname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Assoc Exec Name
                    </label>
                    <input
                      type="text"
                      id="assocexecname"
                      name="assocexecname"
                      value={formData.assocexecname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="assocexeccontactno"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Assoc Exec Contact No
                    </label>
                    <input
                      type="text"
                      id="assocexeccontactno"
                      name="assocexeccontactno"
                      value={formData.assocexeccontactno}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="assocexecemail"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Assoc Exec Email
                    </label>
                    <input
                      type="text"
                      id="assocexecemail"
                      name="assocexecemail"
                      value={formData.assocexecemail}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="auditexecutivelandlineno"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Audit Executive Landline No
                    </label>
                    <input
                      type="text"
                      id="auditexecutivelandlineno"
                      name="auditexecutivelandlineno"
                      value={formData.auditexecutivelandlineno}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="cityname"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      City Name
                    </label>
                    <input
                      type="text"
                      id="cityname"
                      name="cityname"
                      value={formData.cityname}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="statename"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      State Name
                    </label>
                    <input
                      type="text"
                      id="statename"
                      name="statename"
                      value={formData.statename}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor="zonename"
                      className="block text-sm font-medium text-gray-700 dark:text-white"
                    >
                      Zone Name
                    </label>
                    <input
                      type="text"
                      id="zonename"
                      name="zonename"
                      value={formData.zonename}
                      onChange={handleInputChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 dark:bg-transparent focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <button
                    onClick={editMode ? handleUpdate : handleCreate}
                    type="submit"
                    className="mt-4 text-white flex justify-center items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-md text-sm px-3 py-2 text-center"
                  >
                    <i className="bx bx-plus text-xl"></i>
                    <span className="hidden xs:block ml-2">
                      {editMode ? "Update Dealer Group" : "Create Dealer Group"}
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DealerGroup;
