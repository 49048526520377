import { axios } from "./index";
import { PostResponse, Form, formUrl, postResponseUrl,PreForm,PreFormUrl,PostForm,PostFormUrl } from "./config";
import {
  FormDetail,
  FormResponse,
  FormData,
  FormResponses,
  PageMeta,
} from "types/Form";

const getPostFormById = (formId: string) => {
  return axios<{ creatorId: string } & FormDetail>({
    method: "get",
    url: `${PostFormUrl}/${formId}`,
  });
};

const sendResponse = (data: FormResponse) => {
  return axios({
    method: "post",
    url: PostResponse.submitResponse,
    data,
  });
};

const getAllPostForms = (params?: any) => {
  return axios<{
    list: FormData[];
    pageMeta: PageMeta;
  }>({
    method: "get",
    url: PostForm.getAllForms,
    params,
  });
};

const createPostForm = (data: { templateId?: string, auditId?: string } = {}) => {
  return axios<{
    _id: string;
    title: string;
    projectId: string | undefined;
    auditId: string | undefined;
    updatedAt: string;
    createdAt: string;
  }>({
    method: "post",
    url: PostForm.createForm,
    data,
  });
};

const deletePostFormById = (formId: string) => {
  return axios<{ formId: string; message: string }>({
    method: "delete",
    url: `${PostFormUrl}/${formId}`,
  });
};

const updatePostFormById = ({
  formId,
  data,
}: {
  formId: string;
  data: FormDetail;
}) => {
  return axios<{ formId: string; message: string }>({
    method: "put",
    url: `${PostFormUrl}/${formId}`,
    data,
  });
};

export const checkPostResponseStatus = (formId: string) => {
  return axios<{ status: boolean }>({
    method: "get",
    url: `${PostResponse.checkStatus}/${formId}`,
  });
};

export const getPostFormResponsesById = (formId: string) => {
  return axios<FormResponses>({
    method: "get",
    url: `${postResponseUrl}/${formId}`,
  });
};

export {
  getPostFormById,
  sendResponse,
  getAllPostForms,
  createPostForm,
  deletePostFormById,
  updatePostFormById,
};
