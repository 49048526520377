import { API } from "../config.js";
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;


export const addDataToModel = (data) => {
  console.log(data);
  return fetch(`${BASE_URL}/api/upload`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(data), // Convert data to JSON string
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const readData = () => {
  return fetch(`${BASE_URL}/api/upload`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteData = () => {
  return fetch(`${BASE_URL}/api/upload`, {
    method: "DELETE",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
